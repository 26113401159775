import { FC, PropsWithChildren, useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import * as AuthApi from '../api/auth';
import { AuthenticationContext } from '../contexts/authentication';

interface RequireUnauthenticatedProps extends PropsWithChildren {
  forceLogout?: boolean;
}

const RequireUnauthenticated: FC<RequireUnauthenticatedProps> = ({ forceLogout, children }) => {
  const { signOut, me } = useContext(AuthenticationContext);

  useEffect(() => {
    if (forceLogout) {
      signOut(false);
    }
  }, [signOut, forceLogout]);

  if (me && !forceLogout) {
    return <Navigate to={AuthApi.fetchLoginRedirect(me)} replace />;
  }

  return <>{children}</>;
};

export default RequireUnauthenticated;
