import { useState, useEffect, FC } from 'react';
import { Bar } from 'react-chartjs-2';

import AssessmentIcon from '@mui/icons-material/Assessment';

import { ChartPaper } from '../../../components';
import { GroupedDataSet, DateCount } from '../../../types';
import { ViewMode, chartColors, extractSortedLabels } from '../../../util';
import { intl } from '../../../Internationalization';

interface SubmissionCountsChartProps {
  submissionCounts: GroupedDataSet<string, DateCount>[];
  viewMode: ViewMode;
}

export const populateSubmissionCountsData = (
  submissionCounts: GroupedDataSet<string, DateCount>[]
) => {
  const colorScheme = chartColors(submissionCounts.length);
  const datasets = submissionCounts.map((assignment, index) => ({
    backgroundColor: colorScheme[index],
    label: assignment.group,
    maxBarThickness: 30,
    borderWidth: 1,
    data: assignment.data,
  }));

  return {
    labels: extractSortedLabels(datasets),
    datasets,
  };
};

const SubmissionCountsChart: FC<SubmissionCountsChartProps> = ({ submissionCounts, viewMode }) => {
  const [submissionCountsData, setSubmissionCountsData] = useState(
    populateSubmissionCountsData(submissionCounts)
  );
  useEffect(() => {
    setSubmissionCountsData(populateSubmissionCountsData(submissionCounts));
  }, [submissionCounts]);

  return (
    <ChartPaper
      icon={AssessmentIcon}
      iconColor="#66bb6a"
      title={intl.formatMessage({
        id: 'dashboard.specification.submissionCountChart.title',
        defaultMessage: 'Submissions by day',
      })}
      columnMode={viewMode === 'COLUMN'}
    >
      <Bar
        data={submissionCountsData}
        options={{
          maintainAspectRatio: viewMode === 'COLUMN' ? false : true,
          elements: {
            bar: {
              inflateAmount: 0.2,
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: intl.formatMessage({
                  id: 'dashboard.specification.submissionCountChart.xAxis.label',
                  defaultMessage: 'Date',
                }),
              },
              ticks: {
                autoSkipPadding: 1,
              },
              bounds: 'ticks',
              offset: true,
              type: 'time',
              time: {
                unit: 'day',
                displayFormats: {
                  day: 'yyyy-MM-dd',
                },
                tooltipFormat: 'yyyy-MM-dd',
                stepSize: 1,
              },
            },
            y: {
              title: {
                display: true,
                text: intl.formatMessage({
                  id: 'dashboard.specification.submissionCountChart.yAxis.label',
                  defaultMessage: 'Submissions',
                }),
              },
              min: 0,
              ticks: {
                autoSkip: false,
              },
            },
          },
        }}
      />
    </ChartPaper>
  );
};

export default SubmissionCountsChart;
