import { FC, PropsWithChildren } from 'react';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { Field } from '../../../types/system';
import { intl } from '../../../Internationalization';

import Highlighter from './Highlighter';

export interface FieldHighlighterProps extends PropsWithChildren {
  field?: Field;
}

const FieldHighlighter: FC<FieldHighlighterProps> = ({ children, field }) => (
  <Highlighter
    sx={
      field
        ? {
            color: (theme) => theme.palette.getContrastText(theme.palette.success.light),
            backgroundColor: (theme) => theme.palette.success.light,
            borderColor: (theme) => theme.palette.success.dark,
          }
        : {
            color: (theme) => theme.palette.getContrastText(theme.palette.warning.light),
            backgroundColor: (theme) => theme.palette.warning.light,
            borderColor: (theme) => theme.palette.warning.dark,
          }
    }
    icon={field ? field.icon : AttachMoneyIcon}
    label={
      field
        ? field.label
        : intl.formatMessage({
            id: 'components.editor.fieldHighlighter.customField',
            defaultMessage: 'Custom expression',
          })
    }
  >
    {children}
  </Highlighter>
);

export default FieldHighlighter;
