import { AXIOS, wrapUniquePromise } from './endpoints';

export function receiverMappingByGroup(
  identityProviderKey: string,
  group: string
): Promise<boolean> {
  return wrapUniquePromise(
    AXIOS.head('/receiverMappingByGroup', { params: { identityProviderKey, group } })
  );
}
