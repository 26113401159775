import React, { FC, PropsWithChildren } from 'react';

import { Theme, Popover, MenuItem, SvgIconProps, Box, SxProps } from '@mui/material';

import { SmallListItemIcon } from '../../';

export interface HighlighterProps extends PropsWithChildren {
  icon: React.ComponentType<SvgIconProps>;
  label: string;
  sx?: SxProps<Theme>;
}

const Highlighter: FC<HighlighterProps> = ({ sx = [], icon: Icon, label, children }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      component="span"
      sx={[
        {
          borderStyle: 'dotted',
          borderWidth: '1px',
          borderRadius: 1,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      {children}
      <Popover
        sx={{
          'pointerEvents': 'none',
          '& > .MuiPopover-paper': {
            p: 1,
          },
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableScrollLock
      >
        <MenuItem className="Highlighter-Tooltip">
          <SmallListItemIcon>
            <Icon fontSize="small" />
          </SmallListItemIcon>
          {label}
        </MenuItem>
      </Popover>
    </Box>
  );
};

export default Highlighter;
