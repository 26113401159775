import { FC } from 'react';
import { Grid } from '@mui/material';

import {
  SubmissionDetail,
  ProjectMetadata,
  AssignmentMetadata,
  SpecificationMetadata,
} from '../../../types';
import SubmissionResultDetails from './SubmissionResultDetails';
import { SubmissionErrorMessage } from './SubmissionErrorMessage';

interface SubmissionContentProps {
  submissionUpdated: React.Dispatch<React.SetStateAction<SubmissionDetail | undefined>>;
  submission: SubmissionDetail;
  assignment: AssignmentMetadata;
  project: ProjectMetadata;
  specification: SpecificationMetadata;
}

const SubmissionContent: FC<SubmissionContentProps> = ({
  submissionUpdated,
  submission,
  assignment,
  specification,
  project,
}) => (
  <Grid
    container
    justifyContent="center"
    alignItems="stretch"
    spacing={3}
    className="SubmissionContent-root"
  >
    {submission.errorState && (
      <Grid item xs={12}>
        <SubmissionErrorMessage submission={submission} />
      </Grid>
    )}
    <SubmissionResultDetails
      submissionUpdated={submissionUpdated}
      specification={specification}
      assignment={assignment}
      project={project}
      submission={submission}
    />
  </Grid>
);

export default SubmissionContent;
