import { MediaDetail } from '../types';
import { generateApiUri } from './endpoints';

export const downloadSpecificationDocumentUri = (
  specificationKey: string,
  file: MediaDetail
): string => {
  return generateApiUri(`/specifications/${specificationKey}/documents/${file.storedFilename}`, {
    params: { download: file.filename },
  });
};
