import { Grid } from '@mui/material';

import { ProjectActivity, DailyActivity } from '../../../types';
import ProjectActivityChart from './ProjectActivityChart';
import DailyActivityChart from './DailyActivityChart';
import { VIEW_MODE_METADATA, ViewMode } from '../../../util';
import { FC } from 'react';

interface GlobalChartsProps {
  projectActivity: ProjectActivity[];
  dailyActivity: DailyActivity[];
  viewMode: ViewMode;
}

const GlobalCharts: FC<GlobalChartsProps> = ({ projectActivity, dailyActivity, viewMode }) => {
  const breakPoints = VIEW_MODE_METADATA[viewMode];
  return (
    <Grid container spacing={3}>
      <Grid item {...breakPoints} mt={2}>
        <ProjectActivityChart projectActivity={projectActivity} viewMode={viewMode} />
      </Grid>
      <Grid item {...breakPoints} mt={2}>
        <DailyActivityChart dailyActivity={dailyActivity} viewMode={viewMode} />
      </Grid>
    </Grid>
  );
};

export default GlobalCharts;
