import { FC, useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { Container, Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

import { AuthenticatedContext } from '../../contexts/authentication';
import {
  AppDecorator,
  AccountHeader,
  AppTabs,
  HeaderBreadcrumb,
  RouterTabs,
  RouterTab,
} from '../../components';
import { ErrorBlock } from '../../contexts/error-block';
import { intl } from '../../Internationalization';
import { useRouterTab } from '../../hooks';

import MyProfile from './MyProfile';
import ChangePassword from './ChangePassword';
import MyAvatar from './MyAvatar';

const MyAccountRouting: FC = () => {
  const { me } = useContext(AuthenticatedContext);
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="my-account-tabs" value={routerTab}>
      <RouterTab
        id="profile-tab"
        value="profile"
        label={intl.formatMessage({
          id: 'myAccount.routing.tabs.profile',
          defaultMessage: 'Profile',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="password-tab"
        value="password"
        label={intl.formatMessage({
          id: 'myAccount.routing.tabs.password',
          defaultMessage: 'Password',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="avatar-tab"
        value="avatar"
        label={intl.formatMessage({
          id: 'myAccount.routing.tabs.avatar',
          defaultMessage: 'Avatar',
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  const renderBreadcrumb = () => {
    const { email } = me;

    return (
      <HeaderBreadcrumb>
        <Box display="flex" alignItems="center">
          <PersonIcon
            titleAccess={intl.formatMessage({
              id: 'screens.myAccountRouting.user.titleAccess',
              defaultMessage: 'User',
            })}
          />
          <Typography color="inherit">{email}</Typography>
        </Box>
      </HeaderBreadcrumb>
    );
  };

  return (
    <AppDecorator
      title={intl.formatMessage({ id: 'myAccount.routing.title', defaultMessage: 'My Account' })}
      renderTabs={renderTabs}
      renderBreadcrumb={renderBreadcrumb}
    >
      <AccountHeader user={me} />
      <AppTabs />
      <ErrorBlock>
        <Box p={3}>
          <Container maxWidth="md" disableGutters>
            <Routes>
              <Route path="profile" element={<MyProfile />} />
              <Route path="password" element={<ChangePassword />} />
              <Route path="avatar" element={<MyAvatar />} />
              <Route path="*" element={<Navigate to="profile" replace />} />
            </Routes>
          </Container>
        </Box>
      </ErrorBlock>
    </AppDecorator>
  );
};

export default MyAccountRouting;
