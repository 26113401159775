import { AxiosPromise } from 'axios';
import Schema from 'async-validator';

import { AXIOS } from './endpoints';
import { OneIntegrateServerSettings } from '../types';
import { baseUriValidator } from '../util';
import { intl } from '../Internationalization';

export function getServerSettings(): AxiosPromise<OneIntegrateServerSettings> {
  return AXIOS.get('/1integrate/server');
}

export function updateServerSettings(
  settings: OneIntegrateServerSettings
): AxiosPromise<OneIntegrateServerSettings> {
  return AXIOS.post('/1integrate/server', settings);
}

export const ONE_INTEGRATE_SERVER_SETTINGS_VALIDATOR = new Schema({
  baseUri: [
    {
      type: 'string',
      required: true,
      message: intl.formatMessage({
        id: 'oneIntegrate.validator.baseUri.required',
        defaultMessage: 'Please provide the server base URI',
      }),
    },
    baseUriValidator,
  ],
  password: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'oneIntegrate.validator.password.required',
      defaultMessage: 'Please provide the server password',
    }),
  },
  username: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'oneIntegrate.validator.username.required',
      defaultMessage: 'Please provide the server username',
    }),
  },
});
