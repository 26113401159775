import { FC } from 'react';
import { Text } from '@react-pdf/renderer';

import { PdfDocumentLayout, sharedPdfStyles } from '../../../../components';
import {
  MediaDetail,
  SchemaReport,
  SubmissionDetail,
  ValidateSchemaTaskResult,
} from '../../../../types';
import { intl } from '../../../../Internationalization';

import PdfSubmissionSummary from './shared/PdfSubmissionSummary';
import {
  TaskSummary,
  MissingClasses,
  InvalidClasses,
  ExcessClasses,
} from './validation-report-pdf';

interface SchemaValidationReportPdfProps {
  submission: SubmissionDetail;
  schemaReport: SchemaReport;
  task: ValidateSchemaTaskResult;
  logo?: MediaDetail;
}

const SchemaValidationReportPdf: FC<SchemaValidationReportPdfProps> = ({
  submission,
  schemaReport,
  task,
  logo,
}) => {
  const title = intl.formatMessage({
    id: 'submission.validateSchemaPdf.title',
    defaultMessage: 'Schema Validation Report',
  });
  return (
    <PdfDocumentLayout documentTitle={title} logo={logo}>
      <Text style={sharedPdfStyles.title}>{title}</Text>
      <PdfSubmissionSummary submission={submission} />
      <TaskSummary schemaReport={schemaReport} task={task} />
      <MissingClasses missingClasses={schemaReport.missingClasses} />
      <ExcessClasses excessClasses={schemaReport.excessClasses} />
      <InvalidClasses attributeMismatches={schemaReport.attributeMismatches} />
    </PdfDocumentLayout>
  );
};

export default SchemaValidationReportPdf;
