import React from 'react';

import {
  DataStoreConfigDetail,
  SavedMappingDetail,
  SavedMappingInputDetail,
  SessionSchema,
} from '../../../../../types';

import { MyAssignmentContextValue } from '../../MyAssignmentContext';

interface SavedMappingContextValue extends MyAssignmentContextValue {
  savedMapping: SavedMappingDetail;
  updateSavedMapping: (savedMapping: SavedMappingDetail) => void;
  updateSavedMappingInput: (dataStorePath: string, input?: SavedMappingInputDetail) => void;
  dataStoreConfigs: DataStoreConfigDetail[];
  sessionSchema: SessionSchema;
  specificationValid: boolean;
}

const SavedMappingContextDefaultValue = {} as SavedMappingContextValue;
export const SavedMappingContext = React.createContext(SavedMappingContextDefaultValue);
