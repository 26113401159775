import { FC, PropsWithChildren } from 'react';
import { View } from '@react-pdf/renderer';

import { resultsPdfStyles } from '../../../../../components';

interface PdfSectionHeaderProps extends PropsWithChildren {
  gutterBottom?: boolean;
}

const PdfSectionHeader: FC<PdfSectionHeaderProps> = ({ children }) => (
  <View style={[resultsPdfStyles.rowContainer, resultsPdfStyles.contentPadding]} wrap={false}>
    {children}
  </View>
);

export default PdfSectionHeader;
