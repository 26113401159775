import { FC } from 'react';
import { Box, BoxProps, styled } from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  '& button, & a': {
    'margin': theme.spacing(0, 1),
    '&:last-child': {
      marginRight: 0,
    },
    '&:first-child': {
      marginLeft: 0,
    },
  },
}));

const ButtonRow: FC<BoxProps> = ({ children, ...rest }) => (
  <StyledBox {...rest}>{children}</StyledBox>
);

export default ButtonRow;
