import { FC, PropsWithChildren } from 'react';

import { Grid } from '@mui/material';

const CardGrid: FC<PropsWithChildren> = ({ children }) => (
  <Grid container alignContent="stretch" justifyContent="flex-start" spacing={3}>
    {children}
  </Grid>
);

export default CardGrid;
