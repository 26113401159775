import { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, Route, Routes, Navigate } from 'react-router-dom';

import { Box, Typography, Link } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import {
  AppDecorator,
  AppTabs,
  HeaderBreadcrumb,
  RouterTabs,
  RouterTab,
} from '../../../../components';
import { intl } from '../../../../Internationalization';
import { useRouterTab } from '../../../../hooks';

import SupplierMappings from '../SupplierMappings';
import ReceiverMappings from '../ReceiverMappings';

import Saml2IdentityProviderSettings from './Saml2IdentityProviderSettings';
import Saml2IdentityProviderServiceSettings from './Saml2IdentityProviderServiceSettings';
import Saml2IdentityProviderServiceSigning from './Saml2IdentityProviderServiceSigning';
import Saml2IdentityProviderSigning from './Saml2IdentityProviderSigning';
import { Saml2IdentityProviderContext } from './Saml2IdentityProviderContext';

const Saml2IdentityProviderRouting: FC = () => {
  const { identityProvider } = useContext(Saml2IdentityProviderContext);
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="saml2-tabs" value={routerTab}>
      <RouterTab
        id="identity-provider-settings-tab"
        value="identity_provider_settings"
        label={intl.formatMessage({
          id: 'saml2.routing.tabs.identityProviderSettings',
          defaultMessage: 'Identity Provider Settings',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="service-provider-settings-tab"
        value="service_provider_settings"
        label={intl.formatMessage({
          id: 'saml2.routing.tabs.serviceProviderSettings',
          defaultMessage: 'Service Provider Settings',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="identity-provider-signing-tab"
        value="identity_provider_signing"
        label={intl.formatMessage({
          id: 'saml2.routing.tabs.identityProviderSigning',
          defaultMessage: 'Identity Provider Signing',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="service-provider-signing-tab"
        value="service_provider_signing"
        label={intl.formatMessage({
          id: 'saml2.routing.tabs.serviceProviderSigning',
          defaultMessage: 'Service Provider Signing',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      {identityProvider.mapSuppliersByGroup && (
        <RouterTab
          id="supplier-mapping-tab"
          value="supplier_mappings"
          label={intl.formatMessage({
            id: 'saml2.routing.tabs.supplierMappings',
            defaultMessage: 'Supplier Mappings',
          })}
          resolveTabRoute={resolveTabRoute}
        />
      )}
      {identityProvider.mapReceiverByGroup && (
        <RouterTab
          id="receiver-mapping-tab"
          value="receiver_mappings"
          label={intl.formatMessage({
            id: 'saml2.routing.tabs.receiverMappings',
            defaultMessage: 'Receiver Mappings',
          })}
          resolveTabRoute={resolveTabRoute}
        />
      )}
    </RouterTabs>
  );

  const renderBreadcrumb = () => (
    <HeaderBreadcrumb>
      <Link component={RouterLink} to="/system/identity_providers">
        <VpnKeyIcon />
        <FormattedMessage id="saml2.routing.breadcrumb" defaultMessage="Identity Providers" />
      </Link>
      <Typography>
        <LockOpenIcon
          titleAccess={intl.formatMessage({
            id: 'saml2.routing.breadcrumb.identityProvider.titleAccess',
            defaultMessage: 'Identity Provider',
          })}
        />
        {identityProvider.name}
      </Typography>
    </HeaderBreadcrumb>
  );

  return (
    <AppDecorator renderTabs={renderTabs} renderBreadcrumb={renderBreadcrumb}>
      <AppTabs />
      <Box p={3}>
        <Routes>
          <Route path="identity_provider_settings" element={<Saml2IdentityProviderSettings />} />
          <Route
            path="service_provider_settings"
            element={<Saml2IdentityProviderServiceSettings />}
          />
          <Route path="identity_provider_signing" element={<Saml2IdentityProviderSigning />} />
          <Route
            path="service_provider_signing"
            element={<Saml2IdentityProviderServiceSigning />}
          />
          {identityProvider.mapSuppliersByGroup && (
            <Route
              path="supplier_mappings"
              element={<SupplierMappings identityProviderKey={identityProvider.key} />}
            />
          )}
          {identityProvider.mapReceiverByGroup && (
            <Route
              path="receiver_mappings"
              element={<ReceiverMappings identityProviderKey={identityProvider.key} />}
            />
          )}
          <Route path="*" element={<Navigate to="identity_provider_settings" replace />} />
        </Routes>
      </Box>
    </AppDecorator>
  );
};

export default Saml2IdentityProviderRouting;
