import { FC, useContext } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { SimpleStepper, SimpleStep } from '../../../../../components';
import { intl } from '../../../../../Internationalization';
import { SubmissionState } from '../../../../../types';

import InputUpload from './upload/InputUpload';
import SchemaMapping from './schema-mapping/SchemaMapping';
import SubmissionProcess from './process/SubmissionProcess';
import SubmissionResults from './results/SubmissionResults';
import SubmissionError from './error/SubmissionError';

import { OpenSubmissionContext } from './OpenSubmissionContext';

const STEPS: SimpleStep[] = [
  {
    icon: CloudUploadIcon,
    label: intl.formatMessage({
      id: 'openSubmission.stepper.uploadData',
      defaultMessage: 'Upload Data',
    }),
    renderStep: () => <InputUpload />,
  },
  {
    icon: SyncAltIcon,
    label: intl.formatMessage({
      id: 'openSubmission.stepper.configureMapping',
      defaultMessage: 'Configure Mapping',
    }),
    renderStep: () => <SchemaMapping />,
  },
  {
    icon: PlayArrowIcon,
    label: intl.formatMessage({
      id: 'openSubmission.stepper.processSubmission',
      defaultMessage: 'Process Submission',
    }),
    renderStep: () => <SubmissionProcess />,
  },
  {
    icon: CloudDownloadIcon,
    label: intl.formatMessage({
      id: 'openSubmission.stepper.submissionResults',
      defaultMessage: 'Submission Results',
    }),
    renderStep: () => <SubmissionResults />,
  },
];

const OpenSubmissionStepper: FC = () => {
  const { submission, specificationValid } = useContext(OpenSubmissionContext);

  const renderError = () => <SubmissionError />;

  const calculateSubmissionStateErrorStep = (errorState?: SubmissionState) => {
    if (errorState) {
      return calculateSubmissionStateStep(errorState);
    }
    return undefined;
  };

  const calculateSubmissionStateStep = (submissionState: SubmissionState) => {
    switch (submissionState) {
      case SubmissionState.NOT_STARTED:
      case SubmissionState.UPLOAD:
      case SubmissionState.UPLOADING:
        return 0;
      case SubmissionState.READY:
        return 1;
      case SubmissionState.RUNNING:
      case SubmissionState.PAUSED:
      case SubmissionState.DOWNLOAD:
      case SubmissionState.DOWNLOADING:
      case SubmissionState.PROCESSED:
        return 2;
      case SubmissionState.FINISH:
      case SubmissionState.FINISHING:
      case SubmissionState.FINISHED:
        return 3;
    }
  };

  const activeStep = calculateSubmissionStateStep(submission.state);
  const errorStep = calculateSubmissionStateErrorStep(
    !specificationValid ? submission.state : submission.errorState
  );

  return (
    <SimpleStepper
      activeStep={activeStep}
      steps={STEPS}
      errorStep={errorStep}
      renderError={renderError}
    />
  );
};

export default OpenSubmissionStepper;
