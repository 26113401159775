import { Grid } from '@mui/material';

import { FilesListSkeleton, TextEditorSkeleton } from '../../../../components';

const SpecificationInformationSkeleton = () => (
  <Grid container alignContent="stretch" justifyContent="flex-start" spacing={3}>
    <Grid item sm={12} md={6}>
      <TextEditorSkeleton />
    </Grid>
    <Grid item sm={12} md={6}>
      <FilesListSkeleton />
    </Grid>
  </Grid>
);

export default SpecificationInformationSkeleton;
