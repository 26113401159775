import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import isEqual from 'lodash/isEqual';

import { Table, TableHead, TableRow, TableCell, TableBody, Box } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { extractErrorMessage } from '../../../../../../api/endpoints';
import * as SpecificationApi from '../../../../../../api/specification';
import {
  TableLoadingRow,
  DefaultButton,
  FormButtons,
  MessageBox,
} from '../../../../../../components';
import { useErrorBlock } from '../../../../../../contexts/error-block';
import { useNavigationPrompt } from '../../../../../../contexts/navigation-prompt';
import { TaskConfig, SessionSchema } from '../../../../../../types';
import { intl } from '../../../../../../Internationalization';
import { usePrevious } from '../../../../../../hooks';

import { SpecificationContext } from '../../SpecificationContext';
import TaskConfigTableBody from './TaskConfigTableBody';
import NoSessionMessage from '../NoSessionMessage';

interface TaskConfigEditorProps {
  sessionSchema: SessionSchema;
}

const TaskConfigEditor: FC<TaskConfigEditorProps> = ({ sessionSchema }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { raiseError } = useErrorBlock();
  const { raiseNavigationBlock, clearNavigationBlock } = useNavigationPrompt();
  const {
    projectKey,
    specificationKey,
    specification,
    specificationValidationResult,
    validateSpecification,
  } = useContext(SpecificationContext);

  const [taskConfigs, setTaskConfigs] = useState<TaskConfig[]>();
  const [savingConfig, setSavingConfig] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);

  const prevSessionSchema = usePrevious(sessionSchema);

  const fetchConfig = useCallback(async () => {
    try {
      setFetching(true);
      const response = await SpecificationApi.getTaskConfigs(specificationKey);
      setTaskConfigs(response.data);
      setFetching(false);

      validateSpecification();
    } catch (error: any) {
      raiseError(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.configuration.taskConfig.loadError',
            defaultMessage: 'Failed to fetch specification tasks',
          })
        )
      );
    }
  }, [raiseError, specificationKey, validateSpecification]);

  useEffect(() => {
    if (!isEqual(prevSessionSchema, sessionSchema)) {
      fetchConfig();
    }
  }, [fetchConfig, prevSessionSchema, sessionSchema]);

  const saveTasks = async () => {
    if (!taskConfigs) {
      return;
    }
    setSavingConfig(true);
    try {
      const response = await SpecificationApi.updateTaskConfigs(specificationKey, taskConfigs);
      setTaskConfigs(response.data);
      setSavingConfig(false);
      validateSpecification();
      clearNavigationBlock();
      enqueueSnackbar(
        intl.formatMessage({
          id: 'specification.configuration.taskConfig.saveSuccess',
          defaultMessage: 'Save successful',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      setSavingConfig(false);
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.configuration.taskConfig.saveError',
            defaultMessage: 'Failed to save task config',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const updateTaskConfig = (updatedTask: TaskConfig) => {
    raiseNavigationBlock();
    setTaskConfigs((prevTaskConfigs) => {
      const updatedTaskConfigs = [...(prevTaskConfigs || [])];

      const taskConfigIndex = updatedTaskConfigs.findIndex(
        (d) => d.taskIdentifier === updatedTask.taskIdentifier
      );
      updatedTaskConfigs[taskConfigIndex] = updatedTask;

      return updatedTaskConfigs;
    });
  };

  const tableContent = () => {
    if (taskConfigs) {
      return {
        className: 'TaskConfigEditor-loaded',
        content: (
          <TaskConfigTableBody
            taskConfigs={taskConfigs}
            sessionSchema={sessionSchema}
            updateTaskConfig={updateTaskConfig}
          />
        ),
      };
    }

    if (!specification.sessionPath && !fetching) {
      return {
        className: 'TaskConfigEditor-noContent',
        content: (
          <TableBody>
            <TableRow>
              <TableCell colSpan={3}>
                <NoSessionMessage projectKey={projectKey} specificationKey={specificationKey} />
              </TableCell>
            </TableRow>
          </TableBody>
        ),
      };
    }

    return {
      className: 'TaskConfigEditor-loading',
      content: (
        <TableBody>
          <TableLoadingRow colSpan={4} />
        </TableBody>
      ),
    };
  };

  const content = tableContent();
  return (
    <Box id="specification-task-editor">
      {!specificationValidationResult.tasksValid && (
        <MessageBox
          level="warning"
          gutterBottom
          message={intl.formatMessage({
            id: 'specification.configuration.taskConfig.tasksInvalid',
            defaultMessage:
              'Session changes detected, review and save the task configuration below.',
          })}
        />
      )}
      <Table size="small" className={content.className}>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="specification.configuration.taskConfig.table.taskColumn"
                defaultMessage="Task"
              />
            </TableCell>
            <TableCell colSpan={2}>
              <FormattedMessage
                id="specification.configuration.taskConfig.table.taskOptionsColumn"
                defaultMessage="Task Options"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="specification.configuration.taskConfig.table.passThresholdColumn"
                defaultMessage="Pass Threshold (%)"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        {content.content}
      </Table>
      <FormButtons>
        <DefaultButton
          name="saveTaskConfig"
          startIcon={<SaveIcon />}
          onClick={saveTasks}
          disabled={savingConfig}
        >
          <FormattedMessage
            id="specification.configuration.taskConfig.saveButton"
            defaultMessage="Save task config"
          />
        </DefaultButton>
      </FormButtons>
    </Box>
  );
};

export default TaskConfigEditor;
