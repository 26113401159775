import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';

interface SkeletonTabsProps {
  tabCount: number;
}

const TabsSkeleton = ({ tabCount }: SkeletonTabsProps) => {
  const renderSkeleTabs = () => {
    const tabs = [];
    let i = 0;
    while (i < tabCount) {
      tabs.push(
        <Skeleton key={i} sx={{ mt: 0.5, mr: 0.5 }} variant="rectangular" height={35} width={160} />
      );
      i++;
    }
    return tabs;
  };

  return <Box display="flex">{renderSkeleTabs()}</Box>;
};

export default TabsSkeleton;
