import React, { FC } from 'react';
import { MenuItem, TextField } from '@mui/material';

interface TriStateFilterProps {
  name?: string;
  label: string;
  value?: boolean;
  unsetLabel: string;
  trueLabel: string;
  falseLabel: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, value?: boolean) => void;
}

const extractTriStateBoolean = (value: string) => {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return undefined;
  }
};

const TriStateFilter: FC<TriStateFilterProps> = ({
  name,
  label,
  value,
  unsetLabel,
  trueLabel,
  falseLabel,
  onChange,
}) => (
  <TextField
    name={name}
    label={label}
    value={value === undefined ? '' : value ? 'true' : 'false'}
    select
    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e, extractTriStateBoolean(e.target.value));
    }}
    variant="standard"
  >
    <MenuItem value="">{unsetLabel}</MenuItem>
    <MenuItem value="true">{trueLabel}</MenuItem>
    <MenuItem value="false">{falseLabel}</MenuItem>
  </TextField>
);

export default TriStateFilter;
