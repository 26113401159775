import { AxiosPromise } from 'axios';
import Schema, { InternalRuleItem, Values } from 'async-validator';
import { AXIOS, FileUploadConfig } from './endpoints';

import {
  SubmissionDetail,
  MediaDetail,
  SubmissionSchema,
  DataStoreMapping,
  MappingRatios,
  SubmissionMapConfig,
  ObjectReportRequest,
  ObjectReport,
  ApplyMappingRequest,
  cssToMapLayerStyles,
  MapLayerStyles,
  SubmissionPatch,
  SchemaReport,
  SubmissionInputParameters,
  SubmissionDataSetInputParameters,
} from '../types';

import { createFormDataFromFile } from './media';
import { intl } from '../Internationalization';

export function getSubmission(submissionReference: string): AxiosPromise<SubmissionDetail> {
  return AXIOS.get(`/submissions/${submissionReference}`);
}

export function submissionExistsByReference(submissionReference: string): Promise<boolean> {
  return AXIOS.head(`/submissions/${submissionReference}`);
}

export function removeSubmissionMedia(submissionReference: string): AxiosPromise<SubmissionDetail> {
  return AXIOS.post(`/submissions/${submissionReference}/remove_media`);
}

export function patchSubmission(
  submissionReference: string,
  patch: SubmissionPatch
): AxiosPromise<SubmissionDetail> {
  return AXIOS.patch(`/submissions/${submissionReference}`, patch);
}

export function getSchema(submissionReference: string): AxiosPromise<SubmissionSchema> {
  return AXIOS.get(`/submissions/${submissionReference}/schema`);
}

export function updateSchemaMappings(
  submissionReference: string,
  schemaMap: DataStoreMapping[]
): AxiosPromise<DataStoreMapping[]> {
  return AXIOS.post(`/submissions/${submissionReference}/schema/mappings`, schemaMap);
}

export function evaluateMappings(submissionReference: string): AxiosPromise<MappingRatios> {
  return AXIOS.get(`/submissions/${submissionReference}/schema/mappings/evaluate`);
}

export function applyMapping(
  submissionReference: string,
  request: ApplyMappingRequest
): AxiosPromise<DataStoreMapping[]> {
  return AXIOS.post(`/submissions/${submissionReference}/schema/mappings/apply`, undefined, {
    params: request,
  });
}

export function uploadInput(
  submissionReference: string,
  file: File,
  dataStorePath: string,
  config: FileUploadConfig
): AxiosPromise<MediaDetail> {
  return AXIOS.post(`/submissions/${submissionReference}/inputs`, createFormDataFromFile(file), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: { dataStorePath },
    ...(config || {}),
  });
}

export function patchInputParameters(
  submissionReference: string,
  newParameterValues: Record<string, SubmissionDataSetInputParameters>
): AxiosPromise<SubmissionInputParameters> {
  return AXIOS.patch(`/submissions/${submissionReference}/input_parameters`, newParameterValues);
}

export function removeInput(
  submissionReference: string,
  dataStorePath: string
): AxiosPromise<void> {
  return AXIOS.delete(`/submissions/${submissionReference}/inputs`, { params: { dataStorePath } });
}

export function start(submissionReference: string): AxiosPromise<SubmissionDetail> {
  return AXIOS.post(`/submissions/${submissionReference}/start`);
}

export function run(submissionReference: string): AxiosPromise<SubmissionDetail> {
  return AXIOS.post(`/submissions/${submissionReference}/run`);
}

export function finish(submissionReference: string): AxiosPromise<SubmissionDetail> {
  return AXIOS.post(`/submissions/${submissionReference}/finish`);
}

export function cancel(submissionReference: string): AxiosPromise<SubmissionDetail> {
  return AXIOS.post(`/submissions/${submissionReference}/cancel`);
}

export function close(submissionReference: string): AxiosPromise<SubmissionDetail> {
  return AXIOS.post(`/submissions/${submissionReference}/close`);
}

export function report(
  submissionReference: string,
  request: ObjectReportRequest
): AxiosPromise<ObjectReport[]> {
  return AXIOS.get(`/submissions/${submissionReference}/report`, { params: request });
}

export function schemaReport(
  submissionReference: string,
  taskIdentifier: string
): AxiosPromise<SchemaReport> {
  return AXIOS.get(`/submissions/${submissionReference}/schema_report`, {
    params: {
      taskIdentifier,
    },
  });
}

export function mapConfig(submissionReference: string): AxiosPromise<SubmissionMapConfig> {
  return AXIOS.get(`/submissions/${submissionReference}/map_config`);
}

export function proxyWmsMap(submissionReference: string, currentTaskIdentifier: string) {
  return `/submissions/${submissionReference}/wms/${currentTaskIdentifier}/map`;
}

export function proxyWmsStyle(
  submissionReference: string,
  taskIdentifier: string,
  layers: string[]
): AxiosPromise<MapLayerStyles> {
  return AXIOS.get(`/submissions/${submissionReference}/wms/${taskIdentifier}/style`, {
    responseType: 'text',
    transformResponse: (data) => cssToMapLayerStyles(layers, data),
  });
}

export const OLTileLoader = (tile: any, src: any) => {
  AXIOS.get(src, {
    responseType: 'blob',
  }).then((response) => {
    const blob = response.data;
    const urlCreator = window.URL || (window as any).webkitURL;
    const imageUrl = urlCreator.createObjectURL(blob);
    tile.getImage().src = imageUrl;
  });
};

export const SUBMISSION_REFERENCE_VALIDATOR = new Schema({
  reference: [
    {
      type: 'string',
      required: true,
      len: 36,
      message: intl.formatMessage({
        id: 'submission.validator.reference.minLength',
        defaultMessage: 'Submission reference must be 36 characters',
      }),
    },
    {
      validator: (
        rule: InternalRuleItem,
        value: any,
        callback: (error?: string) => void,
        source: Values
      ) => {
        const { reference } = source;
        submissionExistsByReference(reference)
          .then(() => callback())
          .catch(() =>
            callback(
              intl.formatMessage({
                id: 'submission.validator.reference.exists',
                defaultMessage: 'No submission found with the reference provided',
              })
            )
          );
      },
    },
  ],
});
