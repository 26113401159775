import { useState, FC, useEffect, useCallback } from 'react';

import * as OneIntegrateServerSettingsApi from '../../../api/oneIntegrateServerSettings';
import { extractErrorMessage } from '../../../api/endpoints';
import { Loading } from '../../../components';
import { OneIntegrateServerSettings } from '../../../types';

import { useErrorBlock } from '../../../contexts/error-block';
import { intl } from '../../../Internationalization';

import { ServiceConfigurationContext } from './ServiceConfigurationContext';
import ServiceConfigurationRouting from './ServiceConfigurationRouting';

const ServiceConfiguration: FC = () => {
  const { raiseError } = useErrorBlock();
  const [oneIntegrateServerSettings, setOneIntegrateServerSettings] =
    useState<OneIntegrateServerSettings>();

  const fetchOneIntegrateSettings = useCallback(async () => {
    try {
      const { data } = await OneIntegrateServerSettingsApi.getServerSettings();
      setOneIntegrateServerSettings(data);
    } catch (error: any) {
      raiseError(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'serviceConfiguration.oneIntegrate.fetchSettingsError',
            defaultMessage: 'Failed to fetch 1Integrate server settings',
          })
        )
      );
    }
  }, [raiseError]);

  useEffect(() => {
    fetchOneIntegrateSettings();
  }, [fetchOneIntegrateSettings]);

  if (!oneIntegrateServerSettings) {
    return <Loading />;
  }

  return (
    <ServiceConfigurationContext.Provider
      value={{
        oneIntegrateServerSettings,
        oneIntegrateServerSettingsUpdated: setOneIntegrateServerSettings,
      }}
    >
      <ServiceConfigurationRouting />
    </ServiceConfigurationContext.Provider>
  );
};

export default ServiceConfiguration;
