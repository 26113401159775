import { FC } from 'react';

import { Box } from '@mui/material';

import { MessageBox } from '../../../../../../components';
import { intl } from '../../../../../../Internationalization';

import {
  SchemaMappingValidationResults,
  MappingStatus,
} from '../../../../../components/schema-mapper';

interface DataStoreValidationMessagesProps {
  validationResults: SchemaMappingValidationResults;
}

const DataStoreValidationMessages: FC<DataStoreValidationMessagesProps> = ({
  validationResults,
}) => {
  const sourceValidationMessage = () => {
    if (validationResults.source.status === MappingStatus.ERROR) {
      return (
        <MessageBox
          level="error"
          message={intl.formatMessage({
            id: 'openSubmission.schemaMapping.schemaMapper.unmappedRequiredSourceData',
            defaultMessage:
              'You have unmapped source data which must be mapped before you proceed.',
          })}
        />
      );
    }
    if (validationResults.source.status === MappingStatus.WARNING) {
      return (
        <MessageBox
          level="warning"
          message={intl.formatMessage({
            id: 'openSubmission.schemaMapping.schemaMapper.unmappedSourceData',
            defaultMessage: 'You have unmapped source data.',
          })}
        />
      );
    }
    return (
      <MessageBox
        level="success"
        message={intl.formatMessage({
          id: 'openSubmission.schemaMapping.schemaMapper.sourceFullyMapped',
          defaultMessage: 'All source classes and attributes mapped.',
        })}
      />
    );
  };

  const targetValidationMessage = () => {
    if (validationResults.target.status === MappingStatus.ERROR) {
      return (
        <MessageBox
          level="error"
          message={intl.formatMessage({
            id: 'openSubmission.schemaMapping.schemaMapper.unmappedRequiredTargetData',
            defaultMessage:
              'You have unmapped target data which must be mapped before you proceed.',
          })}
        />
      );
    }
    if (validationResults.target.status === MappingStatus.WARNING) {
      return (
        <MessageBox
          level="warning"
          message={intl.formatMessage({
            id: 'openSubmission.schemaMapping.schemaMapper.unmappedTargetData',
            defaultMessage: 'You have unmapped target data.',
          })}
        />
      );
    }
    return (
      <MessageBox
        level="success"
        message={intl.formatMessage({
          id: 'openSubmission.schemaMapping.schemaMapper.targetFullyMapped',
          defaultMessage: 'All target classes and attributes mapped.',
        })}
      />
    );
  };

  return (
    <>
      <Box mb={2}>{sourceValidationMessage()}</Box>
      <Box mb={2}>{targetValidationMessage()}</Box>
    </>
  );
};

export default DataStoreValidationMessages;
