import { FC, useCallback, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
} from '@mui/material';

import * as SubmissionApi from '../../../../../api/submission';
import { extractErrorMessage } from '../../../../../api/endpoints';
import {
  DefaultButton,
  InputTooltip,
  PaddedDialogActions,
  ValidatedTextField,
} from '../../../../../components';
import {
  SubmissionAutomationMode,
  SUBMISSION_AUTOMATION_MODES,
  SUBMISSION_AUTOMATION_MODE_METADATA,
} from '../../../../../types';
import { intl } from '../../../../../Internationalization';

import { OpenSubmissionContext } from './OpenSubmissionContext';

interface ConfigureAndContinueProps {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const ConfigureAndContinue: FC<ConfigureAndContinueProps> = ({ open, onSubmit, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { submissionUpdated, submission } = useContext(OpenSubmissionContext);
  const [automationMode, setAutomationMode] = useState<SubmissionAutomationMode>(
    submission.automationMode
  );
  const [sendNotifications, setSendNotifications] = useState<boolean>(submission.sendNotifications);
  const [processing, setProcessing] = useState<boolean>(false);

  const patchSubmission = useCallback(async () => {
    setProcessing(true);
    try {
      const submissionData = (
        await SubmissionApi.patchSubmission(submission.reference, {
          automationMode,
          sendNotifications,
        })
      ).data;
      submissionUpdated(submissionData);
      onSubmit();
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'submission.configureAndContinue.patchError',
            defaultMessage: 'Failed to update submission',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  }, [
    enqueueSnackbar,
    onSubmit,
    automationMode,
    sendNotifications,
    submission.reference,
    submissionUpdated,
  ]);

  return (
    <Dialog open={open} onClose={onClose} id="configure-and-continue" fullWidth maxWidth="sm">
      <DialogTitle>
        <FormattedMessage
          id="submission.configureAndContinue.title"
          defaultMessage="Configure And Continue"
        />
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <ValidatedTextField
            label={intl.formatMessage({
              id: 'submission.configureAndContinue.automationMode.label',
              defaultMessage: 'Automation Mode',
            })}
            tooltip={intl.formatMessage({
              id: 'submission.configureAndContinue.automationMode.tooltip',
              defaultMessage:
                'The automation mode controls when the processing of the submission will continue without your attention.',
            })}
            name="automationMode"
            select
            fullWidth
            value={automationMode}
            variant="outlined"
            margin="normal"
            disabled={processing}
            onChange={(event) => setAutomationMode(event.target.value as SubmissionAutomationMode)}
          >
            {SUBMISSION_AUTOMATION_MODES.map((mode) => (
              <MenuItem key={mode} value={mode}>
                {SUBMISSION_AUTOMATION_MODE_METADATA[mode].label}
              </MenuItem>
            ))}
          </ValidatedTextField>
          <InputTooltip
            title={intl.formatMessage({
              id: 'submission.configureAndContinue.sendNotifications.tooltip',
              defaultMessage:
                'When enabled, the system will send you notifications when the submission requires your attention.',
            })}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendNotifications}
                  onChange={(event) => setSendNotifications(event.target.checked)}
                  name="sendNotifications"
                  color="primary"
                  disabled={processing}
                />
              }
              label={intl.formatMessage({
                id: 'submission.configureAndContinue.sendNotifications.label',
                defaultMessage: 'Notify me when action required',
              })}
            />
          </InputTooltip>
        </Box>
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton onClick={onClose} color="secondary" name="cancel">
          <FormattedMessage
            id="submission.configureAndContinue.cancelButton"
            defaultMessage="Cancel"
          />
        </DefaultButton>
        <DefaultButton onClick={patchSubmission} name="continue">
          <FormattedMessage
            id="submission.configureAndContinue.continueButton"
            defaultMessage="Continue"
          />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default ConfigureAndContinue;
