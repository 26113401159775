import { FC } from 'react';
import { NamedSupplierAvatar } from '..';

import * as SuppliersApi from '../../api/suppliers';
import { SupplierDetail } from '../../types';

import FilteredAutocomplete, { BasicFilteredAutocompleteProps } from './FilteredAutocomplete';

type SupplerDetailAutocompleteProps = BasicFilteredAutocompleteProps<SupplierDetail>;

const fetchOptions = async (filter: string) => {
  const response = await SuppliersApi.getSuppliers({ page: 0, size: 100, filter });
  return response.data.results;
};

const renderOption = (supplier: SupplierDetail) => <NamedSupplierAvatar supplier={supplier} />;
const getOptionSelected = (option: SupplierDetail, value: SupplierDetail) =>
  option.key === value.key;
const getOptionLabel = (supplier: SupplierDetail) => supplier.name;

const ActiveSupplierAutocomplete: FC<SupplerDetailAutocompleteProps> = (props) => (
  <FilteredAutocomplete
    {...props}
    fetchOptions={fetchOptions}
    renderOption={renderOption}
    getOptionSelected={getOptionSelected}
    getOptionLabel={getOptionLabel}
  />
);

export default ActiveSupplierAutocomplete;
