import { intl } from '../Internationalization';
import { PageResponse, SimplePageRequest, AuditEventDetail, MediaDetail } from './shared';

export interface CreateProjectRequest extends ProjectSettings {
  key: string;
}

export interface ProjectSettings {
  name: string;
  description: string;
  active: boolean;
  imageStyle: ImageStyle;
}

export interface ProjectMetadata {
  key: string;
  name: string;
  description: string;
  image?: MediaDetail;
  imageStyle: ImageStyle;
}

export enum ImageStyle {
  FIT = 'FIT',
  CROP = 'CROP',
}

export const imageStyles = [ImageStyle.CROP, ImageStyle.FIT];

export const IMAGE_STYLE_METADATA: Record<ImageStyle, { label: string }> = {
  FIT: {
    label: intl.formatMessage({ id: 'project.imageStyle.fit.label', defaultMessage: 'Fit' }),
  },
  CROP: {
    label: intl.formatMessage({ id: 'project.imageStyle.crop.label', defaultMessage: 'Crop' }),
  },
};

export interface ProjectDetail extends ProjectMetadata {
  active: boolean;
  createEvent: AuditEventDetail;
  updateEvent: AuditEventDetail;
}

export interface ProjectsRequest extends SimplePageRequest {
  filter?: string;
  active?: boolean;
}

export type ProjectsResponse = PageResponse<ProjectDetail>;
