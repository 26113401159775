import { useState, useEffect, FC } from 'react';
import { Bar } from 'react-chartjs-2';
import AssessmentIcon from '@mui/icons-material/Assessment';

import { ChartPaper } from '../../../components';
import { DailyActivity } from '../../../types';
import { simpleChartOptions, chartColors, ViewMode } from '../../../util';
import { intl } from '../../../Internationalization';

interface DailyActivityChartProps {
  dailyActivity: DailyActivity[];
  viewMode: ViewMode;
}

const colors = chartColors(2);
export const populateDailyStatsData = (dailyActivity: DailyActivity[]) => ({
  labels: [...new Set(dailyActivity.map((day) => day.date))],
  datasets: [
    {
      backgroundColor: colors[0],
      label: intl.formatMessage({
        id: 'dashboard.global.dailyActivityChart.dataset.successful.label',
        defaultMessage: 'Successful',
      }),
      borderWidth: 1,
      maxBarThickness: 30,
      data: dailyActivity.map((day) => day.successfulSubmissions),
    },
    {
      backgroundColor: colors[1],
      label: intl.formatMessage({
        id: 'dashboard.global.dailyActivityChart.dataset.rejected.label',
        defaultMessage: 'Rejected',
      }),
      borderWidth: 1,
      maxBarThickness: 30,
      data: dailyActivity.map((day) => day.rejectedSubmissions),
    },
  ],
});

const DailyActivityChart: FC<DailyActivityChartProps> = ({ dailyActivity, viewMode }) => {
  const [dailyActivityData, setDailyStatsData] = useState(populateDailyStatsData(dailyActivity));
  useEffect(() => {
    setDailyStatsData(populateDailyStatsData(dailyActivity));
  }, [dailyActivity]);

  return (
    <ChartPaper
      icon={AssessmentIcon}
      iconColor="#66bb6a"
      title={intl.formatMessage({
        id: 'dashboard.global.dailyActivityChart.title',
        defaultMessage: 'Submissions per day',
      })}
      columnMode={viewMode === 'COLUMN'}
    >
      <Bar
        data={dailyActivityData}
        options={simpleChartOptions({
          maintainAspectRatio: viewMode === 'COLUMN' ? false : true,
          displayLegend: false,
          xTitle: intl.formatMessage({
            id: 'dashboard.global.dailyActivityChart.xAxis.label',
            defaultMessage: 'Dates',
          }),
          yTitle: intl.formatMessage({
            id: 'dashboard.global.dailyActivityChart.yAxis.label',
            defaultMessage: 'Activity',
          }),
          stacked: true,
        })}
      />
    </ChartPaper>
  );
};

export default DailyActivityChart;
