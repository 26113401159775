import { createContext } from 'react';
import { VirusScannerSettings } from '../../../types';

export interface VirusScannerContextValue {
  virusScannerSettings: VirusScannerSettings;
  virusScannerSettingsUpdated: (settings: VirusScannerSettings) => void;
}

const VirusScannerContextDefaultValue = {} as VirusScannerContextValue;
export const VirusScannerContext = createContext(VirusScannerContextDefaultValue);
