import { FC, useState } from 'react';
import { EditorState } from 'draft-js';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import BallotIcon from '@mui/icons-material/Ballot';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';

import EditIcon from '@mui/icons-material/Edit';
import { IconButton, TextField, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import {
  DefaultButton,
  PaddedDialogActions,
  TemplatedInputEditor,
} from '../../../../../../components';
import { intl } from '../../../../../../Internationalization';
import { editorStateFromText, textFromEditorState } from '../../../../../../util';
import { DataStoreConfigDetail } from '../../../../../../types';
import { UxDefaultDataStoreIcon } from '../../../../../../components/icons';
import { TextFieldGroup } from '../../../../../../components/editor/TemplatedInputEditor';

const textFieldGroups: TextFieldGroup[] = [
  {
    icon: WorkIcon,
    label: intl.formatMessage({
      id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.project',
      defaultMessage: 'Project',
    }),
    textFields: [
      {
        expression: 'projectName',
        label: intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.projectName',
          defaultMessage: 'Project Name',
        }),
        icon: WorkIcon,
      },
      {
        expression: 'projectKey',
        label: intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.projectKey',
          defaultMessage: 'Project Key',
        }),
        icon: WorkIcon,
      },
    ],
  },
  {
    icon: BallotIcon,
    label: intl.formatMessage({
      id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.specification',
      defaultMessage: 'Specification',
    }),
    textFields: [
      {
        expression: 'specificationName',
        label: intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.specificationName',
          defaultMessage: 'Specification Name',
        }),
        icon: BallotIcon,
      },
      {
        expression: 'specificationKey',
        label: intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.specificationKey',
          defaultMessage: 'Specification Key',
        }),
        icon: BallotIcon,
      },
    ],
  },
  {
    icon: AssignmentIcon,
    label: intl.formatMessage({
      id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.assignment',
      defaultMessage: 'Assignment',
    }),
    textFields: [
      {
        expression: 'assignmentReference',
        label: intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.assignmentReference',
          defaultMessage: 'Assignment Reference',
        }),
        icon: AssignmentIcon,
      },
      {
        expression: 'assignmentKey',
        label: intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.assignmentKey',
          defaultMessage: 'Assignment Key',
        }),
        icon: AssignmentIcon,
      },
    ],
  },
  {
    icon: LocalShippingIcon,
    label: intl.formatMessage({
      id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.fieldGroup.supplier',
      defaultMessage: 'Supplier',
    }),
    textFields: [
      {
        expression: 'supplierName',
        label: intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.supplierName',
          defaultMessage: 'Supplier Name',
        }),
        icon: LocalShippingIcon,
      },
      {
        expression: 'supplierKey',
        label: intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.supplierKey',
          defaultMessage: 'Supplier Key',
        }),
        icon: LocalShippingIcon,
      },
    ],
  },
  {
    icon: AssessmentIcon,
    label: intl.formatMessage({
      id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.fieldGroup.submission',
      defaultMessage: 'Submission',
    }),
    textFields: [
      {
        expression: 'submissionReference',
        label: intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.reference',
          defaultMessage: 'Reference',
        }),
        icon: AssessmentIcon,
      },
      {
        expression: 'submissionStartDate',
        label: intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.submissionStartDate',
          defaultMessage: 'Start Date',
        }),
        icon: CalendarMonthIcon,
      },
      {
        expression: 'dataStoreName',
        label: intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.dataStoreName',
          defaultMessage: 'Data Store',
        }),
        icon: UxDefaultDataStoreIcon,
      },
      {
        expression: 'outputFileExtension',
        label: intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate.field.fileExtension',
          defaultMessage: 'File Extension',
        }),
        icon: UxDefaultDataStoreIcon,
      },
    ],
  },
];

interface OutputFilenameTemplateProps {
  dataStoreConfig: DataStoreConfigDetail;
  updateDataStoreConfig: (dataStoreConfig: DataStoreConfigDetail) => void;
  disabled: boolean;
}

const OutputFilenameTemplate: FC<OutputFilenameTemplateProps> = ({
  dataStoreConfig,
  updateDataStoreConfig,
  disabled,
}) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [filenameTemplateState, setFilenameTemplateState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const handleDialogOpen = () => {
    setFilenameTemplateState(editorStateFromText(dataStoreConfig.outputFilenameTemplate || ''));
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    const updatedConfig = { ...dataStoreConfig };
    updatedConfig.outputFilenameTemplate = textFromEditorState(filenameTemplateState);
    updateDataStoreConfig(updatedConfig);
    setDialogOpen(false);
  };

  return (
    <>
      <TextField
        value={dataStoreConfig.outputFilenameTemplate}
        label={intl.formatMessage({
          id: 'specification.configuration.dataStoreConfig.outputFilenameTemplate',
          defaultMessage: 'Output Filename Template',
        })}
        InputLabelProps={{ shrink: true }}
        name="outputFilenameTemplate"
        margin="dense"
        size="small"
        fullWidth
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={handleDialogOpen}
              sx={{ p: 1, m: -0.5 }}
              size="large"
              disabled={disabled}
            >
              <EditIcon />
            </IconButton>
          ),
        }}
      />
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        id="edit-output-filename-dialog"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <FormattedMessage
            id="specification.configuration.dataStoreConfig.dialog.title"
            defaultMessage="Edit Output Filename"
          />
        </DialogTitle>
        <DialogContent>
          <TemplatedInputEditor
            textFieldGroups={textFieldGroups}
            editorState={filenameTemplateState}
            onEditorStateChange={setFilenameTemplateState}
          />
        </DialogContent>
        <PaddedDialogActions>
          <DefaultButton name="cancel" onClick={handleDialogClose} color="secondary">
            <FormattedMessage
              id="specification.configuration.dataStoreConfig.dialog.cancelBtn"
              defaultMessage="Cancel"
            />
          </DefaultButton>
          <DefaultButton name="confirm" onClick={handleConfirm}>
            <FormattedMessage
              id="specification.configuration.dataStoreConfig.dialog.confirmBtn"
              defaultMessage="Confirm"
            />
          </DefaultButton>
        </PaddedDialogActions>
      </Dialog>
    </>
  );
};

export default OutputFilenameTemplate;
