import { generateApiUri } from './endpoints';
import { SpecificationStatsRequest } from '../types';

const calculateParams = (request: SpecificationStatsRequest, download: string) => ({
  ...request,
  format: 'csv',
  download,
});

export const ruleConformanceUri = (
  specificationKey: string,
  request: SpecificationStatsRequest
) => {
  return generateApiUri(`/reports/specification/${specificationKey}/rule_conformance`, {
    params: calculateParams(request, 'RuleConformance.csv'),
  });
};

export const taskConformanceUri = (
  specificationKey: string,
  request: SpecificationStatsRequest
) => {
  return generateApiUri(`/reports/specification/${specificationKey}/task_conformance`, {
    params: calculateParams(request, 'TaskConformance.csv'),
  });
};

export const submissionsUri = (specificationKey: string, request: SpecificationStatsRequest) => {
  return generateApiUri(`/reports/specification/${specificationKey}/submissions`, {
    params: calculateParams(request, 'Submissions.csv'),
  });
};
