import { FC } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { CircularProgress } from '@mui/material';

interface MoreButtonProps extends Exclude<ButtonProps, 'variant' | 'className' | 'children'> {
  label: string;
  processing: boolean;
  moreName: string;
  onMore: () => void;
}

const MoreButton: FC<MoreButtonProps> = ({
  moreName,
  onMore,
  label,
  disabled,
  processing,
  sx = [],
  ...rest
}) => (
  <ButtonGroup
    sx={{
      'width': '100%',
      'height': '100%',
      'mx': 1,
      '&:last-child': { mr: 0 },
      '&:first-child': { ml: 0 },
    }}
    variant="contained"
    color="primary"
  >
    <Button
      sx={[
        { position: 'relative', py: 2, px: 4, width: '100%' },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      variant="contained"
      disabled={disabled || processing}
      {...rest}
    >
      {label}
      {processing && <CircularProgress sx={{ position: 'absolute' }} size={20} />}
    </Button>
    <Button
      name={moreName}
      sx={{ position: 'relative', py: 2, px: 4 }}
      color="primary"
      size="small"
      onClick={onMore}
      disabled={disabled}
    >
      <MoreHorizIcon />
    </Button>
  </ButtonGroup>
);

export default MoreButton;
