import { FC, useState } from 'react';

import { UserImportJobDetail } from '../../../types';

import UploadCsv from './UploadCsv';
import PreviewUpload from './PreviewUpload';

const Upload: FC = () => {
  const [jobDetail, setJobDetail] = useState<UserImportJobDetail>();

  if (jobDetail) {
    return <PreviewUpload jobDetail={jobDetail} clearJob={() => setJobDetail(undefined)} />;
  }

  return <UploadCsv onUploaded={setJobDetail} />;
};

export default Upload;
