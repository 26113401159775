import {
  CheckCircleSuccessSvg,
  ErrorOutlineOutlinedErrorSvg,
  PdfIcon,
  ReportProblemOutlinedWarningSvg,
} from '../../../components/pdf/PdfIcons';
import { intl } from '../../../Internationalization';
import {
  DataStoreClassMapping,
  DataStoreClassSchema,
  DataStoreConfigDetail,
  DataStoreMapping,
  DataStoreSchema,
  MappedAttributes,
  SchemaMappingMode,
  SpecificationDetail,
} from '../../../types';

export function findDataStoreMapping(dataStorePath: string, dataStoreMappings: DataStoreMapping[]) {
  return dataStoreMappings.find(({ path }) => path === dataStorePath);
}

export function findDataStoreSchema(dataStorePath: string, dataStoreSchemas: DataStoreSchema[]) {
  return dataStoreSchemas.find(({ path }) => path === dataStorePath);
}

export function findMappingForTarget(className: string, classMappings: DataStoreClassMapping[]) {
  return classMappings.find(({ target }) => target === className);
}

export function findMappingForSource(className: string, classMappings: DataStoreClassMapping[]) {
  return classMappings.find(({ source }) => source === className);
}

export function findClassSchema(className: string, classSchemas: DataStoreClassSchema[]) {
  return classSchemas.find(({ name }) => name === className);
}

export function findMappedAttributeForTarget(
  targetAttribute: string,
  mappedAttributes?: MappedAttributes
) {
  return (
    mappedAttributes &&
    Object.keys(mappedAttributes).find(
      (mappedAttribute) => mappedAttributes[mappedAttribute] === targetAttribute
    )
  );
}

export function findMappedAttributeForSource(
  sourceAttribute: string,
  mappedAttributes?: MappedAttributes
) {
  return mappedAttributes && mappedAttributes[sourceAttribute];
}

export function generateNaturalMapping(
  sourceClass: DataStoreClassSchema,
  targetClass: DataStoreClassSchema
) {
  return sourceClass.attributes
    .map((a) => a.name)
    .filter((a) => targetClass.attributes.find((b) => b.name === a))
    .reduce((output, value) => {
      output[value] = value;
      return output;
    }, {} as MappedAttributes);
}

export function findUnmappedSourceAttribute(
  classMapping: DataStoreClassMapping,
  sourceClass: DataStoreClassSchema
) {
  return sourceClass.attributes.find(({ name }) => {
    return !Object.keys(classMapping.attributes).find((attribute) => attribute === name);
  });
}

export enum MappingStatus {
  OK = 'OK',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export const MAPPING_STATUSES = [MappingStatus.OK, MappingStatus.WARNING, MappingStatus.ERROR];

interface MappingStatusMetadata {
  level: 'warning' | 'success' | 'error';
  label: string;
  pdfIcon: PdfIcon;
}

export const MAPPING_STATUS_METADATA: Record<MappingStatus, MappingStatusMetadata> = {
  OK: {
    level: 'success',
    label: intl.formatMessage({
      id: 'schemaMapper.dataStoreClasses.mappingComplete.label',
      defaultMessage: 'Mapping complete',
    }),
    pdfIcon: CheckCircleSuccessSvg,
  },
  WARNING: {
    level: 'warning',
    label: intl.formatMessage({
      id: 'schemaMapper.dataStoreClasses.mappingIncomplete.label',
      defaultMessage: 'Mapping incomplete',
    }),
    pdfIcon: ReportProblemOutlinedWarningSvg,
  },
  ERROR: {
    level: 'error',
    label: intl.formatMessage({
      id: 'schemaMapper.dataStoreClasses.mappingRequired.label',
      defaultMessage: 'Mapping required',
    }),
    pdfIcon: ErrorOutlineOutlinedErrorSvg,
  },
};

export interface ClassValidationResult {
  mapped: boolean;
  unmappedAttributes: number;
  status: MappingStatus;
}

export type ClassValidationResults = {
  [key: string]: ClassValidationResult;
};

export interface DataStoreValidationResult {
  classValidationResults: ClassValidationResults;
  unmappedClasses: number;
  unmappedAttributes: number;
  status: MappingStatus;
}

export type DataStoreValidationResults = {
  [key: string]: DataStoreValidationResult;
};

export interface SchemaMappingValidationResult {
  dataStoreValidationResults: DataStoreValidationResults;
  unmappedClasses: number;
  unmappedAttributes: number;
  status: MappingStatus;
}

export interface SchemaMappingValidationResults {
  target: SchemaMappingValidationResult;
  source: SchemaMappingValidationResult;
}

export const aggregateMappingStatus = (...statuses: MappingStatus[]): MappingStatus => {
  if (statuses.includes(MappingStatus.ERROR)) {
    return MappingStatus.ERROR;
  }
  if (statuses.includes(MappingStatus.WARNING)) {
    return MappingStatus.WARNING;
  }
  return MappingStatus.OK;
};

export const validateClassMappings = (
  dataStoreClassSchemas: DataStoreClassSchema[],
  dataStoreClassMappings: DataStoreClassMapping[],
  findMapping: (
    className: string,
    classMappings: DataStoreClassMapping[]
  ) => DataStoreClassMapping | undefined,
  findAttribute: (attributeName: string, mappedAttributes?: MappedAttributes) => string | undefined,
  unmappedStatus: MappingStatus
): ClassValidationResults =>
  Object.fromEntries(
    dataStoreClassSchemas.map((dataStoreClassSchema) => {
      const classMapping = findMapping(dataStoreClassSchema.name, dataStoreClassMappings);
      if (!classMapping) {
        return [
          dataStoreClassSchema.name,
          {
            mapped: false,
            unmappedAttributes: 0,
            status: unmappedStatus,
          },
        ];
      }
      const unmappedAttributes = dataStoreClassSchema.attributes.filter(
        ({ name }) => !findAttribute(name, classMapping.attributes)
      ).length;
      return [
        dataStoreClassSchema.name,
        {
          mapped: true,
          unmappedAttributes,
          status: unmappedAttributes === 0 ? MappingStatus.OK : unmappedStatus,
        },
      ];
    })
  );

export const validateDataStoreMapping = (
  dataStoreClassSchemas: DataStoreClassSchema[],
  dataStoreClassMappings: DataStoreClassMapping[],
  findMapping: (
    className: string,
    classMappings: DataStoreClassMapping[]
  ) => DataStoreClassMapping | undefined,
  findAttribute: (attributeName: string, mappedAttributes?: MappedAttributes) => string | undefined,
  unmappedStatus: MappingStatus
): DataStoreValidationResult => {
  const classValidationResults = validateClassMappings(
    dataStoreClassSchemas,
    dataStoreClassMappings,
    findMapping,
    findAttribute,
    unmappedStatus
  );
  const resultsArray = Object.values(classValidationResults);
  return {
    classValidationResults,
    unmappedClasses: resultsArray.filter((r) => !r.mapped).length,
    unmappedAttributes: resultsArray.reduce((sum, r) => sum + r.unmappedAttributes, 0),
    status: aggregateMappingStatus(...resultsArray.map((r) => r.status)),
  };
};

const noSchemaMappingValidationResult = (dataStoreClassSchemas: DataStoreClassSchema[]) => ({
  classValidationResults: Object.fromEntries(
    dataStoreClassSchemas.map((dataStoreClassSchema) => [
      dataStoreClassSchema.name,
      {
        mapped: true,
        unmappedAttributes: 0,
        status: MappingStatus.OK,
      },
    ])
  ),
  unmappedClasses: 0,
  unmappedAttributes: 0,
  status: MappingStatus.OK,
});

export const validateDataStoreMappings = (
  dataStoreConfigs: Record<string, DataStoreConfigDetail>,
  dataStoreSchemas: DataStoreSchema[],
  schemaMappings: DataStoreMapping[],
  findMapping: (
    className: string,
    classMappings: DataStoreClassMapping[]
  ) => DataStoreClassMapping | undefined,
  findAttribute: (attributeName: string, mappedAttributes?: MappedAttributes) => string | undefined,
  unmappedStatus: MappingStatus
): DataStoreValidationResults =>
  Object.fromEntries(
    dataStoreSchemas.map((dataStoreSchema) => {
      const path = dataStoreSchema.path;
      const dataStoreClassSchemas = dataStoreSchema.importSchema!.classes;
      if (dataStoreConfigs[path].schemaMappingMode === SchemaMappingMode.NONE) {
        return [path, noSchemaMappingValidationResult(dataStoreClassSchemas)];
      }
      const dataStoreClassMappings =
        findDataStoreMapping(path, schemaMappings)?.importMappings || [];
      return [
        path,
        validateDataStoreMapping(
          dataStoreClassSchemas,
          dataStoreClassMappings,
          findMapping,
          findAttribute,
          unmappedStatus
        ),
      ];
    })
  );

export const validateSchemaMapping = (
  dataStoreConfigs: Record<string, DataStoreConfigDetail>,
  dataStoreSchemas: DataStoreSchema[],
  schemaMappings: DataStoreMapping[],
  findMapping: (
    className: string,
    classMappings: DataStoreClassMapping[]
  ) => DataStoreClassMapping | undefined,
  findAttribute: (attributeName: string, mappedAttributes?: MappedAttributes) => string | undefined,
  unmappedStatus: MappingStatus
): SchemaMappingValidationResult => {
  const dataStoreValidationResults = validateDataStoreMappings(
    dataStoreConfigs,
    dataStoreSchemas,
    schemaMappings,
    findMapping,
    findAttribute,
    unmappedStatus
  );
  const resultsArray = Object.values(dataStoreValidationResults);
  return {
    dataStoreValidationResults,
    unmappedClasses: resultsArray.reduce((sum, r) => sum + r.unmappedClasses, 0),
    unmappedAttributes: resultsArray.reduce((sum, r) => sum + r.unmappedAttributes, 0),
    status: aggregateMappingStatus(...resultsArray.map((r) => r.status)),
  };
};

export const validateSchemaMappings = (
  dataStoreConfigs: Record<string, DataStoreConfigDetail>,
  sourceDataStores: DataStoreSchema[],
  targetDataStores: DataStoreSchema[],
  schemaMappings: DataStoreMapping[],
  specification: SpecificationDetail
): SchemaMappingValidationResults => ({
  source: validateSchemaMapping(
    dataStoreConfigs,
    sourceDataStores,
    schemaMappings,
    findMappingForSource,
    findMappedAttributeForSource,
    specification.allowSourceUnmapped ? MappingStatus.WARNING : MappingStatus.ERROR
  ),
  target: validateSchemaMapping(
    dataStoreConfigs,
    targetDataStores,
    schemaMappings,
    findMappingForTarget,
    findMappedAttributeForTarget,
    specification.allowTargetUnmapped ? MappingStatus.WARNING : MappingStatus.ERROR
  ),
});

export const isManualSchemaMappingSupported = (
  dataStoreConfigs: Record<string, DataStoreConfigDetail>
) => {
  return !!Object.values(dataStoreConfigs).find(
    (dataStoreConfig) => dataStoreConfig.schemaMappingMode === SchemaMappingMode.MANUAL
  );
};

export const areSchemaMappingInputsViewable = (
  dataStoreConfigs: Record<string, DataStoreConfigDetail>
) => {
  return !!Object.values(dataStoreConfigs).find(
    (dataStoreConfig) => dataStoreConfig.schemaMappingMode === SchemaMappingMode.AUTOMATIC
  );
};

export const mapDataStoreConfigs = (dataStoreConfigs: DataStoreConfigDetail[]) => {
  const dataStoreConfigMap: Record<string, DataStoreConfigDetail> = {};
  dataStoreConfigs.forEach(
    (dataStoreConfig) => (dataStoreConfigMap[dataStoreConfig.path] = dataStoreConfig)
  );
  return dataStoreConfigMap;
};
