import { useState, FC, useContext } from 'react';
import { CardContent, Card, CardHeader } from '@mui/material';

import { useSnackbar } from 'notistack';

import * as SpecificationMediaApi from '../../../../../api/specificationMedia';
import { extractErrorMessage, FileUploadConfig } from '../../../../../api/endpoints';
import * as SpecificationApi from '../../../../../api/specification';
import { Upload, FilesList, ConfirmDialog } from '../../../../../components';
import { MediaDetail } from '../../../../../types';
import { intl } from '../../../../../Internationalization';
import { useMediaUpload } from '../../../../../hooks';

import { SpecificationContext } from '../SpecificationContext';

const Documents: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { specification, specificationUpdated, specificationKey } =
    useContext(SpecificationContext);

  const [upload, uploading, uploadProgress] = useMediaUpload<MediaDetail[]>({
    onUpload: (files: File[], config: FileUploadConfig) =>
      SpecificationApi.uploadDocument(specificationKey, files, config),
    onUploadComplete: (documents) => specificationUpdated({ ...specification, documents }),
  });

  const [documentToDelete, setDocumentToDelete] = useState<MediaDetail>();
  const [processing, setProcessing] = useState<boolean>(false);

  const documentsUpdates = (documents: MediaDetail[]) => {
    specificationUpdated({ ...specification, documents });
  };

  const handleDeleteConfirm = async () => {
    setProcessing(true);
    try {
      const { data } = await SpecificationApi.removeDocument(
        specificationKey,
        documentToDelete!.storedFilename
      );
      documentsUpdates(data);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'specification.information.documents.deleteSuccess',
          defaultMessage: 'Document has been deleted',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'specification.information.documents.deleteError',
            defaultMessage: 'Failed to delete document',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setDocumentToDelete(undefined);
      setProcessing(false);
    }
  };

  const handleDelete = (document: MediaDetail) => {
    setDocumentToDelete(document);
  };

  return (
    <Card id="specification-documents">
      <CardHeader
        title={intl.formatMessage({
          id: 'specification.information.documents.title',
          defaultMessage: 'Documents',
        })}
      />
      <CardContent>
        <ConfirmDialog
          id="confirm-delete-document"
          isOpen={!!documentToDelete}
          title={intl.formatMessage({
            id: 'specification.information.documents.confirmDeleteDocument.title',
            defaultMessage: 'Delete document',
          })}
          text={intl.formatMessage(
            {
              id: 'specification.information.documents.confirmDeleteDocument.text',
              defaultMessage: 'Are you sure you wish to delete the document: {filename}?',
            },
            { filename: documentToDelete && documentToDelete.filename }
          )}
          confirmBtnText={intl.formatMessage({
            id: 'specification.information.documents.confirmDeleteDocument.button',
            defaultMessage: 'Delete document',
          })}
          confirmAction={handleDeleteConfirm}
          disabled={processing}
          closeAction={() => setDocumentToDelete(undefined)}
        />
        <FilesList
          documents={specification.documents}
          downloadURL={(file: MediaDetail) =>
            SpecificationMediaApi.downloadSpecificationDocumentUri(specification.key, file)
          }
          onDelete={handleDelete}
        />
        <Upload
          onDrop={upload}
          uploading={uploading}
          progress={uploadProgress}
          disabled={uploading}
        />
      </CardContent>
    </Card>
  );
};

export default Documents;
