import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosPromise } from 'axios';
import { useSnackbar } from 'notistack';
import { isFunction } from 'lodash';

import { extractErrorMessage, RequestConfig } from '../api/endpoints';
import { PageResponse, SimplePageRequest } from '../types';
import { useErrorBlock } from '../contexts/error-block';
import { intl } from '../Internationalization';

interface BrowseRequestOptions<R extends SimplePageRequest, P extends PageResponse<any>> {
  initialRequest: R;
  onRequest: (request: R, config?: RequestConfig) => AxiosPromise<P>;
}

const useBrowseRequest = <R extends SimplePageRequest, P extends PageResponse<any>>({
  initialRequest,
  onRequest,
}: BrowseRequestOptions<R, P>) => {
  const { enqueueSnackbar } = useSnackbar();
  const errorBlock = useErrorBlock();
  const [request, setRequest] = useState<R>(initialRequest);
  const [response, setResponse] = useState<P>();
  const [processing, setProcessing] = useState<boolean>(false);

  // N.B. We don't know if we are in an <ErrorBlock> component and useContext always returns a value
  // we must therefore use the presence of the raiseError functions to determine how to render the error
  const raiseError = errorBlock?.raiseError;

  const setPage = useCallback((page: number) => {
    setRequest((prevState) => ({
      ...prevState,
      page,
    }));
  }, []);

  const refresh = useCallback(() => setRequest((state) => ({ ...state })), []);

  const updateRequest = useCallback((update?: Partial<R> | ((prevState: R) => R)) => {
    if (isFunction(update)) {
      setRequest((prevState) => update({ ...prevState, page: 0 }));
    } else {
      setRequest((prevState) => ({
        ...prevState,
        ...update,
        page: 0,
      }));
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    const performSearch = async () => {
      setProcessing(true);
      try {
        const { data } = await onRequest(request, { signal: abortController.signal });
        setResponse(data);
        setProcessing(false);
      } catch (error: any) {
        if (!axios.isCancel(error)) {
          const errorMessage = extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'hooks.useBrowseRequest.loadError',
              defaultMessage: 'Failed to load data',
            })
          );
          setProcessing(false);
          if (raiseError) {
            raiseError(errorMessage);
          } else {
            enqueueSnackbar(errorMessage, { variant: 'error' });
          }
        }
      }
    };

    performSearch();

    return () => abortController.abort();
  }, [enqueueSnackbar, request, onRequest, raiseError]);

  return { request, updateRequest, setPage, refresh, processing, response } as const;
};

export default useBrowseRequest;
