import { AuditEventDetail, MediaDetail, PageResponse, SimplePageRequest } from './shared';
import { SvgIconProps } from '@mui/material';

import DoneIcon from '@mui/icons-material/Done';
import Autorenew from '@mui/icons-material/Autorenew';
import Error from '@mui/icons-material/Error';

import SuccessIcon from '../components/icons/SuccessIcon';
import AutorenewInfoIcon from '../components/icons/AutorenewInfoIcon';
import FailedIcon from '../components/icons/FailedIcon';

import { ActiveAvatarState } from '../components/avatars/ActiveAvatar';
import { intl } from '../Internationalization';

export enum DeletionJobEntity {
  PROJECT = 'PROJECT',
  ASSIGNMENT = 'ASSIGNMENT',
  SPECIFICATION = 'SPECIFICATION',
  SUPPLIER = 'SUPPLIER',
}

export interface CreateDeletionJobRequest {
  entity: DeletionJobEntity;
  entityKey: string;
}

export interface DeletionJobDetail {
  id: number;
  entity: DeletionJobEntity;
  entityKey: string;
  state: DeletionJobState;
  createEvent: AuditEventDetail;
  startedAt: string;
  finishedAt: string;
  submissionsDeleted: number;
  submissionsRemaining: number;
  log: MediaDetail;
}

export type DeletionJobsRequest = SimplePageRequest;
export type DeletionJobsResponse = PageResponse<DeletionJobDetail>;

export enum DeletionJobState {
  RUNNING = 'RUNNING',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
}

export interface DeletionJobStateMetadata {
  label: string;
  icon: React.ComponentType<SvgIconProps>;
  avatarIcon: React.ComponentType<SvgIconProps>;
  activeAvatarState: ActiveAvatarState;
}

export const DELETION_JOB_STATE_METADATA: {
  [type in DeletionJobState]: DeletionJobStateMetadata;
} = {
  RUNNING: {
    label: intl.formatMessage({
      id: 'deletion.jobStateMetadata.running',
      defaultMessage: 'Running',
    }),
    icon: AutorenewInfoIcon,
    avatarIcon: Autorenew,
    activeAvatarState: 'default',
  },
  COMPLETE: {
    label: intl.formatMessage({
      id: 'deletion.jobStateMetadata.complete',
      defaultMessage: 'Complete',
    }),
    icon: SuccessIcon,
    avatarIcon: DoneIcon,
    activeAvatarState: 'active',
  },
  FAILED: {
    label: intl.formatMessage({
      id: 'deletion.jobStateMetadata.failed',
      defaultMessage: 'Failed',
    }),
    icon: FailedIcon,
    avatarIcon: Error,
    activeAvatarState: 'inactive',
  },
};

export interface DeletionJobEntityMetadata {
  label: string;
}

export const DELETION_JOB_ENTITY_METADATA: {
  [type in DeletionJobEntity]: DeletionJobEntityMetadata;
} = {
  PROJECT: {
    label: intl.formatMessage({
      id: 'deletion.jobEntityMetadata.project',
      defaultMessage: 'Project',
    }),
  },
  ASSIGNMENT: {
    label: intl.formatMessage({
      id: 'deletion.jobEntityMetadata.assignment',
      defaultMessage: 'Assignment',
    }),
  },
  SPECIFICATION: {
    label: intl.formatMessage({
      id: 'deletion.jobEntityMetadata.specification',
      defaultMessage: 'Specification',
    }),
  },
  SUPPLIER: {
    label: intl.formatMessage({
      id: 'deletion.jobEntityMetadata.supplier',
      defaultMessage: 'Supplier',
    }),
  },
};
