import { Skeleton, Box } from '@mui/material';

import { PaddedPaper } from '../';

const FilesListSkeleton = () => {
  const fileRow = () => (
    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
      <Box>
        <Skeleton variant="rectangular" height={60} width={60} />
      </Box>
      <Box flexGrow={1} mx={2}>
        <Skeleton variant="text" height={40} width="100%" />
      </Box>
      <Box>
        <Skeleton variant="circular" height={60} width={60} />
      </Box>
    </Box>
  );

  return (
    <PaddedPaper>
      {fileRow()}
      {fileRow()}
      {fileRow()}
    </PaddedPaper>
  );
};
export default FilesListSkeleton;
