import { styled } from '@mui/material';

const MonospacedContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  color: 'white',
  fontFamily: 'Monospace, Consolas',
  padding: theme.spacing(1),
  whiteSpace: 'pre-line',
  margin: theme.spacing(1, 0),
}));

export default MonospacedContainer;
