import React from 'react';
import { UserDetail } from '../../../types';

export interface UserContextValue {
  userUpdated: (profile: UserDetail) => void;
  userKey: string;
  user: UserDetail;
}

const UserContextDefaultValue = {} as UserContextValue;
export const UserContext = React.createContext(UserContextDefaultValue);
