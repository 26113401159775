import { FC } from 'react';
import { Box } from '@mui/material';

import { UserProfile } from '../../types';

import AccountAvatar from './AccountAvatar';

interface NamedAccountAvatarProps {
  user: UserProfile;
  showEmail?: boolean;
}

const NamedAccountAvatar: FC<NamedAccountAvatarProps> = ({ user, showEmail }) => (
  <Box display="flex" alignItems="center">
    <AccountAvatar user={user} size="small" sx={{ mr: 1 }} />
    {user.name}
    {showEmail && ` (${user.email})`}
  </Box>
);

export default NamedAccountAvatar;
