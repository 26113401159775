import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Paper, Typography, Box, CssBaseline } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import logo from '../../assets/images/1datagateway_1300.jpg';
import background from '../../assets/images/bg1.jpg';

interface SiteErrorProps {
  error?: string;
}

const SiteError: FC<SiteErrorProps> = ({ error }) => (
  <Box
    sx={{
      backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      flexDirection: 'column',
    }}
  >
    <CssBaseline />
    <Paper
      sx={{
        textAlign: 'center',
        padding: '150px 8px 50px',
        backgroundImage: `url(${logo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 40px',
        backgroundSize: '450px auto',
        width: '100%',
        borderRadius: 0,
      }}
      elevation={10}
    >
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" mb={2}>
        <WarningIcon fontSize="large" color="error" />
        <Typography variant="h4">
          &nbsp;
          <FormattedMessage id="contexts.application.siteError.title" defaultMessage="Site Error" />
        </Typography>
      </Box>
      <Typography variant="subtitle1" gutterBottom>
        <FormattedMessage
          id="contexts.application.siteError.description"
          defaultMessage="Failed to configure application, please refresh to try again. If the problem persists, contact your site administrator."
        />
      </Typography>
      {error && (
        <Typography variant="subtitle1" gutterBottom>
          {error}
        </Typography>
      )}
    </Paper>
  </Box>
);

export default SiteError;
