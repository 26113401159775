import { FC } from 'react';
import { Container, Grid } from '@mui/material';

import ReceiverAdminOnly from '../../../../../components/ReceiverAdminOnly';

import SpecificationSettingsForm from './SpecificationSettingsForm';
import SpecificationManagement from './SpecificationManagement';

const SpecificationSettings: FC = () => (
  <Container maxWidth="md" id="project-specification-settings" disableGutters>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SpecificationSettingsForm />
      </Grid>
      <ReceiverAdminOnly>
        <Grid item xs={12}>
          <SpecificationManagement />
        </Grid>
      </ReceiverAdminOnly>
    </Grid>
  </Container>
);

export default SpecificationSettings;
