import { FC, useContext, useEffect, useState } from 'react';

import * as ProjectApi from '../../../api/project';
import { extractErrorMessage } from '../../../api/endpoints';
import { ProjectDetail } from '../../../types';
import { ErrorBlockContext } from '../../../contexts/error-block';
import { intl } from '../../../Internationalization';
import { useRequiredParams } from '../../../hooks';

import { ProjectContext } from './ProjectContext';
import ProjectRouting from './ProjectRouting';
import ProjectSkeleton from './ProjectSkeleton';

export const toProjectLink = (projectKey: string) => `/projects/${projectKey}`;

const Project: FC = () => {
  const { projectKey } = useRequiredParams<{ projectKey: string }>();

  const { raiseError } = useContext(ErrorBlockContext);

  const [project, setProject] = useState<ProjectDetail>();

  useEffect(() => {
    const loadProject = async () => {
      try {
        const projectData = (await ProjectApi.getProject(projectKey)).data;
        setProject(projectData);
      } catch (error: any) {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'project.loadError',
              defaultMessage: 'Failed to load project details',
            })
          )
        );
      }
    };

    loadProject();
  }, [projectKey, raiseError]);

  if (project) {
    return (
      <ProjectContext.Provider
        value={{
          projectUpdated: setProject,
          projectKey,
          project,
        }}
      >
        <ProjectRouting />
      </ProjectContext.Provider>
    );
  }

  return <ProjectSkeleton />;
};

export default Project;
