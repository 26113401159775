import { FormattedMessage } from 'react-intl';

import { Box, Typography, IconButton } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { MediaDetail } from '../../types';
import { iconForFilename } from '../../util/files';
import { intl } from '../../Internationalization';

interface FilesListProps {
  documents: MediaDetail[];
  downloadURL: (file: MediaDetail) => string;
  onDelete?: (file: MediaDetail) => void;
}

const FilesList = ({ documents, downloadURL, onDelete }: FilesListProps) => {
  if (!documents.length) {
    return (
      <Box mb={1}>
        <Typography variant="subtitle1">
          <FormattedMessage
            id="components.media.filesList.noFiles"
            defaultMessage="No files supplied"
          />
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {documents.map((file) => {
        const Icon = iconForFilename(file.filename);
        return (
          <Box
            key={file.storedFilename}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Box>
              <Icon fontSize="large" />
            </Box>
            <Box flexGrow={1} mx={1}>
              <Typography
                className="FilesList-filename"
                sx={{ wordBreak: 'break-all' }}
                variant="subtitle1"
              >
                {file.filename}
              </Typography>
            </Box>
            <Box whiteSpace="nowrap">
              <IconButton
                aria-label={intl.formatMessage(
                  {
                    id: 'components.media.filesList.downloadFile.ariaLabel',
                    defaultMessage: 'Download {filename}',
                  },
                  {
                    filename: file.filename,
                  }
                )}
                href={downloadURL(file)}
                size="large"
              >
                <CloudDownloadIcon />
              </IconButton>
              {onDelete && (
                <IconButton
                  name="filesListDeleteButton"
                  onClick={() => onDelete(file)}
                  aria-label={intl.formatMessage(
                    {
                      id: 'components.media.filesList.deleteFile.ariaLabel',
                      defaultMessage: 'Delete {filename}',
                    },
                    { filename: file.filename }
                  )}
                  size="large"
                >
                  <DeleteForeverIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default FilesList;
