import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Table, TableBody, TableCell, TableHead, TableRow, Divider, Box } from '@mui/material';

import { ObjectFeature } from '../MapContainer';

interface FeatureDetailsProps {
  feature?: ObjectFeature;
}

const FeatureDetails: FC<FeatureDetailsProps> = ({ feature }) => {
  if (!feature) {
    return null;
  }

  const properties = feature.getProperties();
  return (
    <>
      <Divider />
      <Box sx={{ overflowY: 'scroll', height: '100%' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  id="openSubmission.map.menu.featureDetail.table.attributeColumn"
                  defaultMessage="Attribute"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="openSubmission.map.menu.featureDetail.table.valueColumn"
                  defaultMessage="Value"
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(properties)
              .filter((property) => property !== 'boundedBy' && property !== 'geometry')
              .map((property) => (
                <TableRow key={property}>
                  <TableCell>{property}</TableCell>
                  <TableCell>{properties[property]}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

export default FeatureDetails;
