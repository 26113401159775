import React from 'react';
import { Saml2IdentityProviderDetail } from '../../../../types';

export interface Saml2IdentityProviderContextValue {
  identityProvider: Saml2IdentityProviderDetail;
  identityProviderUpdated: (settings: Saml2IdentityProviderDetail) => void;
}

const Saml2IdentityProviderContextDefaultValue = {} as Saml2IdentityProviderContextValue;
export const Saml2IdentityProviderContext = React.createContext(
  Saml2IdentityProviderContextDefaultValue
);
