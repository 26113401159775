import React, { useContext } from 'react';

export type ErrorLevel = 'warning' | 'error';

export interface ErrorBlockContextValue {
  raiseError: (message: string) => void;
  raiseAccessError: (message: string) => void;
}

const ErrorBlockContextDefaultValue = {} as ErrorBlockContextValue;
export const ErrorBlockContext = React.createContext(ErrorBlockContextDefaultValue);

export type WithErrorBlockProps = ErrorBlockContextValue;

export function withErrorBlock<T extends WithErrorBlockProps>(Component: React.ComponentType<T>) {
  return class extends React.Component<Omit<T, keyof WithErrorBlockProps>> {
    render() {
      return (
        <ErrorBlockContext.Consumer>
          {(errorStateContext) => (
            <Component {...(this.props as T)} raiseError={errorStateContext.raiseError} />
          )}
        </ErrorBlockContext.Consumer>
      );
    }
  };
}

export const useErrorBlock = (): WithErrorBlockProps => useContext(ErrorBlockContext);
