import Schema from 'async-validator';
import { AxiosPromise } from 'axios';
import { intl } from '../Internationalization';

import {
  Me,
  ChangePasswordRequest,
  UpdateMyProfileRequest,
  UpdateAvatarRequest,
  Avatar,
  MediaDetail,
  PasswordRequirements,
} from '../types';
import { AXIOS, FileUploadConfig } from './endpoints';
import { PASSWORD_VALIDATOR, verifiedPasswordDescriptor } from './auth';
import { nameLengthValidator } from '../util';

export function getMe(): AxiosPromise<Me> {
  return AXIOS.get('/me');
}

export function updateProfile(request: UpdateMyProfileRequest): AxiosPromise<Me> {
  return AXIOS.post('/me', request);
}

export function updateAvatar(request: UpdateAvatarRequest): AxiosPromise<Avatar> {
  return AXIOS.post('/me/avatar', request);
}

export function uploadImage(image: File, config?: FileUploadConfig): AxiosPromise<MediaDetail> {
  var formData = new FormData();
  formData.append('file', image);
  return AXIOS.post('/me/avatar/image', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...(config || {}),
  });
}

export function deleteImage(): AxiosPromise<void> {
  return AXIOS.delete(`/me/avatar/image`);
}

export function changePassword(changePasswordRequest: ChangePasswordRequest): AxiosPromise<void> {
  return AXIOS.post('/me/change_password', changePasswordRequest);
}

export const UPDATE_MY_PROFILE_VALIDATOR = new Schema({
  name: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'me.validator.name.required',
        defaultMessage: 'Please provide a name',
      }),
    },
    nameLengthValidator,
  ],
});

export const changePasswordValidator = (passwordRequirements: PasswordRequirements) =>
  new Schema({
    currentPassword: PASSWORD_VALIDATOR,
    ...verifiedPasswordDescriptor(passwordRequirements),
  });
