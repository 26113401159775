import { Tab, styled } from '@mui/material';

const InlineIconTab = styled(Tab)(({ theme }) => ({
  'minHeight': 48,
  '&.MuiTab-root': {
    'flexDirection': 'row',
    '& *:first-child': {
      fontSize: '1.25rem',
      marginRight: theme.spacing(1),
      marginBottom: 0,
    },
  },
})) as typeof Tab;

export default InlineIconTab;
