import React from 'react';

import { useTheme } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';

import { ColorAvatar, ContentHeader } from '../../components';

interface SystemHeaderProps {
  icon: React.ComponentType<SvgIconProps>;
  title: string;
}

const SystemHeader = ({ title, icon: Icon }: SystemHeaderProps) => {
  const theme = useTheme();
  return (
    <ContentHeader
      id="system-header"
      title={title}
      avatar={
        <ColorAvatar size="large" color={theme.palette.primary.main}>
          <Icon sx={{ fontSize: 50 }} />
        </ColorAvatar>
      }
    />
  );
};

export default SystemHeader;
