import { FC } from 'react';

import * as ProjectsApi from '../../api/projects';
import { ProjectDetail } from '../../types';

import FilteredAutocomplete, { BasicFilteredAutocompleteProps } from './FilteredAutocomplete';

type ProjectAutocompleteProps = BasicFilteredAutocompleteProps<ProjectDetail>;

const fetchOptions = async (filter: string) => {
  const response = await ProjectsApi.getProjects({ page: 0, size: 100, filter });
  return response.data.results;
};

const renderOption = (project: ProjectDetail) => project.name;
const getOptionSelected = (option: ProjectDetail, value: ProjectDetail) => option.key === value.key;
const getOptionLabel = (project: ProjectDetail) => project.name;

const ProjectAutocomplete: FC<ProjectAutocompleteProps> = (props) => (
  <FilteredAutocomplete
    {...props}
    fetchOptions={fetchOptions}
    renderOption={renderOption}
    getOptionSelected={getOptionSelected}
    getOptionLabel={getOptionLabel}
  />
);

export default ProjectAutocomplete;
