import { FC } from 'react';
import { Text } from '@react-pdf/renderer';

import { PdfDocumentLayout, resultsPdfStyles } from '../../../../components';
import {
  SubmissionDetail,
  SUBMISSION_OUTCOME_METADATA,
  SubmissionOutcome,
  SUBMISSION_REJECTION_METADATA,
  MediaDetail,
} from '../../../../types';

import PdfSubmissionSummary from './shared/PdfSubmissionSummary';
import { PdfSubmissionData, TaskResultSections } from './result-pdf';

export interface TaskFilters {
  hideNotFinished: boolean;
  hideZeroProcessedTasks: boolean;
  hideZeroProcessedRows: boolean;
}

interface ResultPdfProps {
  title: string;
  submission: SubmissionDetail;
  taskFilters: TaskFilters;
  logo?: MediaDetail;
}

const ResultPdf: FC<ResultPdfProps> = ({ title, submission, taskFilters, logo }) => (
  <PdfDocumentLayout documentTitle={title} logo={logo}>
    {submission.outcome && (
      <Text style={resultsPdfStyles.subtitle}>
        {SUBMISSION_OUTCOME_METADATA[submission.outcome].description}
        {submission.outcome === SubmissionOutcome.REJECTED && submission.rejection && (
          <>&nbsp;{SUBMISSION_REJECTION_METADATA[submission.rejection].qualifier}</>
        )}
      </Text>
    )}
    <PdfSubmissionSummary submission={submission} />
    <PdfSubmissionData submission={submission} />
    <TaskResultSections submission={submission} taskFilters={taskFilters} />
  </PdfDocumentLayout>
);

export default ResultPdf;
