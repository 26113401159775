import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, View } from '@react-pdf/renderer';

import { ApplyActionMapTaskResult } from '../../../../../types';
import { substringRuleNameFromPath } from '../../taskResultUtils';
import { PdfTableRow, PdfTableCell } from '../components';
import { OmittedRowsMessage } from './TaskResultSections';

export const ApplyActionMapTaskHeader: FC = () => (
  <PdfTableRow header>
    <PdfTableCell colSpan="quarter" header>
      <Text>
        <FormattedMessage
          id="submission.resultsPdf.applyActionMapTaskResult.ruleHeader"
          defaultMessage="Rule"
        />
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="quarter" header>
      <Text>
        <FormattedMessage
          id="submission.resultsPdf.applyActionMapTaskResult.actionHeader"
          defaultMessage="Action"
        />
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="quarter" header number>
      <Text>
        <FormattedMessage
          id="submission.resultsPdf.applyActionMapTaskResult.resultHeader"
          defaultMessage="Result"
        />
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="quarter" header number>
      <Text>
        <FormattedMessage
          id="submission.resultsPdf.applyActionMapTaskResult.errorHeader"
          defaultMessage="Errors"
        />
      </Text>
    </PdfTableCell>
  </PdfTableRow>
);

interface ApplyActionMapTaskResultContentProps {
  task: ApplyActionMapTaskResult;
  hideZeroProcessedRows: boolean;
}

export const ApplyActionMapTaskResultContent: FC<ApplyActionMapTaskResultContentProps> = ({
  task: { actionmaps },
  hideZeroProcessedRows,
}) => {
  const filteredRows = hideZeroProcessedRows
    ? actionmaps.filter(({ ruleSummary: { processed } }) => processed !== 0)
    : actionmaps;
  const filteredRowCount = actionmaps.length - filteredRows.length;
  return (
    <View>
      {filteredRows.map(({ ruleSummary, actionSummary }, index) => (
        <PdfTableRow key={index}>
          <PdfTableCell colSpan="quarter">
            <Text>{substringRuleNameFromPath(ruleSummary.path)}</Text>
          </PdfTableCell>
          <PdfTableCell colSpan="quarter">
            <Text>{substringRuleNameFromPath(actionSummary.path)}</Text>
          </PdfTableCell>
          <PdfTableCell colSpan="quarter" number>
            <Text>
              <FormattedMessage
                id="submission.resultsPdf.applyActionMapTaskResult.resultData"
                defaultMessage="{count} non-conformances"
                values={{ count: ruleSummary.count }}
              />
            </Text>
          </PdfTableCell>
          <PdfTableCell colSpan="quarter" number>
            <Text>{ruleSummary.errors}</Text>
          </PdfTableCell>
        </PdfTableRow>
      ))}
      <OmittedRowsMessage omittedRowCount={filteredRowCount} />
    </View>
  );
};
