import { FC } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import StorageIcon from '@mui/icons-material/Storage';

import { AppDecorator, AppTabs, RouterTabs, RouterTab } from '../../../components';
import { ErrorBlock } from '../../../contexts/error-block';
import { intl } from '../../../Internationalization';
import { useRequiredParams, useRouterTab } from '../../../hooks';

import SystemHeader from '../SystemHeader';
import DeletionJobs from './DeletionJobs';
import DeletionJob from './DeletionJob';
import { AccountEvents } from '../../components/account-events';

const CurrentDeletionJob = () => {
  const { deletionJobId } = useRequiredParams<{ deletionJobId: string }>();
  return <DeletionJob deletionJobId={parseInt(deletionJobId)} />;
};

const StorageRouting: FC = () => {
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="storage-tabs" value={routerTab}>
      <RouterTab
        id="deletion-jobs-tab"
        value="deletion_jobs"
        label={intl.formatMessage({
          id: 'storage.routing.tabs.deletionJobs',
          defaultMessage: 'Deletion Jobs',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="account-events-tab"
        value="account_events"
        label={intl.formatMessage({
          id: 'storage.routing.tabs.accountEvents',
          defaultMessage: 'Account Events',
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  return (
    <AppDecorator renderTabs={renderTabs}>
      <SystemHeader
        title={intl.formatMessage({
          id: 'storage.routing.title',
          defaultMessage: 'Storage and logging',
        })}
        icon={StorageIcon}
      />
      <AppTabs />
      <Box p={3}>
        <ErrorBlock>
          <Routes>
            <Route path="deletion_jobs" element={<DeletionJobs />} />
            <Route path="deletion_jobs/:deletionJobId/*" element={<CurrentDeletionJob />} />
            <Route path="account_events" element={<AccountEvents />} />
            <Route path="*" element={<Navigate to="deletion_jobs" replace />} />
          </Routes>
        </ErrorBlock>
      </Box>
    </AppDecorator>
  );
};

export default StorageRouting;
