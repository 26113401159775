export const stringToBoolean = (value: string) => {
  switch (value) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return undefined;
  }
};

export const booleanToString = (value?: boolean) => {
  if (value === undefined) {
    return '';
  }
  return value ? 'true' : 'false';
};
