import { FC } from 'react';

import { Loading } from '../';

import TableNoContentRow, { TableNoContentRowProps } from './TableNoContentRow';

interface TableLoadingRowProps extends TableNoContentRowProps {
  colSpan: number;
  height?: number;
  my?: number;
}

const TableLoadingRow: FC<TableLoadingRowProps> = ({ ...rest }) => (
  <TableNoContentRow {...rest}>
    <Loading />
  </TableNoContentRow>
);

export default TableLoadingRow;
