import { startOfDay, sub } from 'date-fns';
import { FC, PropsWithChildren, useContext, useState } from 'react';
import { DashboardState } from '../../screens/dashboard/Dashboard';

import { GlobalStateContext } from './context';

const initializeDashboardState = () => {
  const end = startOfDay(new Date());
  return {
    globalFilters: {
      dateRange: {
        start: sub(end, { months: 1 }),
        end,
      },
      includeRejected: false,
    },
    specificationFilters: {
      dateRange: {
        start: sub(end, { months: 6 }),
        end,
      },
      project: null,
      specification: null,
      assignment: null,
      includeRejected: false,
    },
  };
};

const Global: FC<PropsWithChildren> = ({ children }) => {
  const [dashboardState, setDashboardState] = useState<DashboardState>(initializeDashboardState());
  return (
    <GlobalStateContext.Provider
      value={{
        dashboardState,
        setDashboardState,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useDashboardState = () => {
  const { dashboardState, setDashboardState } = useContext(GlobalStateContext);
  return [dashboardState, setDashboardState] as const;
};

export default Global;
