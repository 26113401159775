import { FC } from 'react';
import { Text } from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';

import { sharedPdfStyles } from '../../../../../components';
import { ReportProblemOutlinedWarningSvg } from '../../../../../components/pdf/PdfIcons';

import { TwoColumnTable } from './';
import { PdfSection, PdfSectionHeader } from '../components';

interface MissingClassesProps {
  missingClasses: string[];
}

const MissingClasses: FC<MissingClassesProps> = ({ missingClasses }) => {
  if (!missingClasses.length) {
    return null;
  }

  return (
    <PdfSection gutterBottom>
      <PdfSectionHeader>
        <ReportProblemOutlinedWarningSvg
          style={[sharedPdfStyles.mediumIcon, sharedPdfStyles.iconMarginRight]}
        />
        <Text style={[sharedPdfStyles.sectionHeaderText]}>
          <FormattedMessage
            id="submission.validateSchemaPdf.missingClasses.title"
            defaultMessage="Classes not supplied"
          />
        </Text>
      </PdfSectionHeader>
      <TwoColumnTable data={missingClasses} />
    </PdfSection>
  );
};

export default MissingClasses;
