import { useContext, useState, FC } from 'react';
import { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import { Container, Typography, Grid } from '@mui/material';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MapIcon from '@mui/icons-material/Map';
import StopIcon from '@mui/icons-material/Stop';
import DoneIcon from '@mui/icons-material/Done';

import * as SubmissionAPI from '../../../../../../api/submission';
import { extractErrorMessage } from '../../../../../../api/endpoints';
import { PaddedPaper, MessageBox, FullWidthButton } from '../../../../../../components';
import { AppBarStatsContext } from '../../../../../../contexts/app-bar-stats';
import { intl } from '../../../../../../Internationalization';
import {
  SubmissionState,
  ACTIVE_TASK_STATES,
  SubmissionOutcome,
  TASK_STATE_METADATA,
} from '../../../../../../types';

import TaskResults from '../../../../../components/submission/TaskResults';

import { OpenSubmissionContext } from '../OpenSubmissionContext';
import SubmissionAutomation from './SubmissionAutomation';

const SubmissionProcess: FC = () => {
  const appBarStatsContext = useContext(AppBarStatsContext);
  const { submissionUpdated, submission } = useContext(OpenSubmissionContext);
  const [processing, setProcessing] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();

  const finishSubmission = () => {
    setProcessing(true);
    SubmissionAPI.finish(submission.reference)
      .then((response) => {
        submissionUpdated(response.data);
      })
      .catch((error: AxiosError) => {
        setProcessing(false);
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'openSubmission.processSubmission.finishError',
              defaultMessage: 'Failed to finish Submission Schema',
            })
          ),
          { variant: 'error' }
        );
      });
  };

  const cancelCurrentSubmission = () => {
    setCancelling(true);
    SubmissionAPI.cancel(submission.reference)
      .then((response) => {
        submissionUpdated(response.data);
        appBarStatsContext.refresh();
      })
      .catch((error: AxiosError) => {
        setCancelling(false);
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'openSubmission.processSubmission.cancelError',
              defaultMessage: 'Failed to cancel submission',
            })
          ),
          { variant: 'error' }
        );
      });
  };

  const resumeSubmission = () => {
    setProcessing(true);
    SubmissionAPI.run(submission.reference)
      .then((response) => {
        submissionUpdated(response.data);
        setProcessing(false);
      })
      .catch((error: AxiosError) => {
        setProcessing(false);
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'openSubmission.processSubmission.resumeError',
              defaultMessage: 'Failed to resume Submission Schema',
            })
          ),
          { variant: 'error' }
        );
      });
  };

  const handleViewMap = () => {
    navigate(`${location.pathname}/map`);
  };

  const renderContinueButton = () => {
    if (submission.outcome === SubmissionOutcome.REJECTED) {
      return (
        <FullWidthButton
          name="finishSubmission"
          label={intl.formatMessage({
            id: 'openSubmission.processSubmission.acknowledgeButton',
            defaultMessage: 'Acknowledge',
          })}
          disabled={cancelling || !submission.canUpdateSubmission}
          processing={processing}
          color="primary"
          startIcon={<DoneIcon />}
          onClick={cancelCurrentSubmission}
        />
      );
    }

    if (
      submission.state === SubmissionState.RUNNING ||
      submission.state === SubmissionState.PAUSED
    ) {
      return (
        <FullWidthButton
          name="resumeSubmission"
          label={intl.formatMessage({
            id: 'openSubmission.processSubmission.resumeButton',
            defaultMessage: 'Resume',
          })}
          disabled={
            submission.state !== SubmissionState.PAUSED ||
            cancelling ||
            !submission.canUpdateSubmission
          }
          processing={processing}
          color="primary"
          endIcon={<PlayArrowIcon />}
          onClick={resumeSubmission}
        />
      );
    }
    return (
      <FullWidthButton
        name="finishSubmission"
        label={intl.formatMessage({
          id: 'openSubmission.processSubmission.continueButton',
          defaultMessage: 'Continue',
        })}
        disabled={
          submission.state !== SubmissionState.PROCESSED ||
          cancelling ||
          !submission.canUpdateSubmission
        }
        processing={processing}
        color="primary"
        endIcon={<PlayArrowIcon />}
        onClick={finishSubmission}
      />
    );
  };

  const renderStatus = () => {
    switch (submission.state) {
      case SubmissionState.PAUSED:
        if (submission.outcome === SubmissionOutcome.REJECTED) {
          return (
            <MessageBox
              message={intl.formatMessage({
                id: 'openSubmission.processSubmission.rejected',
                defaultMessage: 'The submission has been rejected due to non-conformances.',
              })}
              level="error"
            />
          );
        }
        return (
          <MessageBox
            message={intl.formatMessage({
              id: 'openSubmission.processSubmission.paused',
              defaultMessage: 'The submission is paused, click resume to continue.',
            })}
            level="warning"
          />
        );
      case SubmissionState.RUNNING:
      case SubmissionState.DOWNLOAD:
      case SubmissionState.DOWNLOADING:
        return (
          <MessageBox
            message={intl.formatMessage({
              id: 'openSubmission.processSubmission.inProgress',
              defaultMessage: 'Submission in progress.',
            })}
            level="info"
          />
        );
      case SubmissionState.PROCESSED:
        return (
          <MessageBox
            message={intl.formatMessage({
              id: 'openSubmission.processSubmission.processed',
              defaultMessage: 'The submission has been processed, click to finish the submission.',
            })}
            level="success"
          />
        );
    }
  };

  const renderContent = () => {
    const { taskResults } = submission;
    const tasksCount = taskResults.length - 1;
    const currentTaskIndex = taskResults.findIndex((taskResult) =>
      ACTIVE_TASK_STATES.includes(taskResult.status)
    );
    const currentTask = currentTaskIndex > 0 ? taskResults[currentTaskIndex] : taskResults[0];

    return (
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <PaddedPaper fillVertical>
            <Typography variant="h5" gutterBottom>
              <FormattedMessage
                id="openSubmission.processSubmission.status.title"
                defaultMessage="Submission Status"
              />
            </Typography>
            <Grid container spacing={3}>
              <Grid item xl={6} xs={12}>
                <Typography variant="h6">
                  <FormattedMessage
                    id="openSubmission.processSubmission.submissionCount"
                    defaultMessage="Number of tasks: {count}"
                    values={{ count: tasksCount }}
                  />
                </Typography>
                <Typography variant="subtitle2">
                  <FormattedMessage
                    id="openSubmission.processSubmission.submissionStatus"
                    defaultMessage="Current status: {status}"
                    values={{ status: TASK_STATE_METADATA[currentTask.status].label }}
                  />
                </Typography>
              </Grid>
              <Grid item xl={6} xs={12}>
                <FullWidthButton
                  id="view-map"
                  label={intl.formatMessage({
                    id: 'openSubmission.processSubmission.viewMapButton',
                    defaultMessage: 'View Results on Map',
                  })}
                  disabled={processing || submission.state === SubmissionState.RUNNING}
                  color="secondary"
                  startIcon={<MapIcon />}
                  onClick={handleViewMap}
                />
              </Grid>
            </Grid>
          </PaddedPaper>
        </Grid>
        <Grid item md={6} xs={12}>
          <SubmissionAutomation />
        </Grid>
        <Grid item xs={12}>
          <TaskResults submission={submission} />
        </Grid>
        <Grid item xs={12}>
          {renderStatus()}
        </Grid>
      </Grid>
    );
  };

  return (
    <Container maxWidth="xl" disableGutters id="my-assignment-open-submission-process">
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          {renderContent()}
        </Grid>
        <Grid item xs={6}>
          <FullWidthButton
            name="cancelSubmission"
            label={intl.formatMessage({
              id: 'openSubmission.processSubmission.cancelButton',
              defaultMessage: 'Cancel',
            })}
            disabled={
              processing ||
              submission.outcome === SubmissionOutcome.REJECTED ||
              !submission.canUpdateSubmission
            }
            processing={cancelling}
            color="secondary"
            startIcon={<StopIcon />}
            onClick={cancelCurrentSubmission}
          />
        </Grid>
        <Grid item xs={6}>
          {renderContinueButton()}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SubmissionProcess;
