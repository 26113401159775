import { SvgIconProps } from '@mui/material';
import { MediaDetail, PageResponse, SimplePageRequest } from './shared';

import SuccessIcon from '../components/icons/SuccessIcon';
import AutorenewInfoIcon from '../components/icons/AutorenewInfoIcon';
import HourglassInfoIcon from '../components/icons/HourglassInfoIcon';
import CancelledIcon from '../components/icons/CancelledIcon';
import FailedIcon from '../components/icons/FailedIcon';
import { intl } from '../Internationalization';

export type ProjectImportJobsRequest = SimplePageRequest;

export type ProjectImportJobsResponse = PageResponse<ProjectImportJobDetail>;

export enum ProjectImportJobState {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

export interface ProjectImportJobStateMetadata {
  label: string;
  icon: React.ComponentType<SvgIconProps>;
}

export const PROJECT_IMPORT_JOB_STATE_METADATA: {
  [type in ProjectImportJobState]: ProjectImportJobStateMetadata;
} = {
  PENDING: {
    label: intl.formatMessage({
      id: 'projectImport.jobStateMetadata.pending',
      defaultMessage: 'Pending',
    }),
    icon: HourglassInfoIcon,
  },
  RUNNING: {
    label: intl.formatMessage({
      id: 'projectImport.jobStateMetadata.running',
      defaultMessage: 'Running',
    }),
    icon: AutorenewInfoIcon,
  },
  CANCELLED: {
    label: intl.formatMessage({
      id: 'projectImport.jobStateMetadata.cancelled',
      defaultMessage: 'Cancelled',
    }),
    icon: CancelledIcon,
  },
  COMPLETE: {
    label: intl.formatMessage({
      id: 'projectImport.jobStateMetadata.complete',
      defaultMessage: 'Complete',
    }),
    icon: SuccessIcon,
  },
  FAILED: {
    label: intl.formatMessage({
      id: 'projectImport.jobStateMetadata.failed',
      defaultMessage: 'Failed',
    }),
    icon: FailedIcon,
  },
};

export interface ProjectImportJobDetail {
  id: number;
  state: ProjectImportJobState;
  createdAt: string;
  startedAt: string;
  finishedAt: string;
  cancelledAt: string;
  importCount: number;
  data: MediaDetail;
  log: MediaDetail;
}

export interface ExportMetadata {
  exportFormat: string;
  entities: Record<string, ExportItemMetadata>;
  dependencies: Record<string, string[]>;
  inverseDependencies: Record<string, string[]>;
}

export interface ExportItemMetadata {
  key: string;
  name: string;
  exportPath: string;
}
