import { FC, useContext } from 'react';

import { ProjectAvatar, ContentHeader } from '../../../components';

import { MyAssignmentContext } from './MyAssignmentContext';

const MyAssignmentHeader: FC = () => {
  const { project, specification } = useContext(MyAssignmentContext);
  const name = `${project.name} - ${specification.name}`;

  return (
    <ContentHeader
      id="assignments-header"
      title={name}
      avatar={<ProjectAvatar project={project} size="large" />}
    />
  );
};

export default MyAssignmentHeader;
