import { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';

import SaveIcon from '@mui/icons-material/Save';
import { Typography, Container } from '@mui/material';

import * as SiteSettingsApi from '../../../api/siteSettings';
import { extractErrorMessage } from '../../../api/endpoints';
import { intl } from '../../../Internationalization';
import { onEnterCallback, validate } from '../../../util';
import { PaddedPaper, ValidatedTextField, FormButtons, DefaultButton } from '../../../components';

import { SiteContext } from './SiteContext';

interface SettingFields {
  name: string;
  rootUrl: string;
  loginTitle: string;
}

const Settings: FC = () => {
  const { siteSettings, refreshApplicationContext, siteSettingsUpdated } = useContext(SiteContext);
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState<string>(siteSettings.name);
  const [rootUrl, setRootUrl] = useState<string>(siteSettings.rootUrl);
  const [loginTitle, setLoginTitle] = useState<string>(siteSettings.loginTitle);

  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const validateAndSave = async () => {
    setProcessing(true);
    setFieldErrors(undefined);
    try {
      updateSettings(
        await validate(SiteSettingsApi.SITE_SETTINGS_VALIDATOR, {
          name,
          rootUrl,
          loginTitle,
        })
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const submitOnEnter = onEnterCallback(validateAndSave);

  const updateSettings = async (settings: SettingFields) => {
    try {
      const response = await SiteSettingsApi.updateSiteSettings({
        ...SiteSettingsApi.extractConfigurableSiteSettings(siteSettings),
        ...settings,
      });
      siteSettingsUpdated(response.data);
      refreshApplicationContext();
      enqueueSnackbar(
        intl.formatMessage({
          id: 'site.settings.fetchSettingsSuccess',
          defaultMessage: 'Site settings have successfully been updated',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'site.settings.fetchSettingsError',
            defaultMessage: 'Failed to update settings',
          })
        ),
        { variant: 'error' }
      );
    }
    setProcessing(false);
  };

  return (
    <Container maxWidth="md" id="system-site-settings" disableGutters>
      <PaddedPaper>
        <Typography variant="h5" gutterBottom>
          <FormattedMessage id="site.settings.title" defaultMessage="Site Settings" />
        </Typography>
        <ValidatedTextField
          required
          name="name"
          label={intl.formatMessage({
            id: 'site.settings.siteName.label',
            defaultMessage: 'Site Name',
          })}
          value={name}
          onChange={(e) => setName(e.target.value)}
          onKeyDown={submitOnEnter}
          fieldErrors={fieldErrors}
          disabled={processing}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          required
          name="rootUrl"
          label={intl.formatMessage({
            id: 'site.settings.rootUrl.label',
            defaultMessage: 'Root URL',
          })}
          value={rootUrl}
          onChange={(e) => setRootUrl(e.target.value)}
          onKeyDown={submitOnEnter}
          fieldErrors={fieldErrors}
          disabled={processing}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          required
          name="loginTitle"
          label={intl.formatMessage({
            id: 'site.settings.loginTitle.label',
            defaultMessage: 'Login Title',
          })}
          value={loginTitle}
          onChange={(e) => setLoginTitle(e.target.value)}
          onKeyDown={submitOnEnter}
          fieldErrors={fieldErrors}
          disabled={processing}
          margin="normal"
          variant="outlined"
        />
        <FormButtons>
          <DefaultButton
            name="updateSiteSettings"
            onClick={validateAndSave}
            disabled={processing}
            startIcon={<SaveIcon />}
          >
            <FormattedMessage id="site.settings.updateButton" defaultMessage="Update Settings" />
          </DefaultButton>
        </FormButtons>
      </PaddedPaper>
    </Container>
  );
};

export default Settings;
