import { FC } from 'react';

import { UserSummary } from '../../types';
import * as UsersApi from '../../api/users';
import { NamedAccountAvatar } from '../avatars';
import FilteredAutocomplete, { BasicFilteredAutocompleteProps } from './FilteredAutocomplete';

interface UserAutocompleteProps extends BasicFilteredAutocompleteProps<UserSummary> {
  includeAuxiliaryUser?: boolean;
}

const fetchOptions = async (filter: string, includeAuxiliaryUser: boolean) => {
  const response = await UsersApi.getUsers({ page: 0, size: 100, filter, includeAuxiliaryUser });
  return response.data.results;
};

const renderOption = (user: UserSummary) => <NamedAccountAvatar user={user} showEmail />;
const getOptionSelected = (option: UserSummary, value: UserSummary) => option.key === value.key;
const getOptionLabel = (user: UserSummary) => `${user.name} (${user.email})`;

const UserAutocomplete: FC<UserAutocompleteProps> = ({ includeAuxiliaryUser = false, ...rest }) => (
  <FilteredAutocomplete
    {...rest}
    fetchOptions={(filter: string) => fetchOptions(filter, includeAuxiliaryUser)}
    renderOption={renderOption}
    getOptionSelected={getOptionSelected}
    getOptionLabel={getOptionLabel}
  />
);

export default UserAutocomplete;
