import { WarningIcon, ErrorIcon } from '../../../components/icons';
import { ErrorSvg, WarningSvg } from '../../../components/pdf/PdfIcons';
import { intl } from '../../../Internationalization';
import {
  TASK_STATE_METADATA,
  TaskResult,
  TaskResultKind,
  TaskStateMetadata,
  TaskState,
  TaskConformance,
} from '../../../types';

export const substringRuleNameFromPath = (path: string) => path.slice(path.lastIndexOf('/') + 1);

export const getPercentageRemainder = (value: number, processed: number) => {
  // This is to provide a valid percentage when both value and total are 0
  if (processed === 0) {
    return 100;
  }
  return roundPercentageTwoDecimals(100 - (value / processed) * 100);
};

const roundPercentageTwoDecimals = (percentage: number) => Math.floor(percentage * 100) / 100;

export const getTaskStateMetadata = (
  task: TaskResult,
  taskConformance?: TaskConformance
): TaskStateMetadata => {
  if (task.status === TaskState.NOT_STARTED) {
    return TASK_STATE_METADATA[task.status];
  }

  if (
    (task.kind === TaskResultKind.CheckRulesTask ||
      task.kind === TaskResultKind.ApplyActionMapTask) &&
    task.status !== TaskState.SKIPPED
  ) {
    const summaryValidPercent = getPercentageRemainder(task.count, task.processed);
    return {
      label:
        task.kind === TaskResultKind.ApplyActionMapTask && !taskConformance
          ? intl.formatMessage(
              {
                id: 'submission.taskResultsUtils.getTaskStateMetadata.featuresProcessed.label',
                defaultMessage:
                  '{valid} / {processed} features processed {errors,plural,=0{}one{with {errors} error}other{with {errors} errors}}',
              },
              {
                valid: task.processed - task.count,
                processed: task.processed,
                errors: task.errors,
              }
            )
          : intl.formatMessage(
              {
                id: 'submission.taskResultsUtils.getTaskStateMetadata.label',
                defaultMessage:
                  '{valid} / {processed} features valid {errors,plural,=0{}one{with {errors} error} other{with {errors} errors}}',
              },
              {
                valid: task.processed - task.count,
                processed: task.processed,
                errors: task.errors,
              }
            ),
      icon: task.errors
        ? ErrorIcon
        : summaryValidPercent !== 100
        ? WarningIcon
        : TASK_STATE_METADATA[task.status].icon,
      pdfIcon: task.errors
        ? ErrorSvg
        : summaryValidPercent !== 100
        ? WarningSvg
        : TASK_STATE_METADATA[task.status].pdfIcon,
    };
  }

  if (task.kind === TaskResultKind.ValidateSchemaTask && task.status !== TaskState.SKIPPED) {
    return {
      label: intl.formatMessage(
        {
          id: 'submission.taskResultsUtils.getTaskStateMetadata.validateSchema.label',
          defaultMessage:
            '{count} validation failures {errors,plural,=0{}one{with {errors} error}other{with {errors} errors}}',
        },
        {
          count: task.count,
          errors: task.errors,
        }
      ),
      icon: task.errors
        ? ErrorIcon
        : task.count
        ? WarningIcon
        : TASK_STATE_METADATA[task.status].icon,
      pdfIcon: task.errors
        ? ErrorSvg
        : task.count
        ? WarningSvg
        : TASK_STATE_METADATA[task.status].pdfIcon,
    };
  }

  if (task.errors) {
    return {
      label: intl.formatMessage({
        id: 'submission.taskResultsUtils.getTaskStateMetadata.finishedWithErrors',
        defaultMessage: 'Finished with errors',
      }),
      icon: ErrorIcon,
      pdfIcon: ErrorSvg,
    };
  }

  return TASK_STATE_METADATA[task.status];
};
