import React, { FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import {
  TableRow,
  Box,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Typography,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import PersonIcon from '@mui/icons-material/Person';

import { useBrowseRequest } from '../../hooks';
import { intl } from '../../Internationalization';
import * as SubmissionsApi from '../../api/submissions';
import * as SubmissionApi from '../../api/submission';
import { AuthenticatedContext } from '../../contexts/authentication';
import {
  dataStoreNameFromPath,
  dateTimeFormat,
  onEnterCallback,
  toDateQueryOffsetDateTimeRange,
  validate,
} from '../../util';
import {
  ProjectDetail,
  SpecificationDetail,
  AssignmentDetail,
  SubmissionSummary,
  SubmissionState,
  DateRange,
  SupplierDetail,
  UserDetail,
} from '../../types';
import {
  BrowseTable,
  FilterPagination,
  DefaultButton,
  browseTableBody,
  FilterDateRange,
  FilterBar,
  FilterContainer,
  PaddedDialogActions,
  ValidatedTextField,
  SubmissionOutcomeIcon,
  FilterGroup,
  NamedSupplierAvatar,
  NamedAccountAvatar,
  NowrapTableCell,
  MinWidthTableCell,
  ColumnSelector,
  SelectedTableCells,
  ColumnStyle,
  ProjectAutocomplete,
  SpecificationAutocomplete,
  AssignmentAutocomplete,
  StyledTableHead,
  UserAutocomplete,
  ActiveSupplierAutocomplete,
} from '../../components';
import SubmissionOutcomeSelector, {
  SubmissionOutcomeSelections,
  toSubmissionOutcomeArray,
} from './SubmissionOutcomeSelector';
import { deleteStoredDB, storeDb, SUBMISSIONS_COLUMNS_SELECTION_NAME } from '../../store';

const COLUMN_KEYS = [
  'user',
  'supplier',
  'created_at',
  'uploaded_data',
  'project',
  'specification',
  'assignment',
  'outcome',
] as const;
type ColumnKey = (typeof COLUMN_KEYS)[number];
export type SubmissionsColumnSelections = Record<ColumnKey, boolean>;

const COLUMN_LABELS: Record<ColumnKey, string> = {
  user: intl.formatMessage({
    id: 'submissions.table.userHeader',
    defaultMessage: 'User',
  }),
  supplier: intl.formatMessage({
    id: 'submissions.table.supplierHeader',
    defaultMessage: 'Supplier',
  }),
  created_at: intl.formatMessage({
    id: 'submissions.table.createdAtHeader',
    defaultMessage: 'Created At',
  }),
  uploaded_data: intl.formatMessage({
    id: 'submissions.table.uploadedDataHeader',
    defaultMessage: 'Uploaded Data',
  }),
  project: intl.formatMessage({
    id: 'submissions.table.projectHeader',
    defaultMessage: 'Project',
  }),
  specification: intl.formatMessage({
    id: 'submissions.table.specificationHeader',
    defaultMessage: 'Specification',
  }),
  assignment: intl.formatMessage({
    id: 'submissions.table.assignmentHeader',
    defaultMessage: 'Assignment',
  }),
  outcome: intl.formatMessage({
    id: 'submissions.table.outcomeHeader',
    defaultMessage: 'Outcome',
  }),
};

const COLUMN_STYLES: Partial<Record<ColumnKey, ColumnStyle>> = {
  created_at: {
    component: NowrapTableCell,
  },
  outcome: {
    componentProps: {
      align: 'center',
    },
  },
};

const SubmissionsTableBody = browseTableBody<SubmissionSummary>();

const PAGE_SIZE = 10;

const submissionRow = (
  submission: SubmissionSummary,
  columnSelections: SubmissionsColumnSelections,
  pathname: string
) => {
  const { reference, createdAt, assignment, user } = submission;
  const { supplier, specification } = assignment;
  const project = specification.project;

  const cellValues: Record<ColumnKey, JSX.Element | string> = {
    user: <NamedAccountAvatar user={user} />,
    supplier: <NamedSupplierAvatar supplier={supplier} />,
    created_at: dateTimeFormat(createdAt),
    uploaded_data: (
      <>
        {Object.entries(submission.inputs).map(([key, file]) => (
          <Typography key={key} variant="body2">
            <strong>{dataStoreNameFromPath(key)}:</strong> {file.filename}
          </Typography>
        ))}
      </>
    ),
    project: project.name,
    specification: specification.name,
    assignment: assignment.reference,
    outcome: <SubmissionOutcomeIcon submission={submission} />,
  };

  return (
    <TableRow key={reference}>
      <SelectedTableCells
        columnKeys={COLUMN_KEYS}
        columnSelections={columnSelections}
        values={cellValues}
        styles={COLUMN_STYLES}
      />
      <MinWidthTableCell>
        <DefaultButton
          className="Submissions-navigateToSubmission"
          component={RouterLink}
          color="grey"
          to={`${pathname}/${reference}`}
          aria-label={intl.formatMessage({
            id: 'submissions.navigateToSubmission.ariaLabel',
            defaultMessage: 'Navigate to submission',
          })}
        >
          <ArrowRightIcon />
        </DefaultButton>
      </MinWidthTableCell>
    </TableRow>
  );
};

const SUBMISSIONS_COLUMNS_SELECTION_KEY = 'default';
const storeColumnSelections = async (newColumnSelections: SubmissionsColumnSelections) => {
  try {
    return (await storeDb).put(
      SUBMISSIONS_COLUMNS_SELECTION_NAME,
      newColumnSelections,
      SUBMISSIONS_COLUMNS_SELECTION_KEY
    );
  } catch (error) {
    console.error(error);
    deleteStoredDB();
  }
};

const Submissions: FC = () => {
  const {
    me: { receiverPermissions },
  } = useContext(AuthenticatedContext);

  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();
  const [submissionReference, setSubmissionReference] = useState<string>();
  const [jumpToDialogOpen, setJumpToDialogOpen] = useState<boolean>(false);
  const [columnSelections, setColumnSelections] = useState<SubmissionsColumnSelections>({
    user: true,
    supplier: true,
    created_at: true,
    uploaded_data: true,
    project: false,
    specification: false,
    assignment: false,
    outcome: true,
  });
  const [outcomeSelections, setOutcomeSelections] = useState<SubmissionOutcomeSelections>({
    SUCCESS: false,
    ERROR: false,
    CANCELLED: false,
    REJECTED: false,
  });

  const [dateRange, setDateRange] = useState<DateRange>({});
  const [project, setProject] = useState<ProjectDetail | null>(null);
  const [specification, setSpecification] = useState<SpecificationDetail | null>(null);
  const [assignment, setAssignment] = useState<AssignmentDetail | null>(null);

  const [supplier, setSupplier] = useState<SupplierDetail | null>(null);
  const [user, setUser] = useState<UserDetail | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const { request, response, processing, updateRequest, setPage } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, state: SubmissionState.FINISHED },
    onRequest: SubmissionsApi.getSubmissions,
  });

  useEffect(() => {
    const loadStoredColumnSelections = async () => {
      try {
        const storedSelections = await (
          await storeDb
        ).get(SUBMISSIONS_COLUMNS_SELECTION_NAME, SUBMISSIONS_COLUMNS_SELECTION_KEY);
        if (storedSelections) {
          setColumnSelections(storedSelections);
        }
      } catch (error) {
        console.error(error);
        deleteStoredDB();
      }
    };
    loadStoredColumnSelections();
  }, []);

  const handleColumnSelectionChange = (newColumnSelections: SubmissionsColumnSelections) => {
    setColumnSelections(newColumnSelections);
    storeColumnSelections(newColumnSelections);
  };

  const handleDateRangeChange = (selectedDateRange: DateRange) => {
    setDateRange(selectedDateRange);
    updateRequest({ createdAt: toDateQueryOffsetDateTimeRange(selectedDateRange) });
  };

  const handleProjectChange = (selectedProject: ProjectDetail | null) => {
    setProject(selectedProject);
    setSpecification(null);
    setAssignment(null);
    updateRequest({
      projectKey: selectedProject?.key,
      specificationKey: undefined,
      assignmentKey: undefined,
    });
  };

  const handleSpecificationChange = (selectedSpecification: SpecificationDetail | null) => {
    setSpecification(selectedSpecification);
    setAssignment(null);
    updateRequest({ specificationKey: selectedSpecification?.key, assignmentKey: undefined });
  };

  const handleAssignmentChange = (selectedAssignment: AssignmentDetail | null) => {
    setAssignment(selectedAssignment);
    updateRequest({ assignmentKey: selectedAssignment?.key });
  };

  const handleUserChange = (selectedUser: UserDetail | null) => {
    setUser(selectedUser);
    updateRequest({ userKey: selectedUser?.key });
  };

  const handleSupplierChange = (selectedSupplier: SupplierDetail | null) => {
    setSupplier(selectedSupplier);
    updateRequest({ supplierKey: selectedSupplier?.key });
  };

  const handleOutcomeSelectionsChange = (
    submissionOutcomeSelections: SubmissionOutcomeSelections
  ) => {
    setOutcomeSelections(submissionOutcomeSelections);
    updateRequest({ outcomes: toSubmissionOutcomeArray(submissionOutcomeSelections) });
  };

  const validateAndJump = async () => {
    try {
      setFieldErrors({});
      await validate(
        SubmissionApi.SUBMISSION_REFERENCE_VALIDATOR,
        { reference: submissionReference },
        { first: true }
      );
      navigate(`/submissions/${submissionReference}`);
    } catch (errors) {
      setFieldErrors(errors as ValidateFieldsError);
    }
  };

  const submitOnEnter = onEnterCallback(validateAndJump);

  const renderJumpTo = () => {
    return (
      <Box position="fixed" bottom={24} right={24} zIndex={100}>
        <Fab
          name="jumpToSubmission"
          color="primary"
          variant="extended"
          onClick={() => setJumpToDialogOpen(true)}
        >
          <SearchIcon />
          <Box ml={1}>
            <FormattedMessage id="submissions.jumpToButton" defaultMessage="Jump to" />
          </Box>
        </Fab>
        <Dialog
          id="jump-to-submission-dialog"
          open={jumpToDialogOpen}
          onClose={() => setJumpToDialogOpen(false)}
          aria-labelledby="jump-to-dialog-title"
        >
          <DialogTitle id="jump-to-dialog-title">
            <FormattedMessage
              id="submissions.jumpToDialog.title"
              defaultMessage="Jump To Submission"
            />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <FormattedMessage
                id="submissions.jumpToDialog.text"
                defaultMessage="Enter a Submission's reference in the input below and press jump to navigate to said Submission"
              />
            </DialogContentText>
            <ValidatedTextField
              label={intl.formatMessage({
                id: 'submissions.jumpToDialog.submissionReference.label',
                defaultMessage: 'Submission reference',
              })}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSubmissionReference(e.target.value)
              }
              autoFocus
              fullWidth
              name="reference"
              margin="normal"
              variant="outlined"
              onKeyDown={submitOnEnter}
              fieldErrors={fieldErrors}
            />
          </DialogContent>
          <PaddedDialogActions>
            <DefaultButton
              name="cancelJumpToSubmission"
              onClick={() => setJumpToDialogOpen(false)}
              color="secondary"
            >
              <FormattedMessage
                id="submissions.jumpToDialog.cancelButton"
                defaultMessage="Cancel"
              />
            </DefaultButton>
            <DefaultButton name="submitJumpToSubmission" onClick={validateAndJump}>
              <FormattedMessage
                id="submissions.jumpToDialog.jumpToButton"
                defaultMessage="Jump To"
              />
            </DefaultButton>
          </PaddedDialogActions>
        </Dialog>
      </Box>
    );
  };

  const renderFilterGroups = () => {
    const filterGroups: FilterGroup[] = [
      {
        name: 'createdAt',
        title: intl.formatMessage({
          id: 'submissions.filterGroup.createdAt.label',
          defaultMessage: 'Created At',
        }),
        icon: DateRangeIcon,
        component: (
          <FilterContainer>
            <FilterDateRange range={dateRange} onRangeUpdated={handleDateRangeChange} />
          </FilterContainer>
        ),
      },
    ];

    if (receiverPermissions) {
      filterGroups.push(
        {
          name: 'origin',
          title: intl.formatMessage({
            id: 'submissions.filterGroup.origin.label',
            defaultMessage: 'Origin',
          }),
          icon: AccountTreeIcon,
          component: (
            <FilterContainer>
              <ProjectAutocomplete
                id="project-select"
                name="project"
                label={intl.formatMessage({
                  id: 'submissions.projectFilter.label',
                  defaultMessage: 'Project',
                })}
                value={project}
                onChange={handleProjectChange}
                variant="standard"
              />
              <SpecificationAutocomplete
                id="specification-select"
                name="specification"
                label={intl.formatMessage({
                  id: 'submissions.specificationFilter.label',
                  defaultMessage: 'Specification',
                })}
                value={specification}
                onChange={handleSpecificationChange}
                variant="standard"
                projectKey={project?.key}
              />
              <AssignmentAutocomplete
                id="assignment-select"
                name="assignment"
                label={intl.formatMessage({
                  id: 'submissions.assignmentFilter.label',
                  defaultMessage: 'Assignment',
                })}
                value={assignment}
                onChange={handleAssignmentChange}
                variant="standard"
                projectKey={project?.key}
                specificationKey={specification?.key}
              />
            </FilterContainer>
          ),
        },
        {
          name: 'submittedBy',
          title: intl.formatMessage({
            id: 'submissions.filterGroup.submittedBy.label',
            defaultMessage: 'Submitted By',
          }),
          icon: PersonIcon,
          component: (
            <FilterContainer>
              <UserAutocomplete
                label={intl.formatMessage({
                  id: 'submissions.filterGroup.submittedBy.user.label',
                  defaultMessage: 'User',
                })}
                name="user"
                value={user}
                onChange={handleUserChange}
                variant="standard"
              />
              <ActiveSupplierAutocomplete
                label={intl.formatMessage({
                  id: 'submissions.filterGroup.submittedBy.supplier.label',
                  defaultMessage: 'Supplier',
                })}
                name="supplier"
                value={supplier}
                onChange={handleSupplierChange}
                variant="standard"
              />
            </FilterContainer>
          ),
        }
      );
    }

    filterGroups.push({
      name: 'outcome',
      title: intl.formatMessage({
        id: 'submissions.filterGroup.outcome.label',
        defaultMessage: 'Outcome',
      }),
      icon: AssignmentTurnedInIcon,
      component: (
        <FilterContainer>
          <SubmissionOutcomeSelector
            selections={outcomeSelections}
            onSelectionsUpdated={handleOutcomeSelectionsChange}
          />
        </FilterContainer>
      ),
    });

    return filterGroups;
  };

  return (
    <Box p={3} id="submissions">
      {renderJumpTo()}
      <FilterBar
        actions={
          <>
            <ColumnSelector
              columnKeys={COLUMN_KEYS}
              columnLabels={COLUMN_LABELS}
              columnSelections={columnSelections}
              onColumnSelectionsUpdated={handleColumnSelectionChange}
            />
            <FilterPagination
              page={request.page}
              size={request.size}
              total={response?.total}
              disabled={processing}
              setPage={setPage}
            />
          </>
        }
        filterGroups={renderFilterGroups()}
      />
      <BrowseTable>
        <StyledTableHead>
          <TableRow>
            <SelectedTableCells
              columnKeys={COLUMN_KEYS}
              columnSelections={columnSelections}
              values={COLUMN_LABELS}
              styles={COLUMN_STYLES}
            />
            <MinWidthTableCell>
              <FormattedMessage id="submissions.table.actionsHeader" defaultMessage="Actions" />
            </MinWidthTableCell>
          </TableRow>
        </StyledTableHead>
        <SubmissionsTableBody
          data={response?.results}
          mapToRow={(submission) => submissionRow(submission, columnSelections, location.pathname)}
          noDataMessage={intl.formatMessage({
            id: 'submissions.noSubmissions',
            defaultMessage: 'No matching submissions.',
          })}
          numCols={6}
        />
      </BrowseTable>
    </Box>
  );
};

export default Submissions;
