import { FC } from 'react';

import { Box, Typography, Skeleton } from '@mui/material';

const OpenSubmissionSkeleton: FC = () => (
  <Box display="flex" p={1}>
    <Box display="flex" flexGrow={1}>
      <Skeleton variant="rectangular" width={48} height={48} />
      <Box ml={2} flexGrow={1}>
        <Typography variant="h4" gutterBottom>
          <Skeleton />
        </Typography>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
        <Typography variant="caption">
          <Skeleton />
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default OpenSubmissionSkeleton;
