import React, { FC } from 'react';

import clsx from 'clsx';

import { SxProps, Theme, Box, Typography, SvgIconProps } from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

const MESSAGE_LEVEL_STYLES: Record<MessageBoxLevel, SxProps<Theme>> = {
  success: {
    backgroundColor: (theme) => theme.palette.success.main,
    color: (theme) => theme.palette.getContrastText(theme.palette.success.main),
  },
  warning: {
    backgroundColor: (theme) => theme.palette.warning.main,
    color: (theme) => theme.palette.getContrastText(theme.palette.warning.main),
  },
  error: {
    backgroundColor: (theme) => theme.palette.error.main,
    color: (theme) => theme.palette.getContrastText(theme.palette.error.main),
  },
  info: {
    backgroundColor: (theme) => theme.palette.info.main,
    color: (theme) => theme.palette.getContrastText(theme.palette.info.main),
  },
};

type MessageBoxLevel = 'warning' | 'success' | 'info' | 'error';
type MessageBoxSize = 'small' | 'medium' | 'large';

export interface MessageBoxProps {
  message: string;
  level: MessageBoxLevel;
  size?: MessageBoxSize;
  endAdornment?: React.ReactNode;
  gutterBottom?: boolean;
  className?: string;
  sx?: SxProps;
}

export interface LevelMetadata {
  icon: React.ComponentType<SvgIconProps>;
}

export const LEVEL_METADATA: { [type in MessageBoxLevel]: LevelMetadata } = {
  success: {
    icon: CheckCircleOutlineOutlinedIcon,
  },
  info: {
    icon: InfoOutlinedIcon,
  },
  warning: {
    icon: ReportProblemOutlinedIcon,
  },
  error: {
    icon: ErrorIcon,
  },
};

const getPadding = (size: MessageBoxSize) => {
  if (size === 'small') {
    return 1;
  } else if (size === 'large') {
    return 3;
  }
  return 2;
};

const MessageBox: FC<MessageBoxProps> = ({
  sx = [],
  message,
  level,
  size = 'medium',
  gutterBottom = false,
  endAdornment,
  className,
}) => {
  const MessageBoxIcon = LEVEL_METADATA[level].icon;

  return (
    <Box
      p={getPadding(size)}
      mb={gutterBottom ? 2 : 0}
      display="flex"
      alignItems="center"
      className={clsx('MessageBox-root', `MessageBox-${level}`, className)}
      sx={[MESSAGE_LEVEL_STYLES[level], ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <MessageBoxIcon />
      <Typography sx={{ ml: 2, flexGrow: 1 }} variant="body1">
        {message}
      </Typography>
      {endAdornment}
    </Box>
  );
};

export default MessageBox;
