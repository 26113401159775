import React from 'react';
import { AssignmentDetail, ProjectDetail, SpecificationDetail } from '../../../types';

export interface MyAssignmentContextValue {
  navigateToMySubmissions: () => void;
  assignmentKey: string; // probably don't need the key as we have the assignment
  assignment: AssignmentDetail;
  project: ProjectDetail;
  specification: SpecificationDetail;
}

const MyAssignmentContextDefaultValue = {} as MyAssignmentContextValue;
export const MyAssignmentContext = React.createContext(MyAssignmentContextDefaultValue);
