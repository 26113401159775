import { FC } from 'react';

import { TaskResult, TaskResultKind, TaskState } from '../../../../../types';

import {
  ApplyActionMapTaskResultContent,
  ApplyActionTaskResultContent,
  CheckRulesTaskResultContent,
  DataTaskResultContent,
  ValidateSchemaTaskResultContent,
} from '.';

interface TaskTableContentProps {
  task: TaskResult;
  hideZeroProcessedRows: boolean;
}

const TaskTableContent: FC<TaskTableContentProps> = ({ task, hideZeroProcessedRows }) => {
  if (task.status !== TaskState.FINISHED) {
    return null;
  }

  switch (task.kind) {
    case TaskResultKind.OpenDataTask:
    case TaskResultKind.CopyToTask:
    case TaskResultKind.CommitTask:
      return <DataTaskResultContent task={task} hideZeroProcessedRows={hideZeroProcessedRows} />;
    case TaskResultKind.CheckRulesTask:
      return (
        <CheckRulesTaskResultContent task={task} hideZeroProcessedRows={hideZeroProcessedRows} />
      );
    case TaskResultKind.ApplyActionMapTask:
      return (
        <ApplyActionMapTaskResultContent
          task={task}
          hideZeroProcessedRows={hideZeroProcessedRows}
        />
      );
    case TaskResultKind.ApplyActionTask:
      return (
        <ApplyActionTaskResultContent task={task} hideZeroProcessedRows={hideZeroProcessedRows} />
      );
    case TaskResultKind.ValidateSchemaTask:
      return <ValidateSchemaTaskResultContent task={task} />;
    default:
      return null;
  }
};

export default TaskTableContent;
