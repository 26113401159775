import { FC, useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Loading } from '../../../../../components';
import {
  AssignmentDetail,
  DataStoreConfigDetail,
  ProjectDetail,
  SpecificationDetail,
  SubmissionDetail,
} from '../../../../../types';
import * as SpecificationApi from '../../../../../api/specification';
import { extractErrorMessage } from '../../../../../api/endpoints';
import { ErrorBlockContext } from '../../../../../contexts/error-block';
import { intl } from '../../../../../Internationalization';

import MapContainer from './map/MapContainer';
import { OpenSubmissionContext } from './OpenSubmissionContext';
import OpenSubmissionStepper from './OpenSubmissionStepper';

interface OpenSubmissionProps {
  submissionUpdated: React.Dispatch<React.SetStateAction<SubmissionDetail | undefined>>;
  submission: SubmissionDetail;
  assignment: AssignmentDetail;
  project: ProjectDetail;
  specification: SpecificationDetail;
}

interface OpenSubmissionContextData {
  specificationValid: boolean;
  dataStores: DataStoreConfigDetail[];
}

const OpenSubmission: FC<OpenSubmissionProps> = ({
  submissionUpdated,
  submission,
  assignment,
  project,
  specification,
}) => {
  const { raiseError } = useContext(ErrorBlockContext);
  const [openSubmissionContextData, setOpenSubmissionContextData] =
    useState<OpenSubmissionContextData>();

  useEffect(() => {
    const loadAndValidate = async () => {
      try {
        const [{ data: dataStores }, specificationValid] = await Promise.all([
          SpecificationApi.getDataStoreConfigs(specification.key),
          SpecificationApi.isSpecificationValid(specification.key),
        ]);
        setOpenSubmissionContextData({ dataStores, specificationValid });
      } catch (error: any) {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'openSubmission.loadError',
              defaultMessage: 'Failed to fetch submission data',
            })
          )
        );
      }
    };

    loadAndValidate();
  }, [raiseError, specification.key]);

  if (openSubmissionContextData) {
    return (
      <OpenSubmissionContext.Provider
        value={{
          submissionUpdated,
          submission,
          assignment,
          project,
          specification,
          ...openSubmissionContextData,
        }}
      >
        <Routes>
          <Route index element={<OpenSubmissionStepper />} />
          <Route path="map" element={<MapContainer />} />
          <Route path="*" element={<Navigate to="." replace />} />
        </Routes>
      </OpenSubmissionContext.Provider>
    );
  }

  return <Loading />;
};

export default OpenSubmission;
