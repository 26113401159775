import { FC, useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';

import { Typography, Box, Link } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import * as AuthApi from '../../api/auth';
import { extractErrorMessage } from '../../api/endpoints';
import { VerifiedPassword } from '../../api/auth';
import {
  DefaultButton,
  PasswordRequirementDetails,
  ValidatedPasswordField,
} from '../../components';
import { ApplicationContext } from '../../contexts/application';
import { intl } from '../../Internationalization';
import { onEnterCallback, updateValue, validate } from '../../util';
import { useQuery } from '../../hooks';

import PreAuthPage from './PreAuthPage';

const ResetPassword: FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const passwordResetToken = useQuery().get('token') || '';
  const {
    applicationDetails: {
      site: { passwordRequirements },
    },
  } = useContext(ApplicationContext);

  const [processing, setProcessing] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const [verifiedPassword, setVerifiedPassword] = useState<VerifiedPassword>({
    password: '',
    verifyPassword: '',
  });

  useEffect(() => {
    const verifyResetToken = async () => {
      try {
        await AuthApi.verifyPasswordResetToken({ passwordResetToken });
        setValidated(true);
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          enqueueSnackbar(
            intl.formatMessage({
              id: 'preauth.resetPassword.tokenInvalid',
              defaultMessage: 'Password reset token is not valid',
            }),
            { variant: 'warning' }
          );
        } else {
          enqueueSnackbar(
            extractErrorMessage(
              error,
              intl.formatMessage({
                id: 'preauth.resetPassword.tokenVerifyError',
                defaultMessage: 'Unexpected error when validating token',
              })
            ),
            { variant: 'error' }
          );
        }
        navigate('/');
      }
    };
    verifyResetToken();
  }, [enqueueSnackbar, navigate, passwordResetToken]);

  const validateAndSubmit = async () => {
    setProcessing(true);

    try {
      resetPassword(
        await validate(AuthApi.passwordResetValidator(passwordRequirements), verifiedPassword)
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const resetPassword = async (validatedPassword: VerifiedPassword) => {
    try {
      await AuthApi.passwordReset({ password: validatedPassword.password, passwordResetToken });
      enqueueSnackbar(
        intl.formatMessage({
          id: 'preauth.resetPassword.resetSuccess',
          defaultMessage: 'Your password has been reset successfully',
        }),
        { variant: 'success' }
      );
      navigate('/');
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'preauth.resetPassword.resetError',
            defaultMessage: 'Unexpected error when resetting password',
          })
        ),
        { variant: 'error' }
      );

      setProcessing(false);
    }
  };

  const submitOnEnter = onEnterCallback(validateAndSubmit);

  const updateVerifiedPasswordValue = updateValue(setVerifiedPassword);

  return (
    <PreAuthPage
      loading={!validated}
      loadingMessage={intl.formatMessage({
        id: 'preauth.resetPassword.loadingMessage',
        defaultMessage: 'Validating token…',
      })}
    >
      <Typography variant="h4" gutterBottom>
        <FormattedMessage id="preauth.resetPassword.title" defaultMessage="Reset your password" />
      </Typography>
      <Typography variant="body2" gutterBottom>
        <FormattedMessage
          id="preauth.resetPassword.description"
          defaultMessage="Enter a new password below to complete your password reset."
        />
      </Typography>
      <Box id="reset-password-form" display="flex" flexDirection="column">
        <ValidatedPasswordField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="password"
          label={intl.formatMessage({
            id: 'preauth.resetPassword.password.label',
            defaultMessage: 'Password',
          })}
          type="password"
          value={verifiedPassword.password}
          onChange={updateVerifiedPasswordValue}
          margin="normal"
          variant="outlined"
        />
        <ValidatedPasswordField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="verifyPassword"
          label={intl.formatMessage({
            id: 'preauth.resetPassword.verifyPassword.label',
            defaultMessage: 'Verify Password',
          })}
          type="password"
          value={verifiedPassword.verifyPassword}
          onChange={updateVerifiedPasswordValue}
          onKeyDown={submitOnEnter}
          margin="normal"
          variant="outlined"
        />
        <Box textAlign="left">
          <PasswordRequirementDetails
            passwordRequirements={passwordRequirements}
            newPasswordValue={verifiedPassword.password}
          />
        </Box>
        <DefaultButton
          startIcon={<VpnKeyIcon />}
          name="resetPassword"
          fullWidth
          size="large"
          type="submit"
          disabled={processing}
          onClick={validateAndSubmit}
          sx={{ mt: 2 }}
        >
          <FormattedMessage
            id="preauth.resetPassword.resetButton"
            defaultMessage="Reset Password"
          />
        </DefaultButton>
        <Typography align="right" variant="body2" sx={{ mt: 2 }}>
          <Link id="navigate-sign-in-instead" component={RouterLink} to="/">
            <FormattedMessage id="preauth.resetPassword.loginLink" defaultMessage="Login instead" />
          </Link>
        </Typography>
      </Box>
    </PreAuthPage>
  );
};

export default ResetPassword;
