import React, { FC, PropsWithChildren, useState } from 'react';

import { TransitionProps } from '@mui/material/transitions';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';

import { onEnterCallback } from '../util';
import { ValidatedTextField } from './fields';
import { intl } from '../Internationalization';
import { PaddedDialogActions, DefaultButton, MessageBox } from './';

interface SecureConfirmDialogProps extends PropsWithChildren {
  id: string;
  disabled: boolean;
  confirmCode: string;
  confirmCodeLabel: string;
  confirmAction: () => void;
  closeAction: () => void;
  isOpen: boolean;
  title?: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
}

const TransitionComponent = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

const SecureConfirmDialog: FC<SecureConfirmDialogProps> = ({
  id,
  disabled,
  confirmCode,
  confirmCodeLabel,
  confirmAction,
  closeAction,
  isOpen,
  children,
  title,
  confirmBtnText = intl.formatMessage({
    id: 'components.secureConfirmDialog.confirmButton',
    defaultMessage: 'Confirm',
  }),
  cancelBtnText = intl.formatMessage({
    id: 'components.secureConfirmDialog.cancelButton',
    defaultMessage: 'Cancel',
  }),
}) => {
  const [confirmValue, setConfirmValue] = useState<string>('');

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  const onDrop = (event: React.DragEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  const onConfirm = () => {
    if (confirmValue === confirmCode) {
      confirmAction();
    }
  };

  const onClose = () => {
    setConfirmValue('');
    closeAction();
  };

  const submitOnEnter = onEnterCallback(onConfirm);

  return (
    <Dialog
      id={id}
      open={isOpen}
      onClose={disabled ? undefined : onClose}
      TransitionComponent={TransitionComponent}
      maxWidth="sm"
      fullWidth
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent dividers={true}>
        <DialogContentText component="div">
          <MessageBox
            level="warning"
            message={intl.formatMessage({
              id: 'components.secureConfirmDialog.warningMessage',
              defaultMessage: 'This action cannot be undone.',
            })}
            gutterBottom
          />
          {children}
        </DialogContentText>
        <ValidatedTextField
          name="confirmValueField"
          label={confirmCodeLabel}
          value={confirmValue}
          onChange={(event) => setConfirmValue(event.target.value.toUpperCase())}
          margin="normal"
          variant="outlined"
          inputProps={{ onPaste, onDrop }}
          onKeyDown={submitOnEnter}
          autoComplete="off"
        />
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton name="cancel" onClick={onClose} disabled={disabled} color="secondary">
          {cancelBtnText}
        </DefaultButton>
        <DefaultButton
          name="confirm"
          onClick={onConfirm}
          disabled={disabled || confirmValue !== confirmCode}
        >
          {confirmBtnText}
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default SecureConfirmDialog;
