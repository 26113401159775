import { FC } from 'react';
import { EditorState, Modifier } from 'draft-js';

import { Field } from '../../../types';
import { intl } from '../../../Internationalization';

import { Option } from './Option';
import SmallListItemIcon from '../SmallListItemIcon';

export interface FieldToolbarButtonProps {
  onChange?: (editorState: EditorState) => void;
  editorState?: EditorState;
  field: Field;
}

const FieldToolbarButton: FC<FieldToolbarButtonProps> = ({ editorState, onChange, field }) => {
  const { icon: Icon, label } = field;
  const handleClick = (): void => {
    if (!editorState || !onChange) {
      return;
    }

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `\${${field.expression}}`,
      editorState.getCurrentInlineStyle()
    );

    onChange(EditorState.push(editorState, contentState, 'insert-fragment'));
  };

  return (
    <Option
      onClick={handleClick}
      title={intl.formatMessage({
        id: 'components.editor.fieldToolbarButton.addField',
        defaultMessage: 'Add field',
      })}
      value="field"
      className="AddFieldButton"
    >
      <>
        <SmallListItemIcon>
          <Icon fontSize="small" />
        </SmallListItemIcon>
        {label}
      </>
    </Option>
  );
};

export default FieldToolbarButton;
