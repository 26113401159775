import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import { TableInfoRow, TableLoadingRow } from '../../../../components';
import { LdapUserSyncJobDetail } from '../../../../types';
import { intl } from '../../../../Internationalization';

import SyncJobTableRow from './SyncJobTableRow';

interface SyncMonitorTableProps {
  jobs: LdapUserSyncJobDetail[];
  loading: boolean;
  onRefresh: () => void;
}

const SyncMonitorTable: FC<SyncMonitorTableProps> = ({ jobs, loading, onRefresh }) => {
  const renderJobTableRows = () => {
    if (loading) {
      return <TableLoadingRow colSpan={8} />;
    }

    if (!jobs.length) {
      return (
        <TableInfoRow
          colSpan={8}
          size="medium"
          message={intl.formatMessage({
            id: 'ldap.syncMonitor.noJobs',
            defaultMessage: 'No synchronisation jobs to display',
          })}
        />
      );
    }

    return jobs.map((job) => (
      <SyncJobTableRow key={job.id} job={job} disabled={loading} onCancel={onRefresh} />
    ));
  };

  return (
    <Table size="medium">
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage id="ldap.syncMonitor.table.statusColumn" defaultMessage="Status" />
          </TableCell>
          <TableCell>
            <FormattedMessage
              id="ldap.syncMonitor.table.startedAtColumn"
              defaultMessage="Started At"
            />
          </TableCell>
          <TableCell align="right">
            <FormattedMessage id="ldap.syncMonitor.table.addedColumn" defaultMessage="Added" />
          </TableCell>
          <TableCell align="right">
            <FormattedMessage id="ldap.syncMonitor.table.updatedColumn" defaultMessage="Updated" />
          </TableCell>
          <TableCell align="right">
            <FormattedMessage id="ldap.syncMonitor.table.removedColumn" defaultMessage="Removed" />
          </TableCell>
          <TableCell align="right">
            <FormattedMessage id="ldap.syncMonitor.table.skippedColumn" defaultMessage="Skipped" />
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>{renderJobTableRows()}</TableBody>
    </Table>
  );
};

export default SyncMonitorTable;
