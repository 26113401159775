import { FC, useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Schema, { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';

import { Typography, Box, Link } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import * as AuthApi from '../../api/auth';
import { extractErrorMessage } from '../../api/endpoints';
import { ValidatedTextField, DefaultButton, MessageBox } from '../../components';
import { ApplicationContext } from '../../contexts/application';
import { onEnterCallback, validate } from '../../util';
import { intl } from '../../Internationalization';

import PreAuthPage from './PreAuthPage';

const VALIDATOR = new Schema({
  email: {
    type: 'email',
    required: true,
    message: intl.formatMessage({
      id: 'preauth.requestPasswordReset.validator.emailRequired',
      defaultMessage: 'Please provide a valid email address',
    }),
  },
});

const RequestPasswordReset: FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const applicationContext = useContext(ApplicationContext);

  const [email, setEmail] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const validateAndSubmit = async () => {
    setProcessing(true);
    try {
      requestPasswordReset(await validate(VALIDATOR, { email }));
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const requestPasswordReset = async (validatedEmail: { email: string }) => {
    try {
      await AuthApi.requestPasswordReset({ email: validatedEmail.email });
      enqueueSnackbar(
        intl.formatMessage({
          id: 'preauth.requestPasswordReset.requestSuccessful',
          defaultMessage:
            'If you have an active account, you should receive a password reset email shortly',
        }),
        { variant: 'success' }
      );
      navigate('/');
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'preauth.requestPasswordReset.requestDenied',
            defaultMessage:
              'You may not reset your password as your account is remotely managed. Please contact your administrator for further information.',
          }),
          { variant: 'warning' }
        );
      } else {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'preauth.requestPasswordReset.requestError',
              defaultMessage: 'Failed to request password reset',
            })
          ),
          { variant: 'error' }
        );
      }
      setProcessing(false);
    }
  };

  const submitOnEnter = onEnterCallback(validateAndSubmit);
  const disabled = processing || !applicationContext.applicationDetails.mailSenderEnabled;

  return (
    <PreAuthPage>
      <Typography variant="h4" gutterBottom>
        <FormattedMessage
          id="preauth.requestPasswordReset.title"
          defaultMessage="Forgotten your password?"
        />
      </Typography>
      <Typography variant="body2" gutterBottom>
        <FormattedMessage
          id="preauth.requestPasswordReset.description"
          defaultMessage="Enter your email address below to request for your password to be reset."
        />
      </Typography>
      {!applicationContext.applicationDetails.mailSenderEnabled && (
        <Box sx={{ mt: 2, mb: 1, textAlign: 'left' }}>
          <MessageBox
            level="warning"
            message={intl.formatMessage({
              id: 'preauth.requestPasswordReset.contactAdministrator',
              defaultMessage:
                'Email sending is disabled on this platform. If you have forgotten your password, please contact your administrator.',
            })}
          />
        </Box>
      )}
      <Box id="request-password-reset-form" display="flex" flexDirection="column">
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={disabled}
          name="email"
          label={intl.formatMessage({
            id: 'preauth.requestPasswordReset.email.label',
            defaultMessage: 'Email',
          })}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          onKeyDown={submitOnEnter}
          margin="normal"
          variant="outlined"
        />
        <DefaultButton
          startIcon={<VpnKeyIcon />}
          name="requestPasswordReset"
          fullWidth
          size="large"
          disabled={disabled}
          onClick={validateAndSubmit}
          sx={{ mt: 2 }}
        >
          <FormattedMessage
            id="preauth.requestPasswordReset.requestButton"
            defaultMessage="Request Password Reset"
          />
        </DefaultButton>
        <Typography align="right" variant="body2" sx={{ mt: 2 }}>
          <Link id="navigate-sign-in-instead" component={RouterLink} to="/">
            <FormattedMessage
              id="preauth.requestPasswordReset.loginLink"
              defaultMessage="Login instead"
            />
          </Link>
        </Typography>
      </Box>
    </PreAuthPage>
  );
};

export default RequestPasswordReset;
