import React, { FC } from 'react';
import { Skeleton, TableCell, TableRow } from '@mui/material';

interface TableRowsSkeletonProps {
  colSpan: number;
  rows?: number;
  rowHeight?: number;
}

const TableRowsSkeleton: FC<TableRowsSkeletonProps> = ({ colSpan, rows = 3, rowHeight = 40 }) => (
  <>
    {[...Array(rows)].map((_, index) => (
      <TableRow key={index}>
        <TableCell colSpan={colSpan} sx={{ py: 1 }}>
          <Skeleton variant="rectangular" width="100%" height={rowHeight} />
        </TableCell>
      </TableRow>
    ))}
  </>
);

export default TableRowsSkeleton;
