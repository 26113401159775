import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { SubmissionOutcome, SUBMISSION_OUTCOME_METADATA } from '../../types';

export type SubmissionOutcomeSelections = Record<SubmissionOutcome, boolean>;

export const toSubmissionOutcomeArray = (
  submissionOutcomeSelections: SubmissionOutcomeSelections
): SubmissionOutcome[] => {
  return (Object.keys(submissionOutcomeSelections) as SubmissionOutcome[]).filter(
    (outcome) => submissionOutcomeSelections[outcome]
  );
};

interface SubmissionOutcomeSelectorProps {
  selections: SubmissionOutcomeSelections;
  onSelectionsUpdated: (outcomeSelections: SubmissionOutcomeSelections) => void;
}

function SubmissionOutcomeSelector({
  selections,
  onSelectionsUpdated,
}: SubmissionOutcomeSelectorProps) {
  return (
    <Box display="flex" my={1.5} mx={1}>
      {(Object.keys(SUBMISSION_OUTCOME_METADATA) as SubmissionOutcome[]).map((outcomeKey) => {
        const checked = selections[outcomeKey];
        return (
          <FormControlLabel
            key={outcomeKey}
            control={
              <Checkbox
                checked={checked}
                onChange={() => {
                  onSelectionsUpdated({
                    ...selections,
                    [outcomeKey]: !checked,
                  });
                }}
                name={outcomeKey}
                sx={{ p: (theme) => theme.spacing(0, 0.5, 0, 1) }}
              />
            }
            label={SUBMISSION_OUTCOME_METADATA[outcomeKey].label}
          />
        );
      })}
    </Box>
  );
}

export default SubmissionOutcomeSelector;
