import mime from 'mime-types';

import {
  UxExcelFileIcon,
  UxWordFileIcon,
  UxPowerpointFileIcon,
  UxZipFileIcon,
  UxTextFileIcon,
  UxPdfFileIcon,
  UxFileIcon,
  UxImageIcon,
} from '../components/icons';

export const iconForFilename = (filename: string) => {
  const mimeType = mime.lookup(filename);
  if (!mimeType) {
    return UxFileIcon;
  }
  return subTypeIcon(mimeType) || typeIcon(mimeType) || UxFileIcon;
};

const subTypeIcon = (mimeType: string) => {
  switch (mimeType) {
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.oasis.opendocument.spreadsheet':
    case 'application/vnd.ms-excel':
      return UxExcelFileIcon;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.oasis.opendocument.text':
    case 'application/msword':
      return UxWordFileIcon;
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.oasis.opendocument.presentation':
    case 'application/vnd.ms-powerpoint':
      return UxPowerpointFileIcon;
    case 'application/zip':
    case 'application/x-rar-compressed':
    case 'application/gzip':
      return UxZipFileIcon;
    case 'application/pdf':
      return UxPdfFileIcon;
    default:
      return undefined;
  }
};

const typeIcon = (mimeType: string) => {
  const type = mimeType.substring(0, mimeType.indexOf('/'));
  switch (type) {
    case 'image':
      return UxImageIcon;
    case 'text':
      return UxTextFileIcon;
    default:
      return undefined;
  }
};
