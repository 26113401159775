import { FC, useContext, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Authentication, AuthenticationContext } from './contexts/authentication';
import AuthenticatedRouting from './AuthenticatedRouting';
import { RequireAuthenticated, RequireUnauthenticated } from './routing';
import { ResetPassword, SignIn, RequestPasswordReset, Activate } from './screens/pre-auth';
import { intl } from './Internationalization';

const AuthenticationRedirect = () => {
  const { signOut } = useContext(AuthenticationContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    signOut(false);
    enqueueSnackbar(
      intl.formatMessage({
        id: 'app.routing.authenticationError',
        defaultMessage: 'Authentication failed, please try again.',
      }),
      { variant: 'warning' }
    );
  }, [signOut, enqueueSnackbar]);

  return <Navigate to="/" replace />;
};

const UnauthorizedRedirect = () => {
  const { signOut } = useContext(AuthenticationContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    signOut(false);
    enqueueSnackbar(
      intl.formatMessage({
        id: 'app.routing.unauthorizedRedirect',
        defaultMessage: 'Please log in to continue',
      })
    );
  }, [signOut, enqueueSnackbar]);

  return <Navigate to="/" replace />;
};

const RemoveTrailingSlashes = () => {
  const location = useLocation();
  return (
    location.pathname.match('/.*/$') && (
      <Navigate
        to={{
          pathname: location.pathname.replace(/\/+$/, ''),
          search: location.search,
        }}
        replace
      />
    )
  );
};

const AppRouting: FC = () => (
  <Authentication>
    <RemoveTrailingSlashes />
    <Routes>
      <Route path="/unauthorized" element={<UnauthorizedRedirect />} />
      <Route path="/authentication_failed" element={<AuthenticationRedirect />} />
      <Route
        path="/"
        element={
          <RequireUnauthenticated>
            <SignIn />
          </RequireUnauthenticated>
        }
      />
      <Route
        path="/request_password_reset"
        element={
          <RequireUnauthenticated>
            <RequestPasswordReset />
          </RequireUnauthenticated>
        }
      />
      <Route
        path="/reset_password"
        element={
          <RequireUnauthenticated forceLogout>
            <ResetPassword />
          </RequireUnauthenticated>
        }
      />
      <Route
        path="/activate"
        element={
          <RequireUnauthenticated forceLogout>
            <Activate />
          </RequireUnauthenticated>
        }
      />
      <Route
        path="/*"
        element={
          <RequireAuthenticated>
            <AuthenticatedRouting />
          </RequireAuthenticated>
        }
      />
    </Routes>
  </Authentication>
);

export default AppRouting;
