import React from 'react';
import { ContentBlock } from 'draft-js';

import { Field } from '../../../types';

import FieldHighlighter, { FieldHighlighterProps } from './FieldHighlighter';
import TagHighlighter from './TagHighlighter';

// draftjs types inline this everywhere it's used; let's give it a name
type DraftDecoratorCallback = (start: number, end: number) => void;

type FindWithRegexCallback = (start: number, end: number, match: RegExpMatchArray) => void;

const FIELD_REGEX = /\$\{([^}]+)\}/g;
const TAG_REGEX = /\[\/?#.+?]/g;

const findWithRegex = (
  regex: RegExp,
  contentBlock: ContentBlock,
  callback: FindWithRegexCallback
) => {
  const text = contentBlock.getText();
  let matchArray;
  while ((matchArray = regex.exec(text)) !== null) {
    const start = matchArray.index;
    const end = start + matchArray[0].length;
    callback(start, end, matchArray);
  }
};

const findFieldPatterns = (contentBlock: ContentBlock, callback: DraftDecoratorCallback) => {
  findWithRegex(FIELD_REGEX, contentBlock, callback);
};

const findTagPatterns = (contentBlock: ContentBlock, callback: DraftDecoratorCallback) => {
  findWithRegex(TAG_REGEX, contentBlock, callback);
};

interface FieldDecoratorWithFieldsProps extends FieldHighlighterProps {
  decoratedText: string;
}

const fieldDecoration =
  (fields: Field[]): React.FC<FieldDecoratorWithFieldsProps> =>
  (props) => {
    const decoratedExpression = props.decoratedText.substring(2, props.decoratedText.length - 1);
    const field = fields.find((f) => f.expression === decoratedExpression);
    return <FieldHighlighter field={field} children={props.children} />;
  };

export function templateEditorDecorators(fields: Field[]) {
  return [
    {
      strategy: findFieldPatterns,
      component: fieldDecoration(fields),
    },
    {
      strategy: findTagPatterns,
      component: TagHighlighter,
    },
  ];
}
