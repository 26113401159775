import { UserProfile, isLdapUser } from '../../../types';
import { MessageBox } from '../../../components';
import { intl } from '../../../Internationalization';

const LdapUserMessage = ({ user }: { user: UserProfile }) => {
  if (isLdapUser(user)) {
    return (
      <MessageBox
        level="info"
        message={intl.formatMessage({
          id: 'user.ldapUser',
          defaultMessage:
            'This user is managed by LDAP. Suspending or modifying the user must be done on the LDAP server.',
        })}
      />
    );
  }
  return null;
};

export default LdapUserMessage;
