import { FC, PropsWithChildren } from 'react';
import { View } from '@react-pdf/renderer';
import { ViewProps, Style } from '@react-pdf/types';

import { sharedPdfStyles } from '../../../../../components';

type ColSpan = 'half' | 'quarter' | 'third' | 'fifth' | 'twoFifths';

const COL_SPAN_STYLE: Record<ColSpan, Style> = {
  half: sharedPdfStyles.cell_halves,
  quarter: sharedPdfStyles.cell_4ths,
  third: sharedPdfStyles.cell_3rds,
  fifth: sharedPdfStyles.cell_5ths,
  twoFifths: sharedPdfStyles.cell_2_5ths,
};

interface PdfTableCellProps extends ViewProps, PropsWithChildren {
  style?: Style | Style[];
  colSpan?: ColSpan;
  icon?: boolean;
  number?: boolean;
  header?: boolean;
}

const PdfTableCell: FC<PdfTableCellProps> = ({
  colSpan,
  icon,
  number,
  header,
  style = [],
  children,
}) => (
  <View
    style={[
      {
        fontSize: 10,
        fontWeight: 300,
        paddingRight: 5,
        textAlign: number ? 'right' : 'left',
      },
      icon ? sharedPdfStyles.rowContainer : {},
      header ? sharedPdfStyles.tableHead : {},
      colSpan ? COL_SPAN_STYLE[colSpan] : {},
      ...(Array.isArray(style) ? style : [style]),
    ]}
  >
    {children}
  </View>
);

export default PdfTableCell;
