import Schema from 'async-validator';
import { AxiosPromise } from 'axios';

import { intl } from '../Internationalization';

import { VirusScannerSettings, VirusScannerTestResponse } from '../types';
import { durationValidator } from '../util';
import { AXIOS } from './endpoints';

export function getSettings(): AxiosPromise<VirusScannerSettings> {
  return AXIOS.get('virus_scanner');
}

export function updateSettings(
  virusScannerSettings: VirusScannerSettings
): AxiosPromise<VirusScannerSettings> {
  return AXIOS.post('/virus_scanner', virusScannerSettings);
}

export function testSettings(
  virusScannerSettings: VirusScannerSettings
): AxiosPromise<VirusScannerTestResponse> {
  return AXIOS.post('/virus_scanner/test', virusScannerSettings);
}

export const SETTINGS_VALIDATOR = new Schema({
  scannerSettings: {
    required: true,
    type: 'object',
    fields: {
      scannerPath: {
        type: 'string',
        required: true,
        message: intl.formatMessage({
          id: 'virusScanner.validator.scannerPath.required',
          defaultMessage: 'Please provide the scanner path',
        }),
      },
      scanTimeout: [
        {
          required: true,
          message: intl.formatMessage({
            id: 'virusScanner.validator.scanTimeout.required',
            defaultMessage: 'Please provide a timeout duration',
          }),
        },
        durationValidator({ seconds: 1 }),
      ],
    },
  },
});
