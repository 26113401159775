import { FC, PropsWithChildren } from 'react';

import { Breadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const HeaderBreadcrumb: FC<PropsWithChildren> = ({ children }) => (
  <Breadcrumbs
    sx={{
      'color': (theme) => theme.palette.getContrastText(theme.palette.secondary.light),
      '& a, & p': {
        alignItems: 'center',
        color: (theme) => theme.palette.getContrastText(theme.palette.secondary.light),
        display: 'flex',
      },
      '& a': {
        cursor: 'pointer',
      },
      '& .MuiSvgIcon-root': {
        mr: 0.5,
        width: 20,
        height: 20,
      },
    }}
    separator={<NavigateNextIcon fontSize="small" />}
    aria-label="Breadcrumb"
  >
    {children}
  </Breadcrumbs>
);

export default HeaderBreadcrumb;
