import { FC, Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, View } from '@react-pdf/renderer';

import { ArrowRightAltSvg, HelpSvg, PdfIcon } from '../../../../../../components/pdf/PdfIcons';
import { PdfDocumentLayout, schemaPdfStyles } from '../../../../../../components';
import {
  DataStoreSchema,
  DataStoreMapping,
  DataStoreClassMapping,
  DataStoreClassSchema,
  SchemaMappingMode,
  DataStoreConfigDetail,
  MediaDetail,
} from '../../../../../../types';

import {
  SchemaMappingDirection,
  SCHEMA_MAPPING_DIRECTION_METADATA,
  findDataStoreSchema,
  findDataStoreMapping,
  SchemaMappingValidationResults,
  MAPPING_STATUS_METADATA,
} from '../../../../../components/schema-mapper';

interface SchemaPdfProps {
  title: string;

  direction: SchemaMappingDirection;
  sourceDataStores: DataStoreSchema[];
  targetDataStores: DataStoreSchema[];
  schemaMappings: DataStoreMapping[];
  validationResults: SchemaMappingValidationResults;
  dataStoreConfigs: Record<string, DataStoreConfigDetail>;
  logo?: MediaDetail;
}

const SchemaPdf: FC<SchemaPdfProps> = ({
  title,
  schemaMappings,
  validationResults,
  sourceDataStores,
  targetDataStores,
  direction,
  dataStoreConfigs,
  logo,
}) => {
  const { findClassMappingForLeft, selectLeftOrSource, selectRightOrTarget } =
    SCHEMA_MAPPING_DIRECTION_METADATA[direction];

  const leftDataStores = selectLeftOrSource(sourceDataStores, targetDataStores);

  return (
    <PdfDocumentLayout title={title} documentTitle={title} logo={logo}>
      {leftDataStores
        .filter(
          (leftDataStore) =>
            dataStoreConfigs[leftDataStore.path].schemaMappingMode !==
            SchemaMappingMode.AUTOMATIC_HIDDEN
        )
        .map((leftDataStore, index) => {
          const leftClasses = leftDataStore.importSchema!.classes;
          const rightClasses = findDataStoreSchema(
            leftDataStore.path,
            selectRightOrTarget(sourceDataStores, targetDataStores)
          )?.importSchema!.classes;
          const classMappings = findDataStoreMapping(
            leftDataStore.path,
            schemaMappings
          )?.importMappings;

          return (
            <Fragment key={leftDataStore.path}>
              <View break={index > 0}>
                <Text style={schemaPdfStyles.summary}>
                  <FormattedMessage
                    id="openSubmission.schemaMapping.schemaPdf.dataSet"
                    defaultMessage="Data Set: {path}"
                    values={{ path: leftDataStore.path }}
                  />
                </Text>
              </View>
              {!classMappings || !leftClasses || !rightClasses ? (
                <Text style={schemaPdfStyles.dataSetSummarySubHeader}>
                  <FormattedMessage
                    id="openSubmission.schemaMapping.schemaPdf.invalidData"
                    defaultMessage="Failed to load display submission schema, for invalid data."
                  />
                </Text>
              ) : (
                <>
                  <DataStoreSummary
                    dataStoreName={leftDataStore.path}
                    validationResults={validationResults}
                    direction={direction}
                  />
                  {leftClasses.map((leftClass, i) => {
                    const classMapping = findClassMappingForLeft(leftClass.name, classMappings);
                    return (
                      <DataStoreClass
                        key={leftDataStore.path + '-' + leftClass.name}
                        leftClass={leftClass}
                        classMapping={classMapping}
                        first={i === 0}
                        dataStoreName={leftDataStore.path}
                        validationResults={validationResults}
                        direction={direction}
                      />
                    );
                  })}
                </>
              )}
            </Fragment>
          );
        })}
    </PdfDocumentLayout>
  );
};

interface DataStoreSummaryProps {
  dataStoreName: string;
  displayClassSummary?: boolean;
  validationResults: SchemaMappingValidationResults;
  direction: SchemaMappingDirection;
}

const DataStoreSummary: FC<DataStoreSummaryProps> = ({
  dataStoreName,
  validationResults,
  direction,
}) => {
  const {
    leftDataStorePdfValidationResults: LeftValidationResults,
    rightDataStorePdfValidationResults: RightValidationResults,
  } = SCHEMA_MAPPING_DIRECTION_METADATA[direction];
  return (
    <View style={[schemaPdfStyles.summaryOuterContainer, schemaPdfStyles.rowContainer]}>
      <LeftValidationResults dataStoreName={dataStoreName} validationResults={validationResults} />
      <RightValidationResults dataStoreName={dataStoreName} validationResults={validationResults} />
    </View>
  );
};

interface ClassHeadRowProps {
  direction: SchemaMappingDirection;
}

const ClassHeadRow: FC<ClassHeadRowProps> = ({ direction }) => (
  <View
    style={[schemaPdfStyles.tableRow, schemaPdfStyles.tableHeadRow, schemaPdfStyles.rowContainer]}
  >
    <View style={[schemaPdfStyles.tableHead, schemaPdfStyles.cell_5ths]}>
      <Text>
        <FormattedMessage
          id="openSubmission.schemaMapping.schemaPdf.statusLabel"
          defaultMessage="Status"
        />
      </Text>
    </View>
    <View style={[schemaPdfStyles.tableHead, schemaPdfStyles.cell_2_5ths]}>
      <Text>{SCHEMA_MAPPING_DIRECTION_METADATA[direction].leftClassHeaderText}</Text>
    </View>
    <View style={[schemaPdfStyles.tableHead, schemaPdfStyles.cell_2_5ths]}>
      <Text>{SCHEMA_MAPPING_DIRECTION_METADATA[direction].rightClassHeaderText}</Text>
    </View>
  </View>
);

interface ClassRowProps {
  statusIcon: PdfIcon;
  rightClassName?: string;
  leftClassName: string;
}

const ClassRow: FC<ClassRowProps> = ({ statusIcon: StatusIcon, leftClassName, rightClassName }) => (
  <View style={[schemaPdfStyles.tableRow, schemaPdfStyles.rowContainer]}>
    <View style={schemaPdfStyles.cell_5ths}>
      <StatusIcon style={schemaPdfStyles.largeIcon} />
    </View>
    <View
      style={[
        schemaPdfStyles.rowContainer,
        schemaPdfStyles.cell_2_5ths,
        schemaPdfStyles.mappingContainer,
      ]}
    >
      <Text>{leftClassName}</Text>
      <ArrowRightAltSvg />
    </View>
    <View style={[schemaPdfStyles.cell_2_5ths]}>
      {rightClassName ? (
        <Text>{rightClassName}</Text>
      ) : (
        <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <HelpSvg style={schemaPdfStyles.largeIcon} />
          <Text style={{ marginLeft: 4 }}>
            <FormattedMessage
              id="openSubmission.schemaMapping.schemaPdf.classNotMapped"
              defaultMessage="Class not mapped"
            />
          </Text>
        </View>
      )}
    </View>
  </View>
);

interface AttributeHeadRowProps {
  direction: SchemaMappingDirection;
}

const AttributeHeadRow: FC<AttributeHeadRowProps> = ({ direction }) => (
  <View
    style={[schemaPdfStyles.tableRow, schemaPdfStyles.tableHeadRow, schemaPdfStyles.rowContainer]}
    wrap={false}
  >
    <View style={[schemaPdfStyles.tableHead, schemaPdfStyles.cell_halves]}>
      <Text>{SCHEMA_MAPPING_DIRECTION_METADATA[direction].leftAttributeHeaderText}</Text>
    </View>
    <View style={[schemaPdfStyles.tableHead, schemaPdfStyles.cell_halves]}>
      <Text>{SCHEMA_MAPPING_DIRECTION_METADATA[direction].rightAttributeHeaderText}</Text>
    </View>
  </View>
);

interface AttributeRowProps {
  leftAttribute: string;
  rightAttribute?: string;
}

const AttributeRow: FC<AttributeRowProps> = ({ leftAttribute, rightAttribute }) => (
  <View
    style={[schemaPdfStyles.tableRow, schemaPdfStyles.attributeRow, schemaPdfStyles.rowContainer]}
    wrap={false}
  >
    <View
      style={[
        schemaPdfStyles.cell_halves,
        schemaPdfStyles.mappingContainer,
        schemaPdfStyles.rowContainer,
      ]}
    >
      <Text>{leftAttribute}</Text>
      <ArrowRightAltSvg style={schemaPdfStyles.largeIcon} />
    </View>
    <View style={[schemaPdfStyles.cell_halves]}>
      {rightAttribute && <Text>{rightAttribute}</Text>}
    </View>
  </View>
);

interface ClassSummaryProps {
  dataStoreName: string;
  validationResults: SchemaMappingValidationResults;
  direction: SchemaMappingDirection;
  sourceClassName: string;
  targetClassName: string;
}

const ClassSummary: FC<ClassSummaryProps> = ({
  dataStoreName,
  validationResults,
  direction,
  sourceClassName,
  targetClassName,
}) => {
  const {
    leftClassPdfValidationResults: LeftClassValidationResults,
    rightClassPdfValidationResults: RightClassValidationResults,
  } = SCHEMA_MAPPING_DIRECTION_METADATA[direction];
  return (
    <View style={[schemaPdfStyles.summaryOuterContainer, schemaPdfStyles.rowContainer]}>
      <LeftClassValidationResults
        dataStoreName={dataStoreName}
        validationResults={validationResults}
        sourceClassName={sourceClassName}
        targetClassName={targetClassName}
      />
      <RightClassValidationResults
        dataStoreName={dataStoreName}
        validationResults={validationResults}
        sourceClassName={sourceClassName}
        targetClassName={targetClassName}
      />
    </View>
  );
};

interface DataStoreClassProps {
  leftClass: DataStoreClassSchema;
  classMapping?: DataStoreClassMapping;
  first: boolean;
  dataStoreName: string;
  validationResults: SchemaMappingValidationResults;
  direction: SchemaMappingDirection;
}

const DataStoreClass: FC<DataStoreClassProps> = ({
  leftClass,
  classMapping,
  first,
  dataStoreName,
  validationResults,
  direction,
}) => {
  const {
    selectRightOrTarget,
    selectLeftOrSource,
    extractLeftValidationResults,
    findLeftMappedAttributeName,
  } = SCHEMA_MAPPING_DIRECTION_METADATA[direction];
  const leftValidationResults =
    extractLeftValidationResults(validationResults).dataStoreValidationResults[dataStoreName];
  const rightClassName = selectRightOrTarget(classMapping?.source, classMapping?.target);
  return (
    <View
      style={{
        fontSize: 12,
        display: 'flex',
        flexDirection: 'column',
      }}
      break={!first}
    >
      <ClassHeadRow direction={direction} />
      <ClassRow
        statusIcon={
          MAPPING_STATUS_METADATA[
            leftValidationResults.classValidationResults[leftClass.name].status
          ].pdfIcon
        }
        leftClassName={leftClass.name}
        rightClassName={rightClassName}
      />
      {rightClassName && (
        <View style={schemaPdfStyles.classInfoContainer}>
          <ClassSummary
            dataStoreName={dataStoreName}
            validationResults={validationResults}
            direction={direction}
            sourceClassName={selectLeftOrSource(leftClass.name, rightClassName)}
            targetClassName={selectRightOrTarget(leftClass.name, rightClassName)}
          />
          <AttributeHeadRow direction={direction} />
          {leftClass.attributes.map(({ name }) => (
            <AttributeRow
              key={name}
              leftAttribute={name}
              rightAttribute={findLeftMappedAttributeName(name, classMapping?.attributes)}
            />
          ))}
        </View>
      )}
    </View>
  );
};

export default SchemaPdf;
