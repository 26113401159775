import { FC } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import Assignments from './Assignments';
import Assignment from './assignment/Assignment';

const AssignmentsRouting: FC = () => (
  <Routes>
    <Route index element={<Assignments />} />
    <Route path=":assignmentKey/*" element={<Assignment />} />
    <Route path="*" element={<Navigate to="." replace />} />
  </Routes>
);

export default AssignmentsRouting;
