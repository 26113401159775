import { FC, PropsWithChildren } from 'react';

import * as SubmissionApi from '../../../../../api/submission';
import { extractErrorMessage } from '../../../../../api/endpoints';
import { useErrorBlock } from '../../../../../contexts/error-block';
import { useInterval } from '../../../../../hooks';
import { intl } from '../../../../../Internationalization';
import { SubmissionState, SubmissionDetail, VirusScanState } from '../../../../../types';

interface MySubmissionPollerProps extends PropsWithChildren {
  submission: SubmissionDetail;
  onSubmissionUpdated: (submissionDetails: SubmissionDetail) => void;
}

const POLL_INTERVAL = 5000;

const MySubmissionPoller: FC<MySubmissionPollerProps> = ({
  submission,
  onSubmissionUpdated,
  children,
}) => {
  const { raiseError } = useErrorBlock();

  const pollIfRequired = () => {
    if (shouldPoll()) {
      fetchMySubmission();
    }
  };

  useInterval(pollIfRequired, POLL_INTERVAL);

  const fetchMySubmission = async () => {
    try {
      const response = await SubmissionApi.getSubmission(submission.reference);
      onSubmissionUpdated(response.data);
    } catch (error: any) {
      raiseError(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'mySubmission.poller.loadError',
            defaultMessage: 'Failed to fetch open submission',
          })
        )
      );
    }
  };

  const shouldPoll = () => {
    switch (submission.state) {
      case SubmissionState.UPLOAD:
      case SubmissionState.UPLOADING:
      case SubmissionState.RUNNING:
      case SubmissionState.DOWNLOAD:
      case SubmissionState.DOWNLOADING:
      case SubmissionState.FINISH:
      case SubmissionState.FINISHING:
        return true;
      case SubmissionState.NOT_STARTED:
        return !!Object.values(submission.inputs).find(
          (input) => input.virusScanState === VirusScanState.PENDING
        );
      case SubmissionState.READY:
      case SubmissionState.PAUSED:
      case SubmissionState.PROCESSED:
      case SubmissionState.FINISHED:
      default:
        return false;
    }
  };

  return <>{children}</>;
};

export default MySubmissionPoller;
