import { FC, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { ACCESS_TOKEN } from '../../api/endpoints';
import { useQuery } from '../../hooks';

const Token: FC = () => {
  const token = useQuery().get('token');

  useEffect(() => {
    if (token) {
      localStorage.setItem(ACCESS_TOKEN, token);
    }
  });

  return <Navigate to="/" />;
};

export default Token;
