import { FC, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { DefaultButton } from '../';

import TableNoContentRow from './TableNoContentRow';

interface TablePreviewRowProps extends PropsWithChildren {
  fetchData: () => void;
  message: string;
  colSpan: number;
}

const TablePreviewRow: FC<TablePreviewRowProps> = ({ fetchData, message, colSpan }) => (
  <TableNoContentRow colSpan={colSpan}>
    <DefaultButton
      name="preview"
      color="secondary"
      startIcon={<VisibilityIcon />}
      onClick={fetchData}
    >
      <FormattedMessage id="components.tablePreviewRow.previewButton" defaultMessage="Preview" />
    </DefaultButton>
    <Typography variant="subtitle1">{message}</Typography>
  </TableNoContentRow>
);

export default TablePreviewRow;
