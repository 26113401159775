import { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Typography } from '@mui/material';

import { intl } from '../../Internationalization';

interface LoadingProps {
  loadingText?: string;
}

const Loading: FC<LoadingProps> = ({ loadingText }) => (
  <Box
    sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      p: 2,
    }}
  >
    <CircularProgress size={70} sx={{ m: 3, color: 'text.secondary' }} />
    <Typography variant="h5" sx={{ p: 2 }} color="textSecondary">
      {loadingText
        ? loadingText
        : intl.formatMessage({
            id: 'components.loading.defaultLoadingText',
            defaultMessage: 'Loading…',
          })}
    </Typography>
  </Box>
);

export default Loading;
