import { FC } from 'react';
import { Grid } from '@mui/material';

import { intl } from '../../../../../../Internationalization';
import { DataStoreConfigDetail, DataStoreSchema } from '../../../../../../types';

import { NoContentPlaceholder } from '../../../../../../components';

import { findDataStoreSchema } from './DataStoreConfigEditor';
import DataStoreConfigCard from './DataStoreConfigCard';

interface DataStoreConfigCardsProps {
  dataStoreConfigs: DataStoreConfigDetail[];
  dataStoreSchemas: DataStoreSchema[];
  savingConfig: boolean;
  updateDataStoreConfig: (dataStoreConfig: DataStoreConfigDetail) => void;
}

const DataStoreConfigCards: FC<DataStoreConfigCardsProps> = ({
  dataStoreConfigs,
  dataStoreSchemas,
  savingConfig,
  updateDataStoreConfig,
}) => {
  return (
    <>
      {dataStoreConfigs.length ? (
        dataStoreConfigs.map((dataStoreConfig) => {
          const dataStoreSchema = findDataStoreSchema(dataStoreConfig.path, dataStoreSchemas);
          if (!dataStoreSchema) {
            return null;
          }

          return (
            <Grid item xs={12} key={dataStoreConfig.path}>
              <DataStoreConfigCard
                dataStoreConfig={dataStoreConfig}
                dataStoreSchema={dataStoreSchema}
                savingConfig={savingConfig}
                updateDataStoreConfig={updateDataStoreConfig}
              />
            </Grid>
          );
        })
      ) : (
        <NoContentPlaceholder
          size="medium"
          message={intl.formatMessage({
            id: 'specification.configuration.dataStoreConfig.noDataStores',
            defaultMessage: 'No data stores in the session template.',
          })}
        />
      )}
    </>
  );
};

export default DataStoreConfigCards;
