import Schema, { RuleItem } from 'async-validator';
import { AxiosPromise } from 'axios';

import {
  SupplierDetail,
  SupplierSettings,
  MembersRequest,
  UsersResponse,
  Avatar,
  UpdateAvatarRequest,
  SupplierMembershipRequest,
  SupplierMembershipDetail,
  MediaDetail,
} from '../types';
import { AXIOS, wrapUniquePromise, FileUploadConfig, RequestConfig } from './endpoints';
import {
  NOT_BLANK_REGEX,
  duplicateValidator,
  slugKeyValidator,
  notTrimmableValidator,
  nameLengthValidator,
} from '../util';
import { intl } from '../Internationalization';
import { supplierByName } from './supplierLookup';

export function getSupplier(supplierKey: string): AxiosPromise<SupplierDetail> {
  return AXIOS.get(`/suppliers/${supplierKey}`);
}

export function uniqueByKey(supplierKey: string): Promise<boolean> {
  return wrapUniquePromise(AXIOS.head(`/suppliers/${supplierKey}`));
}

export function updateSupplier(
  supplierKey: string,
  supplier: SupplierSettings
): AxiosPromise<SupplierDetail> {
  return AXIOS.post(`/suppliers/${supplierKey}`, {
    name: supplier.name,
    active: supplier.active,
  });
}

export function updateAvatar(
  supplierKey: string,
  request: UpdateAvatarRequest
): AxiosPromise<Avatar> {
  return AXIOS.post(`/suppliers/${supplierKey}/avatar`, request);
}

export function uploadImage(
  supplierKey: string,
  image: File,
  config?: FileUploadConfig
): AxiosPromise<MediaDetail> {
  var formData = new FormData();
  formData.append('file', image);
  return AXIOS.post(`/suppliers/${supplierKey}/avatar/image`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...(config || {}),
  });
}

export function deleteImage(supplierKey: string): AxiosPromise<void> {
  return AXIOS.delete(`/suppliers/${supplierKey}/avatar/image`);
}

export function getMembers(
  supplierKey: string,
  request: MembersRequest,
  config?: RequestConfig
): AxiosPromise<UsersResponse> {
  return AXIOS.get(`/suppliers/${supplierKey}/members`, {
    ...config,
    params: request,
  });
}

export function fetchSupplierMembership(
  supplierKey: string,
  userKey: string
): AxiosPromise<SupplierMembershipDetail> {
  return AXIOS.get(`/suppliers/${supplierKey}/membership/${userKey}`);
}

export function grantSupplierMembership(
  supplierKey: string,
  userKey: string,
  request: SupplierMembershipRequest
): AxiosPromise<SupplierMembershipDetail> {
  return AXIOS.post(`/suppliers/${supplierKey}/membership/${userKey}`, request);
}

export function revokeSupplierMembership(
  supplierKey: string,
  userKey: string
): AxiosPromise<UsersResponse> {
  return AXIOS.delete(`/suppliers/${supplierKey}/membership/${userKey}`);
}

export function extractSupplierSettings(supplierDetail: SupplierDetail): SupplierSettings {
  return (({ name, active }) => ({ name, active }))(supplierDetail);
}

function supplierNameValidator(getExistingName?: () => string): RuleItem[] {
  return [
    {
      required: true,
      pattern: NOT_BLANK_REGEX,
      message: intl.formatMessage({
        id: 'supplier.validator.name.required',
        defaultMessage: 'Please provide a name',
      }),
    },
    nameLengthValidator,
    notTrimmableValidator,
    duplicateValidator({
      regex: NOT_BLANK_REGEX,
      existingValue: getExistingName,
      checkUnique: supplierByName,
      alreadyExistsMessage: intl.formatMessage({
        id: 'supplier.validator.name.unique',
        defaultMessage: 'A supplier with this name already exists',
      }),
      errorMessage: intl.formatMessage({
        id: 'supplier.validator.name.checkUniqueError',
        defaultMessage: 'There was a problem verifying the supplier name',
      }),
    }),
  ];
}

export function supplierSettingsValidator(getExistingName?: () => string): Schema {
  return new Schema({
    name: supplierNameValidator(getExistingName),
  });
}

export function createSupplierRequestValidator(): Schema {
  return new Schema({
    name: supplierNameValidator(),
    key: [
      {
        required: true,
        pattern: NOT_BLANK_REGEX,
        message: intl.formatMessage({
          id: 'supplier.validator.key.required',
          defaultMessage: 'Please provide a key',
        }),
      },
      slugKeyValidator,
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        checkUnique: uniqueByKey,
        alreadyExistsMessage: intl.formatMessage({
          id: 'supplier.validator.key.unique',
          defaultMessage: 'A supplier with this key already exists',
        }),
        errorMessage: intl.formatMessage({
          id: 'supplier.validator.key.checkUniqueError',
          defaultMessage: 'There was a problem verifying the supplier key',
        }),
      }),
    ],
  });
}
