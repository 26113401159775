import defaultProjectImage from '../assets/images/default-project.jpg';
import { MediaDetail, ProjectMetadata } from '../types';
import { generateApiUri } from './endpoints';

export const downloadProjectImageUri = (projectKey: string, image: MediaDetail): string => {
  return generateApiUri(`/projects/${projectKey}/image/${image.storedFilename}`);
};

export const projectImageOrDefault = (project: ProjectMetadata): string => {
  const { key, image } = project;
  return image ? downloadProjectImageUri(key, image) : defaultProjectImage;
};
