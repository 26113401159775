import { FC } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import { AppDecorator } from '../../components';
import { ErrorBlock } from '../../contexts/error-block';
import { intl } from '../../Internationalization';
import { useRequiredParams } from '../../hooks';

import MyAssignments from './MyAssignments';
import MyAssignment from './my-assignment/MyAssignment';

// Key is required on MyAssignment component to remount it when the assignment key changes
const CurrentMyAssignment: FC = () => {
  const { assignmentKey } = useRequiredParams<{ assignmentKey: string }>();
  return <MyAssignment key={assignmentKey} assignmentKey={assignmentKey} />;
};

const MyAssignmentsRouting: FC = () => (
  <AppDecorator
    title={intl.formatMessage({
      id: 'myAssignments.routing.title',
      defaultMessage: 'My Assignments',
    })}
  >
    <ErrorBlock>
      <Routes>
        <Route index element={<MyAssignments />} />
        <Route path=":assignmentKey/*" element={<CurrentMyAssignment />} />
        <Route path="*" element={<Navigate to="." replace />} />
      </Routes>
    </ErrorBlock>
  </AppDecorator>
);

export default MyAssignmentsRouting;
