import { FC } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import PolicyIcon from '@mui/icons-material/Policy';

import { AppDecorator, AppTabs, RouterTabs, RouterTab } from '../../../components';
import { ErrorBlock } from '../../../contexts/error-block';
import { intl } from '../../../Internationalization';
import { useRouterTab } from '../../../hooks';

import SystemHeader from '../SystemHeader';
import VirusScannerSettings from './VirusScannerSettings';

const VirusScannerRouting: FC = () => {
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="virus-scanner-tabs" value={routerTab}>
      <RouterTab
        id="settings-tab"
        value="settings"
        label={intl.formatMessage({
          id: 'virusScanner.routing.tabs.settings',
          defaultMessage: 'Settings',
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  return (
    <AppDecorator renderTabs={renderTabs}>
      <SystemHeader
        title={intl.formatMessage({
          id: 'virusScanner.routing.title',
          defaultMessage: 'Virus Scanner',
        })}
        icon={PolicyIcon}
      />
      <AppTabs />
      <Box p={3}>
        <ErrorBlock>
          <Routes>
            <Route path="settings" element={<VirusScannerSettings />} />
            <Route path="*" element={<Navigate to="settings" replace />} />
          </Routes>
        </ErrorBlock>
      </Box>
    </AppDecorator>
  );
};

export default VirusScannerRouting;
