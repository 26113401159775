import { useState, FC, useEffect, useCallback, PropsWithChildren } from 'react';
import { useLocation } from 'react-router';

import { intl } from '../../Internationalization';

import ErrorMessage from './ErrorMessage';
import { ErrorBlockContext, ErrorLevel } from './context';

interface ErrorDescriptor {
  level: ErrorLevel;
  title: string;
  description: string;
  message: string;
}

const ErrorBlock: FC<PropsWithChildren> = ({ children }) => {
  const [error, setError] = useState<ErrorDescriptor>();
  const { pathname } = useLocation();

  useEffect(() => {
    setError(undefined);
  }, [pathname]);

  const raiseError = useCallback((message: string) => {
    setError({
      level: 'error',
      title: intl.formatMessage({
        id: 'contexts.errorBlock.errorMessage.errorTitle',
        defaultMessage: 'An Error Occurred',
      }),
      description: intl.formatMessage({
        id: 'contexts.errorBlock.errorMessage.errorDescription',
        defaultMessage:
          'An error occurred, please try again. If the problem persists, contact your site administrator.',
      }),
      message,
    });
  }, []);

  const raiseAccessError = useCallback((message: string) => {
    setError({
      level: 'warning',
      title: intl.formatMessage({
        id: 'contexts.errorBlock.errorMessage.accessErrorTitle',
        defaultMessage: 'Access Denied',
      }),
      description: intl.formatMessage({
        id: 'contexts.errorBlock.errorMessage.accessErrorDescription',
        defaultMessage:
          'You do not have permission to view the requested resource. If you believe this is a mistake, contact your site administrator.',
      }),
      message,
    });
  }, []);

  if (error) {
    return <ErrorMessage {...error} onClear={() => setError(undefined)} />;
  }

  return (
    <ErrorBlockContext.Provider
      value={{
        raiseError,
        raiseAccessError,
      }}
    >
      {children}
    </ErrorBlockContext.Provider>
  );
};

export default ErrorBlock;
