import { Grid, Paper, Skeleton } from '@mui/material';

const ChartPaperSkeleton = () => (
  <Grid item lg={6} md={12}>
    <Paper sx={{ py: 2, px: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Skeleton height={45} width={200} />
      <Skeleton height={300} width="100%" />
    </Paper>
  </Grid>
);

export default ChartPaperSkeleton;
