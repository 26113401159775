import React from 'react';
import Skeleton from '@mui/material/Skeleton';

import { AvatarPaperHeader, TextEditorSkeleton, AvatarPaperSkeleton } from '../../../../components';

const TemplateEditorSkeleton: React.FC = () => (
  <AvatarPaperSkeleton>
    <AvatarPaperHeader>
      <Skeleton width={150} height={30} />
      <Skeleton width={350} height={20} />
    </AvatarPaperHeader>
    <TextEditorSkeleton />
  </AvatarPaperSkeleton>
);

export default TemplateEditorSkeleton;
