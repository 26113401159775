import { FC } from 'react';

import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Variant } from '@mui/material/styles/createTypography';

interface NoContentPlaceholderSizeSetting {
  iconMargin: number;
  iconFontSize: string;
  typographyVariant: Variant;
  height: number;
}

const NO_CONTENT_PLACEHOLDER_SIZE_SETTINGS: Record<
  NoContentPlaceholderSize,
  NoContentPlaceholderSizeSetting
> = {
  small: {
    iconMargin: 2,
    iconFontSize: '2rem',
    typographyVariant: 'body2',
    height: 150,
  },
  medium: {
    iconMargin: 2,
    iconFontSize: '2.5rem',
    typographyVariant: 'body1',
    height: 200,
  },
  large: {
    iconMargin: 5,
    iconFontSize: '3rem',
    typographyVariant: 'h5',
    height: 300,
  },
};

type NoContentPlaceholderSize = 'small' | 'medium' | 'large';

export interface NoContentPlaceholderProps {
  message: string;
  size?: NoContentPlaceholderSize;
  id?: string;
}

const NoContentPlaceholder: FC<NoContentPlaceholderProps> = ({ id, message, size = 'large' }) => {
  const { iconMargin, iconFontSize, typographyVariant, height } =
    NO_CONTENT_PLACEHOLDER_SIZE_SETTINGS[size];

  return (
    <Box
      id={id}
      className="NoContentPlaceholder-root"
      height={height}
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <InfoOutlinedIcon
        sx={{
          color: 'text.secondary',
          mb: iconMargin,
          fontSize: iconFontSize,
        }}
      />
      <Typography variant={typographyVariant} color="textSecondary">
        {message}
      </Typography>
    </Box>
  );
};

export default NoContentPlaceholder;
