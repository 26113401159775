import { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Fab, styled } from '@mui/material';

import { FabProps } from '@mui/material/Fab';

type AddFabProps = Omit<FabProps, 'className' | 'color' | 'children'>;

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(3),
  right: theme.spacing(3),
}));

const AddFab: FC<AddFabProps> = ({ ...props }) => (
  <StyledFab color="primary" {...props}>
    <AddIcon />
  </StyledFab>
);

export default AddFab;
