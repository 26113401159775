import { FC, useCallback, useContext, useEffect, useState } from 'react';

import * as GlobalStatsApi from '../../../api/globalStats';
import { extractErrorMessage } from '../../../api/endpoints';
import { FilterBar, FilterContainer, FilterToggle } from '../../../components';
import { ViewMode, getCurrentTimeZone, toCompleteOffsetDateTimeRange } from '../../../util';
import {
  GlobalActivity,
  DailyActivity,
  ProjectActivity,
  PeriodStatsRequest,
  CompleteDateRange,
} from '../../../types';
import { intl } from '../../../Internationalization';
import { useErrorBlock } from '../../../contexts/error-block';
import FilterCompleteDateRange from '../../../components/browse-table/FilterCompleteDateRange';

import { DashboardContext } from '../DashboardContext';
import ChartsSkeleton from '../ChartsSkeleton';

import GlobalStats from './GlobalStats';
import GlobalCharts from './GlobalCharts';
import DashboardViewMode from '../DashboardViewMode';

const Global: FC = () => {
  const { raiseError } = useErrorBlock();
  const {
    updateGlobalFilters,
    globalFilters,
    globalFilters: { dateRange, includeRejected },
  } = useContext(DashboardContext);

  const [viewMode, setViewMode] = useState<ViewMode>('GRID');
  const [globalActivity, setGlobalActivity] = useState<GlobalActivity>();
  const [dailyActivity, setDailyActivity] = useState<DailyActivity[]>();
  const [projectActivity, setProjectActivity] = useState<ProjectActivity[]>();

  useEffect(() => {
    const loadGlobalActivity = async () => {
      if (!dateRange.start || !dateRange.end) {
        return;
      }

      const request: PeriodStatsRequest = {
        period: toCompleteOffsetDateTimeRange(dateRange),
        timeZone: getCurrentTimeZone(),
        includeRejected,
      };

      try {
        const [
          { data: globalActivityData },
          { data: dailyActivityData },
          { data: projectActivityData },
        ] = await Promise.all([
          GlobalStatsApi.globalActivity(request),
          GlobalStatsApi.dailyActivity(request),
          GlobalStatsApi.projectActivity(request),
        ]);

        setGlobalActivity(globalActivityData);
        setDailyActivity(dailyActivityData);
        setProjectActivity(projectActivityData);
      } catch (error: any) {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'dashboard.global.loadError',
              defaultMessage: 'Failed to load dashboard data',
            })
          )
        );
      }
    };

    loadGlobalActivity();
  }, [includeRejected, dateRange, raiseError]);

  const onRangeChange = useCallback(
    (newDateRange: CompleteDateRange) => {
      updateGlobalFilters({ ...globalFilters, dateRange: newDateRange });
    },
    [globalFilters, updateGlobalFilters]
  );

  const onIncludeRejectedChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateGlobalFilters({ ...globalFilters, includeRejected: event.target.checked });
    },
    [globalFilters, updateGlobalFilters]
  );

  const handleViewModeChange = (_: React.MouseEvent<HTMLElement>, newViewMode: ViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  if (!globalActivity || !dailyActivity || !projectActivity) {
    return <ChartsSkeleton />;
  }

  return (
    <div id="dashboard-global">
      <FilterBar
        actions={
          <>
            <FilterToggle
              label={intl.formatMessage({
                id: 'dashboard.global.filterRejectedToggle.label',
                defaultMessage: 'Include Rejected',
              })}
              name="includeRejected"
              checked={includeRejected}
              onChange={onIncludeRejectedChange}
            />
            <DashboardViewMode onChange={handleViewModeChange} viewMode={viewMode} />
          </>
        }
        barFilters={
          <FilterContainer>
            <FilterCompleteDateRange
              range={dateRange}
              onRangeUpdated={onRangeChange}
              rangeLimit={{ years: 1 }}
            />
          </FilterContainer>
        }
      />
      <GlobalStats globalActivity={globalActivity} />
      <GlobalCharts
        projectActivity={projectActivity}
        dailyActivity={dailyActivity}
        viewMode={viewMode}
      />
    </div>
  );
};

export default Global;
