import { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import {
  Container,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { extractErrorMessage } from '../../../../api/endpoints';
import * as IdentityProviderLinksApi from '../../../../api/identityProviderLinks';
import * as IdentityProviderLinkApi from '../../../../api/identityProviderLink';
import {
  PaddedPaper,
  AddFab,
  TableInfoRow,
  TableLoadingRow,
  ConfirmDialog,
  MinWidthTableCell,
  FilterPagination,
  DefaultButton,
} from '../../../../components';
import { IdentityProviderLinkDetail, IDENTITY_PROVIDER_TYPE_METADATA } from '../../../../types';
import { intl } from '../../../../Internationalization';
import { useBrowseRequest } from '../../../../hooks';

import { UserContext } from '../UserContext';

import NewIdentityProviderLink from './NewIdentityProviderLink';

const PAGE_SIZE = 10;

const IdentityProviderLinks: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { userKey } = useContext(UserContext);

  const { request, response, processing, setPage, refresh } = useBrowseRequest({
    initialRequest: { page: 0, size: PAGE_SIZE, userKey },
    onRequest: IdentityProviderLinksApi.getIdentityProviderLinks,
  });

  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false);
  const [linkToDelete, setLinkToDelete] = useState<IdentityProviderLinkDetail>();

  const handleIdentityProviderLinkCreated = () => {
    setAddDialogOpen(false);
    refresh();
  };

  const confirmDelete = async () => {
    if (!linkToDelete) {
      return;
    }
    try {
      await IdentityProviderLinkApi.deleteIdentityProviderLink(
        linkToDelete.identityProvider.key,
        userKey
      );
      refresh();
      enqueueSnackbar(
        intl.formatMessage({
          id: 'user.identityProviderLinks.deleteSuccess',
          defaultMessage: 'Identity provider link has been deleted',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'user.identityProviderLinks.deleteError',
            defaultMessage: 'Failed to delete identity provider link',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setLinkToDelete(undefined);
    }
  };

  const renderTableContent = () => {
    if (!response) {
      return <TableLoadingRow colSpan={4} />;
    }

    if (!response.results.length) {
      return (
        <TableInfoRow
          colSpan={4}
          size="medium"
          message={intl.formatMessage({
            id: 'user.identityProviderLinks.noIdentityProviderLinks',
            defaultMessage: 'No identity provider links found',
          })}
        />
      );
    }

    return response.results.map((identityProviderLink) => {
      return (
        <TableRow
          key={`${identityProviderLink.remoteId}-${identityProviderLink.identityProvider.key}`}
        >
          <TableCell>{identityProviderLink.identityProvider.name}</TableCell>
          <TableCell>
            {IDENTITY_PROVIDER_TYPE_METADATA[identityProviderLink.identityProvider.type].label}
          </TableCell>
          <TableCell>{identityProviderLink.remoteId}</TableCell>
          <MinWidthTableCell>
            <DefaultButton
              color="grey"
              onClick={() => setLinkToDelete(identityProviderLink)}
              aria-label={intl.formatMessage({
                id: 'user.identityProviderLinks.deleteIdentityProviderLink.ariaLabel',
                defaultMessage: 'Delete identity provider link',
              })}
            >
              <CloseIcon />
            </DefaultButton>
          </MinWidthTableCell>
        </TableRow>
      );
    });
  };

  return (
    <Container maxWidth="md" id="user-identity-provider-links" disableGutters>
      <PaddedPaper>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h5">
            <FormattedMessage
              id="user.identityProviderLinks.title"
              defaultMessage="Identity Provider Links"
            />
          </Typography>
          <Box>
            <FilterPagination
              page={request.page}
              size={request.size}
              total={response?.total}
              disabled={processing}
              setPage={setPage}
            />
          </Box>
        </Box>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage
                  id="user.identityProviderLinks.table.identityProviderColumn"
                  defaultMessage="Identity Provider"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="user.identityProviderLinks.table.typeColumn"
                  defaultMessage="Type"
                />
              </TableCell>
              <TableCell>
                <FormattedMessage
                  id="user.identityProviderLinks.table.remoteIdColumn"
                  defaultMessage="Remote Id"
                />
              </TableCell>
              <MinWidthTableCell />
            </TableRow>
          </TableHead>
          <TableBody>{renderTableContent()}</TableBody>
        </Table>
      </PaddedPaper>
      <AddFab
        id="add-identity-provider-link"
        onClick={() => setAddDialogOpen(true)}
        aria-label={intl.formatMessage({
          id: 'user.identityProviderLinks.addFab.addIdentityProviderLink.ariaLabel',
          defaultMessage: 'Add identity provider link',
        })}
      />
      {addDialogOpen && (
        <NewIdentityProviderLink
          onCancel={() => setAddDialogOpen(false)}
          onCreated={handleIdentityProviderLinkCreated}
        />
      )}
      <ConfirmDialog
        id="confirm-remove-identity-provider-link"
        isOpen={!!linkToDelete}
        title={intl.formatMessage({
          id: 'user.identityProviderLinks.confirmRemoveIdentityProviderLink.title',
          defaultMessage: 'Remove identity provider link',
        })}
        text={intl.formatMessage({
          id: 'user.identityProviderLinks.confirmRemoveIdentityProviderLink.text',
          defaultMessage: 'Are you sure you wish to remove this identity provider link?',
        })}
        confirmBtnText={intl.formatMessage({
          id: 'user.identityProviderLinks.confirmRemoveIdentityProviderLink.button',
          defaultMessage: 'Delete',
        })}
        confirmAction={confirmDelete}
        closeAction={() => setLinkToDelete(undefined)}
      />
    </Container>
  );
};

export default IdentityProviderLinks;
