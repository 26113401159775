import { FC, PropsWithChildren } from 'react';
import { View } from '@react-pdf/renderer';
import { Style, ViewProps } from '@react-pdf/types';

interface PdfTableRowProps extends ViewProps, PropsWithChildren {
  gutterBottom?: boolean;
  style?: Style | Style[];
  header?: boolean;
}

const PdfTableRow: FC<PdfTableRowProps> = ({ style = [], header, children, ...rest }) => (
  <View
    style={[
      {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        padding: 4,
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderBottom: header ? '1 solid #E8E8E8' : 'none',
      },
      ...(Array.isArray(style) ? style : [style]),
    ]}
    wrap={false}
    {...rest}
  >
    {children}
  </View>
);

export default PdfTableRow;
