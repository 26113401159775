import { FC, useContext, useState } from 'react';
import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import { Container, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import * as AssignmentSavedMappingApi from '../../../../../api/assignmentSavedMapping';
import { extractErrorMessage } from '../../../../../api/endpoints';
import {
  DefaultButton,
  FormButtons,
  PaddedPaper,
  ValidatedTextField,
} from '../../../../../components';
import { intl } from '../../../../../Internationalization';
import { validate } from '../../../../../util';

import { SavedMappingContext } from './SavedMappingContext';

const SavedMappingSettings: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { assignmentKey, savedMapping, updateSavedMapping } = useContext(SavedMappingContext);

  const [name, setName] = useState<string>(savedMapping.name);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();
  const [processing, setProcessing] = useState<boolean>(false);

  const VALIDATOR = AssignmentSavedMappingApi.savedMappingSettingsValidator(
    () => assignmentKey,
    () => savedMapping.name
  );

  const handleUpdateMapping = async (validationResult: { name: string }) => {
    try {
      const response = await AssignmentSavedMappingApi.updateSavedMapping(
        assignmentKey,
        savedMapping.key,
        { name: validationResult.name, mappings: savedMapping.mappings }
      );
      updateSavedMapping(response.data);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'myAssignment.savedMapping.settings.updateSuccess',
          defaultMessage: 'Saved mapping updated',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'myAssignment.savedMapping.settings.updateError',
            defaultMessage: 'Failed to update saved mapping',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const validateAndSubmit = async () => {
    setProcessing(true);
    try {
      setFieldErrors({});
      await handleUpdateMapping(await validate(VALIDATOR, { name }));
    } catch (errors: any) {
      setFieldErrors(errors);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Container maxWidth="md" disableGutters>
      <PaddedPaper>
        <Typography variant="h5">
          <FormattedMessage
            id="myAssignment.savedMapping.settings.title"
            defaultMessage="Saved Mapping Settings"
          />
        </Typography>
        <ValidatedTextField
          tooltip={intl.formatMessage({
            id: 'myAssignment.savedMapping.settings.key.tooltip',
            defaultMessage: 'Key is an automatically generated identifier, not editable.',
          })}
          disabled={true}
          name="key"
          label={intl.formatMessage({
            id: 'myAssignment.savedMapping.settings.key.label',
            defaultMessage: 'Key',
          })}
          value={savedMapping.key}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          name="name"
          value={name}
          label={intl.formatMessage({
            id: 'myAssignment.savedMapping.settings.name.label',
            defaultMessage: 'Name',
          })}
          tooltip={intl.formatMessage({
            id: 'myAssignment.savedMapping.settings.name.tooltip',
            defaultMessage: 'A descriptive name, unique to this assignment.',
          })}
          onChange={(e) => setName(e.target.value)}
          fieldErrors={fieldErrors}
          disabled={processing}
          margin="normal"
          variant="outlined"
        />
        <FormButtons>
          <DefaultButton
            name="updateSavedMappingSettings"
            onClick={validateAndSubmit}
            disabled={processing}
            startIcon={<SaveIcon />}
          >
            <FormattedMessage
              id="myAssignment.savedMapping.settings.saveButton"
              defaultMessage="Save"
            />
          </DefaultButton>
        </FormButtons>
      </PaddedPaper>
    </Container>
  );
};

export default SavedMappingSettings;
