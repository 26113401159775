import { FC } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import Specifications from './Specifications';
import Specification from './specification/Specification';

const SpecificationsRouting: FC = () => (
  <Routes>
    <Route index element={<Specifications />} />
    <Route path=":specificationKey/*" element={<Specification />} />
    <Route path="*" element={<Navigate to="." replace />} />
  </Routes>
);

export default SpecificationsRouting;
