import * as React from 'react';
import { Me } from '../../types';

export interface AuthenticationContextValue {
  updateMe: (me: Me) => void;
  refresh: () => void;
  signOut: (redirect: boolean) => void;
  me?: Me;
}

const AuthenticationContextDefaultValue = {} as AuthenticationContextValue;
export const AuthenticationContext = React.createContext(AuthenticationContextDefaultValue);

export interface AuthenticatedContextValue extends AuthenticationContextValue {
  me: Me;
}

const AuthenticatedContextDefaultValue = {} as AuthenticatedContextValue;
export const AuthenticatedContext = React.createContext(AuthenticatedContextDefaultValue);
