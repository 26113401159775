import { FC } from 'react';

import { Avatar, Theme, AvatarProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export type AvatarSize = 'small' | 'medium' | 'large';

const AVATAR_STYLES: Record<AvatarSize, SystemStyleObject<Theme>> = {
  small: {
    width: (theme) => theme.spacing(3),
    height: (theme) => theme.spacing(3),
    fontSize: '0.8rem',
  },
  medium: {
    width: (theme) => theme.spacing(5),
    height: (theme) => theme.spacing(5),
  },
  large: {
    width: (theme) => theme.spacing(10),
    height: (theme) => theme.spacing(10),
    fontSize: '2.5rem',
  },
};

export interface SizedAvatarProps extends AvatarProps {
  size?: 'small' | 'medium' | 'large';
}

const SizedAvatar: FC<SizedAvatarProps> = ({ sx = [], size = 'medium', children, ...rest }) => (
  <Avatar sx={[AVATAR_STYLES[size], ...(Array.isArray(sx) ? sx : [sx])]} {...rest}>
    {children}
  </Avatar>
);
export default SizedAvatar;
