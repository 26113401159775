import React, { FC, useState } from 'react';
import { EditorState, Modifier } from 'draft-js';
import { FormattedMessage } from 'react-intl';

import { Field } from '../../../types';
import { intl } from '../../../Internationalization';

import { Option } from './Option';
import FieldMenu from './FieldMenu';
import { SvgIconProps } from '@mui/material';
import SmallListItemIcon from '../SmallListItemIcon';

export interface FieldToolbarMenuButtonProps {
  onChange?: (editorState: EditorState) => void;
  editorState?: EditorState;
  fields: Field[];
  label?: string;
  icon?: React.ComponentType<SvgIconProps>;
}

const FieldToolbarMenuButton: FC<FieldToolbarMenuButtonProps> = ({
  editorState,
  onChange,
  fields,
  label,
  icon: Icon,
}) => {
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const insertField = (field: Field): void => {
    if (!editorState || !onChange) {
      return;
    }

    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      `\${${field.expression}}`,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, 'insert-fragment'));
    handleClose();
  };

  return (
    <>
      <Option
        onClick={handleClick}
        title={
          label
            ? label
            : intl.formatMessage({
                id: 'components.editor.fieldToolbarMenuButton.addField',
                defaultMessage: 'Add field',
              })
        }
        value="field"
        className="AddFieldButton"
      >
        <>
          {Icon && (
            <SmallListItemIcon>
              <Icon />
            </SmallListItemIcon>
          )}
          {label ? (
            label
          ) : (
            <FormattedMessage
              id="components.editor.fieldToolbarMenuButton.addField"
              defaultMessage="Add field"
            />
          )}
        </>
      </Option>
      <FieldMenu
        id="subject-editor-add-field-menu"
        fields={fields}
        anchorElement={anchorElement}
        onClose={handleClose}
        onFieldSelected={insertField}
      />
    </>
  );
};

export default FieldToolbarMenuButton;
