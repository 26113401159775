import React, { FC, PropsWithChildren, useCallback } from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';

import es from './locale/lang/es.json';
import en from './locale/lang/en.json';
import cy from './locale/lang/cy.json';
import enUS from './locale/lang/en-US.json';

import { Locale, setDefaultOptions } from 'date-fns';
import {
  enGB as dateFnsEnGB,
  es as dateFnsEs,
  cy as dateFnsCy,
  enUS as dateFnsEnUS,
} from 'date-fns/locale';

import { UserLocale, USER_LOCALES } from './types/user';

export interface InternationalizationContextValue {
  setPreferredLocale: (preferredLocale?: UserLocale) => void;
}

const InternationalizationContextDefaultValue = {} as InternationalizationContextValue;
export const InternationalizationContext = React.createContext(
  InternationalizationContextDefaultValue
);

const PREFERRED_LOCALE_KEY = 'preferredLocale';

const getExistingPreferredLocale = () => {
  const preferredLocale = (localStorage.getItem(PREFERRED_LOCALE_KEY) as UserLocale) || undefined;
  if (USER_LOCALES.includes(preferredLocale)) {
    return preferredLocale;
  }
};

const LOCALE_LOOKUP: Record<string, UserLocale> = {
  // Supported locales
  'es-ES': 'es-ES',
  'en-US': 'en-US',
  'en-GB': 'en-GB',
  'cy-GB': 'cy-GB',

  // Language fallbacks
  'en': 'en-GB',
  'es': 'es-ES',
  'cy': 'cy-GB',
};

const messages: Record<UserLocale, Record<string, string>> = {
  'en-GB': en,
  'es-ES': es,
  'en-US': { ...en, ...enUS },
  'cy-GB': cy,
};

const findSupportedLocale = (localeCode: string) => {
  return LOCALE_LOOKUP[localeCode] || LOCALE_LOOKUP[localeCode.substring(0, 2)];
};

const calculateLocale = () => {
  const preferredLocale = getExistingPreferredLocale();

  if (preferredLocale) {
    return preferredLocale;
  }

  for (const localeCode of navigator.languages) {
    const supportedLocale = findSupportedLocale(localeCode);
    if (supportedLocale) {
      return supportedLocale;
    }
  }

  return 'en-GB';
};

export const DATE_FNS_LOCALE_LOOKUP: Record<string, Locale> = {
  // Supported locales
  'es-ES': dateFnsEs,
  'en-US': dateFnsEnUS,
  'en-GB': dateFnsEnGB,
  'cy-GB': dateFnsCy,

  // Language fallbacks
  'en': dateFnsEnGB,
  'es': dateFnsEs,
  'cy': dateFnsCy,
};

// calculate the locale we are going to be displaying the site in
export const LOCALE = calculateLocale();

// configure date-fns default locale
setDefaultOptions({ locale: DATE_FNS_LOCALE_LOOKUP[LOCALE] });

// configure react-intl
const cache = createIntlCache();
export const intl = createIntl(
  { locale: LOCALE, messages: messages[LOCALE], defaultLocale: 'en-GB' },
  cache
);

const reloadIfRequired = () => {
  if (intl.locale !== calculateLocale()) {
    window.location.reload();
  }
};

const Internationalization: FC<PropsWithChildren> = ({ children }) => {
  const setPreferredLocale = useCallback((preferredLocale?: UserLocale) => {
    if (preferredLocale) {
      localStorage.setItem(PREFERRED_LOCALE_KEY, preferredLocale);
    } else {
      localStorage.removeItem(PREFERRED_LOCALE_KEY);
    }
    reloadIfRequired();
  }, []);

  return (
    <InternationalizationContext.Provider value={{ setPreferredLocale }}>
      <RawIntlProvider key={LOCALE} value={intl}>
        {children}
      </RawIntlProvider>
    </InternationalizationContext.Provider>
  );
};

export default Internationalization;
