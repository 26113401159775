import { Box, Grid, Skeleton } from '@mui/material';

const HeaderSkeleton = () => (
  <Box p={2} position="relative" bgcolor="secondary.light">
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid item>
        <Skeleton variant="circular" width={80} height={80} />
      </Grid>
      <Grid item>
        <Skeleton width={150} height={30} />
        <Skeleton width={250} height={20} />
      </Grid>
    </Grid>
  </Box>
);

export default HeaderSkeleton;
