import { useState, useEffect, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';
import { Geometry } from 'ol/geom';
import { Extent } from 'ol/extent';
import * as ol from 'ol';

import { useResizeDetector } from 'react-resize-detector';

import { Box, Link, Typography } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MapIcon from '@mui/icons-material/Map';

import * as SubmissionApi from '../../../../../../api/submission';
import { extractErrorMessage } from '../../../../../../api/endpoints';
import {
  AppDecorator,
  HeaderBreadcrumb,
  Loading,
  NoContentPlaceholder,
} from '../../../../../../components';
import {
  TaskMapConfig,
  TaskMapConfigs,
  TaskState,
  TaskResult,
  ObjectReport,
  TaskResultKind,
} from '../../../../../../types';
import { useErrorBlock } from '../../../../../../contexts/error-block';
import { intl } from '../../../../../../Internationalization';
import { dateTimeFormat } from '../../../../../../util';

import { toMySubmissionLink } from '../../MySubmissions';

import { OpenSubmissionContext } from '../OpenSubmissionContext';

import MapComponent from './MapComponent';
import MapMenu from './menu/MapMenu';

export interface ObjectFeature extends ol.Feature<Geometry> {
  ol_uid: string;
  layer: string;
}

const taskIsReportable = (taskResult: TaskResult) =>
  taskResult.kind === TaskResultKind.CheckRulesTask ||
  taskResult.kind === TaskResultKind.ApplyActionTask ||
  taskResult.kind === TaskResultKind.ApplyActionMapTask;

const taskIsPausedAndNotLast = (taskStatus: TaskState, index: number, taskLength: number) =>
  taskStatus === TaskState.PAUSED && index < taskLength - 1;

const findLatestTaskResult = (taskResults: TaskResult[]): TaskResult =>
  taskResults.find((taskResult, index) =>
    taskIsPausedAndNotLast(taskResult.status, index, taskResults.length)
  ) || taskResults[taskResults.length - 2];

const getLayersFromConfig = (taskMapConfigs: TaskMapConfigs) => {
  const layerSelections: LayerSelections = {};
  Object.values(taskMapConfigs).forEach((taskMapConfig) => {
    taskMapConfig.layers.forEach((layer) => {
      layerSelections[layer] = true;
    });
  });
  return layerSelections;
};

export interface LayerSelections {
  [layer: string]: boolean;
}

const consumedHeight = () =>
  document.getElementById('assignments-header')!.getBoundingClientRect().bottom;

const MapContainer = () => {
  const { width: containerWidth, ref: containerRef } = useResizeDetector();

  const { enqueueSnackbar } = useSnackbar();
  const { raiseError } = useErrorBlock();

  const { submission, assignment } = useContext(OpenSubmissionContext);

  const [taskResult, setTaskResult] = useState<TaskResult>();
  const [taskMapConfigs, setTaskMapConfigs] = useState<TaskMapConfigs>();
  const [taskMapConfig, setTaskMapConfig] = useState<TaskMapConfig>();
  const [layerSelections, setLayerSelections] = useState<LayerSelections>({});

  const [featureList, setFeatureList] = useState<ObjectFeature[]>([]);
  const [selectedFeature, setSelectedFeature] = useState<ObjectFeature>();
  const [hoveredFeature, setHoveredFeature] = useState<ObjectFeature>();
  const [zoomExtent, setZoomExtent] = useState<Extent>();

  const [reportStart, setReportStart] = useState<number>(0);
  const [objectReport, setObjectReport] = useState<ObjectReport>();
  const [loadingObjectReport, setLoadingObjectReport] = useState<boolean>(false);

  useEffect(() => {
    if (taskMapConfigs && !taskResult) {
      const latestTaskResult = findLatestTaskResult(submission.taskResults);
      setTaskResult(latestTaskResult);
      setTaskMapConfig(taskMapConfigs[latestTaskResult.taskIdentifier]);
    }
  }, [submission, taskResult, taskMapConfigs]);

  useEffect(() => {
    const fetchMapConfig = async () => {
      try {
        const response = await SubmissionApi.mapConfig(submission.reference);
        setLayerSelections(getLayersFromConfig(response.data.taskMapConfigs));
        setTaskMapConfigs(response.data.taskMapConfigs);
      } catch (error: any) {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'openSubmission.map.mapContainer.loadConfigError',
              defaultMessage: "Failed to fetch current submission's progress",
            })
          )
        );
      }
    };

    fetchMapConfig();
  }, [raiseError, submission.reference]);

  useEffect(() => {
    const fetchObjectReport = async (currentTaskResult: TaskResult) => {
      try {
        setLoadingObjectReport(true);
        const response = await SubmissionApi.report(submission.reference, {
          start: reportStart,
          count: 1,
          taskIdentifier: currentTaskResult.taskIdentifier,
        });
        selectObjectReport(response.data[0]);
      } catch (error: any) {
        selectObjectReport();
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'openSubmission.map.mapContainer.loadReportError',
              defaultMessage: 'Failed to fetch object report',
            })
          ),
          { variant: 'error' }
        );
      } finally {
        setLoadingObjectReport(false);
      }
    };

    if (taskResult && taskIsReportable(taskResult)) {
      fetchObjectReport(taskResult);
    } else {
      selectObjectReport();
    }
  }, [submission.reference, taskResult, reportStart, enqueueSnackbar]);

  const handleZoomToFeature = () => {
    const extent = selectedFeature?.getGeometry()?.getExtent();
    setZoomExtent(extent && [...extent]);
  };

  const selectObjectReport = (selectedObjectReport?: ObjectReport) => {
    setFeatureList([]);
    setSelectedFeature(undefined);
    setHoveredFeature(undefined);
    setObjectReport(selectedObjectReport);
  };

  const selectTaskResult = (selectedTaskResult: TaskResult) => {
    setObjectReport(undefined);
    setTaskResult(selectedTaskResult);
    setReportStart(0);
    setTaskMapConfig(taskMapConfigs![selectedTaskResult.taskIdentifier]);
    setFeatureList([]);
    setSelectedFeature(undefined);
    setHoveredFeature(undefined);
  };

  const renderBreadcrumb = () => {
    return (
      <HeaderBreadcrumb>
        <Link component={RouterLink} to={`/my_assignments/${assignment.key}/submissions`}>
          <AssignmentIcon
            titleAccess={intl.formatMessage({
              id: 'openSubmission.map.mapContainer.breadcrumb.assignment.titleAccess',
              defaultMessage: 'Assignment',
            })}
          />
          {assignment.reference}
        </Link>
        <Link component={RouterLink} to={toMySubmissionLink(submission)}>
          <AssessmentIcon
            titleAccess={intl.formatMessage({
              id: 'openSubmission.map.mapContainer.breadcrumb.submission.titleAccess',
              defaultMessage: 'Submission created at',
            })}
          />
          {dateTimeFormat(submission.createdAt)}
        </Link>
        <Typography>
          <MapIcon />
          <FormattedMessage
            id="openSubmission.map.mapContainer.viewMap"
            defaultMessage="View Map"
          />
        </Typography>
      </HeaderBreadcrumb>
    );
  };

  if (taskMapConfigs && Object.keys(taskMapConfigs).length === 0) {
    return <NoContentPlaceholder message="No features to display" />;
  }

  if (!taskResult || !taskMapConfig) {
    return <Loading />;
  }

  return (
    <AppDecorator renderBreadcrumb={renderBreadcrumb} renderTabs={undefined}>
      <Box
        ref={containerRef}
        id="my-assignment-open-submission-map"
        sx={{
          position: 'relative',
          margin: -3,
          height: `calc(100vh - ${consumedHeight()}px)`,
        }}
      >
        <MapMenu
          submission={submission}
          taskResult={taskResult}
          setTaskResult={selectTaskResult}
          taskMapConfig={taskMapConfig}
          layerSelections={layerSelections}
          setLayerSelections={setLayerSelections}
          zoomToFeature={handleZoomToFeature}
          featureList={featureList}
          selectedFeature={selectedFeature}
          setSelectedFeature={setSelectedFeature}
          setHoveredFeature={setHoveredFeature}
          objectReport={objectReport}
          reportStart={reportStart}
          setReportStart={setReportStart}
          loadingObjectReport={loadingObjectReport}
        />
        <MapComponent
          objectReport={objectReport}
          zoomExtent={zoomExtent}
          submissionReference={submission.reference}
          currentTaskIdentifier={taskResult.taskIdentifier}
          layers={layerSelections}
          taskMapConfig={taskMapConfig}
          hoveredFeature={hoveredFeature}
          selectedFeature={selectedFeature}
          setSelectedFeature={setSelectedFeature}
          setFeatureList={setFeatureList}
          containerWidth={containerWidth}
        />
      </Box>
    </AppDecorator>
  );
};

export default MapContainer;
