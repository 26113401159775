import { useContext } from 'react';
import { Container, Grid } from '@mui/material';

import * as SiteSettingsApi from '../../../api/siteSettings';
import * as SiteMediaApi from '../../../api/siteMedia';
import { UploadImage } from '../../../components';
import { MediaDetail } from '../../../types';
import { intl } from '../../../Internationalization';

import { SiteContext } from './SiteContext';

const Branding = () => {
  const { siteSettings, siteSettingsUpdated, refreshApplicationContext } = useContext(SiteContext);
  const { resources } = siteSettings;

  const logoUpdated = (logo?: MediaDetail) => {
    siteSettingsUpdated({ ...siteSettings, resources: { ...siteSettings.resources, logo } });
    refreshApplicationContext();
  };
  const logoDeleted = () => logoUpdated();

  const heroImageUpdated = (heroImage?: MediaDetail) => {
    siteSettingsUpdated({ ...siteSettings, resources: { ...siteSettings.resources, heroImage } });
    refreshApplicationContext();
  };
  const heroImageDeleted = () => heroImageUpdated();

  return (
    <Container maxWidth="lg" id="system-site-branding" disableGutters>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <UploadImage
            id="system-site-branding-logo"
            title={intl.formatMessage({
              id: 'site.branding.uploadLogo.title',
              defaultMessage: 'Logo',
            })}
            image={resources?.logo}
            imageUrl={SiteMediaApi.siteLogoOrDefault(resources.logo)}
            uploadHandler={SiteSettingsApi.uploadLogo}
            deleteHandler={SiteSettingsApi.deleteLogo}
            onUploaded={logoUpdated}
            onDeleted={logoDeleted}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <UploadImage
            id="system-site-branding-hero-image"
            title={intl.formatMessage({
              id: 'site.branding.uploadHeroImage.title',
              defaultMessage: 'Hero Image',
            })}
            image={resources?.heroImage}
            imageUrl={SiteMediaApi.siteHeroImageOrDefault(resources.heroImage)}
            uploadHandler={SiteSettingsApi.uploadHeroImage}
            deleteHandler={SiteSettingsApi.deleteHeroImage}
            onUploaded={heroImageUpdated}
            onDeleted={heroImageDeleted}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Branding;
