import { FC } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import SavedMapping from './saved-mapping/SavedMapping';
import SavedMappings from './SavedMappings';

const SavedMappingsRouting: FC = () => (
  <Routes>
    <Route index element={<SavedMappings />} />
    <Route path=":savedMappingKey/*" element={<SavedMapping />} />
    <Route path="*" element={<Navigate to="." replace />} />
  </Routes>
);

export default SavedMappingsRouting;
