import { FC, PropsWithChildren, useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import {
  AuthenticatedContext,
  AuthenticatedContextValue,
  AuthenticationContext,
} from '../contexts/authentication/context';
import * as AuthApi from '../api/auth';

const RequireAuthenticated: FC<PropsWithChildren> = ({ children }) => {
  const authenticationContext = useContext(AuthenticationContext);
  const location = useLocation();

  useEffect(() => {
    if (!authenticationContext.me) {
      AuthApi.storeLoginRedirect(location);
    }
  }, [authenticationContext.me, location]);

  if (authenticationContext.me) {
    return (
      <AuthenticatedContext.Provider value={authenticationContext as AuthenticatedContextValue}>
        {children}
      </AuthenticatedContext.Provider>
    );
  }

  return <Navigate to="/unauthorized" replace />;
};

export default RequireAuthenticated;
