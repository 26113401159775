import { FC } from 'react';

import { AccountAvatar, ContentHeader } from '../../components';
import { UserProfile } from '../../types';

interface AccountHeaderProps {
  user: UserProfile;
}

const AccountHeader: FC<AccountHeaderProps> = ({ user }) => {
  const { name } = user;
  return (
    <ContentHeader
      id="account-header"
      title={name}
      avatar={<AccountAvatar user={user} size="large" />}
    />
  );
};

export default AccountHeader;
