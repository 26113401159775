import { Stroke, Style, Circle, Fill, RegularShape } from 'ol/style';

export const SELECTED_SHAPE_STYLE = new Style({
  stroke: new Stroke({
    color: 'rgb(255, 255, 0)',
    width: 3,
    lineDash: [8, 12],
    lineCap: 'round',
    lineJoin: 'round',
  }),
  fill: new Fill({ color: 'rgb(255, 255, 0, .2)' }),
});

export const SELECTED_POINT_STYLE = new Style({
  image: new Circle({
    radius: 7,
    fill: new Fill({ color: 'rgb(255, 255, 0)' }),
    stroke: new Stroke({
      color: 'rgba(0, 0, 0)',
      width: 1,
    }),
  }),
});

export const HOVER_SHAPE_STYLE = new Style({
  stroke: new Stroke({
    color: 'rgba(0, 0, 255)',
    width: 3,
    lineDash: [8, 12],
    lineCap: 'round',
    lineJoin: 'round',
  }),
  fill: new Fill({ color: 'rgb(0, 0, 255, .2)' }),
});

export const HOVER_POINT_STYLE = new Style({
  image: new Circle({
    radius: 7,
    fill: new Fill({ color: 'rgb(0, 0, 255)' }),
    stroke: new Stroke({
      color: 'rgba(0, 0, 0)',
      width: 1,
    }),
  }),
});

export const HOTSPOT_SHAPE_STYLE = new Style({
  stroke: new Stroke({
    color: 'rgb(155, 150, 0)',
    width: 3,
    lineDash: [8, 12],
    lineCap: 'round',
    lineJoin: 'round',
  }),
  fill: new Fill({ color: 'rgb(155, 150, 0, .2)' }),
});

export const HOTSPOT_POINT_STYLE = new Style({
  image: new RegularShape({
    fill: new Fill({ color: 'rgb(255, 150, 0)' }),
    stroke: new Stroke({ color: 'black', width: 1 }),
    points: 3,
    radius: 8,
    radius2: 8,
    angle: 0,
  }),
});
