import { Grid, Skeleton } from '@mui/material';

import { PaddedPaper } from '../';

interface SkeletonFormProps {
  contentRows: number;
}

const PaperFormSkeleton = ({ contentRows }: SkeletonFormProps) => {
  const renderContentRows = () => {
    const rows = [];
    let i = 0;
    while (i < contentRows) {
      rows.push(
        <Grid key={i} item xs={12}>
          <Skeleton variant="rectangular" height={50} width="100%" />
        </Grid>
      );
      i++;
    }

    return rows;
  };

  return (
    <PaddedPaper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={30} width={350} />
        </Grid>
        {renderContentRows()}
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={35} width={150} />
        </Grid>
      </Grid>
    </PaddedPaper>
  );
};

export default PaperFormSkeleton;
