import React, { useState, ReactElement, FC } from 'react';
import clsx from 'clsx';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FilterListIcon from '@mui/icons-material/FilterList';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { Box, Button, Paper } from '@mui/material';

import { intl } from '../../Internationalization';

export type FilterGroup = {
  name: string;
  title: string;
  component: ReactElement;
  icon?: React.ComponentType<SvgIconProps>;
};

interface FilterBarProps {
  filterGroups?: FilterGroup[];
  actions?: React.ReactNode;
  startInput?: React.ReactNode;
  barFilters?: React.ReactNode;
}

const FilterBar: FC<FilterBarProps> = ({ filterGroups, actions, startInput, barFilters }) => {
  const [expandedSection, setExpandedSection] = useState<string>();
  const expandedFilterGroup =
    filterGroups && filterGroups.find(({ name }) => name === expandedSection);

  return (
    <Paper className="FilterBar-root" sx={{ mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: { xs: 'wrap', lg: 'nowrap' } }}>
        {startInput && <Box display="flex">{startInput}</Box>}
        {barFilters && (
          <Box sx={{ display: 'flex', flexWrap: 'nowrap', pr: 2, flexGrow: { xs: 1, lg: 0 } }}>
            {barFilters}
          </Box>
        )}
        <Box flexGrow={1}>
          {filterGroups &&
            filterGroups.map(({ name, title, icon: Icon }) => {
              const active = expandedSection === name;

              return (
                <Button
                  key={name}
                  name={name}
                  variant="text"
                  color="inherit"
                  sx={{
                    py: 1.5,
                    px: 2,
                    borderRadius: 0,
                    fontWeight: active ? 'fontWeightBold' : 'fontWeightRegular',
                  }}
                  className={clsx('FilterBar-groupToggle', { 'FilterBar-activeGroup': active })}
                  onClick={() => setExpandedSection(expandedSection === name ? undefined : name)}
                  startIcon={Icon ? <Icon color="action" /> : <FilterListIcon color="action" />}
                  endIcon={
                    <ExpandMoreIcon
                      color="action"
                      sx={{
                        transition: (theme) =>
                          theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                          }),
                        transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
                      }}
                      aria-label={
                        active
                          ? intl.formatMessage({
                              id: 'components.filterBar.collapse.ariaLabel',
                              defaultMessage: 'Collapse filter group',
                            })
                          : intl.formatMessage({
                              id: 'components.filterBar.expand.ariaLabel',
                              defaultMessage: 'Expand filter group',
                            })
                      }
                    />
                  }
                >
                  {title}
                </Button>
              );
            })}
        </Box>
        {actions && (
          <Box display="flex" ml="auto" mr={1} columnGap={1}>
            {actions}
          </Box>
        )}
      </Box>
      {expandedFilterGroup && expandedFilterGroup.component}
    </Paper>
  );
};

export default FilterBar;
