import { FC, useCallback, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';

import { Box, IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';

import * as AssignmentSavedMappingApi from '../../../../../api/assignmentSavedMapping';
import { extractErrorMessage, FileUploadConfig } from '../../../../../api/endpoints';
import { ConfirmDialog, UploadCompact } from '../../../../../components';
import { UploadedIcon } from '../../../../../components/icons';
import { useMediaUpload } from '../../../../../hooks';
import { intl } from '../../../../../Internationalization';
import { dataStoreNameFromPath, dataStoreTypeMetaDataFromPath } from '../../../../../util';
import { DataStoreConfigDetail, SavedMappingInputDetail, UploadMode } from '../../../../../types';

import { SavedMappingContext } from './SavedMappingContext';

interface SampleDataUploadRowProps {
  dataStorePath: string;
  dataStoreConfig?: DataStoreConfigDetail;
  filename?: string;
  handleUploadChange: (value: boolean) => void;
}

const SampleDataUploadRow: FC<SampleDataUploadRowProps> = ({
  dataStorePath,
  filename,
  dataStoreConfig,
  handleUploadChange,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { assignmentKey, savedMapping, sessionSchema, updateSavedMappingInput } =
    useContext(SavedMappingContext);

  const [upload, uploading, uploadProgress] = useMediaUpload({
    onUpload: useCallback(
      (files: File[], filUploadConfig: FileUploadConfig) => {
        handleUploadChange(true);
        return AssignmentSavedMappingApi.uploadInput(
          assignmentKey,
          savedMapping.key,
          dataStorePath,
          files[0],
          filUploadConfig
        );
      },
      [assignmentKey, dataStorePath, savedMapping.key, handleUploadChange]
    ),
    onUploadComplete: (savedInput: SavedMappingInputDetail) => {
      updateSavedMappingInput(dataStorePath, savedInput);
      handleUploadChange(false);
    },
  });

  const [confirmDeleteFile, setConfirmDeleteFile] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const { icon: Icon, label } = dataStoreTypeMetaDataFromPath(
    sessionSchema,
    dataStoreConfig?.path,
    'importSchema'
  );

  const deleteSubmissionInputMedia = async () => {
    setProcessing(true);
    try {
      await AssignmentSavedMappingApi.deleteInput(assignmentKey, savedMapping.key, dataStorePath);
      updateSavedMappingInput(dataStorePath);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'myAssignment.savedMapping.sampleData.deleteSuccess',
          defaultMessage: 'Input deleted',
        }),
        { variant: 'success' }
      );
      setConfirmDeleteFile(false);
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'myAssignment.savedMapping.sampleData.deleteError',
            defaultMessage: 'Failed to remove input',
          })
        ),
        { variant: 'warning' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const uploadTitle = () => {
    if (filename) {
      if (!dataStoreConfig) {
        return intl.formatMessage(
          {
            id: 'myAssignment.savedMapping.sampleData.dataSetNotFound',
            defaultMessage: '{filename} - Data set not found',
          },
          { filename }
        );
      }
      return filename;
    }
    return intl.formatMessage({
      id: 'myAssignment.savedMapping.sampleData.uploadAFile',
      defaultMessage: 'Please upload a file',
    });
  };

  return (
    <TableRow>
      <TableCell>
        <Tooltip title={dataStorePath} placement="top-start">
          <span>{dataStoreNameFromPath(dataStorePath)}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Icon fontSize="large" />
          <Box pl={1}>{label}</Box>
        </Box>
      </TableCell>
      <TableCell align="center">
        {dataStoreConfig?.uploadMode === UploadMode.REQUIRED && (
          <CheckIcon
            fontSize="large"
            titleAccess={intl.formatMessage({
              id: 'myAssignment.savedMapping.sampleData.uploadRequired.titleAccess',
              defaultMessage: 'Upload required',
            })}
          />
        )}
      </TableCell>
      <TableCell>
        <Box minWidth={400} display="flex" width="100%">
          <UploadCompact
            onDrop={upload}
            progress={uploadProgress}
            uploading={uploading}
            disabled={!dataStoreConfig}
            title={uploadTitle()}
            icon={filename ? UploadedIcon : undefined}
          />
          <Box alignItems="center" display="flex" ml={1}>
            <IconButton
              name="removeMedia"
              onClick={() => setConfirmDeleteFile(true)}
              disabled={dataStoreConfig && !filename}
              size="large"
              aria-label={intl.formatMessage({
                id: 'myAssignment.savedMapping.sampleData.deleteFile.ariaLabel',
                defaultMessage: 'Delete uploaded file',
              })}
            >
              {dataStoreConfig ? <DeleteForeverIcon /> : <CloseIcon />}
            </IconButton>
          </Box>
          <ConfirmDialog
            id="confirm-delete-uploaded-file"
            isOpen={confirmDeleteFile}
            title={intl.formatMessage({
              id: 'myAssignment.savedMapping.sampleData.confirmDelete.title',
              defaultMessage: 'Delete file',
            })}
            text={intl.formatMessage({
              id: 'myAssignment.savedMapping.sampleData.confirmDelete.text',
              defaultMessage: 'Are you sure you wish to delete the uploaded file?',
            })}
            confirmBtnText={intl.formatMessage({
              id: 'myAssignment.savedMapping.sampleData.confirmDelete.confirmButton',
              defaultMessage: 'Delete File',
            })}
            confirmAction={deleteSubmissionInputMedia}
            closeAction={() => setConfirmDeleteFile(false)}
            disabled={processing}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default SampleDataUploadRow;
