import { FC, useState, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useSnackbar } from 'notistack';
import { ValidateFieldsError } from 'async-validator';

import * as Saml2IdentityProviderApi from '../../../../api/saml2IdentityProvider';
import { extractErrorMessage } from '../../../../api/endpoints';
import {
  PaddedPaper,
  ValidatedTextField,
  FormButtons,
  DefaultButton,
} from '../../../../components';
import { validate, normalizeUri } from '../../../../util';
import { Saml2IdentityProviderSettings } from '../../../../types';
import { intl } from '../../../../Internationalization';

import { Saml2IdentityProviderContext } from './Saml2IdentityProviderContext';

const Saml2IdentityProviderServiceSettings: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [processing, setProcessing] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();
  const { identityProvider, identityProviderUpdated } = useContext(Saml2IdentityProviderContext);

  const [entityId, setEntityId] = useState<string>(identityProvider.entityId);
  const [ssoServiceLocation, setSsoServiceLocation] = useState<string>(
    identityProvider.ssoServiceLocation
  );

  useEffect(() => {
    setEntityId(identityProvider.entityId);
    setSsoServiceLocation(identityProvider.ssoServiceLocation);
  }, [identityProvider]);

  const collectServiceSettings = (): Partial<Saml2IdentityProviderSettings> => ({
    entityId: normalizeUri(entityId),
    ssoServiceLocation: normalizeUri(ssoServiceLocation),
  });

  const validateAndSubmit = async () => {
    setProcessing(true);
    const serviceSettings = collectServiceSettings();
    try {
      updateIdentityProvider(
        await validate(
          Saml2IdentityProviderApi.SAML2_IDENTITY_PROVIDER_SERVICE_SETTINGS_VALIDATOR,
          serviceSettings
        )
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const updateIdentityProvider = async (
    serviceSettings: Partial<Saml2IdentityProviderSettings>
  ) => {
    setFieldErrors(undefined);
    try {
      const { data: updatedIdentityProvider } =
        await Saml2IdentityProviderApi.updateIdentityProvider(identityProvider.key, {
          ...identityProvider,
          ...serviceSettings,
        });
      identityProviderUpdated(updatedIdentityProvider);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'saml2.identityProviderServiceSettings.saveSuccess',
          defaultMessage: 'Identity provider updated',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'saml2.identityProviderServiceSettings.saveError',
            defaultMessage: 'Failed to update identity provider',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Container maxWidth="md" id="system-identity-provider-saml2-service-settings" disableGutters>
      <PaddedPaper>
        <Typography variant="h5" gutterBottom>
          <FormattedMessage
            id="saml2.identityProviderServiceSettings.title"
            defaultMessage="Identity Provider Service Settings"
          />
        </Typography>
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="entityId"
          label={intl.formatMessage({
            id: 'saml2.identityProviderServiceSettings.entityId.label',
            defaultMessage: 'Entity Id',
          })}
          value={entityId}
          onChange={(event) => setEntityId(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="ssoServiceLocation"
          label={intl.formatMessage({
            id: 'saml2.identityProviderServiceSettings.ssoServiceLocation.label',
            defaultMessage: 'SSO Service Location',
          })}
          value={ssoServiceLocation}
          onChange={(event) => setSsoServiceLocation(event.target.value)}
          margin="normal"
          variant="outlined"
        />
        <FormButtons>
          <DefaultButton
            id="update-identity-provider-settings"
            onClick={validateAndSubmit}
            disabled={processing}
            startIcon={<SaveIcon />}
          >
            <FormattedMessage
              id="saml2.identityProviderServiceSettings.saveButton"
              defaultMessage="Save Settings"
            />
          </DefaultButton>
        </FormButtons>
      </PaddedPaper>
    </Container>
  );
};

export default Saml2IdentityProviderServiceSettings;
