import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { DefaultButton, PaddedDialogActions } from '../../../../../components';

interface CopySavedMappingActionsProps {
  disableNext?: boolean;
  processing?: boolean;

  onCancel: () => void;
  onBack?: () => void;
  nextButton: JSX.Element;
}

const CopySavedMappingActions: FC<CopySavedMappingActionsProps> = ({
  processing,
  onCancel,
  onBack,
  nextButton,
}) => (
  <PaddedDialogActions>
    <DefaultButton name="cancel" color="secondary" onClick={onCancel} disabled={processing}>
      <FormattedMessage
        id="myAssignment.savedMapping.copy.actions.cancelButton"
        defaultMessage="Cancel"
      />
    </DefaultButton>
    <Box flexGrow={1} />
    {onBack && (
      <DefaultButton
        name="back"
        onClick={onBack}
        disabled={processing}
        color="grey"
        startIcon={<NavigateBeforeIcon />}
      >
        <FormattedMessage
          id="myAssignment.savedMapping.copy.actions.backButton"
          defaultMessage="Back"
        />
      </DefaultButton>
    )}
    {nextButton}
  </PaddedDialogActions>
);

export default CopySavedMappingActions;
