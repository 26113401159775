import { FC } from 'react';
import { VirusScanState, VIRUS_SCANNER_STATE_METADATA } from '../../types';

interface VirusScanStateIconProps {
  state: VirusScanState;
}

const VirusScanStateIcon: FC<VirusScanStateIconProps> = ({ state }) => {
  const { icon: Icon, color } = VIRUS_SCANNER_STATE_METADATA[state];
  return <Icon sx={{ color }} fontSize="large" />;
};

export default VirusScanStateIcon;
