import { Box, TableBody } from '@mui/material';

import {
  HeaderSkeleton,
  TabsSkeleton,
  TableFilterSkeleton,
  BrowseTable,
  TableRowsSkeleton,
} from '../../../components';
import { SpecificationsTableHeader } from './Specifications';

const ProjectSkeleton = () => (
  <>
    <HeaderSkeleton />
    <TabsSkeleton tabCount={2} />
    <Box p={3}>
      <TableFilterSkeleton displayFilterSkeleton={false} />
      <BrowseTable>
        <SpecificationsTableHeader />
        <TableBody>
          <TableRowsSkeleton colSpan={3} rows={7} />
        </TableBody>
      </BrowseTable>
    </Box>
  </>
);

export default ProjectSkeleton;
