import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Typography } from '@mui/material';

import { ValidationErrors } from '../../../../types';

interface MailEditorValidationErrorProps {
  validationErrors: ValidationErrors;
}

const extractError = (validationErrors: ValidationErrors, facet: string): string | undefined => {
  return (validationErrors.errors[facet] || [])[0];
};

const MailEditorValidationError: FC<MailEditorValidationErrorProps> = ({ validationErrors }) => {
  const subjectError = extractError(validationErrors, 'subject');
  const textError = extractError(validationErrors, 'text');

  return (
    <>
      {subjectError && (
        <Box
          id="mail-template-subject-error"
          sx={(theme) => ({
            mb: 2,
            p: 2,
            bgcolor: theme.palette.error.main,
            color: theme.palette.getContrastText(theme.palette.error.main),
          })}
        >
          <Typography variant="h6" gutterBottom>
            <FormattedMessage
              id="mail.template.subjectError"
              defaultMessage="Mail subject template error"
            />
          </Typography>
          <Typography
            component="pre"
            sx={{
              fontFamily: 'Consolas, monospace',
              fontSize: '0.8rem',
            }}
          >
            {subjectError}
          </Typography>
        </Box>
      )}
      {textError && (
        <Box
          id="mail-template-body-error"
          sx={(theme) => ({
            mb: 2,
            p: 2,
            bgcolor: theme.palette.error.main,
            color: theme.palette.getContrastText(theme.palette.error.main),
          })}
        >
          <Typography variant="h6" gutterBottom>
            <FormattedMessage
              id="mail.template.bodyError"
              defaultMessage="Mail body template error"
            />
          </Typography>
          <Typography
            component="pre"
            sx={{
              fontFamily: 'Consolas, monospace',
              fontSize: '0.8rem',
            }}
          >
            {textError}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default MailEditorValidationError;
