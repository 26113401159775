import { StyleSheet } from '@react-pdf/renderer';

export const sharedPdfStyles = StyleSheet.create({
  tableHead: {
    fontWeight: 500,
    paddingRight: 5,
  },
  cell_2_5ths: {
    width: '40%',
  },
  cell_5ths: {
    width: '20%',
  },
  cell_3rds: {
    width: '33.33%',
  },
  cell_4ths: {
    width: '25%',
  },
  cell_halves: {
    width: '50%',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  largeIcon: {
    height: 24,
    width: 24,
    marginLeft: 4,
  },
  mediumIcon: {
    width: 18,
    height: 18,
  },
  title: {
    fontSize: 24,
    marginBottom: 15,
  },
  iconMarginRight: {
    marginRight: 8,
  },
  contentPadding: {
    padding: 8,
  },
  gutterBottom: {
    marginBottom: 8,
  },
  sectionHeaderText: {
    fontSize: 12,
    marginRight: 24,
    maxHeight: 24,
    fontWeight: 'bold',
  },
  borderTop: {
    borderTop: '1 solid #E8E8E8',
  },
});
