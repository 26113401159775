import { AxiosPromise } from 'axios';
import { MediaDetail } from '../types';

import { FileUploadConfig, AXIOS } from './endpoints';

export const imageMimeTypes = ['image/jpeg', 'image/png', 'image/gif'];

export const createFormDataFromFile = (file: File) => {
  var formData = new FormData();
  formData.append('file', file);
  return formData;
};

export function uploadMedia(
  url: string,
  file: File,
  config?: FileUploadConfig
): AxiosPromise<MediaDetail> {
  return AXIOS.post(url, createFormDataFromFile(file), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    ...(config || {}),
  });
}
