import { FC, PropsWithChildren } from 'react';

import { Box, BoxProps, TextFieldProps, Tooltip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';

export interface InputTooltipProps extends PropsWithChildren {
  title: string;
  ml?: number;
  mt?: number;
}

export const calculateTooltipVariantTopOffset = (
  margin?: BoxProps['margin'],
  variant?: TextFieldProps['variant']
) => {
  const variantOffset = variant === 'standard' ? 2 : 0;
  const calculateMarginOffset = () => {
    switch (margin) {
      case 'normal':
        return 1;
      case 'dense':
        return 0.5;
      case 'none':
      default:
        return 0;
    }
  };
  return variantOffset + calculateMarginOffset();
};

const InputTooltip: FC<InputTooltipProps> = ({ ml, mt, title, children }) => (
  <Box display="flex" alignItems="center">
    {children}
    <Box ml={ml} mt={mt}>
      <Tooltip title={title} placement="right">
        <HelpIcon sx={{ color: (theme) => theme.palette.grey[500] }} />
      </Tooltip>
    </Box>
  </Box>
);

export default InputTooltip;
