import { FC } from 'react';
import { View, Text } from '@react-pdf/renderer';

import { resultsPdfStyles } from '../../../../../components';

interface SummaryInfoProps {
  infoText: string;
  infoIcon: JSX.Element;
  gutterBottom?: boolean;
}

export const SummaryInfo: FC<SummaryInfoProps> = ({ infoText, infoIcon, gutterBottom }) => (
  <View style={[resultsPdfStyles.rowContainer, gutterBottom ? resultsPdfStyles.gutterBottom : {}]}>
    {infoIcon}
    <Text style={[resultsPdfStyles.submissionSummaryInfo]}>{infoText}</Text>
  </View>
);
