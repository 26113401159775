import { HeaderSkeleton, TabsSkeleton } from '../../../components';

const MyAssignmentSkeleton = () => (
  <>
    <HeaderSkeleton />
    <TabsSkeleton tabCount={4} />
  </>
);

export default MyAssignmentSkeleton;
