import { FC } from 'react';

import { SvgIconProps, useTheme } from '@mui/material';
import { ClassNames } from '@emotion/react';

import { Field } from '../../types';

import StyledEditor, { StyledEditorProps } from './StyledEditor';
import {
  FieldToolbarButton,
  FieldToolbarMenuButton,
  templateEditorDecorators,
} from './editor-components';

export interface TextFieldGroup {
  icon: React.ComponentType<SvgIconProps>;
  label: string;
  textFields: Field[];
}

export interface TemplatedInputEditorProps
  extends Omit<
    StyledEditorProps,
    'customDecorators' | 'toolbarCustomButtons' | 'toolbar' | 'handlePastedText' | 'handleReturn'
  > {
  textFieldGroups?: TextFieldGroup[];
  textFields?: Field[];
}

const TemplatedInputEditor: FC<TemplatedInputEditorProps> = ({
  textFields = [],
  textFieldGroups = [],
  toolbarClassName,
  wrapperClassName,
  editorClassName,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <ClassNames>
      {({ css, cx }) => (
        <StyledEditor
          {...rest}
          toolbarClassName={cx([
            css({
              '&&': {
                marginBottom: 0,
                padding: theme.spacing(0.5),
                rowGap: theme.spacing(0.5),
              },
            }),
            toolbarClassName,
          ])}
          wrapperClassName={cx([
            'MuiInputBase-root',
            'MuiOutlinedInput-root',
            'MuiInputBase-colorPrimary',
            wrapperClassName,
          ])}
          editorClassName={cx([
            'MuiInputBase-root',
            'MuiOutlinedInput-root',
            'MuiInputBase-colorPrimary',
            css({
              'flexGrow': 1,
              'display': 'flex',
              'padding': theme.spacing(0, 1.5),
              '& .DraftEditor-root': {
                flexGrow: 1,
              },
              '& .public-DraftStyleDefault-block': {
                margin: theme.spacing(1, 0),
                wordBreak: 'break-word',
              },
            }),
            editorClassName,
          ])}
          customDecorators={templateEditorDecorators([
            ...textFields,
            ...textFieldGroups.flatMap((group) => group.textFields),
          ])}
          toolbarCustomButtons={[
            ...textFields.map((field) => <FieldToolbarButton field={field} />),
            ...textFieldGroups.map((fieldGroup) => (
              <FieldToolbarMenuButton
                fields={fieldGroup.textFields}
                icon={fieldGroup.icon}
                label={fieldGroup.label}
              />
            )),
          ]}
          toolbar={{
            options: [],
            inline: { options: [] },
          }}
          handlePastedText={() => true}
          handleReturn={() => true}
        />
      )}
    </ClassNames>
  );
};

export default TemplatedInputEditor;
