import { HeaderSkeleton, TabsSkeleton, MDPaperAvatarRowsSkeleton } from '../../../components';

const UserSkeleton = () => (
  <>
    <HeaderSkeleton />
    <TabsSkeleton tabCount={5} />
    <MDPaperAvatarRowsSkeleton contentRows={4} />
  </>
);

export default UserSkeleton;
