import { FC, PropsWithChildren } from 'react';

import { Box, Table, Paper } from '@mui/material';

interface BrowseTableProps extends PropsWithChildren {
  height?: number;
  gutterBottom?: boolean;
}

const BrowseTable: FC<BrowseTableProps> = ({ height, gutterBottom = true, children }) => (
  <Paper>
    <Box sx={{ overflowX: 'auto', mb: gutterBottom ? 3 : 0, height }}>
      <Table>{children}</Table>
    </Box>
  </Paper>
);

export default BrowseTable;
