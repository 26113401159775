import { AxiosPromise } from 'axios';
import { AXIOS } from './endpoints';
import { GlobalActivity, DailyActivity, ProjectActivity, PeriodStatsRequest } from '../types';

export function globalActivity(request: PeriodStatsRequest): AxiosPromise<GlobalActivity> {
  return AXIOS.post(`/stats/global_activity`, request);
}

export function projectActivity(request: PeriodStatsRequest): AxiosPromise<ProjectActivity[]> {
  return AXIOS.post(`/stats/project_activity`, request);
}

export function dailyActivity(request: PeriodStatsRequest): AxiosPromise<DailyActivity[]> {
  return AXIOS.post(`/stats/daily_activity`, request);
}
