import { openDB, DBSchema, deleteDB } from 'idb';
import { SubmissionsColumnSelections } from '../screens/submissions/Submissions';

const DB_NAME = 'StoreDb';
const DB_VERSION = 1;

export const SUBMISSIONS_COLUMNS_SELECTION_NAME = 'SubmissionColumns';

interface FilterDbSchema extends DBSchema {
  [SUBMISSIONS_COLUMNS_SELECTION_NAME]: {
    key: string;
    value: SubmissionsColumnSelections;
  };
}

export const storeDb = openDB<FilterDbSchema>(DB_NAME, DB_VERSION, {
  upgrade(db) {
    Array.from(db.objectStoreNames).forEach((storeName) => {
      db.deleteObjectStore(storeName);
    });
    db.createObjectStore(SUBMISSIONS_COLUMNS_SELECTION_NAME);
  },
});

export const deleteStoredDB = () => deleteDB(DB_NAME, {});
