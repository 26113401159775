import { FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Container, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import {
  DataStoreConfigDetail,
  SavedMappingInputDetail,
  SchemaMappingMode,
  UploadMode,
} from '../../../../../types';
import { browseTableBody, PaddedPaper } from '../../../../../components';
import { intl } from '../../../../../Internationalization';

import { SavedMappingContext } from './SavedMappingContext';
import SampleDataUploadRow from './SampleDataUploadRow';
import { NavigationPromptContext } from '../../../../../contexts/navigation-prompt';

export interface Input {
  dataStorePath: string;
  filename?: string;
  dataStoreConfig?: DataStoreConfigDetail;
}

const DataStoreInputUploadTableBody = browseTableBody<Input>();

const calculateDataStoreInputs = (
  dataStoreConfigs: DataStoreConfigDetail[],
  inputFiles: SavedMappingInputDetail[]
): Input[] => {
  const inputs: Record<string, Input> = {};

  // 1. Produce inputs for specification configs which are expected
  dataStoreConfigs.forEach((dataStoreConfig) => {
    if (
      dataStoreConfig.uploadMode === UploadMode.NOT_SUPPORTED ||
      dataStoreConfig.schemaMappingMode !== SchemaMappingMode.MANUAL
    ) {
      return;
    }
    const dataStorePath = dataStoreConfig.path;
    const filename = inputFiles.find(
      (inputFile) => inputFile.dataStorePath === dataStorePath
    )?.filename;
    inputs[dataStorePath] = { dataStorePath, dataStoreConfig, filename };
  });

  // 2. Produce inputs for input files which are unexpected
  inputFiles
    .map((inputFile) => inputFile.dataStorePath)
    .filter((dataStorePath) => !inputs.hasOwnProperty(dataStorePath))
    .forEach((dataStorePath) => {
      inputs[dataStorePath] = { dataStorePath };
    });

  return Object.keys(inputs)
    .sort()
    .map((key: string) => inputs[key]);
};

const SampleData: FC = () => {
  const { dataStoreConfigs, savedMapping } = useContext(SavedMappingContext);
  const inputs = calculateDataStoreInputs(dataStoreConfigs, savedMapping.inputs);

  const { raiseNavigationBlock, clearNavigationBlock } = useContext(NavigationPromptContext);

  const [uploadingCount, setUploadingCount] = useState<number>(0);

  const updateUploadingCount = (uploading: boolean) => {
    setUploadingCount((prev) => (uploading ? prev + 1 : prev - 1));
  };

  useEffect(() => {
    // only raise block once - if the number of files uploaded equal to one,
    // there is no point calling this again
    if (uploadingCount === 1) {
      raiseNavigationBlock();
    }

    if (uploadingCount === 0) {
      clearNavigationBlock();
    }
  }, [clearNavigationBlock, raiseNavigationBlock, uploadingCount]);

  return (
    <Container maxWidth="lg" disableGutters>
      <Box mb={2}>
        <PaddedPaper>
          <Typography variant="h5">
            <FormattedMessage
              id="myAssignment.savedMapping.sampleData.title"
              defaultMessage="Sample Data"
            />
          </Typography>
          <Box my={2} width="100%">
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      id="myAssignment.savedMapping.sampleData.table.dataSetColumn"
                      defaultMessage="Data Set"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="myAssignment.savedMapping.sampleData.table.fileTypeColumn"
                      defaultMessage="File Type"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <FormattedMessage
                      id="myAssignment.savedMapping.sampleData.table.requiredColumn"
                      defaultMessage="Required"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="myAssignment.savedMapping.sampleData.table.uploadFileColumn"
                      defaultMessage="Upload File"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <DataStoreInputUploadTableBody
                data={inputs}
                mapToRow={({ dataStorePath, dataStoreConfig, filename }) => (
                  <SampleDataUploadRow
                    key={dataStorePath}
                    dataStorePath={dataStorePath}
                    dataStoreConfig={dataStoreConfig}
                    filename={filename}
                    handleUploadChange={updateUploadingCount}
                  />
                )}
                noDataMessage={intl.formatMessage({
                  id: 'myAssignment.savedMapping.sampleData.noUploadRequired',
                  defaultMessage: 'No uploads required.',
                })}
                numCols={5}
              />
            </Table>
          </Box>
        </PaddedPaper>
      </Box>
    </Container>
  );
};

export default SampleData;
