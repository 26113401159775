import React, { FC } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ClickAwayListener, MenuItem, MenuList, Paper, Popper, ButtonProps } from '@mui/material';
import { intl } from '../../Internationalization';

interface SplitButtonItem {
  label: string;
  onClick: () => void;
}

interface SplitButtonProps {
  name?: string;
  moreName?: string;
  label: string;
  onClick: () => void;
  items: SplitButtonItem[];
  color?: ButtonProps['color'];
  disabled?: boolean;
}

const SplitButton: FC<SplitButtonProps> = ({
  name,
  moreName,
  color = 'grey',
  label,
  onClick,
  items,
  disabled = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const buttonClickHandler = (handleClick: () => void) => () => {
    setOpen(false);
    handleClick();
  };

  return (
    <>
      <ButtonGroup ref={anchorRef} variant="contained" color={color} disabled={disabled}>
        <Button name={name} onClick={buttonClickHandler(onClick)}>
          {label}
        </Button>
        <Button
          color={color}
          size="small"
          name={moreName}
          onClick={handleToggle}
          aria-label={intl.formatMessage({
            id: 'components.buttons.splitButton.moreOptions.ariaLabel',
            defaultMessage: 'Open more options dialog',
          })}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current}>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper>
            <MenuList>
              {items.map((item) => (
                <MenuItem key={item.label} onClick={buttonClickHandler(item.onClick)}>
                  {item.label}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default SplitButton;
