import { FC, PropsWithChildren, useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthenticatedContext } from '../contexts/authentication';

const RequireReceiverAdmin: FC<PropsWithChildren> = ({ children }) => {
  const { me } = useContext(AuthenticatedContext);

  if (me.receiverPermissions?.admin) {
    return <>{children}</>;
  }

  return <Navigate to="/" replace />;
};

export default RequireReceiverAdmin;
