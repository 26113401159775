import { FC, MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useContext, useState } from 'react';

import { Menu, MenuItem, IconButton, Link } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import { toCompleteOffsetDateTimeRange, getCurrentTimeZone } from '../../../util';
import * as SpecificationReportApi from '../../../api/specificationReport';
import { SpecificationStatsRequest } from '../../../types';
import { intl } from '../../../Internationalization';

import { DashboardContext } from '../DashboardContext';

const SpecificationExportMenu: FC = () => {
  const { specificationFilters } = useContext(DashboardContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(undefined);
  };

  const generateUrl = (
    urlGenerator: (specificationKey: string, request: SpecificationStatsRequest) => string
  ) => {
    return urlGenerator(specificationFilters.specification!.key, {
      period: toCompleteOffsetDateTimeRange(specificationFilters.dateRange),
      timeZone: getCurrentTimeZone(),
      assignmentKey: specificationFilters.assignment?.key,
      includeRejected: specificationFilters.includeRejected,
    });
  };

  return (
    <div>
      <IconButton
        name="openExportMenu"
        aria-label={intl.formatMessage({
          id: 'dashboard.specification.openExport.ariaLabel',
          defaultMessage: 'Open export menu',
        })}
        aria-controls="specification-export-menu"
        aria-haspopup="true"
        onClick={openMenu}
        disabled={!specificationFilters.specification}
        size="large"
      >
        <CloudDownloadIcon />
      </IconButton>
      {specificationFilters.specification && (
        <Menu
          id="specification-export-menu"
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={closeMenu}
        >
          <MenuItem href={generateUrl(SpecificationReportApi.submissionsUri)} component={Link}>
            <FormattedMessage
              id="dashboard.specification.exportMenu.submission"
              defaultMessage="Submissions"
            />
          </MenuItem>
          <MenuItem href={generateUrl(SpecificationReportApi.ruleConformanceUri)} component={Link}>
            <FormattedMessage
              id="dashboard.specification.exportMenu.ruleConformance"
              defaultMessage="Rule Conformance"
            />
          </MenuItem>
          <MenuItem href={generateUrl(SpecificationReportApi.taskConformanceUri)} component={Link}>
            <FormattedMessage
              id="dashboard.specification.exportMenu.taskConformance"
              defaultMessage="Task Conformance"
            />
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default SpecificationExportMenu;
