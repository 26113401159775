import { useContext } from 'react';
import { Container } from '@mui/material';

import * as SupplierApi from '../../../api/supplier';
import { Avatar, MediaDetail, UpdateAvatarRequest } from '../../../types';
import { AvatarSelect } from '../../../components';
import * as SupplierMediaApi from '../../../api/supplierMedia';
import { FileUploadConfig } from '../../../api/endpoints';
import { intl } from '../../../Internationalization';

import { SupplierContext } from './SupplierContext';

const SupplierAvatarSelect = () => {
  const { supplier, supplierUpdated } = useContext(SupplierContext);

  const onUpdate = (avatar: Avatar) => {
    supplierUpdated({ ...supplier, avatar });
  };

  const onDelete = () => {
    supplierUpdated({ ...supplier, avatar: { ...supplier.avatar, image: undefined } });
  };

  return (
    <Container maxWidth="md" id="supplier-avatar" disableGutters>
      <AvatarSelect
        title={intl.formatMessage({
          id: 'supplier.avatarSelect.title',
          defaultMessage: 'Supplier Avatar',
        })}
        avatar={supplier.avatar}
        name={supplier.name}
        onUpdate={onUpdate}
        onDelete={onDelete}
        deleteImage={() => SupplierApi.deleteImage(supplier.key)}
        uploadImage={(image: File, config: FileUploadConfig) =>
          SupplierApi.uploadImage(supplier.key, image, config)
        }
        updateAvatar={(request: UpdateAvatarRequest) =>
          SupplierApi.updateAvatar(supplier.key, request)
        }
        imageUrl={(image: MediaDetail) => SupplierMediaApi.downloadImageUri(supplier.key, image)}
        logo
      />
    </Container>
  );
};

export default SupplierAvatarSelect;
