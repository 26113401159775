import { FC } from 'react';

import { Box, Typography, Skeleton } from '@mui/material';

const NotificationSkeleton: FC = () => (
  <Box display="flex" p={1}>
    <Box display="flex" flexGrow={1}>
      <Skeleton variant="rectangular" height={48} width={48} />
      <Box ml={2} flexGrow={1}>
        <Typography variant="h6" gutterBottom>
          <Skeleton />
        </Typography>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
        <Box my={1}>
          <Typography variant="body1">
            <Skeleton />
          </Typography>
        </Box>
        <Typography variant="caption">
          <Skeleton />
        </Typography>
      </Box>
    </Box>
    <Box>
      <Box ml={2}>
        <Skeleton variant="rectangular" height={32} width={32} />
      </Box>
    </Box>
  </Box>
);

export default NotificationSkeleton;
