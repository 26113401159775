import { FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

import * as ApplicationApi from '../../api/application';
import { extractErrorMessage } from '../../api/endpoints';
import { FullScreenLoading } from '../../components';
import { ApplicationDetails } from '../../types';
import { intl } from '../../Internationalization';

import { CustomThemeContext } from '../custom-theme';

import { ApplicationContext } from './context';
import SiteError from './SiteError';

const Application: FC<PropsWithChildren> = ({ children }) => {
  const { updatePalette } = useContext(CustomThemeContext);
  const [applicationError, setApplicationError] = useState<string>();
  const [applicationDetails, setApplicationDetails] = useState<ApplicationDetails>();

  const fetchApplicationDetails = useCallback(async () => {
    try {
      const response = await ApplicationApi.details();
      setApplicationDetails(response.data);
      if (response.data.site.name !== document.title) {
        document.title = response.data.site.name;
      }
      updatePalette(response.data.site.theme.palette);
    } catch (error: any) {
      setApplicationError(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'contexts.application.loadError',
            defaultMessage: 'Failed to fetch application details.',
          })
        )
      );
    }
  }, [updatePalette]);

  useEffect(() => {
    fetchApplicationDetails();
  }, [fetchApplicationDetails]);

  if (applicationDetails) {
    return (
      <ApplicationContext.Provider
        value={{
          applicationDetails,
          refresh: fetchApplicationDetails,
        }}
      >
        {children}
      </ApplicationContext.Provider>
    );
  }

  if (applicationError) {
    return <SiteError error={applicationError} />;
  }

  return <FullScreenLoading />;
};

export default Application;
