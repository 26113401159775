import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box, Typography, Chip } from '@mui/material';
import GpsOffIcon from '@mui/icons-material/GpsOff';

import { MapLayerStyles } from '../../../../../../../types';

import { ObjectFeature } from '../MapContainer';

import LayerKey from './LayerKey';
import FeatureDetails from './FeatureDetails';

interface FeaturePanelProps {
  mapStyles?: MapLayerStyles;
  featureList: ObjectFeature[];
  selectedFeature?: ObjectFeature;
  setHoveredFeature: (feature?: ObjectFeature) => void;
  setSelectedFeature: (feature?: ObjectFeature) => void;
}

const FeaturePanel: FC<FeaturePanelProps> = ({
  mapStyles,
  featureList,
  selectedFeature,
  setSelectedFeature,
  setHoveredFeature,
}) => (
  <Box
    id="map-control-tabpanel-features"
    sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '0' /* IMPORTANT: you need this for non-chrome browsers */,
    }}
  >
    {featureList.length ? (
      <Box py={2} px={1} justifyContent="flex-start" display="flex" flexWrap="wrap">
        {featureList.map((feature) => (
          <div
            key={feature.ol_uid}
            onMouseEnter={() => setHoveredFeature(feature)}
            onMouseLeave={() => setHoveredFeature(undefined)}
          >
            <Chip
              label={feature.layer}
              size="small"
              sx={{
                'm': 0.5,
                'border': 'none',
                'backgroundColor': 'transparent',
                'pl': 0.5,
                '&:hover': {
                  bgcolor: (theme) => theme.palette.grey[400],
                },
                ...(selectedFeature &&
                  feature.getId() === selectedFeature.getId() && {
                    bgcolor: (theme) => theme.palette.grey[300],
                  }),
              }}
              icon={mapStyles && <LayerKey style={mapStyles[feature.layer]} />}
              onClick={() => setSelectedFeature(feature)}
            />
          </div>
        ))}
      </Box>
    ) : (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="center"
        minHeight={125}
        m={1}
      >
        <GpsOffIcon />
        <Typography variant="subtitle1">
          <FormattedMessage
            id="openSubmission.map.menu.featurePanel.noFeatureSelected"
            defaultMessage="No feature selected"
          />
        </Typography>
      </Box>
    )}
    <FeatureDetails feature={selectedFeature} />
  </Box>
);

export default FeaturePanel;
