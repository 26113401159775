import { Box, Paper, Skeleton } from '@mui/material';
import { FC } from 'react';

interface TableFilterSkeletonProps {
  displayFilterSkeleton?: boolean;
  displayRadioSkeleton?: boolean;
}

const TableFilterSkeleton: FC<TableFilterSkeletonProps> = ({
  displayFilterSkeleton = true,
  displayRadioSkeleton = true,
}) => (
  <Paper sx={{ py: 0.25, px: 0.5, display: 'flex', alignItems: 'center', mb: 3 }}>
    <Skeleton sx={{ mr: 1 }} height={40} width={200} variant="rounded" />
    {displayFilterSkeleton && <Skeleton height={40} width={200} variant="rounded" />}
    <Box flexGrow={1} />
    <Skeleton height={35} width={35} variant="text" />
    <Skeleton height={30} width={30} variant="circular" sx={{ mx: 1 }} />
    <Skeleton height={30} width={30} variant="circular" />
    {displayRadioSkeleton && <Skeleton height={30} width={100} variant="rounded" sx={{ ml: 1 }} />}
  </Paper>
);

export default TableFilterSkeleton;
