import { FC, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import { Grid, Container, TableRow, TableCell } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

import { useErrorBlock } from '../../../../../../contexts/error-block';
import { AppBarStatsContext } from '../../../../../../contexts/app-bar-stats';
import { ApplicationContext } from '../../../../../../contexts/application';
import { NavigationPromptContext } from '../../../../../../contexts/navigation-prompt';
import {
  MessageBox,
  FullWidthButton,
  Loading,
  BrowseTable,
  browseTableBody,
  MoreButton,
  StyledTableHead,
} from '../../../../../../components';
import {
  VirusCheckedMediaDetail,
  SubmissionInputFiles,
  DataStoreConfigDetail,
  SubmissionState,
  SessionSchema,
  UploadMode,
  virusScanStatePassed,
  SubmissionInputParameters,
  DataStoreParameterDetail,
} from '../../../../../../types';
import { extractErrorMessage } from '../../../../../../api/endpoints';
import * as SubmissionApi from '../../../../../../api/submission';
import * as SpecificationApi from '../../../../../../api/specification';
import { intl } from '../../../../../../Internationalization';

import ConfigureAndContinue from '../ConfigureAndContinue';
import { OpenSubmissionContext } from '../OpenSubmissionContext';

import InputUploadRow from './InputUploadRow';
import VirusScanMessage from './VirusScanMessage';

const displayMediaColumn = (inputs: Inputs) =>
  !!Object.values(inputs).find(
    (currentInput) =>
      currentInput?.dataStoreConfig?.uploadMode !== UploadMode.NOT_SUPPORTED || currentInput.media
  );

const validateInputUploads = (inputs: Inputs) =>
  !Object.values(inputs).find(
    (currentInput) =>
      !currentInput.dataStoreConfig ||
      (currentInput.dataStoreConfig.uploadMode === UploadMode.REQUIRED && !currentInput.media) ||
      (currentInput.dataStoreConfig.uploadMode === UploadMode.NOT_SUPPORTED && currentInput.media)
  );

const validateVirusScanPassed = (inputs: Inputs) =>
  !Object.values(inputs).find(
    (currentInput) => currentInput.media && !virusScanStatePassed(currentInput.media.virusScanState)
  );

const areParametersRequired = (inputs: Inputs) =>
  !!Object.values(inputs).find(({ dataStoreConfig }) => dataStoreConfig?.overrideParameters.length);

const sortedInputs = (inputs: Inputs) =>
  Object.keys(inputs)
    .sort()
    .map((key: string) => inputs[key])
    .map((currentInput) => currentInput);

const calculateCurrentInputs = (
  inputFiles: SubmissionInputFiles = {},
  inputParameters: SubmissionInputParameters,
  dataStores: DataStoreConfigDetail[]
) => {
  const updatedInputs: Inputs = {};

  // 1. Produce inputs for specification configs which are expected
  dataStores.forEach((dataStoreConfig) => {
    const currentInput: Partial<Input> = {};

    const dataStorePath = dataStoreConfig.path;

    if (
      dataStoreConfig.uploadMode === UploadMode.NOT_SUPPORTED &&
      !dataStoreConfig.overrideParameters.length
    ) {
      return;
    }

    currentInput.dataStorePath = dataStorePath;
    currentInput.dataStoreConfig = dataStoreConfig;

    if (dataStoreConfig.overrideParameters.length) {
      currentInput.inputParameters = inputParameters[dataStorePath];
    }
    const currentInputFiles = inputFiles[dataStorePath];
    if (dataStoreConfig.uploadMode !== UploadMode.NOT_SUPPORTED || currentInputFiles) {
      currentInput.media = inputFiles[dataStorePath];
    }

    updatedInputs[dataStorePath] = currentInput as Input;
  });

  // 2. Produce inputs for input files which are unexpected
  Object.keys(inputFiles)
    .filter((dataStorePath) => !updatedInputs.hasOwnProperty(dataStorePath))
    .forEach((dataStorePath) => {
      updatedInputs[dataStorePath] = { dataStorePath, media: inputFiles[dataStorePath] };
    });

  return updatedInputs;
};

const InputUploadTableBody = browseTableBody<Input>();

interface Input {
  dataStorePath: string;
  media?: VirusCheckedMediaDetail;
  dataStoreConfig?: DataStoreConfigDetail;
  inputParameters?: DataStoreParameterDetail[];
}

export interface Inputs {
  [key: string]: Input;
}

const InputUpload: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { submission, submissionUpdated, specification, dataStores } =
    useContext(OpenSubmissionContext);
  const appBarStatsContext = useContext(AppBarStatsContext);
  const {
    applicationDetails: { virusScannerEnabled },
  } = useContext(ApplicationContext);

  const { raiseNavigationBlock, clearNavigationBlock } = useContext(NavigationPromptContext);

  const { raiseError } = useErrorBlock();

  const [inputs, setInputs] = useState<Inputs>({});
  const [sessionSchema, setSessionSchema] = useState<SessionSchema>();
  const [openAutomationDialog, setOpenAutomationDialog] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const [uploadingCount, setUploadingCount] = useState<number>(0);

  const updateUploadingCount = (uploading: boolean) => {
    setUploadingCount((prev) => (uploading ? prev + 1 : prev - 1));
  };

  useEffect(() => {
    if (uploadingCount === 1) {
      raiseNavigationBlock();
    }

    if (uploadingCount === 0) {
      clearNavigationBlock();
    }
  }, [clearNavigationBlock, raiseNavigationBlock, uploadingCount]);

  useEffect(() => {
    const fetchSchema = async () => {
      try {
        const response = await SpecificationApi.getSessionSchema(specification.key);
        setSessionSchema(response.data);
      } catch (error: any) {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'openSubmission.inputUpload.loadError',
              defaultMessage: 'Failed to load session schema',
            })
          )
        );
      }
    };
    fetchSchema();
  }, [raiseError, specification.key]);

  useEffect(() => {
    setInputs(calculateCurrentInputs(submission.inputs, submission.inputParameters, dataStores));
  }, [dataStores, submission.inputParameters, submission.inputs]);

  const cancelCurrentSubmission = async () => {
    setProcessing(true);
    try {
      const response = await SubmissionApi.cancel(submission.reference);
      submissionUpdated(response.data);
      appBarStatsContext.refresh();
    } catch (error: any) {
      setProcessing(false);
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.inputUpload.cancelError',
            defaultMessage: 'Failed to cancel submission',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const startCurrentSubmission = async () => {
    setProcessing(true);
    try {
      const response = await SubmissionApi.start(submission.reference);
      submissionUpdated(response.data);
      appBarStatsContext.refresh();
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'openSubmission.inputUpload.startError',
            defaultMessage: 'Failed to start submission',
          })
        ),
        { variant: 'error' }
      );
      setProcessing(false);
    }
  };

  const onFileUpload = (dataStorePath: string, media: VirusCheckedMediaDetail) => {
    submissionUpdated(
      (prevSubmission) =>
        prevSubmission && {
          ...prevSubmission,
          inputs: { ...prevSubmission.inputs, [dataStorePath]: media },
        }
    );
  };

  const onRemoveMedia = (dataStorePath: string) => {
    submissionUpdated((prevSubmission) => {
      if (!prevSubmission) {
        return prevSubmission;
      }
      const updatedInputs = { ...prevSubmission.inputs };
      delete updatedInputs[dataStorePath];
      return { ...prevSubmission, inputs: updatedInputs };
    });
  };

  const handleOpenAutomationDialog = () => {
    setOpenAutomationDialog(true);
  };

  const handleCloseAutomationDialog = () => {
    setOpenAutomationDialog(false);
  };

  const inputUploadsValid = validateInputUploads(inputs);
  const mediaColumn = displayMediaColumn(inputs);
  const parametersRequired = areParametersRequired(inputs);
  const virusScanStateValid = validateVirusScanPassed(inputs);

  const renderReadyMessage = () => {
    if (!inputUploadsValid) {
      return (
        <MessageBox
          key="uploadInput"
          level="info"
          message={intl.formatMessage({
            id: 'openSubmission.inputUpload.uploadRequirements',
            defaultMessage: 'Please upload required files.',
          })}
        />
      );
    }

    if (virusScannerEnabled && !virusScanStateValid) {
      return <VirusScanMessage inputs={inputs} />;
    }

    return (
      <MessageBox
        level="success"
        message={intl.formatMessage({
          id: 'openSubmission.inputUpload.ready',
          defaultMessage: 'Submission is ready to run.',
        })}
      />
    );
  };

  if (!sessionSchema) {
    return <Loading />;
  }

  return (
    <Container maxWidth="lg" id="my-assignment-current-submission-upload" disableGutters>
      {submission.state === SubmissionState.NOT_STARTED ? (
        <Grid container spacing={3} direction="row" alignItems="stretch">
          <Grid item xs={12}>
            <BrowseTable gutterBottom={false}>
              <StyledTableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      id="openSubmission.inputUpload.table.dataSetColumn"
                      defaultMessage="Data Set"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="openSubmission.inputUpload.table.fileTypeColumn"
                      defaultMessage="File Type"
                    />
                  </TableCell>
                  {mediaColumn && (
                    <TableCell align="center">
                      <FormattedMessage
                        id="openSubmission.inputUpload.table.requiredColumn"
                        defaultMessage="Required"
                      />
                    </TableCell>
                  )}
                  {mediaColumn && virusScannerEnabled && (
                    <TableCell>
                      <FormattedMessage
                        id="openSubmission.inputUpload.table.virusScanColumn"
                        defaultMessage="Virus Scan"
                      />
                    </TableCell>
                  )}
                  {mediaColumn && (
                    <TableCell>
                      <FormattedMessage
                        id="openSubmission.inputUpload.table.uploadFileColumn"
                        defaultMessage="Upload File"
                      />
                    </TableCell>
                  )}
                  {parametersRequired && (
                    <TableCell align="center">
                      <FormattedMessage
                        id="openSubmission.inputUpload.table.parametersColumn"
                        defaultMessage="Parameters"
                      />
                    </TableCell>
                  )}
                </TableRow>
              </StyledTableHead>
              <InputUploadTableBody
                processing={processing}
                data={sortedInputs(inputs)}
                mapToRow={({ dataStorePath, dataStoreConfig, media, inputParameters }) => (
                  <InputUploadRow
                    key={dataStorePath}
                    dataStorePath={dataStorePath}
                    dataStoreConfig={dataStoreConfig}
                    media={media}
                    dataStoreInputParameters={inputParameters}
                    virusScannerEnabled={virusScannerEnabled}
                    onFileUpload={onFileUpload}
                    onRemoveMedia={onRemoveMedia}
                    submissionReference={submission.reference}
                    sessionSchema={sessionSchema}
                    handleUploadChange={updateUploadingCount}
                    mediaColumn={mediaColumn}
                    displayParameters={parametersRequired}
                  />
                )}
                noDataMessage={intl.formatMessage({
                  id: 'openSubmission.inputUpload.noUploadRequired',
                  defaultMessage: 'No uploads required.',
                })}
                numCols={5}
              />
            </BrowseTable>
          </Grid>
          <Grid item xs={12}>
            {renderReadyMessage()}
          </Grid>
          <Grid item xs={6}>
            <FullWidthButton
              name="cancelSubmission"
              label={intl.formatMessage({
                id: 'openSubmission.inputUpload.cancelButton',
                defaultMessage: 'Cancel',
              })}
              disabled={processing || !submission.canUpdateSubmission}
              color="secondary"
              startIcon={<StopIcon />}
              onClick={cancelCurrentSubmission}
            />
          </Grid>
          <Grid item xs={6}>
            <MoreButton
              name="continueSubmission"
              moreName="automateSubmission"
              label={intl.formatMessage({
                id: 'openSubmission.inputUpload.continueButton',
                defaultMessage: 'Continue',
              })}
              disabled={
                !inputUploadsValid ||
                !virusScanStateValid ||
                uploadingCount > 0 ||
                !submission.canUpdateSubmission
              }
              processing={processing}
              color="primary"
              endIcon={<PlayArrowIcon />}
              onClick={startCurrentSubmission}
              onMore={handleOpenAutomationDialog}
            />
            <ConfigureAndContinue
              open={openAutomationDialog}
              onSubmit={startCurrentSubmission}
              onClose={handleCloseAutomationDialog}
            />
          </Grid>
        </Grid>
      ) : (
        <Loading
          loadingText={intl.formatMessage({
            id: 'openSubmission.inputUpload.uploading',
            defaultMessage: 'Uploading data…',
          })}
        />
      )}
    </Container>
  );
};

export default InputUpload;
