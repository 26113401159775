import Schema from 'async-validator';
import { AxiosPromise } from 'axios';

import { intl } from '../Internationalization';
import {
  SupplierMappingsRequest,
  SupplierMappingsResponse,
  SupplierMappingSettings,
  SupplierMappingDetail,
} from '../types';
import { duplicateValidator, NOT_BLANK_REGEX } from '../util';

import * as SupplierMappingLookupApi from './supplierMappingLookup';
import { AXIOS, RequestConfig } from './endpoints';

export function getSupplierMappings(
  request: SupplierMappingsRequest,
  config?: RequestConfig
): AxiosPromise<SupplierMappingsResponse> {
  return AXIOS.get('/supplier_mappings', {
    ...config,
    params: request,
  });
}

export function createSupplierMapping(
  request: SupplierMappingSettings
): AxiosPromise<SupplierMappingDetail> {
  return AXIOS.post('/supplier_mappings', request);
}

export const supplierMappingValidator = (
  getIdentityProviderKey: () => string,
  getSupplierKey: () => string
): Schema => {
  return new Schema({
    group: [
      {
        required: true,
        pattern: NOT_BLANK_REGEX,
        message: intl.formatMessage({
          id: 'supplierMapping.validator.group.required',
          defaultMessage: 'Please provide a group identifier for this mapping',
        }),
      },
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        checkUnique: (group) =>
          SupplierMappingLookupApi.supplierMappingBySupplierKeyAndGroup(
            getIdentityProviderKey(),
            getSupplierKey(),
            group
          ),
        alreadyExistsMessage: intl.formatMessage({
          id: 'supplierMapping.validator.group.unique',
          defaultMessage: 'A mapping with this group identifier already exists for this supplier',
        }),
        errorMessage: intl.formatMessage({
          id: 'supplierMapping.validator.group.checkUniqueError',
          defaultMessage: 'There was a problem verifying the supplier mapping',
        }),
      }),
    ],
    supplierKey: {
      required: true,
      pattern: NOT_BLANK_REGEX,
      message: intl.formatMessage({
        id: 'supplierMapping.validator.supplierKey.required',
        defaultMessage: 'Please select a supplier',
      }),
    },
  });
};
