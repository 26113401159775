import { FC } from 'react';
import { View, Text } from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';

import { sharedPdfStyles } from '../../../../../components';
import { ReportProblemOutlinedWarningSvg } from '../../../../../components/pdf/PdfIcons';
import { AttributeMismatch } from '../../../../../types';
import { intl } from '../../../../../Internationalization';

import { TwoColumnTable, SummaryCounts } from './';
import { PdfSection, PdfSectionHeader, PdfTableCell, PdfTableRow } from '../components';

interface InvalidClassesProps {
  attributeMismatches: AttributeMismatch[];
}

const InvalidClasses: FC<InvalidClassesProps> = ({ attributeMismatches }) => {
  if (!attributeMismatches.length) {
    return null;
  }

  return (
    <>
      {attributeMismatches.map((attributeMismatch) => (
        <PdfSection gutterBottom key={attributeMismatch.className}>
          <PdfSectionHeader>
            <ReportProblemOutlinedWarningSvg
              style={[sharedPdfStyles.mediumIcon, sharedPdfStyles.iconMarginRight]}
            />
            <Text style={[sharedPdfStyles.sectionHeaderText]}>
              <FormattedMessage
                id="submission.validateSchemaPdf.invalidClass.title"
                defaultMessage="Class with error: {className}"
                values={{ className: attributeMismatch.className }}
              />
            </Text>
          </PdfSectionHeader>
          <SummaryCounts
            missing={{
              label: intl.formatMessage(
                {
                  id: 'submission.validateSchemaPdf.invalidClass.summary.missingAttributes',
                  defaultMessage: 'Missing attributes: {missingAttributes}',
                },
                { missingAttributes: attributeMismatch.missingAttributes.length }
              ),
              error: !!attributeMismatch.missingAttributes.length,
            }}
            excess={{
              label: intl.formatMessage(
                {
                  id: 'submission.validateSchemaPdf.invalidClass.summary.excessAttributes',
                  defaultMessage: 'Excess attributes: {excessAttributes}',
                },
                { excessAttributes: attributeMismatch.excessAttributes.length }
              ),
              error: !!attributeMismatch.excessAttributes.length,
            }}
            invalid={{
              label: intl.formatMessage(
                {
                  id: 'submission.validateSchemaPdf.invalidClass.summary.invalidAttributes',
                  defaultMessage: 'Attributes with errors: {invalidAttributes}',
                },
                { invalidAttributes: attributeMismatch.typeMismatches?.length || 0 }
              ),
              error: !!attributeMismatch.typeMismatches?.length,
            }}
          />
          <TwoColumnTable
            tableHeader={intl.formatMessage({
              id: 'submission.validateSchemaPdf.invalidClass.missingAttributes',
              defaultMessage: 'Missing Attributes',
            })}
            data={attributeMismatch.missingAttributes}
            style={sharedPdfStyles.gutterBottom}
          />
          <TwoColumnTable
            tableHeader={intl.formatMessage({
              id: 'submission.validateSchemaPdf.invalidClass.excessAttributes',
              defaultMessage: 'Excess Attributes',
            })}
            data={attributeMismatch.excessAttributes}
            style={sharedPdfStyles.gutterBottom}
          />
          {!!attributeMismatch.typeMismatches.length && (
            <View style={sharedPdfStyles.gutterBottom}>
              <PdfTableRow header>
                <PdfTableCell header colSpan="third">
                  <Text>
                    <FormattedMessage
                      id="submission.validateSchemaPdf.invalidClasses.invalidAttribute"
                      defaultMessage="Attribute with errors"
                    />
                  </Text>
                </PdfTableCell>
                <PdfTableCell header colSpan="third">
                  <Text>
                    <FormattedMessage
                      id="submission.validateSchemaPdf.invalidClasses.expectedType"
                      defaultMessage="Expected Type"
                    />
                  </Text>
                </PdfTableCell>
                <PdfTableCell header colSpan="third">
                  <Text>
                    <FormattedMessage
                      id="submission.validateSchemaPdf.invalidClasses.actualType"
                      defaultMessage="Actual Type"
                    />
                  </Text>
                </PdfTableCell>
              </PdfTableRow>
              {attributeMismatch.typeMismatches.map((typeMismatch) => (
                <PdfTableRow key={typeMismatch.attributeName}>
                  <PdfTableCell colSpan="third">
                    <Text>{typeMismatch.attributeName}</Text>
                  </PdfTableCell>
                  <PdfTableCell colSpan="third">
                    <Text>{typeMismatch.expected}</Text>
                  </PdfTableCell>
                  <PdfTableCell colSpan="third">
                    <Text>{typeMismatch.actual}</Text>
                  </PdfTableCell>
                </PdfTableRow>
              ))}
            </View>
          )}
        </PdfSection>
      ))}
    </>
  );
};

export default InvalidClasses;
