import { FC, useCallback, useContext, useEffect, useState } from 'react';

import DateRangeIcon from '@mui/icons-material/DateRange';

import * as SpecificationStatsApi from '../../../api/specificationStats';
import { intl } from '../../../Internationalization';
import { extractErrorMessage } from '../../../api/endpoints';
import { useErrorBlock } from '../../../contexts/error-block';
import { toCompleteOffsetDateTimeRange, getCurrentTimeZone, ViewMode } from '../../../util';
import {
  MessageBox,
  FilterBar,
  FilterContainer,
  ProjectAutocomplete,
  SpecificationAutocomplete,
  AssignmentAutocomplete,
  FilterCompleteDateRange,
  FilterToggle,
} from '../../../components';
import {
  ValuePercentage,
  GroupedDataSet,
  DateCount,
  OffsetDateTimePercentage,
  ProjectDetail,
  SpecificationDetail,
  AssignmentDetail,
  SpecificationStatsRequest,
  CompleteDateRange,
} from '../../../types';

import ChartsSkeleton from '../ChartsSkeleton';
import { DashboardContext } from '../DashboardContext';

import SpecificationCharts from './SpecificationCharts';
import SpecificationExportMenu from './SpecificationExportMenu';
import DashboardViewMode from '../DashboardViewMode';

const Specification: FC = () => {
  const { specificationFilters, updateSpecificationFilters } = useContext(DashboardContext);
  const { assignment, specification, project, dateRange, includeRejected } = specificationFilters;
  const { raiseError } = useErrorBlock();

  const [viewMode, setViewMode] = useState<ViewMode>('GRID');
  const [ruleConformance, setRuleConformance] = useState<ValuePercentage[]>();
  const [submissionCounts, setSubmissionCounts] = useState<GroupedDataSet<string, DateCount>[]>();
  const [submissionQuality, setSubmissionQuality] =
    useState<GroupedDataSet<string, OffsetDateTimePercentage>[]>();

  useEffect(() => {
    const loadSpecificationStats = async () => {
      if (!dateRange.start || !dateRange.end || (!specification && !assignment)) {
        return;
      }

      const request: SpecificationStatsRequest = {
        period: toCompleteOffsetDateTimeRange(dateRange),
        timeZone: getCurrentTimeZone(),
        assignmentKey: assignment?.key,
        includeRejected,
      };

      const specKey = specification?.key || assignment?.specification.key;
      if (!specKey) {
        return;
      }

      try {
        const [
          { data: ruleConformanceData },
          { data: submissionCountsData },
          { data: submissionQualityData },
        ] = await Promise.all([
          SpecificationStatsApi.ruleConformance(specKey, request),
          SpecificationStatsApi.submissionCounts(specKey, request),
          SpecificationStatsApi.submissionQuality(specKey, request),
        ]);

        setRuleConformance(ruleConformanceData);
        setSubmissionCounts(submissionCountsData);
        setSubmissionQuality(submissionQualityData);
      } catch (error: any) {
        raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'dashboard.specification.loadError',
              defaultMessage: 'Failed to load dashboard data',
            })
          )
        );
      }
    };

    loadSpecificationStats();
  }, [dateRange, specification, assignment, raiseError, includeRejected]);

  const onRangeChange = (newDateRange: CompleteDateRange) => {
    updateSpecificationFilters({ ...specificationFilters, dateRange: newDateRange });
  };

  const handleProjectFilterChange = (selectedProject: ProjectDetail | null) => {
    if (selectedProject?.key !== project?.key) {
      updateSpecificationFilters({
        ...specificationFilters,
        project: selectedProject,
        specification: null,
        assignment: null,
      });
    }
  };

  const handleSpecificationFilterChange = (selectedSpecification: SpecificationDetail | null) => {
    if (selectedSpecification?.key !== specification?.key) {
      updateSpecificationFilters({
        ...specificationFilters,
        specification: selectedSpecification,
        assignment: null,
      });
    }
  };

  const handleAssignmentFilterChange = (selectedAssignment: AssignmentDetail | null) => {
    if (selectedAssignment?.key !== assignment?.key) {
      updateSpecificationFilters({ ...specificationFilters, assignment: selectedAssignment });
    }
  };

  const onIncludeRejectedChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateSpecificationFilters({
        ...specificationFilters,
        includeRejected: event.target.checked,
      });
    },
    [specificationFilters, updateSpecificationFilters]
  );

  const handleViewModeChange = (_: React.MouseEvent<HTMLElement>, newViewMode: ViewMode) => {
    if (newViewMode !== null) {
      setViewMode(newViewMode);
    }
  };

  const renderContent = () => {
    if (!specification && !assignment) {
      return (
        <MessageBox
          message={intl.formatMessage({
            id: 'dashboard.specification.noFilter',
            defaultMessage: 'No specification or assignment selected',
          })}
          level="info"
        />
      );
    }

    if (!ruleConformance || !submissionCounts || !submissionQuality) {
      return <ChartsSkeleton />;
    }

    if (!ruleConformance.length && !submissionCounts.length && !submissionQuality.length) {
      return (
        <MessageBox
          message={intl.formatMessage({
            id: 'dashboard.specification.noData',
            defaultMessage:
              'There is no data for your selected date period. Please try to refine your search to try again.',
          })}
          level="info"
        />
      );
    }

    return (
      <SpecificationCharts
        viewMode={viewMode}
        ruleConformance={ruleConformance}
        submissionCounts={submissionCounts}
        submissionQuality={submissionQuality}
      />
    );
  };

  return (
    <div id="dashboard-specification">
      <FilterBar
        actions={
          <>
            <FilterToggle
              label={intl.formatMessage({
                id: 'dashboard.specification.filterRejectedToggle.label',
                defaultMessage: 'Include Rejected',
              })}
              name="includeRejected"
              checked={includeRejected}
              onChange={onIncludeRejectedChange}
            />
            <DashboardViewMode viewMode={viewMode} onChange={handleViewModeChange} />
            <SpecificationExportMenu />
          </>
        }
        filterGroups={[
          {
            name: 'finishedAt',
            title: intl.formatMessage({
              id: 'dashboard.specification.filterGroup.finishedAt.label',
              defaultMessage: 'Finished At',
            }),
            icon: DateRangeIcon,
            component: (
              <FilterContainer>
                <FilterCompleteDateRange
                  range={dateRange}
                  onRangeUpdated={onRangeChange}
                  rangeLimit={{ years: 1 }}
                />
              </FilterContainer>
            ),
          },
          {
            name: 'filters',
            title: intl.formatMessage({
              id: 'dashboard.specification.filterGroup.filters.label',
              defaultMessage: 'Filters',
            }),
            component: (
              <FilterContainer>
                <ProjectAutocomplete
                  name="project"
                  label={intl.formatMessage({
                    id: 'dashboard.specification.filterProject.label',
                    defaultMessage: 'Project',
                  })}
                  value={project}
                  onChange={handleProjectFilterChange}
                  variant="standard"
                />
                <SpecificationAutocomplete
                  name="specification"
                  label={intl.formatMessage({
                    id: 'dashboard.specification.filterSpecification.label',
                    defaultMessage: 'Specification',
                  })}
                  value={specification}
                  onChange={handleSpecificationFilterChange}
                  variant="standard"
                  projectKey={project?.key}
                />
                <AssignmentAutocomplete
                  name="assignment"
                  label={intl.formatMessage({
                    id: 'dashboard.specification.filterAssignment.label',
                    defaultMessage: 'Assignment',
                  })}
                  value={assignment}
                  onChange={handleAssignmentFilterChange}
                  variant="standard"
                  projectKey={project?.key}
                  specificationKey={specification?.key}
                />
              </FilterContainer>
            ),
          },
        ]}
      />
      {renderContent()}
    </div>
  );
};

export default Specification;
