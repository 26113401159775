import React, { FC, useContext } from 'react';

import SettingsIcon from '@mui/icons-material/Settings';

import { intl } from '../../../Internationalization';
import { AuthenticatedContext } from '../../../contexts/authentication';

import SidebarMenuItem from './SidebarMenuItem';

const ReceiverAdminMenuItems: FC = () => {
  const authenticatedContext = useContext(AuthenticatedContext);
  const receiverPermissions = authenticatedContext.me.receiverPermissions;

  if (receiverPermissions?.admin) {
    return (
      <SidebarMenuItem
        id="navigate-system"
        icon={SettingsIcon}
        label={intl.formatMessage({
          id: 'components.layout.sidebarMenu.item.system',
          defaultMessage: 'System',
        })}
        linkUrl="/system"
      />
    );
  }

  return null;
};

export default ReceiverAdminMenuItems;
