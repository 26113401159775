import { FC } from 'react';

import { Typography } from '@mui/material';
import { ErrorIcon } from '../icons';
import TableNoContentRow from './TableNoContentRow';

interface TableErrorRowProps {
  title?: string;
  errorMessage: string;
  colSpan: number;
}

const TableErrorRow: FC<TableErrorRowProps> = ({ title, errorMessage, colSpan }) => (
  <TableNoContentRow colSpan={colSpan}>
    <ErrorIcon fontSize="large" />
    {title && (
      <Typography variant="subtitle1" color="error">
        {title}
      </Typography>
    )}
    <Typography variant="subtitle1">{errorMessage}</Typography>
  </TableNoContentRow>
);

export default TableErrorRow;
