import { useEffect, useRef, useState } from 'react';
import axios, { AxiosPromise } from 'axios';
import { useSnackbar } from 'notistack';

import { FileUploadConfig, extractErrorMessage } from '../api/endpoints';
import { intl } from '../Internationalization';

interface MediaUploadOptions<T> {
  onUpload: (files: File[], config: FileUploadConfig) => AxiosPromise<T>;
  onUploadComplete: (response: T) => void;
  onResetCallback?: () => void;
}

const useMediaUpload = <T>({
  onUpload,
  onUploadComplete,
  onResetCallback,
}: MediaUploadOptions<T>) => {
  const { enqueueSnackbar } = useSnackbar();
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<ProgressEvent>();

  const abortController = useRef(new AbortController());

  useEffect(() => {
    const currentAbortController = abortController.current;
    return () => {
      currentAbortController.abort();
    };
  }, []);

  const resetUploadingStates = () => {
    setUploading(false);
    setUploadProgress(undefined);
    if (!!onResetCallback) {
      onResetCallback();
    }
  };

  const uploadMedia = async (files: File[]) => {
    try {
      setUploading(true);
      const response = await onUpload(files, {
        onUploadProgress: setUploadProgress,
        signal: abortController.current.signal,
      });

      onUploadComplete(response.data);
      resetUploadingStates();
      enqueueSnackbar(
        intl.formatMessage({
          id: 'hooks.mediaUpload.uploadSuccess',
          defaultMessage: 'Upload successful',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      if (axios.isCancel(error)) {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'hooks.mediaUpload.uploadAborted',
            defaultMessage: 'Upload aborted',
          }),
          { variant: 'warning' }
        );
      } else if (error.response && error.response.status === 400) {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'hooks.mediaUpload.uploadInvalid',
            defaultMessage: 'Uploaded file was invalid',
          }),
          { variant: 'error' }
        );
        resetUploadingStates();
      } else {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'hooks.mediaUpload.uploadError',
              defaultMessage: 'Upload failed',
            })
          ),
          { variant: 'error' }
        );
        resetUploadingStates();
      }
    }
  };

  return [uploadMedia, uploading, uploadProgress] as const;
};

export default useMediaUpload;
