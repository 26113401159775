import React, { Dispatch, SetStateAction } from 'react';
import { DashboardState } from '../../screens/dashboard/Dashboard';

export interface GlobalStateContextValue {
  dashboardState: DashboardState;
  setDashboardState: Dispatch<SetStateAction<DashboardState>>;
}

const GlobalStateContextDefaultValue = {} as GlobalStateContextValue;
export const GlobalStateContext = React.createContext(GlobalStateContextDefaultValue);
