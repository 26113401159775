import { FC, useContext } from 'react';

import { SupplierAvatar, ContentHeader } from '../../../components';

import { SupplierContext } from './SupplierContext';

const SupplierHeader: FC = () => {
  const { supplier } = useContext(SupplierContext);
  const { name } = supplier;

  return (
    <ContentHeader
      id="supplier-header"
      title={name}
      avatar={<SupplierAvatar supplier={supplier} size="large" />}
    />
  );
};

export default SupplierHeader;
