import { FC, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import DateRangeIcon from '@mui/icons-material/DateRange';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { TableRow, TableCell, Typography } from '@mui/material';

import { intl } from '../../../../Internationalization';
import {
  toDateQueryOffsetDateTimeRange,
  dataStoreNameFromPath,
  dateTimeFormat,
} from '../../../../util';
import * as CreateSubmissionApi from '../../../../api/createSubmission';
import * as SubmissionsApi from '../../../../api/submissions';
import { extractErrorMessage } from '../../../../api/endpoints';
import * as SpecificationApi from '../../../../api/specification';
import { useErrorBlock } from '../../../../contexts/error-block';
import { AppBarStatsContext } from '../../../../contexts/app-bar-stats';
import {
  SubmissionSummary,
  SpecificationValidationResult,
  evaluateSpecificationValidationResult,
  DateRange,
} from '../../../../types';
import {
  SubmissionOutcomeIcon,
  FilterPagination,
  FilterDateRange,
  BrowseTable,
  browseTableBody,
  FilterBar,
  FilterContainer,
  DefaultButton,
  LabeledAddFab,
  MessageBox,
  MinWidthTableCell,
  StyledTableHead,
  NamedAccountAvatar,
} from '../../../../components';
import SubmissionStateIcon from '../../../../components/SubmissionStateIcon';

import { useBrowseRequest } from '../../../../hooks';
import { MyAssignmentContext } from '../MyAssignmentContext';

const SubmissionsTableBody = browseTableBody<SubmissionSummary>();

const PAGE_SIZE = 10;

export const toMySubmissionLink = (submission: SubmissionSummary) => {
  return `/my_assignments/${submission.assignment.key}/submissions/${submission.reference}`;
};

const MySubmissions: FC = () => {
  const { assignmentKey, specification } = useContext(MyAssignmentContext);
  const appBarStatsContext = useContext(AppBarStatsContext);

  const [dateRange, setDateRange] = useState<DateRange>({});
  const [validationResult, setValidationResult] = useState<SpecificationValidationResult>();

  const navigate = useNavigate();
  const errorBlock = useErrorBlock();

  const { request, response, processing, updateRequest, setPage } = useBrowseRequest({
    initialRequest: {
      page: 0,
      size: PAGE_SIZE,
      assignmentKey,
      specificationKey: specification.key,
      projectKey: specification.project.key,
    },
    onRequest: SubmissionsApi.getSubmissions,
  });

  const onRangeChange = (selectedDateRange: DateRange) => {
    setDateRange(selectedDateRange);
    updateRequest({ createdAt: toDateQueryOffsetDateTimeRange(selectedDateRange) });
  };

  const createNewSubmission = async () => {
    try {
      const submissionDetail = (await CreateSubmissionApi.createSubmission(assignmentKey)).data;
      appBarStatsContext.refresh();
      navigate(toMySubmissionLink(submissionDetail));
    } catch (error: any) {
      errorBlock.raiseError(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'myAssignment.mySubmissions.createSubmissionError',
            defaultMessage: 'Failed to create submission',
          })
        )
      );
    }
  };

  useEffect(() => {
    const validateSpecification = async () => {
      try {
        const result = (await SpecificationApi.validateSpecification(specification.key)).data;
        setValidationResult(result);
      } catch (error: any) {
        errorBlock.raiseError(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'myAssignment.mySubmissions.specificationValid',
              defaultMessage: 'Failed to validate specification',
            })
          )
        );
      }
    };

    validateSpecification();
  }, [errorBlock, specification.key]);

  const populateSubmissionTableRow = (submission: SubmissionSummary) => (
    <TableRow key={submission.reference}>
      <TableCell>
        <NamedAccountAvatar user={submission.user} />
      </TableCell>
      <TableCell>{dateTimeFormat(submission.createdAt)}</TableCell>
      <TableCell>
        {Object.entries(submission.inputs).map(([key, file]) => (
          <Typography variant="body2">
            <strong>{dataStoreNameFromPath(key)}:</strong> {file.filename}
          </Typography>
        ))}
      </TableCell>
      <TableCell align="center">
        <SubmissionStateIcon submission={submission} />
      </TableCell>
      <TableCell align="center">
        <SubmissionOutcomeIcon submission={submission} />
      </TableCell>
      <MinWidthTableCell>
        <DefaultButton
          color="grey"
          component={RouterLink}
          to={toMySubmissionLink(submission)}
          className="ViewSubmissionButton"
          aria-label={intl.formatMessage({
            id: 'myAssignment.mySubmissions.navigateToSubmission.ariaLabel',
            defaultMessage: 'Navigate to submission',
          })}
        >
          <ArrowRightIcon />
        </DefaultButton>
      </MinWidthTableCell>
    </TableRow>
  );

  const specificationInvalid =
    validationResult && !evaluateSpecificationValidationResult(validationResult);

  return (
    <div id="my-assignment-my-submissions">
      {specificationInvalid && (
        <MessageBox
          gutterBottom
          level="warning"
          message={intl.formatMessage({
            id: 'myAssignment.mySubmissions.invalidSpecification',
            defaultMessage:
              'There is a configuration issue which will prevent you from starting new submissions. Please contact your administrator for assistance.',
          })}
        />
      )}
      <FilterBar
        filterGroups={[
          {
            name: 'createdAt',
            title: intl.formatMessage({
              id: 'myAssignment.mySubmissions.filterGroup.createdAt.label',
              defaultMessage: 'Created At',
            }),
            icon: DateRangeIcon,
            component: (
              <FilterContainer>
                <FilterDateRange range={dateRange} onRangeUpdated={onRangeChange} />
              </FilterContainer>
            ),
          },
        ]}
        actions={
          <FilterPagination
            page={request.page}
            size={request.size}
            total={response?.total}
            disabled={processing}
            setPage={setPage}
          />
        }
      />
      <BrowseTable>
        <StyledTableHead>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="myAssignment.mySubmissions.table.userColumn"
                defaultMessage="User"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="myAssignment.mySubmissions.table.createdAtColumn"
                defaultMessage="Created At"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="myAssignment.mySubmissions.table.uploadedDataColumn"
                defaultMessage="Uploaded Data"
              />
            </TableCell>
            <TableCell align="center">
              <FormattedMessage
                id="myAssignment.mySubmissions.table.stateColumn"
                defaultMessage="State"
              />
            </TableCell>
            <TableCell align="center">
              <FormattedMessage
                id="myAssignment.mySubmissions.table.outcomeColumn"
                defaultMessage="Outcome"
              />
            </TableCell>
            <MinWidthTableCell />
          </TableRow>
        </StyledTableHead>
        <SubmissionsTableBody
          data={response?.results}
          mapToRow={populateSubmissionTableRow}
          noDataMessage={intl.formatMessage({
            id: 'myAssignment.mySubmissions.noSubmissions',
            defaultMessage: 'No matching submissions',
          })}
          numCols={6}
        />
      </BrowseTable>
      <LabeledAddFab
        disabled={!validationResult || specificationInvalid}
        name="createSubmission"
        aria-label="Start a new submission"
        label={intl.formatMessage({
          id: 'myAssignment.mySubmissions.startSubmission',
          defaultMessage: 'Start a new submission',
        })}
        onClick={createNewSubmission}
      />
    </div>
  );
};

export default MySubmissions;
