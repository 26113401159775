import { FC } from 'react';
import { Container } from '@mui/material';

import { PaddedPaper } from '../../../components';
import { UserDetail } from '../../../types';

import ReceiverAdministration from './permissions/ReceiverAdministration';

interface UserPermissionsProps {
  user: UserDetail;
  userUpdated: (profile: UserDetail) => void;
}

const UserPermissions: FC<UserPermissionsProps> = ({ user, userUpdated }) => (
  <Container maxWidth="md" id="user-permissions" disableGutters>
    <PaddedPaper>
      <ReceiverAdministration user={user} userUpdated={userUpdated} />
    </PaddedPaper>
  </Container>
);

export default UserPermissions;
