import React, { FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

interface AppDecorationContextValue {
  title?: string;
  renderTabs?: () => JSX.Element;
  renderBreadcrumb?: () => JSX.Element;
}

const AppDecorationContextDefaultValue = {} as AppDecorationContextValue;
export const AppDecorationContext = React.createContext(AppDecorationContextDefaultValue);

interface AppDecoratorContextValue {
  updateDecoration: (index: number, decoration: AppDecorationContextValue) => void;
  removeDecorator: (index: number) => void;
  index: number;
}

const AppDecoratorContextDefaultValue = {} as AppDecoratorContextValue;
const AppDecoratorContext = React.createContext(AppDecoratorContextDefaultValue);

const AppDecorationWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [appDecoration, setAppDecoration] = useState<AppDecorationContextValue>({});
  const [decorations, setDecorations] = useState<AppDecorationContextValue[]>([]);

  useEffect(() => {
    setAppDecoration(calculateCurrentDecoration(decorations));
  }, [decorations]);

  const updateDecoration = useCallback((index: number, decoration: AppDecorationContextValue) => {
    setDecorations((prevDecorations) => {
      const updatedDecorations = [...prevDecorations];
      while (updatedDecorations.length < index) {
        updatedDecorations[updatedDecorations.length] = {};
      }
      updatedDecorations[index] = decoration;
      return updatedDecorations;
    });
  }, []);

  const removeDecorator = (index: number) => {
    setDecorations((prevDecorations) => [...prevDecorations].slice(0, index));
  };

  const [decoratorContext] = useState<AppDecoratorContextValue>({
    updateDecoration,
    removeDecorator,
    index: 0,
  });

  const calculateCurrentDecoration = (
    currentDecorations: AppDecorationContextValue[]
  ): AppDecorationContextValue => {
    var decoration = {};
    currentDecorations.forEach((d) => {
      decoration = { ...decoration, ...d };
    });
    return decoration;
  };

  return (
    <AppDecoratorContext.Provider value={decoratorContext}>
      <AppDecorationContext.Provider value={appDecoration}>
        {children}
      </AppDecorationContext.Provider>
    </AppDecoratorContext.Provider>
  );
};

export default AppDecorationWrapper;

export const AppDecorator: FC<AppDecorationContextValue & PropsWithChildren> = (props) => {
  const { children, ...decoration } = props;

  const appDecoratorContext = useContext(AppDecoratorContext);
  const { index, removeDecorator, updateDecoration } = appDecoratorContext;

  const updateDecorationContext = useCallback(() => {
    updateDecoration(index, { ...decoration });
  }, [index, decoration, updateDecoration]);

  useEffect(() => {
    updateDecorationContext();
    return () => removeDecorator(index);
  }, [index, removeDecorator, updateDecorationContext]);

  return (
    <AppDecoratorContext.Provider value={{ ...appDecoratorContext, index: index + 1 }}>
      {children}
    </AppDecoratorContext.Provider>
  );
};

export const AppBreadcrumb: React.FunctionComponent<AppDecorationContextValue> = () => (
  <AppDecorationContext.Consumer>
    {({ renderBreadcrumb }) => {
      if (renderBreadcrumb) {
        return renderBreadcrumb();
      }
      return null;
    }}
  </AppDecorationContext.Consumer>
);

export const AppTabs: React.FunctionComponent<AppDecorationContextValue> = () => (
  <AppDecorationContext.Consumer>
    {({ renderTabs }) => {
      if (renderTabs) {
        return renderTabs();
      }
      return null;
    }}
  </AppDecorationContext.Consumer>
);
