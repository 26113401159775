import React from 'react';
import clsx from 'clsx';

import { TableBody } from '@mui/material';
import { TableLoadingRow, TableInfoRow } from '.';

interface BrowseTableBodyProps<T> {
  processing?: boolean;
  data?: T[];
  mapToRow: (value: T) => JSX.Element;
  noDataMessage: string;
  numCols: number;
}

const browseTableBody =
  <T,>(): React.FC<BrowseTableBodyProps<T>> =>
  (props) => {
    const { processing, data, mapToRow, noDataMessage, numCols } = props;
    const tableContent = () => {
      if (!data || processing) {
        return {
          className: 'BrowseTableBody-loading',
          content: <TableLoadingRow colSpan={numCols} />,
        };
      }
      if (!data.length) {
        return {
          className: 'BrowseTableBody-noContent',
          content: <TableInfoRow colSpan={numCols} message={noDataMessage} size="medium" />,
        };
      }
      return {
        className: 'BrowseTableBody-loaded',
        content: data.map(mapToRow),
      };
    };

    const content = tableContent();
    return (
      <TableBody className={clsx('BrowseTableBody-root', content.className)}>
        {content.content}
      </TableBody>
    );
  };

export default browseTableBody;
