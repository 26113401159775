import { FC } from 'react';
import { TableCell, TableCellProps } from '@mui/material';

interface MinWidthTableCellProps extends TableCellProps {
  nowrap?: boolean;
}

const MinWidthTableCell: FC<MinWidthTableCellProps> = ({
  sx = [],
  nowrap,
  align = 'center',
  children,
  ...rest
}) => (
  <TableCell
    align={align}
    sx={[
      {
        width: '1px',
        whiteSpace: nowrap ? 'nowrap' : 'normal',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...rest}
  >
    {children}
  </TableCell>
);

export default MinWidthTableCell;
