import { styled, Typography } from '@mui/material';

const IconTypography = styled(Typography)(({ theme }) => ({
  'display': 'flex',
  'alignItems': 'center',
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
  },
}));

export default IconTypography;
