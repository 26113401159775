import { FC, PropsWithChildren, useCallback } from 'react';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import prettyBytes from 'pretty-bytes';

import { LinearProgress, Theme, Box } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

import { intl } from '../../Internationalization';

export const renderProgressText = (uploading: boolean, progress?: ProgressEvent): string => {
  if (uploading) {
    return progress
      ? `${prettyBytes(progress.loaded)} / ${prettyBytes(progress.total)} ${intl.formatMessage({
          id: 'components.media.uploadDropzone.uploaded',
          defaultMessage: 'uploaded',
        })}`
      : intl.formatMessage({
          id: 'components.media.uploadDropzone.uploading',
          defaultMessage: 'Uploading…',
        });
  }

  return intl.formatMessage({
    id: 'components.media.uploadDropzone.uploadPrompt',
    defaultMessage: 'Drop files here, or click to browse',
  });
};

export interface UploadDropzoneProps extends PropsWithChildren {
  onDrop: (acceptedFiles: File[]) => void;
  accept?: string | string[];
  disabled?: boolean;
  uploading: boolean;
  progress?: ProgressEvent;
  id?: string;
  p?: number;
}

const renderProgress = (progress?: ProgressEvent) => {
  if (!progress) {
    return null;
  }
  if (!progress.lengthComputable) {
    return <LinearProgress variant="indeterminate" />;
  }

  var percentCompleted = Math.round((progress.loaded * 100) / progress.total);
  return <LinearProgress variant="determinate" value={percentCompleted} />;
};

const UploadDropzone: FC<UploadDropzoneProps> = ({
  onDrop,
  accept,
  uploading,
  p = 2,
  progress,
  disabled,
  id,
  children,
}) => {
  const { getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept,
    disabled: disabled || uploading,
  });

  // build styles SxProp array
  const uploadDropZoneStyles = useCallback((): SystemStyleObject<Theme>[] => {
    const styles: SystemStyleObject<Theme>[] = [];
    if (uploading) {
      return styles;
    }
    if (disabled) {
      styles.push({
        cursor: 'default',
        borderColor: (theme) => theme.palette.grey[400],
        color: (theme) => theme.palette.grey[400],
      });
    } else {
      if (isDragAccept) {
        styles.push({ borderColor: 'success.main' });
      }
      if (isDragReject) {
        styles.push({ borderColor: 'error.main' });
      }
    }

    return styles;
  }, [disabled, isDragAccept, isDragReject, uploading]);

  return (
    <Box
      id={id}
      className={clsx('UploadDropZone-root', { 'UploadDropZone-uploading': uploading })}
      {...getRootProps()}
      sx={[
        {
          p,
          borderWidth: 2,
          borderRadius: 0.5,
          borderColor: (theme) => theme.palette.grey[700],
          borderStyle: 'dashed',
          color: (theme) => theme.palette.grey[700],
          transition: 'border .24s ease-in-out',
          cursor: 'pointer',
          width: '100%',
        },
        ...uploadDropZoneStyles(),
      ]}
    >
      <input {...getInputProps()} />
      {children}
      {renderProgress(progress)}
    </Box>
  );
};

export default UploadDropzone;
