import { useMatch, useResolvedPath, resolvePath } from 'react-router-dom';

const useRouterTab = () => {
  const routePath = useResolvedPath('');
  const routerTabPath = useResolvedPath(':tab/*');
  const routerTabPathMatch = useMatch(routerTabPath.pathname);

  const routerTab = routerTabPathMatch?.params?.tab || false;
  const resolveTabRoute = (tab: string) => resolvePath(tab, routePath.pathname);

  return { routerTab, resolveTabRoute } as const;
};

export default useRouterTab;
