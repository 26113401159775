import { styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

interface ActivatedIconProps {
  disabled: boolean;
}

const ActivatedIcon = styled(CheckIcon)<ActivatedIconProps>(({ theme, disabled }) => ({
  color: disabled ? theme.palette.grey[200] : theme.palette.success.main,
}));

export default ActivatedIcon;
