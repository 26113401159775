import { FC } from 'react';
import { MenuItem, TextField } from '@mui/material';

import { ConformanceMode, conformanceModes, CONFORMANCE_MODE_LABELS } from '../../../../../types';
import { intl } from '../../../../../Internationalization';

interface ConformanceModeSelectorProps {
  conformanceMode: ConformanceMode;
  onUpdateConformanceMode: (conformanceMode: ConformanceMode) => void;
}

const ConformanceModeSelector: FC<ConformanceModeSelectorProps> = ({
  conformanceMode,
  onUpdateConformanceMode,
}) => (
  <TextField
    label={intl.formatMessage({
      id: 'specification.configuration.conformanceMode.label',
      defaultMessage: 'Conformance Mode',
    })}
    name="conformanceMode"
    value={conformanceMode}
    onChange={(event) => onUpdateConformanceMode(event.target.value as ConformanceMode)}
    margin="normal"
    variant="outlined"
    select
    fullWidth
  >
    {conformanceModes.map((mode) => (
      <MenuItem key={mode} value={mode}>
        {CONFORMANCE_MODE_LABELS[mode]}
      </MenuItem>
    ))}
  </TextField>
);

export default ConformanceModeSelector;
