import React from 'react';
import { SiteSettingsDetail } from '../../../types';

export interface SiteContextValue {
  siteSettings: SiteSettingsDetail;
  siteSettingsUpdated: (siteSettings: SiteSettingsDetail) => void;
  refreshApplicationContext: () => void;
}

const SiteContextDefaultValue = {} as SiteContextValue;
export const SiteContext = React.createContext(SiteContextDefaultValue);
