import { useState, useEffect, FC } from 'react';
import { Bar } from 'react-chartjs-2';

import AssessmentIcon from '@mui/icons-material/Assessment';

import { ChartPaper } from '../../../components';
import { ProjectActivity, SubmissionOutcome } from '../../../types';
import { simpleChartOptions, chartColors, ViewMode } from '../../../util';
import { intl } from '../../../Internationalization';

interface ProjectActivityChartProps {
  projectActivity: ProjectActivity[];
  viewMode: ViewMode;
}

const colors = chartColors(2);
const populateProjectSubmissionsStatsData = (projectActivity: ProjectActivity[]) => ({
  labels: [...new Set(projectActivity.map((project) => project.projectName))],
  datasets: [
    {
      backgroundColor: colors[0],
      label: intl.formatMessage({
        id: 'dashboard.global.projectActivityChart.dataset.successful.label',
        defaultMessage: 'Successful',
      }),
      borderWidth: 1,
      maxBarThickness: 30,
      data: projectActivity
        .filter((activity) => activity.outcome === SubmissionOutcome.SUCCESS)
        .map((project) => ({ x: project.projectName, y: project.submissions })),
    },
    {
      backgroundColor: colors[1],
      label: intl.formatMessage({
        id: 'dashboard.global.projectActivityChart.dataset.rejected.label',
        defaultMessage: 'Rejected',
      }),
      borderWidth: 1,
      maxBarThickness: 30,
      data: projectActivity
        .filter((activity) => activity.outcome === SubmissionOutcome.REJECTED)
        .map((project) => ({ x: project.projectName, y: project.submissions })),
    },
  ],
});

const ProjectActivityChart: FC<ProjectActivityChartProps> = ({ projectActivity, viewMode }) => {
  const [submissionsData, setSubmissionsData] = useState(
    populateProjectSubmissionsStatsData(projectActivity)
  );

  useEffect(() => {
    setSubmissionsData(populateProjectSubmissionsStatsData(projectActivity));
  }, [projectActivity]);

  return (
    <ChartPaper
      icon={AssessmentIcon}
      iconColor="#66bb6a"
      title={intl.formatMessage({
        id: 'dashboard.global.projectActivityChart.title',
        defaultMessage: 'Submissions per project',
      })}
      columnMode={viewMode === 'COLUMN'}
    >
      <Bar
        data={submissionsData}
        options={simpleChartOptions({
          maintainAspectRatio: viewMode === 'COLUMN' ? false : true,
          displayLegend: false,
          xTitle: intl.formatMessage({
            id: 'dashboard.global.projectActivityChart.xAxis.label',
            defaultMessage: 'Projects',
          }),
          yTitle: intl.formatMessage({
            id: 'dashboard.global.projectActivityChart.yAxis.label',
            defaultMessage: 'Submissions',
          }),
          stacked: true,
        })}
      />
    </ChartPaper>
  );
};

export default ProjectActivityChart;
