import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FaceIcon from '@mui/icons-material/Face';
import MailIcon from '@mui/icons-material/Mail';
import PublicIcon from '@mui/icons-material/Public';
import CheckIcon from '@mui/icons-material/Check';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import WebIcon from '@mui/icons-material/Web';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BallotIcon from '@mui/icons-material/Ballot';
import WorkIcon from '@mui/icons-material/Work';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import { Field, MailTemplateMetadata } from '../../../../types';
import { intl } from '../../../../Internationalization';

const userAccountMailFields: Field[] = [
  {
    expression: 'site.name',
    label: intl.formatMessage({
      id: 'mail.templates.field.siteName',
      defaultMessage: 'Site name',
    }),
    icon: PublicIcon,
  },
  {
    expression: 'user.name',
    label: intl.formatMessage({
      id: 'mail.templates.field.userName',
      defaultMessage: 'User name',
    }),
    icon: FaceIcon,
  },
  {
    expression: 'user.email',
    label: intl.formatMessage({
      id: 'mail.templates.field.userEmail',
      defaultMessage: 'User email',
    }),
    icon: MailIcon,
  },
];

export const mailTemplates: MailTemplateMetadata[] = [
  {
    name: 'activation',
    title: intl.formatMessage({
      id: 'mail.templates.template.accountActivation.title',
      defaultMessage: 'Account Activation',
    }),
    description: intl.formatMessage({
      id: 'mail.templates.template.accountActivation.description',
      defaultMessage: 'Email to send when a user account has been created and needs activating.',
    }),
    icon: PersonAddIcon,
    textFields: [...userAccountMailFields],
    linkFields: [
      {
        // eslint-disable-next-line no-template-curly-in-string
        expression: '${site.rootUrl}/activate?token=${activationToken}',
        label: intl.formatMessage({
          id: 'mail.templates.field.accountActivationUrl',
          defaultMessage: 'Account activation URL',
        }),
        icon: CheckIcon,
      },
    ],
  },
  {
    name: 'request_password_reset',
    title: intl.formatMessage({
      id: 'mail.templates.template.requestPasswordReset.title',
      defaultMessage: 'Request Password Reset',
    }),
    description: intl.formatMessage({
      id: 'mail.templates.template.requestPasswordReset.description',
      defaultMessage: 'Email to send when a user requests to reset their password.',
    }),
    icon: VpnKeyIcon,
    textFields: [...userAccountMailFields],
    linkFields: [
      {
        // eslint-disable-next-line no-template-curly-in-string
        expression: '${site.rootUrl}/reset_password?token=${passwordResetToken}',
        label: intl.formatMessage({
          id: 'mail.templates.field.passwordResetUrl',
          defaultMessage: 'Password reset URL',
        }),
        icon: VpnKeyIcon,
      },
    ],
  },
  {
    name: 'welcome',
    title: intl.formatMessage({
      id: 'mail.templates.template.welcome.title',
      defaultMessage: 'Welcome',
    }),
    description: intl.formatMessage({
      id: 'mail.templates.template.welcome.description',
      defaultMessage: 'Email to send to the user when their account is activated.',
    }),
    icon: HowToRegIcon,
    textFields: [
      ...userAccountMailFields,
      {
        expression: 'site.rootUrl',
        label: intl.formatMessage({
          id: 'mail.templates.field.siteUrl',
          defaultMessage: 'Site URL',
        }),
        icon: WebIcon,
      },
    ],
    linkFields: [],
  },
  {
    name: 'notification',
    title: intl.formatMessage({
      id: 'mail.templates.template.notification.title',
      defaultMessage: 'Notification',
    }),
    description: intl.formatMessage({
      id: 'mail.templates.template.notification.description',
      defaultMessage: "Email to send to the user to notify them about their submission's progress.",
    }),
    icon: NotificationsIcon,
    textFields: [
      ...userAccountMailFields,
      {
        expression: 'createdAt',
        label: intl.formatMessage({
          id: 'mail.templates.field.createdAt',
          defaultMessage: 'Created Date',
        }),
        icon: AccessTimeIcon,
      },
      {
        expression: 'submission.reference',
        label: intl.formatMessage({
          id: 'mail.templates.field.submissionReference',
          defaultMessage: 'Submission Reference',
        }),
        icon: AssessmentIcon,
      },
      {
        expression: 'submission.assignment.reference',
        label: intl.formatMessage({
          id: 'mail.templates.field.assignmentReference',
          defaultMessage: 'Assignment Reference',
        }),
        icon: AssignmentIcon,
      },
      {
        expression: 'submission.specification.name',
        label: intl.formatMessage({
          id: 'mail.templates.field.specificationName',
          defaultMessage: 'Specification Name',
        }),
        icon: BallotIcon,
      },
      {
        expression: 'submission.project.name',
        label: intl.formatMessage({
          id: 'mail.templates.field.projectName',
          defaultMessage: 'Project Name',
        }),
        icon: WorkIcon,
      },
      {
        expression: 'submission.supplier.name',
        label: intl.formatMessage({
          id: 'mail.templates.field.supplierName',
          defaultMessage: 'Supplier Name',
        }),
        icon: LocalShippingIcon,
      },
    ],
    linkFields: [
      {
        expression:
          // eslint-disable-next-line no-template-curly-in-string
          '${site.rootUrl}/my_assignments/${submission.assignment.key}/submissions/${submission.reference}',
        label: intl.formatMessage({
          id: 'mail.templates.field.submissionUrl',
          defaultMessage: 'Submission URL',
        }),
        icon: VpnKeyIcon,
      },
    ],
  },
];
