import { FC } from 'react';
import { Text } from '@react-pdf/renderer';
import { FormattedMessage } from 'react-intl';

import { sharedPdfStyles } from '../../../../../components';
import { ReportProblemOutlinedWarningSvg } from '../../../../../components/pdf/PdfIcons';

import { TwoColumnTable } from './';
import { PdfSection, PdfSectionHeader } from '../components';

interface ExcessClassesProps {
  excessClasses: string[];
}

const ExcessClasses: FC<ExcessClassesProps> = ({ excessClasses }) => {
  if (!excessClasses.length) {
    return null;
  }

  return (
    <PdfSection gutterBottom>
      <PdfSectionHeader>
        <ReportProblemOutlinedWarningSvg
          style={[sharedPdfStyles.mediumIcon, sharedPdfStyles.iconMarginRight]}
        />
        <Text style={[sharedPdfStyles.sectionHeaderText]}>
          <FormattedMessage
            id="submission.validateSchemaPdf.excessClasses.title"
            defaultMessage="Excess Classes"
          />
        </Text>
      </PdfSectionHeader>
      <TwoColumnTable data={excessClasses} />
    </PdfSection>
  );
};

export default ExcessClasses;
