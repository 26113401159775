import { FC, useState } from 'react';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ValidateFieldsError } from 'async-validator';

import { Dialog, DialogTitle, DialogContent, Checkbox } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import * as UserApi from '../../../api/user';
import { intl } from '../../../Internationalization';
import { onEnterCallback, validate } from '../../../util';
import { extractErrorMessage } from '../../../api/endpoints';
import {
  ValidatedTextField,
  PaddedDialogActions,
  DefaultButton,
  BlockFormControlLabel,
  MessageBox,
} from '../../../components';

import { toUserUrl } from '../Users';

interface NewUserFormProps {
  onCancel: () => void;
}

const VALIDATOR = UserApi.userProfileValidator();

const NewUserForm: FC<NewUserFormProps> = ({ onCancel }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [activate, setActivate] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const onSubmit = () => {
    setProcessing(true);
    validateAndSubmit();
  };

  const submitOnEnter = onEnterCallback(onSubmit);

  const validateAndSubmit = () => {
    validate(VALIDATOR, { email, name })
      .then(createUser)
      .catch((errors: ValidateFieldsError) => {
        setFieldErrors(errors);
        setProcessing(false);
      });
  };

  const createUser = () => {
    UserApi.createUser({ name, email, activate })
      .then((response) => {
        navigate(toUserUrl(response.data));
        enqueueSnackbar(
          intl.formatMessage(
            {
              id: 'user.create.saveSuccess',
              defaultMessage: 'User {name} has been created',
            },
            { name }
          ),
          { variant: 'success' }
        );
      })
      .catch((error: AxiosError) => {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'user.create.saveError',
              defaultMessage: 'Failed to create user',
            })
          ),
          { variant: 'error' }
        );
        setProcessing(false);
      });
  };

  return (
    <Dialog
      id="new-user-dialog"
      onClose={onCancel}
      aria-labelledby="new-user-dialog-title"
      open={true}
      fullWidth
    >
      <DialogTitle id="new-user-dialog-title">
        <FormattedMessage id="user.create.title" defaultMessage="Add User" />
      </DialogTitle>
      <DialogContent dividers={true}>
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="name"
          label={intl.formatMessage({
            id: 'user.create.name.label',
            defaultMessage: 'Name',
          })}
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          variant="outlined"
        />
        <ValidatedTextField
          fieldErrors={fieldErrors}
          disabled={processing}
          name="email"
          label={intl.formatMessage({
            id: 'user.create.email.label',
            defaultMessage: 'Email',
          })}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={submitOnEnter}
          margin="normal"
          variant="outlined"
        />
        <BlockFormControlLabel
          control={
            <Checkbox
              color="primary"
              name="activate"
              checked={activate}
              onChange={() => setActivate((userActive) => !userActive)}
            />
          }
          label={intl.formatMessage({
            id: 'user.create.activate.label',
            defaultMessage: 'Activate automatically?',
          })}
        />
        <MessageBox
          level="info"
          message={
            activate
              ? intl.formatMessage({
                  id: 'user.create.activated',
                  defaultMessage:
                    'The account will be activated and the user will receive the welcome email.',
                })
              : intl.formatMessage({
                  id: 'user.create.notActivated',
                  defaultMessage: 'The user will receive the activation email.',
                })
          }
        />
      </DialogContent>
      <PaddedDialogActions>
        <DefaultButton
          name="cancelAddUser"
          color="secondary"
          onClick={onCancel}
          disabled={processing}
        >
          <FormattedMessage id="user.create.cancelButton" defaultMessage="Cancel" />
        </DefaultButton>
        <DefaultButton
          name="addNewUser"
          onClick={onSubmit}
          disabled={processing}
          startIcon={<AddIcon />}
        >
          <FormattedMessage id="user.create.addButton" defaultMessage="Add User" />
        </DefaultButton>
      </PaddedDialogActions>
    </Dialog>
  );
};

export default NewUserForm;
