import React, { useContext } from 'react';

export interface NavigationPromptContextValue {
  raiseNavigationBlock: (message?: string) => void;
  clearNavigationBlock: () => void;
  handleNavigationPrompt: (onConfirmNavigation: () => void) => void;
}

const NavigationPromptDefaultValue = {} as NavigationPromptContextValue;
export const NavigationPromptContext = React.createContext(NavigationPromptDefaultValue);

export const useNavigationPrompt = () => useContext(NavigationPromptContext);
