import { FC } from 'react';
import { Box } from '@mui/material';

import { SupplierMetadata } from '../../types';
import SupplierAvatar from './SupplierAvatar';

interface NamedSupplierAvatarProps {
  supplier: SupplierMetadata;
}

const NamedSupplierAvatar: FC<NamedSupplierAvatarProps> = ({ supplier }) => (
  <Box display="flex" alignItems="center">
    <SupplierAvatar supplier={supplier} size="small" sx={{ mr: 1 }} />
    {supplier.name}
  </Box>
);

export default NamedSupplierAvatar;
