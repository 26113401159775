import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, View } from '@react-pdf/renderer';

import { ApplyActionTaskResult } from '../../../../../types';
import { substringRuleNameFromPath } from '../../taskResultUtils';
import { PdfTableRow, PdfTableCell } from '../components';
import { OmittedRowsMessage } from './TaskResultSections';

export const ApplyActionTaskHeader: FC = () => (
  <PdfTableRow header>
    <PdfTableCell colSpan="third" header>
      <Text>
        <FormattedMessage
          id="submission.resultsPdf.applyActionTaskResult.actionHeader"
          defaultMessage="Action"
        />
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="third" header number>
      <Text>
        <FormattedMessage
          id="submission.resultsPdf.applyActionTaskResult.processedHeader"
          defaultMessage="Processed"
        />
      </Text>
    </PdfTableCell>
    <PdfTableCell colSpan="third" header number>
      <Text>
        <FormattedMessage
          id="submission.resultsPdf.applyActionTaskResult.errorHeader"
          defaultMessage="Errors"
        />
      </Text>
    </PdfTableCell>
  </PdfTableRow>
);

interface ApplyActionTaskResultContentProps {
  task: ApplyActionTaskResult;
  hideZeroProcessedRows: boolean;
}

export const ApplyActionTaskResultContent: FC<ApplyActionTaskResultContentProps> = ({
  task: { actions },
  hideZeroProcessedRows,
}) => {
  const filteredRows = hideZeroProcessedRows
    ? actions.filter(({ processed }) => processed !== 0)
    : actions;
  const filteredRowCount = actions.length - filteredRows.length;
  return (
    <View>
      {filteredRows.map(({ path, errors, processed }) => (
        <PdfTableRow key={path}>
          <PdfTableCell colSpan="third">
            <Text>{substringRuleNameFromPath(path)}</Text>
          </PdfTableCell>
          <PdfTableCell colSpan="third" number>
            <Text>{processed}</Text>
          </PdfTableCell>
          <PdfTableCell colSpan="third" number>
            <Text>{errors}</Text>
          </PdfTableCell>
        </PdfTableRow>
      ))}
      <OmittedRowsMessage omittedRowCount={filteredRowCount} />
    </View>
  );
};
