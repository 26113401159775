import { FC } from 'react';

import { Editor, EditorProps } from 'react-draft-wysiwyg';
import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useTheme } from '@mui/material';
import { ClassNames } from '@emotion/react';

export interface StyledEditorProps extends EditorProps {
  // react-draft-wysiwyg exposes the following function - it is missing from the types.
  handleReturn?(): boolean;
}

const StyledEditor: FC<StyledEditorProps> = ({
  toolbarClassName,
  wrapperClassName,
  editorClassName,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <ClassNames>
      {({ css, cx }) => (
        <Editor
          wrapperClassName={cx([
            css({
              borderColor: theme.palette.divider,
              borderStyle: 'solid',
              borderWidth: '1px',
              borderRadius: theme.shape.borderRadius,
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }),
            wrapperClassName,
          ])}
          editorClassName={cx([
            css({
              backgroundColor: theme.palette.background.paper,
              padding: theme.spacing(1),
            }),
            editorClassName,
          ])}
          toolbarClassName={cx([
            css({
              '&&': {
                backgroundColor: theme.palette.background.default,
              },
            }),
            toolbarClassName,
          ])}
          {...rest}
        />
      )}
    </ClassNames>
  );
};

export default StyledEditor;
