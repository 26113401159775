import { FC } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { Box } from '@mui/material';
import ColorLensIcon from '@mui/icons-material/ColorLens';

import { intl } from '../../../Internationalization';
import { AppDecorator, AppTabs, RouterTabs, RouterTab } from '../../../components';
import { useRouterTab } from '../../../hooks';

import SystemHeader from '../SystemHeader';

import Settings from './Settings';
import Security from './Security';
import ThemeCustomizer from './theme-customizer/ThemeCustomizer';
import Branding from './Branding';
import Password from './Password';

const SiteRouting: FC = () => {
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="site-settings-tabs" value={routerTab}>
      <RouterTab
        id="settings-tab"
        value="settings"
        label={intl.formatMessage({
          id: 'site.routing.tabs.settings',
          defaultMessage: 'Settings',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="theme-tab"
        value="theme"
        label={intl.formatMessage({
          id: 'site.routing.tabs.theme',
          defaultMessage: 'Theme',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="branding-tab"
        value="branding"
        label={intl.formatMessage({
          id: 'site.routing.tabs.branding',
          defaultMessage: 'Branding',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="security-tab"
        value="security"
        label={intl.formatMessage({
          id: 'site.routing.tabs.security',
          defaultMessage: 'Security',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="password-tab"
        value="password"
        label={intl.formatMessage({
          id: 'site.routing.tabs.password',
          defaultMessage: 'Password',
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  return (
    <AppDecorator renderTabs={renderTabs}>
      <SystemHeader
        title={intl.formatMessage({
          id: 'site.routing.title',
          defaultMessage: 'Site Settings',
        })}
        icon={ColorLensIcon}
      />
      <AppTabs />
      <Box p={3}>
        <Routes>
          <Route path="settings" element={<Settings />} />
          <Route path="theme" element={<ThemeCustomizer />} />
          <Route path="branding" element={<Branding />} />
          <Route path="security" element={<Security />} />
          <Route path="password" element={<Password />} />
          <Route path="*" element={<Navigate to="settings" replace />} />
        </Routes>
      </Box>
    </AppDecorator>
  );
};

export default SiteRouting;
