import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import ApiTokens from './ApiTokens';
import ApiToken from './ApiToken';

const ApiTokenRouting: FC = () => (
  <Routes>
    <Route index element={<ApiTokens />} />
    <Route path=":apiTokenName" element={<ApiToken />} />
    <Route path="*" element={<Navigate to="." replace />} />
  </Routes>
);

export default ApiTokenRouting;
