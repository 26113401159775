import { useLocation } from 'react-router-dom';

import { Grid, Box } from '@mui/material';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import GroupIcon from '@mui/icons-material/Group';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DnsIcon from '@mui/icons-material/Dns';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import StorageIcon from '@mui/icons-material/Storage';
import PolicyIcon from '@mui/icons-material/Policy';
import WorkIcon from '@mui/icons-material/Work';

import { CardGrid, IconCard } from '../../components';
import { intl } from '../../Internationalization';

const System = () => {
  const location = useLocation();

  return (
    <Box p={3} id="system">
      <CardGrid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <IconCard
            icon={DnsIcon}
            linkUrl={location.pathname + '/service_configuration'}
            title={intl.formatMessage({
              id: 'system.serviceConfiguration.title',
              defaultMessage: 'Service Configuration',
            })}
            description={intl.formatMessage({
              id: 'system.serviceConfiguration.description',
              defaultMessage: 'Configure 1Integrate connection settings',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <IconCard
            icon={MailOutlineIcon}
            linkUrl={location.pathname + '/mail'}
            title={intl.formatMessage({
              id: 'system.mail.title',
              defaultMessage: 'Mail',
            })}
            description={intl.formatMessage({
              id: 'system.mail.description',
              defaultMessage:
                'Configure the email sender settings, as well as the email templates used within the automated mail system, such as notification and account emails',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <IconCard
            icon={ColorLensIcon}
            linkUrl={location.pathname + '/site'}
            title={intl.formatMessage({
              id: 'system.site.title',
              defaultMessage: 'Site Settings',
            })}
            description={intl.formatMessage({
              id: 'system.site.description',
              defaultMessage: 'Configure site settings, theme colours and security settings',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <IconCard
            icon={GroupIcon}
            linkUrl={location.pathname + '/ldap'}
            title={intl.formatMessage({
              id: 'system.ldap.title',
              defaultMessage: 'LDAP Configuration',
            })}
            description={intl.formatMessage({
              id: 'system.ldap.description',
              defaultMessage:
                'Configure user synchronisation using your LDAP or Active Directory server',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <IconCard
            icon={GroupAddIcon}
            linkUrl={location.pathname + '/user_import'}
            title={intl.formatMessage({
              id: 'system.userImport.title',
              defaultMessage: 'User Import',
            })}
            description={intl.formatMessage({
              id: 'system.userImport.description',
              defaultMessage: 'Import multiple users from CSV and view import history logs',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <IconCard
            icon={WorkIcon}
            linkUrl={location.pathname + '/project_import'}
            title={intl.formatMessage({
              id: 'system.projectImport.title',
              defaultMessage: 'Project Import',
            })}
            description={intl.formatMessage({
              id: 'system.projectImport.description',
              defaultMessage: 'Import multiple projects from Zip and view import history logs',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <IconCard
            icon={VpnKeyIcon}
            linkUrl={location.pathname + '/identity_providers'}
            title={intl.formatMessage({
              id: 'system.identityProviders.title',
              defaultMessage: 'Identity Providers',
            })}
            description={intl.formatMessage({
              id: 'system.identityProviders.description',
              defaultMessage: 'Configure remote authentication services',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <IconCard
            icon={StorageIcon}
            linkUrl={location.pathname + '/storage'}
            title={intl.formatMessage({
              id: 'system.storage.title',
              defaultMessage: 'Storage and Logging',
            })}
            description={intl.formatMessage({
              id: 'system.storage.description',
              defaultMessage: 'Monitor and manage disk usage, and view logs',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <IconCard
            icon={PolicyIcon}
            linkUrl={location.pathname + '/virus_scanner'}
            title={intl.formatMessage({
              id: 'system.virusScanner.title',
              defaultMessage: 'Virus scanner',
            })}
            description={intl.formatMessage({
              id: 'system.virusScanner.description',
              defaultMessage: 'Virus scanner configuration',
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} xl={3}>
          <IconCard
            icon={HelpOutlineIcon}
            linkUrl={location.pathname + '/help'}
            title={intl.formatMessage({
              id: 'system.help.title',
              defaultMessage: 'Help and Support',
            })}
            description={intl.formatMessage({
              id: 'system.help.description',
              defaultMessage: 'Get help and support with using 1Data Gateway',
            })}
          />
        </Grid>
      </CardGrid>
    </Box>
  );
};

export default System;
