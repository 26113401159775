import { FC } from 'react';

import Paper, { PaperProps } from '@mui/material/Paper';

interface PaddedPaperProps extends PaperProps {
  fillVertical?: boolean;
}

const PaddedPaper: FC<PaddedPaperProps> = ({ sx = [], children, fillVertical, ...rest }) => (
  <Paper
    {...rest}
    sx={[{ p: 2, height: fillVertical ? '100%' : 'auto' }, ...(Array.isArray(sx) ? sx : [sx])]}
  >
    {children}
  </Paper>
);

export default PaddedPaper;
