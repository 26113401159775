import { FC } from 'react';
import { Box, SxProps, ToggleButton, ToggleButtonGroup } from '@mui/material';

import ViewStreamIcon from '@mui/icons-material/ViewStream';
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';

import { ViewMode } from '../../util';

interface DashboardViewModeProps {
  viewMode: ViewMode;
  onChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: ViewMode) => void;
  sx?: SxProps;
}

const DashboardViewMode: FC<DashboardViewModeProps> = ({ sx, viewMode, onChange }) => (
  <Box alignSelf="center" sx={sx}>
    <ToggleButtonGroup exclusive value={viewMode} onChange={onChange} size="small">
      <ToggleButton value="GRID">
        <GridViewRoundedIcon />
      </ToggleButton>
      <ToggleButton value="COLUMN">
        <ViewStreamIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  </Box>
);

export default DashboardViewMode;
