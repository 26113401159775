import { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, Route, Routes, Navigate } from 'react-router-dom';

import { Box, Typography, Link } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import {
  AppDecorator,
  AppTabs,
  HeaderBreadcrumb,
  RouterTab,
  RouterTabs,
} from '../../../../components';
import { intl } from '../../../../Internationalization';
import { useRouterTab } from '../../../../hooks';

import SupplierMappings from '../SupplierMappings';
import ReceiverMappings from '../ReceiverMappings';

import { OAuth2IdentityProviderContext } from './OAuth2IdentityProviderContext';
import OAuth2IdentityProviderSettings from './OAuth2IdentityProviderSettings';
import OAuth2IdentityProviderServiceSettings from './OAuth2IdentityProviderServiceSettings';

const OAuth2IdentityProviderRouting: FC = () => {
  const { identityProvider } = useContext(OAuth2IdentityProviderContext);

  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="oauth2-tabs" value={routerTab}>
      <RouterTab
        id="identity-provider-settings-tab"
        value="identity_provider_settings"
        label={intl.formatMessage({
          id: 'oauth2.routing.tabs.identityProviderSettings',
          defaultMessage: 'Identity Provider Settings',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="service-settings-tab"
        value="service_settings"
        label={intl.formatMessage({
          id: 'oauth2.routing.tabs.serviceSettings',
          defaultMessage: 'Service Settings',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      {identityProvider.mapSuppliersByGroup && (
        <RouterTab
          id="supplier-mapping-tab"
          value="supplier_mappings"
          label={intl.formatMessage({
            id: 'oauth2.routing.tabs.supplierMappings',
            defaultMessage: 'Supplier Mappings',
          })}
          resolveTabRoute={resolveTabRoute}
        />
      )}
      {identityProvider.mapReceiverByGroup && (
        <RouterTab
          id="receiver-mapping-tab"
          value="receiver_mappings"
          label={intl.formatMessage({
            id: 'oauth2.routing.tabs.receiverMappings',
            defaultMessage: 'Receiver Mappings',
          })}
          resolveTabRoute={resolveTabRoute}
        />
      )}
    </RouterTabs>
  );

  const renderBreadcrumb = () => (
    <HeaderBreadcrumb>
      <Link component={RouterLink} to="/system/identity_providers">
        <VpnKeyIcon />
        <FormattedMessage id="oauth2.routing.breadcrumb" defaultMessage="Identity Providers" />
      </Link>
      <Typography>
        <LockOpenIcon
          titleAccess={intl.formatMessage({
            id: 'oauth2.routing.breadcrumb.identityProvider.titleAccess',
            defaultMessage: 'Identity provider',
          })}
        />
        {identityProvider.name}
      </Typography>
    </HeaderBreadcrumb>
  );

  return (
    <AppDecorator renderTabs={renderTabs} renderBreadcrumb={renderBreadcrumb}>
      <AppTabs />
      <Box p={3}>
        <Routes>
          <Route path="identity_provider_settings" element={<OAuth2IdentityProviderSettings />} />
          <Route path="service_settings" element={<OAuth2IdentityProviderServiceSettings />} />
          {identityProvider.mapSuppliersByGroup && (
            <Route
              path="supplier_mappings"
              element={<SupplierMappings identityProviderKey={identityProvider.key} />}
            />
          )}
          {identityProvider.mapReceiverByGroup && (
            <Route
              path="receiver_mappings"
              element={<ReceiverMappings identityProviderKey={identityProvider.key} />}
            />
          )}
          <Route path="*" element={<Navigate to="identity_provider_settings" replace />} />
        </Routes>
      </Box>
    </AppDecorator>
  );
};

export default OAuth2IdentityProviderRouting;
