import React, { FC } from 'react';

import { FormControlLabel, Switch } from '@mui/material';

interface FilterToggleProps {
  name?: string;
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const FilterToggle: FC<FilterToggleProps> = ({ name, label, checked, onChange, disabled }) => (
  <FormControlLabel
    control={<Switch name={name} checked={checked} onChange={onChange} disabled={disabled} />}
    label={label}
    labelPlacement="start"
  />
);

export default FilterToggle;
