import defaultLogo from '../assets/images/1datagateway_1300.jpg';
import defaultHeroImage from '../assets/images/bg1.jpg';
import { MediaDetail } from '../types';

import { generateApiUri } from './endpoints';

export const downloadSiteResourceUri = (filename: string): string => {
  return generateApiUri(`/site/resources/${filename}`);
};

export const siteLogoOrDefault = (logo?: MediaDetail): string => {
  return logo ? downloadSiteResourceUri(logo.storedFilename) : defaultLogo;
};

export const siteHeroImageOrDefault = (heroImage?: MediaDetail): string => {
  return heroImage ? downloadSiteResourceUri(heroImage.storedFilename) : defaultHeroImage;
};
