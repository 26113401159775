import { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text, View } from '@react-pdf/renderer';

import { Box, Typography } from '@mui/material';

import { schemaPdfStyles } from '../../../components';
import { intl } from '../../../Internationalization';
import { DataStoreClassMapping, DataStoreClassSchema, MappedAttributes } from '../../../types';

import MappingStatusIcon from './MappingStatusIcon';
import { SchemaMappingContext } from './SchemaMappingContext';
import {
  findMappedAttributeForSource,
  findMappedAttributeForTarget,
  findMappingForSource,
  findMappingForTarget,
  MAPPING_STATUS_METADATA,
  SchemaMappingValidationResult,
  SchemaMappingValidationResults,
} from './schemaMappingUtils';
import { MappingStatus } from '.';

const TargetAttributesResult = ({ unmappedAttributes }: { unmappedAttributes: number }) => (
  <FormattedMessage
    id="schemaMapper.metadata.targetAttributesResult"
    defaultMessage="Unmapped target attributes: {unmappedAttributes}"
    values={{ unmappedAttributes }}
  />
);

const SourceAttributesResult = ({ unmappedAttributes }: { unmappedAttributes: number }) => (
  <FormattedMessage
    id="schemaMapper.metadata.sourceAttributesResult"
    defaultMessage="Unmapped source attributes: {unmappedAttributes}"
    values={{ unmappedAttributes }}
  />
);

const TargetClassesResult = ({ unmappedClasses }: { unmappedClasses: number }) => (
  <FormattedMessage
    id="schemaMapper.metadata.targetClassesResult"
    defaultMessage="Unmapped target classes: {unmappedClasses}"
    values={{ unmappedClasses }}
  />
);

const SourceClassesResult = ({ unmappedClasses }: { unmappedClasses: number }) => (
  <FormattedMessage
    id="schemaMapper.metadata.sourceClassesResult"
    defaultMessage="Unmapped source classes: {unmappedClasses}"
    values={{ unmappedClasses }}
  />
);

const SourceDataStoreValidationResults: FC = () => {
  const { validationResults, selectedDataStoreName } = useContext(SchemaMappingContext);
  const sourceValidationResults =
    validationResults.source.dataStoreValidationResults[selectedDataStoreName];
  return (
    <Box display="flex" alignItems="center" height="100%">
      <MappingStatusIcon status={sourceValidationResults.status} iconSize="large" />
      <Box mx={2}>
        <Typography variant="body2">
          <SourceClassesResult unmappedClasses={sourceValidationResults.unmappedClasses} />
        </Typography>
        <Typography variant="body2">
          <SourceAttributesResult unmappedAttributes={sourceValidationResults.unmappedAttributes} />
        </Typography>
      </Box>
    </Box>
  );
};

const TargetDataStoreValidationResults: FC = () => {
  const { validationResults, selectedDataStoreName } = useContext(SchemaMappingContext);
  const targetValidationResults =
    validationResults.target.dataStoreValidationResults[selectedDataStoreName];
  return (
    <Box display="flex" alignItems="center" height="100%">
      <MappingStatusIcon status={targetValidationResults.status} iconSize="large" />
      <Box mx={2}>
        <Typography variant="body2">
          <TargetClassesResult unmappedClasses={targetValidationResults.unmappedClasses} />
        </Typography>
        <Typography variant="body2">
          <TargetAttributesResult unmappedAttributes={targetValidationResults.unmappedAttributes} />
        </Typography>
      </Box>
    </Box>
  );
};

interface ClassValidationResultsProps {
  sourceClass: DataStoreClassSchema;
  targetClass: DataStoreClassSchema;
}

const SourceClassValidationResults: FC<ClassValidationResultsProps> = ({ sourceClass }) => {
  const { validationResults, selectedDataStoreName } = useContext(SchemaMappingContext);
  const sourceValidationResults =
    validationResults.source.dataStoreValidationResults[selectedDataStoreName]
      .classValidationResults[sourceClass.name];
  return (
    <Box display="flex" alignItems="center" height="100%">
      <MappingStatusIcon status={sourceValidationResults.status} iconSize="large" />
      <Box mx={2}>
        <Typography variant="body2">
          <SourceAttributesResult unmappedAttributes={sourceValidationResults.unmappedAttributes} />
        </Typography>
      </Box>
    </Box>
  );
};

const TargetClassValidationResults: FC<ClassValidationResultsProps> = ({ targetClass }) => {
  const { validationResults, selectedDataStoreName } = useContext(SchemaMappingContext);
  const targetValidationResults =
    validationResults.target.dataStoreValidationResults[selectedDataStoreName]
      .classValidationResults[targetClass.name];
  return (
    <Box display="flex" alignItems="center" height="100%">
      <MappingStatusIcon status={targetValidationResults.status} iconSize="large" />
      <Box mx={2}>
        <Typography variant="body2">
          <TargetAttributesResult unmappedAttributes={targetValidationResults.unmappedAttributes} />
        </Typography>
      </Box>
    </Box>
  );
};

interface PdfDataStoreValidationResults {
  dataStoreName: string;
  validationResults: SchemaMappingValidationResults;
}

const SourceDataStorePdfValidationResults: FC<PdfDataStoreValidationResults> = ({
  dataStoreName,
  validationResults,
}) => {
  const sourceValidationResults =
    validationResults.source.dataStoreValidationResults[dataStoreName];
  const { pdfIcon: StatusIcon } = MAPPING_STATUS_METADATA[sourceValidationResults.status];

  return (
    <View style={[schemaPdfStyles.rowContainer]}>
      <StatusIcon />
      <View style={schemaPdfStyles.mappingInfoText}>
        <Text>
          <SourceClassesResult unmappedClasses={sourceValidationResults.unmappedClasses} />
        </Text>
        <Text>
          <SourceAttributesResult unmappedAttributes={sourceValidationResults.unmappedAttributes} />
        </Text>
      </View>
    </View>
  );
};

const TargetDataStorePdfValidationResults: FC<PdfDataStoreValidationResults> = ({
  dataStoreName,
  validationResults,
}) => {
  const targetValidationResults =
    validationResults.target.dataStoreValidationResults[dataStoreName];
  const { pdfIcon: StatusIcon } = MAPPING_STATUS_METADATA[targetValidationResults.status];

  return (
    <View style={[schemaPdfStyles.rowContainer]}>
      <StatusIcon />
      <View style={schemaPdfStyles.mappingInfoText}>
        <Text>
          <TargetClassesResult unmappedClasses={targetValidationResults.unmappedClasses} />
        </Text>
        <Text>
          <TargetAttributesResult unmappedAttributes={targetValidationResults.unmappedAttributes} />
        </Text>
      </View>
    </View>
  );
};

interface PdfClassValidationResultsProps {
  dataStoreName: string;
  validationResults: SchemaMappingValidationResults;
  sourceClassName: string;
  targetClassName: string;
}

const SourceClassPdfValidationResults: FC<PdfClassValidationResultsProps> = ({
  dataStoreName,
  validationResults,
  sourceClassName,
}) => {
  const sourceValidationResults =
    validationResults.source.dataStoreValidationResults[dataStoreName].classValidationResults[
      sourceClassName
    ];
  const { pdfIcon: StatusIcon } = MAPPING_STATUS_METADATA[sourceValidationResults.status];

  return (
    <View style={[schemaPdfStyles.rowContainer]}>
      <StatusIcon />
      <Text style={schemaPdfStyles.mappingInfoText}>
        <SourceAttributesResult unmappedAttributes={sourceValidationResults.unmappedAttributes} />
      </Text>
    </View>
  );
};

const TargetClassPdfValidationResults: FC<PdfClassValidationResultsProps> = ({
  dataStoreName,
  validationResults,
  targetClassName,
}) => {
  const targetValidationResults = validationResults.target.dataStoreValidationResults[dataStoreName]
    .classValidationResults[targetClassName] || {
    // Assume SchemaMappingMode.NONE if no target class validation present.
    // Present it as mapped so the user can see what the uploaded schema was.
    mapped: true,
    unmappedAttributes: 0,
    status: MappingStatus.OK,
  };
  const { pdfIcon: StatusIcon } = MAPPING_STATUS_METADATA[targetValidationResults.status];

  return (
    <View style={[schemaPdfStyles.rowContainer]}>
      <StatusIcon />
      <Text style={schemaPdfStyles.mappingInfoText}>
        <TargetAttributesResult unmappedAttributes={targetValidationResults.unmappedAttributes} />
      </Text>
    </View>
  );
};

type Selector = <T>(leftOrSource: T, rightOrTarget: T) => T;

type ClassFinder = (
  className: string,
  classMappings: DataStoreClassMapping[]
) => DataStoreClassMapping | undefined;
type AttributeFinder = (
  attribute: string,
  mappedAttributes?: MappedAttributes
) => string | undefined;

interface SchemaMappingDirectionMetadata {
  label: string;
  selectLeftOrSource: Selector;
  selectRightOrTarget: Selector;

  extractLeftValidationResults: (
    validationResults: SchemaMappingValidationResults
  ) => SchemaMappingValidationResult;
  findClassMappingForLeft: ClassFinder;
  findClassMappingForRight: ClassFinder;

  findLeftMappedAttributeName: AttributeFinder;
  findRightMappedAttributeName: AttributeFinder;
  extractMappedAttributes: (mappedAttributes: MappedAttributes) => string[];

  leftDataStoreValidationResults: FC;
  rightDataStoreValidationResults: FC;
  leftClassValidationResults: FC<ClassValidationResultsProps>;
  rightClassValidationResults: FC<ClassValidationResultsProps>;

  leftDataStorePdfValidationResults: FC<PdfDataStoreValidationResults>;
  rightDataStorePdfValidationResults: FC<PdfDataStoreValidationResults>;
  leftClassPdfValidationResults: FC<PdfClassValidationResultsProps>;
  rightClassPdfValidationResults: FC<PdfClassValidationResultsProps>;

  leftClassHeaderText: string;
  rightClassHeaderText: string;
  leftAttributeHeaderText: string;
  rightAttributeHeaderText: string;
}

export enum SchemaMappingDirection {
  TARGET_TO_SOURCE = 'TARGET_TO_SOURCE',
  SOURCE_TO_TARGET = 'SOURCE_TO_TARGET',
}

export const schemaMappingDirections = [
  SchemaMappingDirection.SOURCE_TO_TARGET,
  SchemaMappingDirection.TARGET_TO_SOURCE,
];

const sourceClassText = intl.formatMessage({
  id: 'schemaMapper.metadata.sourceClassLabel',
  defaultMessage: 'Source Class',
});
const targetClassText = intl.formatMessage({
  id: 'schemaMapper.metadata.targetClassLabel',
  defaultMessage: 'Target Class',
});
const sourceAttributeText = intl.formatMessage({
  id: 'schemaMapper.metadata.sourceAttributeLabel',
  defaultMessage: 'Source Attribute',
});
const targetAttributeText = intl.formatMessage({
  id: 'schemaMapper.metadata.targetAttributeLabel',
  defaultMessage: 'Target Attribute',
});

export const SCHEMA_MAPPING_DIRECTION_METADATA: Record<
  SchemaMappingDirection,
  SchemaMappingDirectionMetadata
> = {
  [SchemaMappingDirection.SOURCE_TO_TARGET]: {
    label: intl.formatMessage({
      id: 'schemaMapper.metadata.sourceToTarget.label',
      defaultMessage: 'Source to target',
    }),
    selectLeftOrSource: (sourceOrLeft, _) => sourceOrLeft,
    selectRightOrTarget: (_, rightOrTarget) => rightOrTarget,

    extractLeftValidationResults: (validationResults) => validationResults.source,

    findClassMappingForLeft: findMappingForSource,
    findClassMappingForRight: findMappingForTarget,

    findLeftMappedAttributeName: findMappedAttributeForSource,
    findRightMappedAttributeName: findMappedAttributeForTarget,
    extractMappedAttributes: Object.keys,

    leftDataStoreValidationResults: SourceDataStoreValidationResults,
    rightDataStoreValidationResults: TargetDataStoreValidationResults,
    leftClassValidationResults: SourceClassValidationResults,
    rightClassValidationResults: TargetClassValidationResults,

    leftDataStorePdfValidationResults: SourceDataStorePdfValidationResults,
    rightDataStorePdfValidationResults: TargetDataStorePdfValidationResults,
    leftClassPdfValidationResults: SourceClassPdfValidationResults,
    rightClassPdfValidationResults: TargetClassPdfValidationResults,

    leftClassHeaderText: sourceClassText,
    rightClassHeaderText: targetClassText,
    leftAttributeHeaderText: sourceAttributeText,
    rightAttributeHeaderText: targetAttributeText,
  },
  [SchemaMappingDirection.TARGET_TO_SOURCE]: {
    label: intl.formatMessage({
      id: 'schemaMapper.metadata.targetToSource.label',
      defaultMessage: 'Target to source',
    }),
    selectLeftOrSource: (_, rightOrTarget) => rightOrTarget,
    selectRightOrTarget: (sourceOrLeft, _) => sourceOrLeft,

    extractLeftValidationResults: (validationResults) => validationResults.target,

    findClassMappingForLeft: findMappingForTarget,
    findClassMappingForRight: findMappingForSource,

    findLeftMappedAttributeName: findMappedAttributeForTarget,
    findRightMappedAttributeName: findMappedAttributeForSource,
    extractMappedAttributes: Object.values,

    leftDataStoreValidationResults: TargetDataStoreValidationResults,
    rightDataStoreValidationResults: SourceDataStoreValidationResults,
    leftClassValidationResults: TargetClassValidationResults,
    rightClassValidationResults: SourceClassValidationResults,

    leftDataStorePdfValidationResults: TargetDataStorePdfValidationResults,
    rightDataStorePdfValidationResults: SourceDataStorePdfValidationResults,
    leftClassPdfValidationResults: TargetClassPdfValidationResults,
    rightClassPdfValidationResults: SourceClassPdfValidationResults,

    leftClassHeaderText: targetClassText,
    rightClassHeaderText: sourceClassText,
    leftAttributeHeaderText: targetAttributeText,
    rightAttributeHeaderText: sourceAttributeText,
  },
};
