import { FC, PropsWithChildren } from 'react';

import { Tabs } from '@mui/material';

interface RouterTabsProps extends PropsWithChildren {
  id: string;
  value: string | false;
}

const RouterTabs: FC<RouterTabsProps> = ({ id, value, children }) => (
  <Tabs id={id} value={value} variant="scrollable">
    {children}
  </Tabs>
);

export default RouterTabs;
