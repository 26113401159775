import { Grid } from '@mui/material';

import { TableFilterSkeleton, ActivityCardSkeleton, ChartPaperSkeleton } from '../../components';

const ChartsSkeleton = () => (
  <>
    <TableFilterSkeleton displayRadioSkeleton={false} />
    <Grid container spacing={6}>
      <ActivityCardSkeleton />
      <ActivityCardSkeleton />
      <ActivityCardSkeleton />
      <ActivityCardSkeleton />
      <ChartPaperSkeleton />
      <ChartPaperSkeleton />
    </Grid>
  </>
);

export default ChartsSkeleton;
