import React from 'react';
import { OneIntegrateServerSettings } from '../../../types';

export interface ServiceConfigurationContextValue {
  oneIntegrateServerSettings: OneIntegrateServerSettings;
  oneIntegrateServerSettingsUpdated: (settings: OneIntegrateServerSettings) => void;
}

const ServiceConfigurationContextDefaultValue = {} as ServiceConfigurationContextValue;
export const ServiceConfigurationContext = React.createContext(
  ServiceConfigurationContextDefaultValue
);
