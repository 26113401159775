import { Container } from '@mui/material';

import { AppDecorator, TabsSkeleton, PaperFormSkeleton } from '../../../../components';

const SpecificationSkeleton = () => (
  <AppDecorator renderTabs={() => <TabsSkeleton tabCount={4} />}>
    <Container maxWidth="md" disableGutters>
      <PaperFormSkeleton contentRows={3} />
    </Container>
  </AppDecorator>
);

export default SpecificationSkeleton;
