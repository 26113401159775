export interface OneIntegrateServerSettings {
  baseUri: string;
  authenticationType: AuthenticationType;
  username: string;
  password: string;
  apiKey: string;
}

export interface OneIntegrateTestServerResponse {
  version: string;
  versionStatus: VersionStatus;
  connectionStatus: string;
}

export enum AuthenticationType {
  CREDENTIALS = 'CREDENTIALS',
  API_KEY = 'API_KEY',
}

export interface FmeServerSettings {
  baseUri: string;
  apiToken: string;
}

export enum VersionStatus {
  SUPPORTED = 'SUPPORTED',
  UNSUPPORTED = 'UNSUPPORTED',
  UNKNOWN = 'UNKNOWN',
}
