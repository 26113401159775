import { AxiosPromise } from 'axios';
import Schema from 'async-validator';

import { AXIOS } from './endpoints';
import { LdapUserSettings, LdapUser } from '../types';
import { intl } from '../Internationalization';
import { durationValidator } from '../util';

export function getUserSettings(): AxiosPromise<LdapUserSettings> {
  return AXIOS.get('/ldap/user');
}

export function updateUserSettings(
  ldapUserSettings: LdapUserSettings
): AxiosPromise<LdapUserSettings> {
  return AXIOS.post('/ldap/user', ldapUserSettings);
}

export function previewLdapUsers(): AxiosPromise<LdapUser[]> {
  return AXIOS.get('/ldap/user/preview');
}

export const LDAP_USER_SETTINGS_VALIDATOR = new Schema({
  uid: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'ldap.validator.uid.required',
      defaultMessage: 'Must provide a UID field',
    }),
  },
  filter: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'ldap.validator.filter.required',
      defaultMessage: 'Must Provide a filter',
    }),
  },
  email: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'ldap.validator.email.required',
      defaultMessage: 'Must provide an email address field',
    }),
  },
  firstName: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'ldap.validator.firstName.required',
      defaultMessage: 'Must provide given name field',
    }),
  },
  synchronizationPeriod: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'ldap.validator.synchronizationPeriod.required',
        defaultMessage: 'Please provide a synchronisation period',
      }),
    },
    durationValidator({ hours: 1 }),
  ],
  jobRetentionPeriod: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'ldap.validator.jobRetentionPeriod.required',
        defaultMessage: 'Please provide a job retention period',
      }),
    },
    durationValidator({ hours: 1 }),
  ],
});
