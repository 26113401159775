import { FC, useContext } from 'react';

import { ProjectAvatar, ContentHeader } from '../../../components';

import { ProjectContext } from './ProjectContext';

const ProjectHeader: FC = () => {
  const { project } = useContext(ProjectContext);
  const { name } = project;
  return (
    <ContentHeader
      id="project-header"
      title={name}
      avatar={<ProjectAvatar project={project} size="large" />}
    />
  );
};

export default ProjectHeader;
