import { StyleSheet } from '@react-pdf/renderer';
import { sharedPdfStyles } from './sharedPdfStyles';

const resultsPdf = StyleSheet.create({
  subtitle: {
    fontSize: 18,
    marginBottom: 8,
  },
  submissionSummaryInfo: {
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 300,
    marginBottom: 0,
    marginLeft: 8,
    marginRight: 24,
  },
  noTaskIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  footerSummary: {
    marginTop: 8,
    paddingTop: 8,
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 300,
  },
});

export const resultsPdfStyles = {
  ...sharedPdfStyles,
  ...resultsPdf,
};
