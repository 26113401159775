import { FC, useContext, useState } from 'react';
import { ValidateFieldsError } from 'async-validator';
import { FormattedMessage } from 'react-intl';
import { useSnackbar } from 'notistack';

import { MenuItem, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { extractErrorMessage } from '../../api/endpoints';
import * as MeApi from '../../api/me';
import {
  PaddedPaper,
  ValidatedTextField,
  FormButtons,
  DefaultButton,
  MessageBox,
} from '../../components';
import { AuthenticatedContext } from '../../contexts/authentication';
import {
  UpdateMyProfileRequest,
  UserLocale,
  USER_LOCALES,
  USER_LOCALE_METADATA,
} from '../../types';
import { onEnterCallback, validate } from '../../util';
import { intl } from '../../Internationalization';

import { remotelyManagedMessage } from './remotelyManagedMessage';

const MyProfile: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { me, updateMe } = useContext(AuthenticatedContext);

  const [name, setName] = useState<string>(me.name);
  const [locale, setLocale] = useState<string | undefined>(me.locale);
  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const validateAndSubmit = async () => {
    setProcessing(true);
    try {
      updateProfile(await validate(MeApi.UPDATE_MY_PROFILE_VALIDATOR, { name, locale }));
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const updateProfile = async (request: UpdateMyProfileRequest) => {
    setFieldErrors(undefined);
    try {
      const response = await MeApi.updateProfile(request);
      updateMe(response.data);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'account.myProfile.saveSuccess',
          defaultMessage: 'Your profile has been successfully updated',
        }),
        { variant: 'success' }
      );
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'account.myProfile.saveError',
            defaultMessage: 'Failed to update profile',
          })
        ),
        { variant: 'error' }
      );
    } finally {
      setProcessing(false);
    }
  };

  const updateLocale = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocale((event.target.value as UserLocale) || undefined);
  };

  const submitOnEnter = onEnterCallback(validateAndSubmit);

  return (
    <PaddedPaper id="account-profile">
      <Typography variant="h5" gutterBottom>
        <FormattedMessage id="account.myProfile.title" defaultMessage="Update your profile" />
      </Typography>
      {me.remotelyManaged && <MessageBox level="info" message={remotelyManagedMessage} />}
      <ValidatedTextField
        fieldErrors={fieldErrors}
        disabled={processing || me.remotelyManaged}
        name="name"
        label={intl.formatMessage({
          id: 'account.myProfile.name.label',
          defaultMessage: 'Name',
        })}
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={submitOnEnter}
        margin="normal"
        variant="outlined"
      />
      <ValidatedTextField
        disabled={true}
        name="email"
        label={intl.formatMessage({
          id: 'account.myProfile.email.label',
          defaultMessage: 'Email',
        })}
        value={me.email}
        margin="normal"
        variant="outlined"
      />
      <ValidatedTextField
        fieldErrors={fieldErrors}
        disabled={processing}
        name="locale"
        label={intl.formatMessage({
          id: 'account.myProfile.locale.label',
          defaultMessage: 'Language',
        })}
        value={locale || ''}
        onChange={updateLocale}
        margin="normal"
        variant="outlined"
        select
      >
        <MenuItem value="">
          <FormattedMessage
            id="account.myProfile.locale.unset.label"
            defaultMessage="Browser default"
          />
        </MenuItem>
        {USER_LOCALES.map((loc) => (
          <MenuItem key={loc} value={loc}>
            {USER_LOCALE_METADATA[loc].label}
          </MenuItem>
        ))}
      </ValidatedTextField>
      <FormButtons>
        <DefaultButton
          name="updateProfile"
          onClick={validateAndSubmit}
          disabled={processing}
          startIcon={<SaveIcon />}
        >
          <FormattedMessage id="account.myProfile.saveButton" defaultMessage="Update Profile" />
        </DefaultButton>
      </FormButtons>
    </PaddedPaper>
  );
};

export default MyProfile;
