import { FC, useCallback } from 'react';

import { intl } from '../../Internationalization';
import { CompleteDateRange, DateRange } from '../../types';

import { ValidationResult } from './FilterDateRange';
import { FilterDateRange } from '.';
import { Duration } from 'date-fns';

interface FilterCompleteDateRangeProps {
  range: CompleteDateRange;
  onRangeUpdated: (range: CompleteDateRange) => void;
  rangeLimit?: Duration;
}

const validateRange = (range: DateRange): ValidationResult => {
  const startAbsent = !range.start;
  const endAbsent = !range.end;
  if (startAbsent || endAbsent) {
    return {
      startError: startAbsent,
      endError: endAbsent,
      errorMessage: intl.formatMessage({
        id: 'components.filterCompleteDateRange.invalidRange',
        defaultMessage: 'Start and end dates must be specified',
      }),
    };
  }
};

const FilterCompleteDateRange: FC<FilterCompleteDateRangeProps> = ({
  range,
  onRangeUpdated,
  rangeLimit,
}) => {
  const rangeUpdated = useCallback(
    (updatedRange: DateRange) => {
      onRangeUpdated(updatedRange as CompleteDateRange);
    },
    [onRangeUpdated]
  );
  return (
    <FilterDateRange
      range={range}
      auxiliaryValidator={validateRange}
      onRangeUpdated={rangeUpdated}
      rangeLimit={rangeLimit}
    />
  );
};

export default FilterCompleteDateRange;
