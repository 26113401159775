import { FC, useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { ValidateFieldsError } from 'async-validator';
import { FormattedMessage } from 'react-intl';

import { Box, DialogContent, TableCell, TableRow, Tooltip } from '@mui/material';
import InputIcon from '@mui/icons-material/Input';

import * as AssignmentCopySavedMappingsApi from '../../../../../api/assignmentCopySavedMappings';
import { extractErrorMessage } from '../../../../../api/endpoints';
import { DefaultButton, TableInfoRow, ValidatedTextField } from '../../../../../components';
import { intl } from '../../../../../Internationalization';
import { CopySavedMappingRequest, SavedMappingDetail } from '../../../../../types';
import { dataStoreNameFromPath, validate } from '../../../../../util';

import { MyAssignmentContext } from '../../MyAssignmentContext';

import { toSavedMapping } from '../SavedMappings';

import SavedMappingBreadcrumb from './SavedMappingBreadcrumb';
import CopySavedMappingActions from './CopySavedMappingActions';
import CopySavedMappingTable from './CopySavedMappingTable';

interface CopyMappingProps {
  sourceSavedMapping: SavedMappingDetail;
  dataStoreMappings: Record<string, string>;

  onCancel: () => void;
  onBack: () => void;
}

const CopyMapping: FC<CopyMappingProps> = ({
  sourceSavedMapping,
  dataStoreMappings,
  onCancel,
  onBack,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { assignmentKey } = useContext(MyAssignmentContext);

  const [name, setName] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const copyMapping = async (request: CopySavedMappingRequest) => {
    try {
      const response = await AssignmentCopySavedMappingsApi.copySavedMapping(
        assignmentKey,
        request
      );
      enqueueSnackbar(
        intl.formatMessage({
          id: 'myAssignment.savedMapping.copy.copyMapping.createSuccess',
          defaultMessage: 'Saved mapping created',
        }),
        { variant: 'success' }
      );
      navigate(toSavedMapping(assignmentKey, response.data.key));
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'myAssignment.savedMapping.copy.copyMapping.createError',
            defaultMessage: 'Failed to create saved mapping',
          })
        ),
        { variant: 'error' }
      );
      setProcessing(false);
    }
  };

  const validateAndSubmit = async () => {
    setProcessing(true);
    try {
      setFieldErrors({});
      await copyMapping(
        await validate(
          AssignmentCopySavedMappingsApi.copySavedMappingValidator(() => assignmentKey),
          { name, savedMappingKey: sourceSavedMapping.key, dataStoreMappings }
        )
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const renderTableRows = () => {
    if (!Object.keys(dataStoreMappings).length) {
      return (
        <TableInfoRow
          colSpan={3}
          size="medium"
          message={intl.formatMessage({
            id: 'myAssignment.savedMapping.copy.copyMapping.noDataStores',
            defaultMessage: 'No data sets to display',
          })}
        />
      );
    }

    return Object.entries(dataStoreMappings).map(([dataStoreToCopyPath, dataStorePathToMap]) => (
      <TableRow key={dataStoreToCopyPath}>
        <TableCell>
          <Tooltip title={dataStoreToCopyPath} placement="right">
            <span>{dataStoreNameFromPath(dataStoreToCopyPath)}</span>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Tooltip title={dataStorePathToMap} placement="right">
            <span>{dataStoreNameFromPath(dataStorePathToMap)}</span>
          </Tooltip>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <DialogContent>
        <Box p={2}>
          <SavedMappingBreadcrumb savedMapping={sourceSavedMapping} displaySavedMapping />
        </Box>
        <CopySavedMappingTable>{renderTableRows()}</CopySavedMappingTable>
        <Box my={1} />
        <ValidatedTextField
          name="name"
          value={name}
          label={intl.formatMessage({
            id: 'myAssignment.savedMapping.copy.copyMapping.name.label',
            defaultMessage: 'Name',
          })}
          tooltip={intl.formatMessage({
            id: 'myAssignment.savedMapping.copy.copyMapping.name.tooltip',
            defaultMessage: 'A descriptive name, unique to this assignment.',
          })}
          onChange={(e) => setName(e.target.value)}
          fieldErrors={fieldErrors}
          disabled={processing}
          margin="normal"
          variant="outlined"
        />
      </DialogContent>
      <CopySavedMappingActions
        onCancel={onCancel}
        onBack={onBack}
        nextButton={
          <DefaultButton
            name="copy"
            onClick={validateAndSubmit}
            disabled={processing}
            startIcon={<InputIcon />}
          >
            <FormattedMessage
              id="myAssignment.savedMapping.copy.copyMapping.copyButton"
              defaultMessage="Copy"
            />
          </DefaultButton>
        }
      />
    </>
  );
};

export default CopyMapping;
