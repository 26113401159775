import { FC, PropsWithChildren, useContext } from 'react';
import { styled } from '@mui/material/styles';
import isPropValid from '@emotion/is-prop-valid';
import Paper from '@mui/material/Paper';

import { Loading } from '../../components';
import { ApplicationContext } from '../../contexts/application';
import * as SiteMediaApi from '../../api/siteMedia';

interface PreAuthPageBackgroundProps {
  heroImageUrl: string;
}

const PreAuthPageBackground = styled('div', {
  shouldForwardProp: isPropValid,
})<PreAuthPageBackgroundProps>(({ theme, heroImageUrl }) => ({
  backgroundImage: `url(${heroImageUrl})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
  display: 'flex',
  height: '100vh',
  margin: 'auto',
  padding: theme.spacing(2),
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

const Logo = styled('img')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(5, 3),
}));

interface PreAuthPageProps {
  loading?: boolean;
  loadingMessage?: string;
}

const PreAuthPage: FC<PropsWithChildren<PreAuthPageProps>> = ({
  loading,
  loadingMessage,
  children,
}) => {
  const {
    applicationDetails: {
      site: {
        resources: { logo, heroImage },
      },
    },
  } = useContext(ApplicationContext);

  return (
    <PreAuthPageBackground
      id="pre-auth-page"
      heroImageUrl={SiteMediaApi.siteHeroImageOrDefault(heroImage)}
    >
      <Paper
        sx={(theme) => ({ maxWidth: theme.breakpoints.values.sm, textAlign: 'center', padding: 2 })}
        elevation={10}
      >
        <Logo src={SiteMediaApi.siteLogoOrDefault(logo)} alt="logo" />
        {loading ? <Loading loadingText={loadingMessage} /> : children}
      </Paper>
    </PreAuthPageBackground>
  );
};

export default PreAuthPage;
