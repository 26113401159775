import { useContext } from 'react';
import { Grid, Container } from '@mui/material';

import { ReceiverAdminOnly } from '../../../components';

import { SupplierContext } from './SupplierContext';
import SupplierSettingsForm from './SupplierSettingsForm';
import SupplierManagement from './SupplierManagement';

function SupplierSettings() {
  const supplierContext = useContext(SupplierContext);

  return (
    <Container maxWidth="lg" id="supplier-settings" disableGutters>
      <Grid item xs={12} sm={6}>
        <SupplierSettingsForm
          supplierDetail={supplierContext.supplier}
          onSupplierUpdated={supplierContext.supplierUpdated}
        />
      </Grid>
      <ReceiverAdminOnly>
        <Grid item xs={12}>
          <SupplierManagement />
        </Grid>
      </ReceiverAdminOnly>
    </Container>
  );
}

export default SupplierSettings;
