import { FC } from 'react';

import { ProjectAvatar, ContentHeader } from '../../../components';
import { AssignmentMetadata, ProjectMetadata, SpecificationMetadata } from '../../../types';

interface SubmissionHeaderProps {
  assignment: AssignmentMetadata;
  project: ProjectMetadata;
  specification: SpecificationMetadata;
}

const SubmissionHeader: FC<SubmissionHeaderProps> = ({ assignment, project, specification }) => {
  const name = `${project.name} - ${specification.name} - ${assignment.reference}`;

  return (
    <ContentHeader
      id="submission-header"
      title={name}
      avatar={<ProjectAvatar project={project} size="large" />}
    />
  );
};

export default SubmissionHeader;
