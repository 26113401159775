import { useContext, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';

import {
  Container,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  useTheme,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';

import { extractErrorMessage } from '../../../api/endpoints';
import * as OneIntegrateServerSettingsApi from '../../../api/oneIntegrateServerSettings';
import * as OneIntegrateServerConnectionApi from '../../../api/oneIntegrateServerConnection';
import {
  PaddedPaper,
  ValidatedTextField,
  ValidatedPasswordField,
  FormButtons,
  DefaultButton,
  MessageBox,
  ConfirmDialog,
  MonospacedContainer,
} from '../../../components';
import {
  AuthenticationType,
  OneIntegrateServerSettings,
  OneIntegrateTestServerResponse,
} from '../../../types';
import { validate, normalizeUri, updateValue } from '../../../util';
import { intl } from '../../../Internationalization';

import { ServiceConfigurationContext } from './ServiceConfigurationContext';
import ServerTestResults from './ServerTestResults';

const OneIntegrateServerSettingsForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const { oneIntegrateServerSettings, oneIntegrateServerSettingsUpdated } = useContext(
    ServiceConfigurationContext
  );

  const [serverSettings, setServerSettings] = useState<OneIntegrateServerSettings>(
    oneIntegrateServerSettings
  );
  const [serverTestResults, setServerTestResults] = useState<OneIntegrateTestServerResponse>();
  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>({});
  const [processing, setProcessing] = useState<boolean>(false);
  const [confirmClearCapabilitiesCache, setConfirmClearCapabilitiesCache] =
    useState<boolean>(false);

  const handleUpdate = updateValue(setServerSettings);

  const handleAuthenticationModeChange = (newType: AuthenticationType) => {
    setServerSettings((prev) => ({
      ...prev,
      authenticationType: newType,
      ...(newType === AuthenticationType.API_KEY
        ? {
            password: oneIntegrateServerSettings.password,
            username: oneIntegrateServerSettings.username,
          }
        : {
            apiKey: oneIntegrateServerSettings.apiKey,
          }),
    }));
  };

  useEffect(() => {
    setServerSettings(oneIntegrateServerSettings);
  }, [oneIntegrateServerSettings]);

  const collectSettings = (): OneIntegrateServerSettings => ({
    ...serverSettings,
    baseUri: normalizeUri(serverSettings.baseUri),
  });

  const validateAndPerform = (
    onValidated: (updatedSettings: OneIntegrateServerSettings) => void
  ) => {
    setFieldErrors({});
    const updatedSettings = collectSettings();
    validate(OneIntegrateServerSettingsApi.ONE_INTEGRATE_SERVER_SETTINGS_VALIDATOR, updatedSettings)
      .then(() => onValidated(updatedSettings))
      .catch((newFieldErrors: ValidateFieldsError) => {
        setProcessing(false);
        setFieldErrors(newFieldErrors);
      });
  };

  const validateAndSaveSettings = () => {
    setProcessing(true);
    validateAndPerform(saveSettings);
  };

  const validateAndTestConnection = () => {
    setProcessing(true);
    validateAndPerform(testConnection);
  };

  const saveSettings = (updatedSettings: OneIntegrateServerSettings) => {
    OneIntegrateServerSettingsApi.updateServerSettings(updatedSettings)
      .then((response) => {
        setProcessing(false);
        oneIntegrateServerSettingsUpdated(response.data);
        enqueueSnackbar(
          intl.formatMessage({
            id: 'serviceConfiguration.oneIntegrate.serverSettings.saveSuccess',
            defaultMessage: '1Integrate server settings updated',
          }),
          { variant: 'success' }
        );
      })
      .catch((error) => {
        setProcessing(false);
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'serviceConfiguration.oneIntegrate.serverSettings.saveError',
              defaultMessage: 'Failed to update 1Integrate server settings',
            })
          ),
          { variant: 'error' }
        );
      });
  };

  const testConnection = (updatedSettings: OneIntegrateServerSettings) => {
    OneIntegrateServerConnectionApi.testServerConnection(updatedSettings)
      .then((response) => {
        setProcessing(false);
        setServerTestResults(response.data);
      })
      .catch((error) => {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'serviceConfiguration.oneIntegrate.serverSettings.testError',
              defaultMessage: 'Failed to connect to 1Integrate server',
            })
          ),
          { variant: 'error' }
        );
        setProcessing(false);
      });
  };

  const clearCapabilitiesCache = () => {
    setConfirmClearCapabilitiesCache(false);
    setProcessing(true);
    OneIntegrateServerConnectionApi.evictCapabilities()
      .then(() => {
        setProcessing(false);
        enqueueSnackbar(
          intl.formatMessage({
            id: 'serviceConfiguration.oneIntegrate.serverSettings.clearSuccess',
            defaultMessage: 'Successfully cleared capabilities cache',
          }),
          { variant: 'success' }
        );
      })
      .catch((error) => {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'serviceConfiguration.oneIntegrate.serverSettings.clearError',
              defaultMessage: 'Failed to clear capabilities cache',
            })
          ),
          { variant: 'error' }
        );
        setProcessing(false);
      });
  };

  const renderTestResults = () => {
    if (!serverTestResults) {
      return null;
    }
    return (
      <>
        <Box mt={2}>
          <ServerTestResults versionStatus={serverTestResults.versionStatus} />
        </Box>
        <MonospacedContainer
          className="OneIntegrateTestServerResponse-versionStatus"
          sx={{
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.getContrastText(theme.palette.primary.dark),
          }}
        >
          <Typography gutterBottom>
            <FormattedMessage
              id="serviceConfiguration.oneIntegrate.1Integrate.version"
              defaultMessage="Detected 1Integrate version: {version}"
              values={{ version: serverTestResults.version }}
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="serviceConfiguration.oneIntegrate.1Integrate.connection.status"
              defaultMessage="Connection test: {result}"
              values={{ result: serverTestResults.connectionStatus }}
            />
          </Typography>
        </MonospacedContainer>
      </>
    );
  };
  return (
    <Container maxWidth="md" disableGutters id="system-one-integrate-server-settings">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <PaddedPaper>
            <Typography variant="h5" gutterBottom>
              <FormattedMessage
                id="serviceConfiguration.oneIntegrate.serverSettings.title"
                defaultMessage="1Integrate Server Settings"
              />
            </Typography>
            <ValidatedTextField
              fieldErrors={fieldErrors}
              disabled={processing}
              name="baseUri"
              label={intl.formatMessage({
                id: 'serviceConfiguration.oneIntegrate.serverSettings.baseUri.label',
                defaultMessage: 'Base URI',
              })}
              value={serverSettings.baseUri}
              onChange={handleUpdate}
              margin="normal"
              variant="outlined"
            />
            <FormControl margin="normal" component="fieldset">
              <FormLabel component="legend">
                <FormattedMessage
                  id="serviceConfiguration.oneIntegrate.serverSettings.authenticationMode.label"
                  defaultMessage="Authentication Mode"
                />
              </FormLabel>
              <RadioGroup
                name="authenticationType"
                value={serverSettings.authenticationType}
                onChange={(_, type) => handleAuthenticationModeChange(type as AuthenticationType)}
                row
              >
                <FormControlLabel
                  key={AuthenticationType.CREDENTIALS}
                  value={AuthenticationType.CREDENTIALS}
                  control={<Radio />}
                  label={intl.formatMessage({
                    id: 'serviceConfiguration.oneIntegrate.serverSettings.authenticationMode.credentials',
                    defaultMessage: 'Username and password',
                  })}
                />
                <FormControlLabel
                  key={AuthenticationType.API_KEY}
                  value={AuthenticationType.API_KEY}
                  control={<Radio />}
                  label={intl.formatMessage({
                    id: 'serviceConfiguration.oneIntegrate.serverSettings.authenticationMode.apiKey',
                    defaultMessage: 'API Key',
                  })}
                />
              </RadioGroup>
            </FormControl>
            {serverSettings.authenticationType === AuthenticationType.CREDENTIALS ? (
              <>
                <ValidatedTextField
                  fieldErrors={fieldErrors}
                  disabled={processing}
                  name="username"
                  label={intl.formatMessage({
                    id: 'serviceConfiguration.oneIntegrate.serverSettings.username.label',
                    defaultMessage: 'Username',
                  })}
                  value={serverSettings.username}
                  onChange={handleUpdate}
                  margin="normal"
                  variant="outlined"
                />
                <ValidatedPasswordField
                  fieldErrors={fieldErrors}
                  disabled={processing}
                  name="password"
                  label={intl.formatMessage({
                    id: 'serviceConfiguration.oneIntegrate.serverSettings.password.label',
                    defaultMessage: 'Password',
                  })}
                  value={serverSettings.password}
                  onChange={handleUpdate}
                  margin="normal"
                  variant="outlined"
                />
              </>
            ) : (
              <ValidatedPasswordField
                fieldErrors={fieldErrors}
                disabled={processing}
                name="apiKey"
                label={intl.formatMessage({
                  id: 'serviceConfiguration.oneIntegrate.serverSettings.apiKey.label',
                  defaultMessage: 'API Key',
                })}
                value={serverSettings.apiKey}
                onChange={handleUpdate}
                margin="normal"
                variant="outlined"
              />
            )}
            <FormButtons>
              <DefaultButton
                name="saveSettings"
                onClick={validateAndSaveSettings}
                disabled={processing}
                startIcon={<SaveIcon />}
              >
                <FormattedMessage
                  id="serviceConfiguration.oneIntegrate.serverSettings.saveButton"
                  defaultMessage="Save Settings"
                />
              </DefaultButton>
              <DefaultButton
                name="testConnection"
                onClick={validateAndTestConnection}
                disabled={processing}
                startIcon={<SettingsInputAntennaIcon />}
                color="secondary"
              >
                <FormattedMessage
                  id="serviceConfiguration.oneIntegrate.serverSettings.testButton"
                  defaultMessage="Test Connection"
                />
              </DefaultButton>
            </FormButtons>
            {renderTestResults()}
          </PaddedPaper>
        </Grid>
        <Grid item xs={12}>
          <PaddedPaper>
            <Typography variant="h5" gutterBottom>
              <FormattedMessage
                id="serviceConfiguration.oneIntegrate.serverSettings.clearCacheTitle"
                defaultMessage="Clear Capabilities Cache"
              />
            </Typography>
            <MessageBox
              message={intl.formatMessage({
                id: 'serviceConfiguration.oneIntegrate.serverSettings.clearCache',
                defaultMessage:
                  'This feature will clear the cache of 1Integrate capabilities, forcing a refresh.',
              })}
              level="info"
            />
            <FormButtons>
              <DefaultButton
                name="clearCache"
                onClick={() => setConfirmClearCapabilitiesCache(true)}
              >
                <FormattedMessage
                  id="serviceConfiguration.oneIntegrate.serverSettings.clearCacheButton"
                  defaultMessage="Clear Cache"
                />
              </DefaultButton>
            </FormButtons>
            <ConfirmDialog
              id="confirm-clear-cache"
              title={intl.formatMessage({
                id: 'serviceConfiguration.oneIntegrate.serverSettings.confirmClearCache.title',
                defaultMessage: 'Clear Capabilities Cache',
              })}
              text={intl.formatMessage({
                id: 'serviceConfiguration.oneIntegrate.serverSettings.confirmClearCache.text',
                defaultMessage:
                  'Are you sure you wish to clear the capabilities cache? Capabilities will be refreshed on next request.',
              })}
              confirmBtnText={intl.formatMessage({
                id: 'serviceConfiguration.oneIntegrate.serverSettings.confirmClearCache.confirmButton',
                defaultMessage: 'Clear Cache',
              })}
              confirmAction={clearCapabilitiesCache}
              closeAction={() => setConfirmClearCapabilitiesCache(false)}
              isOpen={confirmClearCapabilitiesCache}
            />
          </PaddedPaper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OneIntegrateServerSettingsForm;
