import axios, { AxiosError, AxiosPromise, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import Qs from 'qs';

export const API_BASE_URL = '/api/v1/';
export const ACCESS_TOKEN = 'accessToken';

export const generateApiUri = (url: string, config?: AxiosRequestConfig) =>
  generateUri(API_BASE_URL, url, config);

export const generateUri = (baseURL: string, url: string, config?: AxiosRequestConfig) =>
  AXIOS.getUri({
    ...config,
    params: {
      ...config?.params,
      token: localStorage.getItem(ACCESS_TOKEN),
    },
    url: constructUriPath(baseURL, url),
  });

// TODO - Monitor https://github.com/axios/axios/pull/2555/files
//
// The workaround below can be removed once the bug in Axios is fixed

const constructUriPath = (baseURL: string, url: string) => {
  if (!baseURL.endsWith('/')) {
    baseURL += '/';
  }
  if (url.startsWith('/')) {
    url = url.substr(1);
  }
  return baseURL + url;
};

export const AXIOS = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  transformRequest: [
    (data: any, headers?: AxiosRequestHeaders) => {
      if (headers && localStorage.getItem(ACCESS_TOKEN)) {
        headers.Authorization = 'Bearer ' + localStorage.getItem(ACCESS_TOKEN);
      }

      if ((headers && headers['Content-Type'] !== 'application/json') || data instanceof Blob) {
        return data;
      }

      return JSON.stringify(data);
    },
  ],
  paramsSerializer: (params) => {
    return Qs.stringify(params, { allowDots: true });
  },
});

export interface FileUploadConfig extends RequestConfig {
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}

export interface RequestConfig {
  signal?: AbortSignal;
}

/**
 * Extracts an error message from an axios error object if the object contains one.
 *
 * Failing that, the provided default error message is returned.
 */
export const extractErrorMessage = (error: AxiosError, defaultMessage: string) =>
  (error.response && error.response.data ? error.response.data.message : error.message) ||
  defaultMessage;

/**
 * Wraps a request which should return 404 if the input was "unique".
 *
 * Used to validate unique values, for form validation for example.
 */
export async function wrapUniquePromise(existsPromise: AxiosPromise): Promise<boolean> {
  try {
    await existsPromise;
    return false;
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      return true;
    }
    throw error;
  }
}
