import { FC } from 'react';
import { VersionStatus } from '../../../types/serviceConfiguration';
import { SupportedTestResult, UnknownTestResult, UnsupportedTestResult } from './TestResultsHeader';

interface ServerSettingsTestResult {
  versionStatus: VersionStatus;
}

const ServerTestResults: FC<ServerSettingsTestResult> = ({ versionStatus }) => {
  switch (versionStatus) {
    case VersionStatus.SUPPORTED:
      return <SupportedTestResult />;
    case VersionStatus.UNSUPPORTED:
      return <UnsupportedTestResult />;
    case VersionStatus.UNKNOWN:
      return <UnknownTestResult />;
    default:
      return null;
  }
};

export default ServerTestResults;
