import React from 'react';
import {
  ProjectMetadata,
  SpecificationMetadata,
  AssignmentMetadata,
  SubmissionDetail,
} from '../../../types';

export interface SubmissionContextValue {
  submissionUpdated: (submission: SubmissionDetail) => void;
  submission: SubmissionDetail;

  assignment: AssignmentMetadata;
  project: ProjectMetadata;
  specification: SpecificationMetadata;
}

const SubmissionContextDefaultValue = {} as SubmissionContextValue;
export const SubmissionContext = React.createContext(SubmissionContextDefaultValue);
