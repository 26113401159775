import React, { FC, useState } from 'react';

import { Chip, InputAdornment, IconButton, styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import ValidatedTextField, { ValidatedTextFieldProps } from './ValidatedTextField';
import { onEnterCallback } from '../../util';

interface ValidatedChipsArrayFieldProps
  extends Omit<ValidatedTextFieldProps, 'onChange' | 'value'> {
  chips: string[];
  onChange: (chips: string[]) => void;
  endAdornmentAriaLabel: string;
}

const Chiplist = styled('ul')({
  display: 'flex',
  flexWrap: 'wrap',
  listStyle: 'none',
  padding: 0.5,
  margin: 0,
});

const ValidatedChipsArrayField: FC<ValidatedChipsArrayFieldProps> = ({
  label,
  endAdornmentAriaLabel,
  chips,
  onChange,
  ...rest
}) => {
  const [newChip, setNewChip] = useState<string>('');

  const handleDelete = (chipToDelete: string) => () => {
    onChange(chips.filter((chip) => chip !== chipToDelete));
  };

  const handleAddChip = () => {
    if (newChip && !chips.find((chip) => chip === newChip)) {
      const updatedChips = [...chips, newChip];
      onChange(updatedChips);
    }
    setNewChip('');
  };

  const submitOnEnter = onEnterCallback(handleAddChip);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewChip(event.target.value);
  };

  return (
    <div>
      <ValidatedTextField
        label={label}
        onKeyDown={submitOnEnter}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label={endAdornmentAriaLabel} onClick={handleAddChip} size="large">
                <AddIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={handleInputChange}
        value={newChip}
        {...rest}
      />
      <Chiplist>
        {chips.map((chip) => (
          <Chip
            component="li"
            key={chip}
            label={chip}
            onDelete={handleDelete(chip)}
            sx={{ mr: 0.5 }}
          />
        ))}
      </Chiplist>
    </div>
  );
};

export default ValidatedChipsArrayField;
