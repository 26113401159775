import { Grid } from '@mui/material';

import {
  ValuePercentage,
  GroupedDataSet,
  DateCount,
  OffsetDateTimePercentage,
} from '../../../types';
import { VIEW_MODE_METADATA, ViewMode } from '../../../util';

import TopRulesChart from './TopRulesChart';
import SubmissionCountsChart from './SubmissionCountsChart';
import SubmissionQualityChart from './SubmissionQualityChart';

interface SpecificationChartsProps {
  ruleConformance: ValuePercentage[];
  submissionCounts: GroupedDataSet<string, DateCount>[];
  submissionQuality: GroupedDataSet<string, OffsetDateTimePercentage>[];
  viewMode: ViewMode;
}

const SpecificationCharts = ({
  ruleConformance,
  submissionCounts,
  submissionQuality,
  viewMode,
}: SpecificationChartsProps) => {
  const breakPoints = VIEW_MODE_METADATA[viewMode];
  return (
    <Grid container spacing={3}>
      <Grid item {...breakPoints} mt={2}>
        <TopRulesChart viewMode={viewMode} ruleConformance={ruleConformance} />
      </Grid>
      <Grid item {...breakPoints} mt={2}>
        <SubmissionCountsChart viewMode={viewMode} submissionCounts={submissionCounts} />
      </Grid>
      <Grid item {...breakPoints} mt={2}>
        <SubmissionQualityChart viewMode={viewMode} submissionQuality={submissionQuality} />
      </Grid>
    </Grid>
  );
};

export default SpecificationCharts;
