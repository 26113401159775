import { TooltipItem, ChartType } from 'chart.js';
import truncate from 'lodash/truncate';

import { ValuePercentage } from '../types';
import { intl } from '../Internationalization';

interface SimpleChartOptions {
  displayLegend: boolean;
  xTitle?: string;
  yTitle?: string;
  stacked?: boolean;
  maintainAspectRatio: boolean;
}

export const simpleChartOptions = ({
  displayLegend,
  xTitle,
  yTitle,
  stacked,
  maintainAspectRatio,
}: SimpleChartOptions) => ({
  maintainAspectRatio,
  plugins: {
    legend: {
      display: displayLegend,
    },
  },
  scales: {
    x: {
      stacked,
      title: {
        display: true,
        text: xTitle,
      },
    },
    y: {
      stacked,
      title: {
        display: true,
        text: yTitle,
      },
      ticks: {
        autoSkip: true,
        sampleSize: 1,
        stepSize: 1,
      },
      beginAtZero: true,
    },
  },
});

const combineRemainingDatasets = (datasets: ValuePercentage[]): ValuePercentage[] => {
  return [
    datasets.reduce((prev, current) => ({ x: prev.x, y: prev.y + current.y }), {
      x: intl.formatMessage({
        id: 'charts.combinedRemainingDatasets.label',
        defaultMessage: 'Others',
      }),
      y: 0,
    }),
  ];
};

export const extractTopNDatasets = (
  datasets: ValuePercentage[],
  maxDatasets: number
): ValuePercentage[] => {
  const sortedDatasets = [...datasets].sort((a, b) => b.y - a.y);

  if (sortedDatasets.length > maxDatasets) {
    return [
      ...sortedDatasets.slice(0, maxDatasets),
      ...combineRemainingDatasets(sortedDatasets.slice(maxDatasets)),
    ];
  }
  return sortedDatasets;
};

const dedupeAndSort = <T>(values: T[]): T[] => Array.from(new Set(values)).sort();

type LabelledDataSet = { data: { x: string }[] };
export const extractSortedLabels = (datasets: LabelledDataSet[]) =>
  dedupeAndSort(datasets.flatMap((dataset) => dataset.data).map((dataPoint) => dataPoint.x));

export const truncateTitle = (tooltipItem: TooltipItem<ChartType>[], length: number) =>
  truncate(tooltipItem[0].label, {
    length: length,
  });

export const limitLabelValueToTwoDecimals = (
  tooltipItem: TooltipItem<ChartType>
): string | string[] => {
  const roundedValue = Math.round(Number(tooltipItem.formattedValue) * 100) / 100;

  return tooltipItem.dataset.label
    ? `${tooltipItem.dataset.label} : ${roundedValue}`
    : `${roundedValue}`;
};

export type ViewMode = 'GRID' | 'COLUMN';
export const VIEW_MODE_METADATA = {
  GRID: {
    lg: 6,
    xs: 12,
  },
  COLUMN: {
    xs: 12,
  },
};
