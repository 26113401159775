import { AxiosPromise } from 'axios';
import Schema from 'async-validator';

import {
  UsersResponse,
  AssignmentDetail,
  AssignmentPermissionsRequest,
  AssignmentSettings,
} from '../types';
import {
  NOT_BLANK_REGEX,
  duplicateValidator,
  notTrimmableValidator,
  referenceLengthValidator,
} from '../util';
import { intl } from '../Internationalization';

import { AXIOS, RequestConfig } from './endpoints';
import { assignmentByReference } from './assignmentLookup';

export function getAssignment(assignmentKey: string): AxiosPromise<AssignmentDetail> {
  return AXIOS.get(`/assignments/${assignmentKey}`);
}

export function updateAssignment(
  assignmentKey: string,
  request: AssignmentSettings
): AxiosPromise<AssignmentDetail> {
  return AXIOS.post(`/assignments/${assignmentKey}`, request);
}

export function getPermissions(
  assignmentKey: string,
  request: AssignmentPermissionsRequest,
  config?: RequestConfig
): AxiosPromise<UsersResponse> {
  return AXIOS.get(`/assignments/${assignmentKey}/permissions`, {
    ...config,
    params: request,
  });
}

export function addPermissions(
  assignmentKey: string,
  userKey: string
): AxiosPromise<UsersResponse> {
  return AXIOS.put(`/assignments/${assignmentKey}/permissions/${userKey}`);
}

export function removePermissions(
  assignmentKey: string,
  userKey: string
): AxiosPromise<UsersResponse> {
  return AXIOS.delete(`/assignments/${assignmentKey}/permissions/${userKey}`);
}

export function assignmentSettingsValidator(
  getSpecificationKey: () => string,
  getReference?: () => string
) {
  return new Schema({
    reference: [
      {
        required: true,
        pattern: NOT_BLANK_REGEX,
        message: intl.formatMessage({
          id: 'assignment.validator.reference.required',
          defaultMessage: 'Please provide a reference',
        }),
      },
      notTrimmableValidator,
      referenceLengthValidator,
      duplicateValidator({
        regex: NOT_BLANK_REGEX,
        existingValue: getReference,
        checkUnique: (reference: string) => assignmentByReference(getSpecificationKey(), reference),
        alreadyExistsMessage: intl.formatMessage({
          id: 'assignment.validator.reference.unique',
          defaultMessage: 'An assignment with this reference already exists',
        }),
        errorMessage: intl.formatMessage({
          id: 'assignment.validator.reference.checkUniqueError',
          defaultMessage: 'There was a problem verifying the assignment reference',
        }),
      }),
    ],
    supplierKey: {
      required: true,
      message: intl.formatMessage({
        id: 'assignment.validator.supplierKey.required',
        defaultMessage: 'Please choose a supplier',
      }),
    },
  });
}

export const COPY_ASSIGNMENT_VALIDATOR = new Schema({
  sourceSpecificationKey: {
    required: true,
    pattern: NOT_BLANK_REGEX,
    message: intl.formatMessage({
      id: 'assignment.copy.validator.sourceSpecificationKey',
      defaultMessage: 'Please choose a specification',
    }),
  },
});
