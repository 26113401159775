import { Box, Paper, Skeleton } from '@mui/material';

const TextEditorSkeleton = () => (
  <Paper>
    <Box p={1}>
      <Skeleton variant="text" height={50} width={200} />
    </Box>
    <Box p={1}>
      <Skeleton variant="rectangular" height={200} width="100%" />
    </Box>
  </Paper>
);

export default TextEditorSkeleton;
