import React from 'react';
import { LdapServerSettings, LdapUserSettings } from '../../../types';

export interface LdapContextValue {
  settings: LdapServerSettings;
  settingsUpdated: (settings: LdapServerSettings) => void;
  userSettings: LdapUserSettings;
  userSettingsUpdated: (userSettings: LdapUserSettings) => void;
}

const LdapContextDefaultValue = {} as LdapContextValue;
export const LdapContext = React.createContext(LdapContextDefaultValue);
