import { AxiosPromise } from 'axios';
import Schema, { InternalRuleItem, Values } from 'async-validator';

import { AXIOS } from './endpoints';
import { LdapServerSettings } from '../types';
import { ldapUrlValidator } from '../util';
import { intl } from '../Internationalization';

export function getServerSettings(): AxiosPromise<LdapServerSettings> {
  return AXIOS.get(`/ldap/server`);
}

export function updateServerSettings(
  ldapSettings: LdapServerSettings
): AxiosPromise<LdapServerSettings> {
  return AXIOS.post(`/ldap/server`, ldapSettings);
}

export const LDAP_SERVER_SETTINGS_VALIDATOR = new Schema({
  serverUrl: [
    {
      type: 'string',
      required: true,
      message: intl.formatMessage({
        id: 'ldap.validator.serverUrl.required',
        defaultMessage: 'Please provide the server URL',
      }),
    },
    ldapUrlValidator(),
  ],
  baseDn: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'ldap.validator.baseDn.required',
      defaultMessage: 'Please provide the base DN',
    }),
  },
  username: {
    validator(
      rule: InternalRuleItem,
      value: any,
      callback: (error?: string) => void,
      source: Values
    ) {
      if (!source.anonymous && !value) {
        callback(
          intl.formatMessage({
            id: 'ldap.validator.username.conditionalyRequired',
            defaultMessage: 'Username must be provided when request is not anonymous',
          })
        );
      }
      callback();
    },
  },
});
