import Schema, { Rule } from 'async-validator';
import { AxiosPromise } from 'axios';
import { intl } from '../Internationalization';
import {
  AssignmentSavedMappingsRequest,
  SavedMappingDetail,
  SavedMappingResponse,
  SavedMappingSettings,
} from '../types';
import {
  duplicateValidator,
  nameLengthValidator,
  notTrimmableValidator,
  NOT_BLANK_REGEX,
} from '../util';

import { AXIOS, RequestConfig } from './endpoints';
import { savedMappingByName } from './savedMappingLookup';

export function getSavedMappings(
  assignmentKey: string,
  request: AssignmentSavedMappingsRequest,
  config?: RequestConfig
): AxiosPromise<SavedMappingResponse> {
  return AXIOS.get(`/assignments/${assignmentKey}/saved_mappings`, {
    ...config,
    params: request,
  });
}

export function createSavedMapping(
  assignmentKey: string,
  mappings: SavedMappingSettings
): AxiosPromise<SavedMappingDetail> {
  return AXIOS.post(`/assignments/${assignmentKey}/saved_mappings`, mappings);
}

export const createSavedMappingValidator = (getAssignmentKey: () => string): Schema =>
  new Schema({
    name: savedMappingNameValidator(getAssignmentKey),
  });

export const savedMappingNameValidator = (
  getAssignmentKey: () => string,
  getExistingName?: () => string
): Rule => [
  {
    required: true,
    pattern: NOT_BLANK_REGEX,
    message: intl.formatMessage({
      id: 'assignmentSavedMapping.settings.validator.name.required',
      defaultMessage: 'Please provide a name',
    }),
  },
  nameLengthValidator,
  notTrimmableValidator,
  duplicateValidator({
    regex: NOT_BLANK_REGEX,
    existingValue: getExistingName,
    checkUnique: (name: string) => savedMappingByName(getAssignmentKey(), name),
    alreadyExistsMessage: intl.formatMessage({
      id: 'assignmentSavedMapping.settings.validator.name.unique',
      defaultMessage: 'A saved mapping with this name already exists',
    }),
    errorMessage: intl.formatMessage({
      id: 'assignmentSavedMapping.settings.validator.name.checkUniqueError',
      defaultMessage: 'There was a problem verifying the saved mapping name',
    }),
  }),
];
