import { intl } from '../Internationalization';
import { ProjectMetadata } from '.';
import { PageResponse, SimplePageRequest, MediaDetail } from './shared';

export interface SpecificationSettings {
  name: string;
  description: string;
  active: boolean;
  sessionPath?: string;
  allowSourceUnmapped: boolean;
  allowTargetUnmapped: boolean;
  showTargetUnmappedWarning: boolean;
  showInformation: boolean;
  documentation: string;
  timeoutPeriod: string;
  autoClosePeriod: string;
  mediaRetentionPolicy: MediaRetentionPolicy;
  mediaRetentionPeriod: string;
  conformanceMode: ConformanceMode;
}

export interface SpecificationDetail extends SpecificationSettings {
  key: string;
  project: ProjectMetadata;
  showInformation: boolean;
  documents: MediaDetail[];
}

export enum SchemaMappingMode {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
  AUTOMATIC_HIDDEN = 'AUTOMATIC_HIDDEN',
  NONE = 'NONE',
}

export const SCHEMA_MAPPING_MODES = [
  SchemaMappingMode.MANUAL,
  SchemaMappingMode.AUTOMATIC,
  SchemaMappingMode.AUTOMATIC_HIDDEN,
  SchemaMappingMode.NONE,
];

export const SCHEMA_MAPPING_MODE_LABELS: { [type in SchemaMappingMode]: string } = {
  MANUAL: intl.formatMessage({
    id: 'specification.schemaMappingModeMetadata.label.manual',
    defaultMessage: 'Automatic - Editable',
  }),
  AUTOMATIC: intl.formatMessage({
    id: 'specification.schemaMappingModeMetadata.label.automatic',
    defaultMessage: 'Automatic - Show mapping',
  }),
  AUTOMATIC_HIDDEN: intl.formatMessage({
    id: 'specification.schemaMappingModeMetadata.label.automaticHidden',
    defaultMessage: 'Automatic - Hide mapping',
  }),
  NONE: intl.formatMessage({
    id: 'specification.schemaMappingModeMetadata.label.none',
    defaultMessage: 'Use source schema',
  }),
};

export enum ConformanceMode {
  ALL_TASKS = 'ALL_TASKS',
  CHECK_RULES_TASKS = 'CHECK_RULES_TASKS',
  SELECTED_TASKS = 'SELECTED_TASKS',
}

export const conformanceModes = [
  ConformanceMode.ALL_TASKS,
  ConformanceMode.CHECK_RULES_TASKS,
  ConformanceMode.SELECTED_TASKS,
];

export const CONFORMANCE_MODE_LABELS: { [type in ConformanceMode]: string } = {
  ALL_TASKS: intl.formatMessage({
    id: 'specification.conformanceModeLabels.allTasks',
    defaultMessage: 'All Tasks',
  }),
  CHECK_RULES_TASKS: intl.formatMessage({
    id: 'specification.conformanceModeLabels.checkRulesTasks',
    defaultMessage: 'All Check Rules Tasks',
  }),
  SELECTED_TASKS: intl.formatMessage({
    id: 'specification.conformanceModeLabels.selectedTasks',
    defaultMessage: 'Selected Tasks Only',
  }),
};

export enum MediaRetentionPolicy {
  NEVER = 'NEVER',
  PERIOD = 'PERIOD',
  INDEFINITE = 'INDEFINITE',
}

export const mediaRetentionPolicies = [
  MediaRetentionPolicy.NEVER,
  MediaRetentionPolicy.PERIOD,
  MediaRetentionPolicy.INDEFINITE,
];

export const MEDIA_RETENTION_POLICY_LABELS: { [type in MediaRetentionPolicy]: string } = {
  NEVER: intl.formatMessage({
    id: 'specification.mediaRetentionPolicyLabels.never',
    defaultMessage: 'Never retain',
  }),
  PERIOD: intl.formatMessage({
    id: 'specification.mediaRetentionPolicyLabels.period',
    defaultMessage: 'Retain for period',
  }),
  INDEFINITE: intl.formatMessage({
    id: 'specification.mediaRetentionPolicyLabels.forever',
    defaultMessage: 'Retain forever',
  }),
};

export interface SpecificationMetadata {
  key: string;
  name: string;
  project: ProjectMetadata;
}

export interface SpecificationsRequest extends SimplePageRequest {
  filter?: string;
  active?: boolean;
}

export type SpecificationsResponse = PageResponse<SpecificationDetail>;

export enum UploadMode {
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  OPTIONAL = 'OPTIONAL',
  REQUIRED = 'REQUIRED',
}

export const UPLOAD_MODES = [UploadMode.NOT_SUPPORTED, UploadMode.OPTIONAL, UploadMode.REQUIRED];

export interface UploadModeMetadata {
  label: string;
}

export const UPLOAD_MODE_METADATA: { [type in UploadMode]: UploadModeMetadata } = {
  NOT_SUPPORTED: {
    label: intl.formatMessage({
      id: 'specification.uploadModeMetadata.label.notSupported',
      defaultMessage: 'Not Supported',
    }),
  },
  OPTIONAL: {
    label: intl.formatMessage({
      id: 'specification.uploadModeMetadata.label.optional',
      defaultMessage: 'Optional',
    }),
  },
  REQUIRED: {
    label: intl.formatMessage({
      id: 'specification.uploadModeMetadata.label.required',
      defaultMessage: 'Required',
    }),
  },
};

export interface DataStoreConfig {
  path: string;
  uploadMode: UploadMode;
  schemaMappingMode: SchemaMappingMode;
  downloadOutput: boolean;
  downloadModifiedInput: boolean;
  overrideParameters: string[];
}

export interface DataStoreConfigDetail {
  path: string;
  uploadMode: UploadMode;
  schemaMappingMode: SchemaMappingMode;
  downloadOutput: boolean;
  downloadModifiedInput: boolean;
  overrideParameters: DataStoreParameter[];
  outputFilenameTemplate: string;
}

export interface DataStoreParameter {
  name: string;
  type: DataStoreParameterType;
}

export enum DataStoreParameterType {
  STRING = 'STRING',
  PASSWORD = 'PASSWORD',
}

export enum TaskConfigKind {
  ApplyActionMapTask = 'ApplyActionMapTask',
  CheckRulesTask = 'CheckRulesTask',
  ValidateSchemaTask = 'ValidateSchemaTask',
  PauseTask = 'PauseTask',
}

interface TaskConfigBase {
  kind: TaskConfigKind;
  taskIdentifier: string;
}

export interface ConformanceTaskConfigBase extends TaskConfigBase {
  validationTask: boolean;
  passThreshold: number;
}

export interface CheckRulesConfig extends ConformanceTaskConfigBase {
  kind: TaskConfigKind.CheckRulesTask;
}

export interface ApplyActionMapConfig extends ConformanceTaskConfigBase {
  kind: TaskConfigKind.ApplyActionMapTask;
}

export interface ValidateSchemaConfig extends ConformanceTaskConfigBase {
  kind: TaskConfigKind.ValidateSchemaTask;
}

export interface PauseConfig extends TaskConfigBase {
  kind: TaskConfigKind.PauseTask;
  enforcePassThresholds: boolean;
}

export type ConformanceTaskConfig = ApplyActionMapConfig | CheckRulesConfig | ValidateSchemaConfig;
export type TaskConfig = ConformanceTaskConfig | PauseConfig;

export interface SpecificationValidationResult {
  sessionPathValid: boolean;
  dataStoresValid: boolean;
  tasksValid: boolean;
}

export const evaluateSpecificationValidationResult = (
  validationResult: SpecificationValidationResult
) => {
  const { dataStoresValid, sessionPathValid, tasksValid } = validationResult;
  return dataStoresValid && sessionPathValid && tasksValid;
};

export interface CopyAssignmentsRequest {
  sourceSpecificationKey: string;
}
