import {
  SessionSchema,
  DataStoreSchema,
  Schema,
  DataStoreTypeMetadata,
  DATA_STORE_TYPE_METADATA,
  DataStoreSchemaKey,
} from '../types';
import { UxErrorIcon, UxDefaultDataStoreIcon } from '../components/icons';
import { intl } from '../Internationalization';

export const dataStoreNameFromPath = (dataStorePath: string) => {
  return dataStorePath.substr(dataStorePath.lastIndexOf('/') + 1);
};

export const dataStoreSchemaFromPath = (
  sessionSchema: SessionSchema,
  dataStorePath: string
): DataStoreSchema | undefined => {
  return sessionSchema.dataStores.find(({ path }) => path === dataStorePath);
};

export const dataStoreTypeMetaDataFromPath = (
  sessionSchema: SessionSchema,
  dataStorePath?: string,
  dataStoreSchemaKey?: DataStoreSchemaKey
) => {
  if (dataStorePath && dataStoreSchemaKey) {
    const dataStoreSchema = dataStoreSchemaFromPath(sessionSchema, dataStorePath);
    const schema = dataStoreSchema?.[dataStoreSchemaKey];
    if (schema) {
      return metadataForSchema(schema);
    }
  }
  return {
    label: intl.formatMessage({
      id: 'submission.dataStoreType.noSchema',
      defaultMessage: 'No Schema',
    }),
    icon: UxErrorIcon,
  };
};

export const metadataForSchema = (schema: Schema): DataStoreTypeMetadata => {
  const metadata = DATA_STORE_TYPE_METADATA[schema.type.name];

  return metadata
    ? metadata
    : {
        label: schema.type.name,
        icon: UxDefaultDataStoreIcon,
      };
};
