import { FC } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import { Box } from '@mui/material';

import { AppDecorator, AppTabs, RouterTabs, RouterTab } from '../../components';
import { ErrorBlock } from '../../contexts/error-block';
import { intl } from '../../Internationalization';
import { useRouterTab } from '../../hooks';

import Global from './global/Global';
import Specification from './specification/Specification';

const DashboardRouting: FC = () => {
  const { routerTab, resolveTabRoute } = useRouterTab();
  const renderTabs = () => (
    <RouterTabs id="dashboard-tabs" value={routerTab}>
      <RouterTab
        id="global-tab"
        value="global"
        label={intl.formatMessage({
          id: 'dashboard.routing.tabs.global',
          defaultMessage: 'Global',
        })}
        resolveTabRoute={resolveTabRoute}
      />
      <RouterTab
        id="specification-tab"
        value="specification"
        label={intl.formatMessage({
          id: 'dashboard.routing.tabs.specification',
          defaultMessage: 'Specification',
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  return (
    <AppDecorator
      title={intl.formatMessage({
        id: 'dashboard.routing.title',
        defaultMessage: 'Dashboard',
      })}
      renderTabs={renderTabs}
    >
      <AppTabs />
      <ErrorBlock>
        <Box p={3}>
          <Routes>
            <Route path="global" element={<Global />} />
            <Route path="specification" element={<Specification />} />
            <Route path="*" element={<Navigate to="global" replace />} />
          </Routes>
        </Box>
      </ErrorBlock>
    </AppDecorator>
  );
};

export default DashboardRouting;
