import { FC, useContext, useState } from 'react';
import { ValidateFieldsError } from 'async-validator';
import { useSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

import { Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import * as MeApi from '../../api/me';
import { VerifiedPassword } from '../../api/auth';
import { extractErrorMessage } from '../../api/endpoints';
import {
  PaddedPaper,
  ValidatedPasswordField,
  FormButtons,
  DefaultButton,
  PasswordRequirementDetails,
  NoContentPlaceholder,
  MessageBox,
} from '../../components';
import { onEnterCallback, validate } from '../../util';
import { intl } from '../../Internationalization';

import { AuthenticatedContext } from '../../contexts/authentication';
import { ApplicationContext } from '../../contexts/application';

import { remotelyManagedMessage } from './remotelyManagedMessage';

interface VerifiedChangePassword extends VerifiedPassword {
  currentPassword: string;
}

const ChangePassword: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { me } = useContext(AuthenticatedContext);

  const {
    applicationDetails: {
      site: { passwordRequirements },
    },
  } = useContext(ApplicationContext);

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [verifyPassword, setVerifyPassword] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);

  const [fieldErrors, setFieldErrors] = useState<ValidateFieldsError>();

  const validateAndSubmit = async () => {
    try {
      changePassword(
        await validate(MeApi.changePasswordValidator(passwordRequirements), {
          currentPassword,
          password,
          verifyPassword,
        })
      );
    } catch (errors: any) {
      setFieldErrors(errors);
      setProcessing(false);
    }
  };

  const changePassword = async (verifiedPassword: VerifiedChangePassword) => {
    setFieldErrors(undefined);
    try {
      await MeApi.changePassword({
        newPassword: verifiedPassword.password,
        currentPassword: verifiedPassword.currentPassword,
      });
      enqueueSnackbar(
        intl.formatMessage({
          id: 'account.changePassword.saveSuccess',
          defaultMessage: 'Your password has been successfully changed',
        }),
        { variant: 'success' }
      );
      setCurrentPassword('');
      setPassword('');
      setVerifyPassword('');
      setProcessing(false);
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        setFieldErrors({
          currentPassword: [
            {
              field: 'currentPassword',
              message: intl.formatMessage({
                id: 'account.changePassword.invalidPassword',
                defaultMessage: 'Current password is incorrect.',
              }),
            },
          ],
        });
      } else {
        enqueueSnackbar(
          extractErrorMessage(
            error,
            intl.formatMessage({
              id: 'account.changePassword.saveError',
              defaultMessage: 'Failed to change password',
            })
          ),
          { variant: 'error' }
        );
      }
    } finally {
      setProcessing(false);
    }
  };

  const submitOnEnter = onEnterCallback(validateAndSubmit);

  return me.remotelyManaged ? (
    <NoContentPlaceholder
      id="account-change-password-hidden"
      message={remotelyManagedMessage}
      size="medium"
    />
  ) : (
    <PaddedPaper id="account-change-password">
      <Typography variant="h5" gutterBottom>
        <FormattedMessage id="account.changePassword.title" defaultMessage="Change your password" />
      </Typography>
      <MessageBox
        level="info"
        message={intl.formatMessage({
          id: 'account.changePassword.ssoMessage',
          defaultMessage: 'This password does not apply when using SSO',
        })}
      />
      <ValidatedPasswordField
        fieldErrors={fieldErrors}
        disabled={processing}
        name="currentPassword"
        label={intl.formatMessage({
          id: 'account.changePassword.currentPassword.label',
          defaultMessage: 'Current Password',
        })}
        type="password"
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
        margin="normal"
        variant="outlined"
      />
      <ValidatedPasswordField
        fieldErrors={fieldErrors}
        disabled={processing}
        name="password"
        label={intl.formatMessage({
          id: 'account.changePassword.password.label',
          defaultMessage: 'Password',
        })}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        margin="normal"
        variant="outlined"
      />
      <ValidatedPasswordField
        fieldErrors={fieldErrors}
        disabled={processing}
        name="verifyPassword"
        label={intl.formatMessage({
          id: 'account.changePassword.verifyPassword.label',
          defaultMessage: 'Verify Password',
        })}
        type="password"
        value={verifyPassword}
        onChange={(e) => setVerifyPassword(e.target.value)}
        onKeyDown={submitOnEnter}
        margin="normal"
        variant="outlined"
      />
      <PasswordRequirementDetails
        passwordRequirements={passwordRequirements}
        newPasswordValue={password}
      />
      <FormButtons>
        <DefaultButton
          name="changePassword"
          onClick={validateAndSubmit}
          disabled={processing}
          startIcon={<SaveIcon />}
        >
          <FormattedMessage
            id="account.changePassword.saveButton"
            defaultMessage="Change Password"
          />
        </DefaultButton>
      </FormButtons>
    </PaddedPaper>
  );
};

export default ChangePassword;
