import { FC, PropsWithChildren } from 'react';

import { View } from '@react-pdf/renderer';
import { Style, ViewProps } from '@react-pdf/types';

interface PdfSectionProps extends ViewProps, PropsWithChildren {
  gutterBottom?: boolean;
  style?: Style | Style[];
}

const PdfSection: FC<PdfSectionProps> = ({ gutterBottom, style = [], children, ...rest }) => (
  <View
    style={[...(Array.isArray(style) ? style : [style]), { marginBottom: gutterBottom ? 16 : 0 }]}
    {...rest}
  >
    {children}
  </View>
);

export default PdfSection;
