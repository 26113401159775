import { SvgIconProps } from '@mui/material/SvgIcon';

import SuccessIcon from '../components/icons/SuccessIcon';
import WarningIcon from '../components/icons/WarningIcon';
import CancelledIcon from '../components/icons/CancelledIcon';
import DeliveryRunningIcon from '../components/icons/SubmissionRunningIcon';
import { intl } from '../Internationalization';

import { MediaDetail, PageResponse } from '.';

export interface LdapServerSettings {
  serverUrl: string;
  baseDn: string;
  anonymous: boolean;
  username: string;
  password: string;
}

export interface LdapUserSettings {
  additionalDn: string;
  filter: string;
  synchronizationPeriod: string;
  jobRetentionPeriod: string;
  synchronizationEnabled: boolean;
  uid: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface LdapUser {
  uid: string;
  dn: string;
  email: string;
  name: string;
}

export interface LdapUserSyncJobStateMetadata {
  label: string;
  icon: React.ComponentType<SvgIconProps>;
}

export enum LdapUserSyncJobState {
  RUNNING = 'RUNNING',
  COMPLETE = 'COMPLETE',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

export const LDAP_SYNC_METADATA: {
  [type in LdapUserSyncJobState]: LdapUserSyncJobStateMetadata;
} = {
  RUNNING: {
    label: intl.formatMessage({
      id: 'ldap.syncJobStateMetadata.running',
      defaultMessage: 'Running',
    }),
    icon: DeliveryRunningIcon,
  },
  FAILED: {
    label: intl.formatMessage({
      id: 'ldap.syncJobStateMetadata.failed',
      defaultMessage: 'Failed',
    }),
    icon: WarningIcon,
  },
  COMPLETE: {
    label: intl.formatMessage({
      id: 'ldap.syncJobStateMetadata.complete',
      defaultMessage: 'Complete',
    }),
    icon: SuccessIcon,
  },
  CANCELLED: {
    label: intl.formatMessage({
      id: 'ldap.syncJobStateMetadata.cancelled',
      defaultMessage: 'Cancelled',
    }),
    icon: CancelledIcon,
  },
};

export interface LdapUserSyncJobDetail {
  id: number;

  state: LdapUserSyncJobState;
  startedAt: string;
  updatedAt: string;
  finishedAt: string;
  cancelledAt: string;

  added: number;
  updated: number;
  removed: number;
  skipped: number;

  log: MediaDetail;
}

export type LdapUserSyncJobsResponse = PageResponse<LdapUserSyncJobDetail>;
