import { AxiosPromise } from 'axios';
import Schema from 'async-validator';

import { ConfigurableSiteSettings, PasswordRequirements, SiteSettingsDetail } from '../types/site';
import { MediaDetail } from '../types';
import { intl } from '../Internationalization';
import { baseUriValidator, durationValidator, periodValidator } from '../util';

import { AXIOS, FileUploadConfig } from './endpoints';
import { uploadMedia } from './media';

export function getSiteSettings(): AxiosPromise<SiteSettingsDetail> {
  return AXIOS.get(`/site`);
}

export function updateSiteSettings(
  request: ConfigurableSiteSettings
): AxiosPromise<SiteSettingsDetail> {
  return AXIOS.post(`/site`, request);
}

export function updatePasswordRequirements(
  request: PasswordRequirements
): AxiosPromise<SiteSettingsDetail> {
  return AXIOS.post(`/site/password_requirements`, request);
}

export const uploadLogo = (logo: File, config?: FileUploadConfig): AxiosPromise<MediaDetail> =>
  uploadMedia('/site/resources/logo', logo, config);

export function deleteLogo(): AxiosPromise<void> {
  return AXIOS.delete('/site/resources/logo');
}

export const uploadHeroImage = (
  heroImage: File,
  config?: FileUploadConfig
): AxiosPromise<MediaDetail> => uploadMedia('/site/resources/heroImage', heroImage, config);

export function deleteHeroImage(): AxiosPromise<void> {
  return AXIOS.delete('/site/resources/heroImage');
}

export function regenerateAuthenticationSecret(): AxiosPromise {
  return AXIOS.post('/site/regenerate_authentication_secret');
}

export const extractConfigurableSiteSettings = ({
  name,
  rootUrl,
  theme,
  loginTitle,
  authenticationTokenExpiration,
  passwordResetTokenExpiration,
  passwordRequirements,
}: SiteSettingsDetail): ConfigurableSiteSettings => ({
  name,
  rootUrl,
  theme,
  loginTitle,
  authenticationTokenExpiration,
  passwordResetTokenExpiration,
});

export const SITE_SETTINGS_VALIDATOR = new Schema({
  name: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'site.validator.name.required',
      defaultMessage: 'Please provide the site name',
    }),
  },
  rootUrl: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'site.validator.rootUrl.required',
        defaultMessage: 'Please provide a valid URL',
      }),
    },
    baseUriValidator,
  ],
  loginTitle: {
    type: 'string',
    required: true,
    message: intl.formatMessage({
      id: 'site.validator.loginTitle.required',
      defaultMessage: 'Please provide the login title',
    }),
  },
});

export const SECURITY_SETTINGS_VALIDATOR = new Schema({
  authenticationTokenExpiration: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'site.validator.authenticationTokenExpiration.required',
        defaultMessage: 'Please provide an expiration duration for authentication tokens',
      }),
    },
    durationValidator({ minutes: 5 }),
  ],
  passwordResetTokenExpiration: [
    {
      required: true,
      message: intl.formatMessage({
        id: 'site.validator.passwordResetTokenExpiration.required',
        defaultMessage: 'Please provide an expiration duration for password reset tokens',
      }),
    },
    durationValidator({ minutes: 5 }),
  ],
});

export const PASSWORD_REQUIREMENT_VALIDATOR = new Schema({
  minLength: {
    required: true,
    type: 'number',
    min: 0,
    message: intl.formatMessage({
      id: 'site.validator.passwordRequirements.minLength.required',
      defaultMessage: 'Please provide an minimum length',
    }),
  },
  uppercase: {
    required: true,
    type: 'number',
    min: 0,
    message: intl.formatMessage({
      id: 'site.validator.passwordRequirements.uppercase.required',
      defaultMessage: 'Please specify the upper case requirement',
    }),
  },
  lowercase: {
    required: true,
    type: 'number',
    min: 0,
    message: intl.formatMessage({
      id: 'site.validator.passwordRequirements.lowercase.required',
      defaultMessage: 'Please specify the lower case requirement',
    }),
  },
  numbers: {
    required: true,
    type: 'number',
    min: 0,
    message: intl.formatMessage({
      id: 'site.validator.passwordRequirements.numbers.required',
      defaultMessage: 'Please specify the number requirement',
    }),
  },
  specialCharacters: {
    required: true,
    type: 'number',
    min: 0,
    message: intl.formatMessage({
      id: 'site.validator.passwordRequirements.specialCharacters.required',
      defaultMessage: 'Please specify the special characters requirement',
    }),
  },
  reuseGenerations: {
    type: 'number',
    min: 0,
    message: intl.formatMessage({
      id: 'site.validator.passwordRequirements.reuseGenerations.required',
      defaultMessage: 'Reuse generations must be a number',
    }),
  },
  authFailureLockoutThreshold: {
    type: 'number',
    min: 0,
    message: intl.formatMessage({
      id: 'site.validator.passwordRequirements.authFailureLockoutThreshold.required',
      defaultMessage: 'Authentication failure lockout threshold must be a number',
    }),
  },
  changeInterval: periodValidator({ days: 1 }),
});
