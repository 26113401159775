import React, { useRef, useEffect, FC } from 'react';
import { useSnackbar } from 'notistack';
import { debounce } from 'lodash';
import { Box } from '@mui/material';
import { cx, css } from '@emotion/css';

import * as ol from 'ol';
import 'ol/ol.css';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import proj4 from 'proj4';
import Point from 'ol/geom/Point';
import { register } from 'ol/proj/proj4';
import { get as getProjection, Projection } from 'ol/proj';
import { Extent, getCenter } from 'ol/extent';
import { defaults as defaultControls, ZoomToExtent, OverviewMap } from 'ol/control';
import WKT from 'ol/format/WKT';
import { Coordinate } from 'ol/coordinate';

import { extractErrorMessage } from '../../../../../../api/endpoints';
import { AXIOS } from '../../../../../../api/endpoints';
import * as SubmissionApi from '../../../../../../api/submission';

import { intl } from '../../../../../../Internationalization';
import { ObjectReport, TaskMapConfig } from '../../../../../../types';
import CompassIcon from '../../../../../../assets/uxwing-icon-svgs/compass.svg';

import { generateSearchCoordinates, parseExtent, createGMLParser } from './utils';
import {
  SELECTED_SHAPE_STYLE,
  SELECTED_POINT_STYLE,
  HOVER_SHAPE_STYLE,
  HOVER_POINT_STYLE,
  HOTSPOT_SHAPE_STYLE,
  HOTSPOT_POINT_STYLE,
} from './mapStyles';
import { ObjectFeature, LayerSelections } from './MapContainer';

const RESIZE_DELAY = 100;

const WKT_FORMAT = new WKT();
const DOM_PARSER = new DOMParser();

interface MapComponentProps {
  objectReport?: ObjectReport;
  zoomExtent?: Extent;
  submissionReference: string;
  currentTaskIdentifier: string;
  layers: LayerSelections;
  taskMapConfig: TaskMapConfig;
  hoveredFeature?: ObjectFeature;
  selectedFeature?: ObjectFeature;
  setSelectedFeature: React.Dispatch<ObjectFeature | undefined>;
  setFeatureList: React.Dispatch<React.SetStateAction<ObjectFeature[]>>;
  containerWidth?: number;
}

interface MapInstance {
  map: ol.Map;
  wmsSource: TileWMS;
  selectedFeatureSource: VectorSource;
  hoverFeatureSource: VectorSource;
  hotspotFeatureSource: VectorSource;
  srid: string;
  zoomToExtent: (zoomTo?: Extent) => void;
}

const layerSelectionsToParam = (layerSelections: LayerSelections) =>
  Object.keys(layerSelections)
    .filter((layer) => layerSelections[layer])
    .join(',');

const calculateResolution = (extent: Extent, projection: Projection) => {
  const view = new ol.View({ projection });
  view.setCenter(getCenter(extent));
  view.fit(extent);
  return view.getResolution();
};

const MapComponent: FC<MapComponentProps> = ({
  submissionReference,
  currentTaskIdentifier,
  taskMapConfig,
  layers: selectedLayers,
  selectedFeature,
  setSelectedFeature,
  hoveredFeature,
  objectReport,
  zoomExtent,
  setFeatureList,
  containerWidth,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const mapDiv = useRef<HTMLDivElement>(null);

  const instance = useRef<MapInstance>(
    (({ srid, sridWkt, minX, maxX, minY, maxY }) => {
      proj4.defs([[srid, sridWkt]]);
      register(proj4);

      const projection = getProjection(srid);
      const extent: Extent = [minX, minY, maxX, maxY];

      const wmsSource = new TileWMS({
        tileLoadFunction: SubmissionApi.OLTileLoader,
        url: SubmissionApi.proxyWmsMap(submissionReference, currentTaskIdentifier),
        params: {
          LAYERS: layerSelectionsToParam(selectedLayers),
          TILED: true,
          SRS: taskMapConfig.srid,
          VERSION: '1.1.0',
        },
        serverType: 'geoserver',
      });

      const selectedFeatureSource = new VectorSource();
      const hoverFeatureSource = new VectorSource();
      const hotspotFeatureSource = new VectorSource();

      const map = new ol.Map({
        controls: defaultControls().extend([
          new OverviewMap({
            layers: [
              new TileLayer({
                source: wmsSource,
              }),
            ],
          }),
          new ZoomToExtent({
            label: '',
            className: cx(
              'ol-zoom-extent',
              css({
                '& button': {
                  backgroundImage: `url(${CompassIcon})`,
                  backgroundSize: '16px, 16px',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                },
              })
            ),
            tipLabel: intl.formatMessage({
              id: 'openSubmission.map.mapComponent.recenterMap.label',
              defaultMessage: 'Recenter Map',
            }),
            extent,
          }),
        ]),
        layers: [
          new TileLayer({
            source: wmsSource,
          }),
          new VectorLayer({
            source: selectedFeatureSource,
            style: (feature) => {
              if (feature.getGeometry() instanceof Point) {
                return SELECTED_POINT_STYLE;
              }
              return SELECTED_SHAPE_STYLE;
            },
          }),
          new VectorLayer({
            source: hoverFeatureSource,
            style: (feature) => {
              if (feature.getGeometry() instanceof Point) {
                return HOVER_POINT_STYLE;
              }
              return HOVER_SHAPE_STYLE;
            },
          }),
          new VectorLayer({
            source: hotspotFeatureSource,
            style: (feature) => {
              if (feature.getGeometry() instanceof Point) {
                return HOTSPOT_POINT_STYLE;
              }
              return HOTSPOT_SHAPE_STYLE;
            },
          }),
        ],
        view: new ol.View(projection ? { projection } : undefined),
      });

      const zoomToExtent = (zoomTo: Extent = extent) => {
        const mapView = map.getView();
        mapView?.setCenter(getCenter(zoomTo));
        mapView?.fit(zoomTo, { padding: [10, 10, 10, 10] });
      };

      return {
        map,
        wmsSource,
        selectedFeatureSource,
        hoverFeatureSource,
        hotspotFeatureSource,
        srid,
        zoomToExtent,
      };
    })(taskMapConfig)
  );

  const debouncedResize = debounce(() => instance.current.map.updateSize(), RESIZE_DELAY);

  useEffect(() => {
    debouncedResize();
  }, [containerWidth, debouncedResize]);

  useEffect(() => {
    const { map, zoomToExtent } = instance.current;
    if (mapDiv.current) {
      map.setTarget(mapDiv.current || undefined);
      zoomToExtent();
      return () => map.setTarget(undefined);
    }
  }, []);

  useEffect(() => {
    const handleMapClick = (evt: ol.MapBrowserEvent<UIEvent>) => {
      const { map, wmsSource, selectedFeatureSource, hotspotFeatureSource, srid } =
        instance.current;
      const view = map.getView();
      if (!view) {
        return;
      }
      const viewResolution = view.getResolution();
      if (!viewResolution) {
        return;
      }
      const projectionCode = view.getProjection().getCode();
      const featureInfoUrl = wmsSource.getFeatureInfoUrl(
        evt.coordinate,
        viewResolution,
        projectionCode,
        {
          INFO_FORMAT: 'application/vnd.ogc.gml',
          FEATURE_COUNT: 8,
        }
      );

      if (!featureInfoUrl) {
        return;
      }

      // clear the currently plotted data we intend to replace it
      setSelectedFeature(undefined);
      selectedFeatureSource.clear();
      hotspotFeatureSource.clear();

      const findFeatureList = async () => {
        try {
          const response = await AXIOS.get(featureInfoUrl);
          const document = DOM_PARSER.parseFromString(response.data, 'application/xml');
          const features = Object.keys(selectedLayers).reduce(
            (value: ObjectFeature[], next: string) => {
              const allFeatures = createGMLParser(next, srid).readFeatures(
                document
              ) as ObjectFeature[];
              allFeatures.forEach((feature) => {
                feature.layer = next;
                value.push(feature);
              });
              return value;
            },
            []
          );
          setFeatureList(features);
        } catch (error: any) {
          setFeatureList([]);
          enqueueSnackbar(
            extractErrorMessage(
              error,
              intl.formatMessage({
                id: 'openSubmission.map.mapComponent.loadFeatureError',
                defaultMessage: 'Failed to fetch features',
              })
            ),
            { variant: 'error' }
          );
        }
      };

      findFeatureList();
    };

    instance.current.map.on('singleclick', handleMapClick);
  }, [enqueueSnackbar, selectedLayers, setFeatureList, setSelectedFeature]);

  useEffect(() => {
    const { wmsSource, srid } = instance.current;
    wmsSource.updateParams({
      LAYERS: layerSelectionsToParam(selectedLayers),
      TILED: true,
      SRS: srid,
    });
  }, [selectedLayers]);

  useEffect(() => {
    instance.current.wmsSource.setUrl(
      SubmissionApi.proxyWmsMap(submissionReference, currentTaskIdentifier)
    );
  }, [submissionReference, currentTaskIdentifier]);

  useEffect(() => {
    const { selectedFeatureSource } = instance.current;
    selectedFeatureSource.clear();
    if (selectedFeature) {
      selectedFeatureSource.addFeature(selectedFeature);
    }
  }, [selectedFeature]);

  useEffect(() => {
    const { hoverFeatureSource } = instance.current;
    hoverFeatureSource.clear();
    if (hoveredFeature) {
      hoverFeatureSource.addFeature(hoveredFeature);
    }
  }, [hoveredFeature]);

  useEffect(() => {
    const { srid, selectedFeatureSource, hotspotFeatureSource } = instance.current;

    const createSearchUrlFactory = (currentObjectReport: ObjectReport, extent: Extent) => {
      // wms source with only the layer we are interested in
      const newWmsSource = new TileWMS({
        tileLoadFunction: SubmissionApi.OLTileLoader,
        url: SubmissionApi.proxyWmsMap(submissionReference, currentTaskIdentifier),
        params: { LAYERS: currentObjectReport.className, TILED: true, SRS: srid, VERSION: '1.1.0' },
        serverType: 'geoserver',
      });

      // a view to use to find the resolution of the requests
      const projection = instance.current.map.getView().getProjection();
      if (!projection) {
        return;
      }
      const resolution = calculateResolution(extent, projection);
      if (!resolution) {
        return;
      }

      // return the URL factory
      return (searchCoordinate: Coordinate) =>
        newWmsSource.getFeatureInfoUrl(searchCoordinate, resolution, projection.getCode(), {
          INFO_FORMAT: 'application/vnd.ogc.gml',
          FEATURE_COUNT: 50,
        });
    };

    const findObjectFeature = async (currentObjectReport: ObjectReport, extent: Extent) => {
      const searchCoordinates = generateSearchCoordinates(extent);
      const searchUrlFactory = createSearchUrlFactory(currentObjectReport, extent);
      if (!searchUrlFactory) {
        return;
      }
      for (var i = 0; i < searchCoordinates.length; i++) {
        try {
          const response = await AXIOS.get(searchUrlFactory(searchCoordinates[i]) || '');
          const document = DOM_PARSER.parseFromString(response.data, 'application/xml');
          const allFeatures = createGMLParser(currentObjectReport.className, srid).readFeatures(
            document
          ) as ObjectFeature[];
          const feature = allFeatures.find((f) => f.getId() === currentObjectReport.gothicId);
          if (feature) {
            feature.layer = currentObjectReport.className;
            setFeatureList([feature]);
            return;
          }
        } catch (error: any) {
          enqueueSnackbar(
            extractErrorMessage(
              error,
              intl.formatMessage({
                id: 'openSubmission.map.mapComponent.findFeatureError',
                defaultMessage: 'Failed to find report feature',
              })
            ),
            { variant: 'error' }
          );
        }
      }
      enqueueSnackbar(
        intl.formatMessage({
          id: 'openSubmission.map.mapComponent.cantLocateFeature',
          defaultMessage: 'Could not locate feature using WMS service',
        }),
        { variant: 'error' }
      );
    };

    const plotHotspots = (currentObjectReport: ObjectReport) => {
      const nonConformances = currentObjectReport.nonconformances || [];
      nonConformances.forEach((nonConformance) => {
        const spatialHotspots = nonConformance.ruleHotspot.spatialHotspots || [];
        spatialHotspots.forEach((spatialHotspot) => {
          const hotspotWKT = spatialHotspot.geometryWkt;
          if (hotspotWKT) {
            hotspotFeatureSource.addFeature(WKT_FORMAT.readFeature(hotspotWKT));
          }
        });
      });
    };

    const handleObjectReportChange = (currentObjectReport?: ObjectReport) => {
      hotspotFeatureSource.clear();
      selectedFeatureSource.clear();
      if (currentObjectReport) {
        plotHotspots(currentObjectReport);
        const extent = parseExtent(currentObjectReport.attributes?.geometry);
        if (extent) {
          findObjectFeature(currentObjectReport, extent);
        }
      }
    };

    handleObjectReportChange(objectReport);
  }, [enqueueSnackbar, setFeatureList, objectReport, submissionReference, currentTaskIdentifier]);

  useEffect(() => {
    if (zoomExtent) {
      instance.current.zoomToExtent(zoomExtent);
    }
  }, [zoomExtent]);

  return <Box width="100%" height="100%" ref={mapDiv}></Box>;
};

export default MapComponent;
