import { FC } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { Box } from '@mui/material';
import DnsIcon from '@mui/icons-material/Dns';

import { AppDecorator, AppTabs, RouterTabs, RouterTab } from '../../../components';
import { intl } from '../../../Internationalization';
import { useRouterTab } from '../../../hooks';

import SystemHeader from '../SystemHeader';
import OneIntegrateServerSettingsForm from './OneIntegrateServerSettingsForm';

const ServiceConfigurationRouting: FC = () => {
  const { routerTab, resolveTabRoute } = useRouterTab();

  const renderTabs = () => (
    <RouterTabs id="one-integrate-tabs" value={routerTab}>
      <RouterTab
        id="one-integrate-server-settings-tab"
        value="one_integrate_server_settings"
        label={intl.formatMessage({
          id: 'serviceConfiguration.routing.tabs.oneIntegrateServerSettings',
          defaultMessage: '1Integrate Server Settings',
        })}
        resolveTabRoute={resolveTabRoute}
      />
    </RouterTabs>
  );

  return (
    <AppDecorator renderTabs={renderTabs}>
      <SystemHeader
        title={intl.formatMessage({
          id: 'serviceConfiguration.routing.title',
          defaultMessage: 'Service Configuration',
        })}
        icon={DnsIcon}
      />
      <AppTabs />
      <Box p={3}>
        <Routes>
          <Route
            path="one_integrate_server_settings"
            element={<OneIntegrateServerSettingsForm />}
          />
          <Route path="*" element={<Navigate to="one_integrate_server_settings" replace />} />
        </Routes>
      </Box>
    </AppDecorator>
  );
};

export default ServiceConfigurationRouting;
