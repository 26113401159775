import { FC, useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import {
  Container,
  Typography,
  Box,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Grid,
  styled,
} from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { extractErrorMessage } from '../../../api/endpoints';
import * as ProjectImportApi from '../../../api/projectImport';
import {
  PaddedPaper,
  TableLoadingRow,
  TableErrorRow,
  TableInfoRow,
  TablePreviewRow,
  FullWidthButton,
} from '../../../components';
import { ExportItemMetadata, ExportMetadata, ProjectImportJobDetail } from '../../../types';

import { intl } from '../../../Internationalization';
interface ImportDetail {
  key: string;
  name: string;
  exportPath: string;
}

interface AssignmentImportDetail extends ImportDetail {
  supplier: ExportItemMetadata;
}
interface SpecificationsImportDetail extends ImportDetail {
  assignments: AssignmentImportDetail[];
}

interface OrganisedImport extends ImportDetail {
  specifications: SpecificationsImportDetail[];
}

const organiseImportPreview = (projectPreview: ExportMetadata) => {
  return Object.entries(projectPreview.inverseDependencies)
    .filter(([key]) => key.startsWith('projects/'))
    .map(([projectKey, specificationKeys]) => ({
      ...projectPreview.entities[projectKey],
      specifications: specificationKeys
        .filter((entity) => entity.startsWith('specifications/'))
        .map((specificationKey) => {
          const specificationDetails = projectPreview.entities[specificationKey];
          return {
            ...specificationDetails,
            assignments: projectPreview.inverseDependencies[specificationKey].map(
              (assignmentKey) => {
                const assignment = projectPreview.entities[assignmentKey];
                return {
                  ...assignment,
                  supplier:
                    projectPreview.entities[
                      projectPreview.inverseDependencies[assignment.exportPath].filter(
                        (entityPath) => entityPath.startsWith('suppliers/')
                      )[0]
                    ],
                };
              }
            ),
          };
        }),
    }));
};

const NoBorderTableCell = styled(TableCell)({
  borderBottom: 'none',
});

interface PreviewUploadProps {
  jobDetail: ProjectImportJobDetail;
  clearJob: () => void;
}

const PreviewUpload: FC<PreviewUploadProps> = ({ jobDetail, clearJob }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>();
  const [processing, setProcessing] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [projectPreview, setProjectPreview] = useState<OrganisedImport[]>();

  const disableImport =
    loading || !!errorMessage || (projectPreview && !Object.keys(projectPreview).length);

  const fetchProjectPreview = useCallback(async () => {
    try {
      setLoading(true);
      const response = await ProjectImportApi.previewJob(jobDetail.id);
      setProjectPreview(organiseImportPreview(response.data));
    } catch (error: any) {
      setProcessing(false);
      setProjectPreview(undefined);
      if (error.response.status === 400) {
        const extractedErrorMessage = extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'projectImport.preview.loadInvalidFile',
            defaultMessage: 'Uploaded file is not a valid backup',
          })
        );
        enqueueSnackbar(extractedErrorMessage, { variant: 'error' });
        setErrorMessage(extractedErrorMessage);
      } else {
        const extractedErrorMessage = extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'projectImport.preview.loadError',
            defaultMessage: 'Failed to fetch preview',
          })
        );
        enqueueSnackbar(extractedErrorMessage, { variant: 'error' });
        setErrorMessage(extractedErrorMessage);
      }
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, jobDetail.id]);

  useEffect(() => {
    fetchProjectPreview();
  }, [fetchProjectPreview]);

  const startImport = async () => {
    try {
      setProcessing(true);
      await ProjectImportApi.startJob(jobDetail.id);
      enqueueSnackbar(
        intl.formatMessage({
          id: 'projectImport.preview.importSuccess',
          defaultMessage: 'Project import job started successfully',
        }),
        { variant: 'success' }
      );
      navigate(`/system/project_import/import_history`);
    } catch (error: any) {
      setProcessing(false);
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'projectImport.preview.importError',
            defaultMessage: 'Failed to import projects',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const cancelImport = async () => {
    try {
      await ProjectImportApi.cancelJob(jobDetail.id).then(() => {
        clearJob();
        enqueueSnackbar(
          intl.formatMessage({
            id: 'projectImport.preview.uploadCancelSuccess',
            defaultMessage: 'Upload cancelled',
          }),
          { variant: 'success' }
        );
      });
    } catch (error: any) {
      enqueueSnackbar(
        extractErrorMessage(
          error,
          intl.formatMessage({
            id: 'projectImport.preview.uploadCancelError',
            defaultMessage: 'Failed to cancel upload',
          })
        ),
        { variant: 'error' }
      );
    }
  };

  const renderTableRows = () => {
    if (loading) {
      return <TableLoadingRow colSpan={5} />;
    }

    if (errorMessage) {
      return <TableErrorRow colSpan={5} errorMessage={errorMessage} />;
    }

    if (!projectPreview) {
      return (
        <TablePreviewRow
          colSpan={5}
          fetchData={fetchProjectPreview}
          message={intl.formatMessage({
            id: 'projectImport.preview.noUpload',
            defaultMessage: 'You may use this panel to verify your projects upload',
          })}
        />
      );
    }

    if (!Object.keys(projectPreview).length) {
      return (
        <TableInfoRow
          colSpan={5}
          size="medium"
          message={intl.formatMessage({
            id: 'projectImport.preview.noProjects',
            defaultMessage: 'No Projects found in the Zip file provided',
          })}
        />
      );
    }

    return Object.values(projectPreview).map((project) => {
      return (
        <>
          <TableRow>
            <TableCell>
              {project.key} - {project.name}
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
          {project.specifications.map((specification) => (
            <>
              <TableRow>
                <NoBorderTableCell />
                <TableCell>
                  {specification.key} - {specification.name}
                </TableCell>
                <TableCell />
                <TableCell />
              </TableRow>
              {specification.assignments.map((assignment) => (
                <TableRow>
                  <NoBorderTableCell />
                  <NoBorderTableCell />
                  <TableCell>
                    {assignment.key} - {assignment.name}
                  </TableCell>
                  <TableCell>
                    {assignment.supplier.key} - {assignment.supplier.name}
                  </TableCell>
                </TableRow>
              ))}
            </>
          ))}
        </>
      );
    });
  };

  return (
    <Container maxWidth="lg" id="system-project-import-preview-upload" disableGutters>
      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <PaddedPaper>
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="h5">
                <FormattedMessage
                  id="projectImport.preview.title"
                  defaultMessage="Preview Projects"
                />
              </Typography>
            </Box>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage
                      id="projectImport.preview.projectsColumn"
                      defaultMessage="Projects"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="projectImport.preview.specificationsColumn"
                      defaultMessage="Specifications"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="projectImport.preview.assignmentsColumn"
                      defaultMessage="Assignments"
                    />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage
                      id="projectImport.preview.suppliersColumn"
                      defaultMessage="Supplier"
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderTableRows()}</TableBody>
            </Table>
          </PaddedPaper>
        </Grid>
        <Grid item xs={6}>
          <FullWidthButton
            id="cancel-import"
            label={intl.formatMessage({
              id: 'projectImport.preview.cancelButton',
              defaultMessage: 'Cancel',
            })}
            color="secondary"
            processing={processing}
            startIcon={<CancelIcon />}
            onClick={cancelImport}
          />
        </Grid>
        <Grid item xs={6}>
          <FullWidthButton
            id="import"
            label={intl.formatMessage({
              id: 'projectImport.preview.importButton',
              defaultMessage: 'Import',
            })}
            color="primary"
            processing={processing}
            disabled={disableImport}
            startIcon={<CloudUploadIcon />}
            onClick={startImport}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PreviewUpload;
