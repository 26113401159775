import { FC } from 'react';
import { MessageBox } from '../../../components';
import { intl } from '../../../Internationalization';

export const SupportedTestResult: FC = () => (
  <MessageBox
    level="success"
    message={intl.formatMessage({
      id: 'serviceConfiguration.oneIntegrate.serverSettings.supportedVersion.message',
      defaultMessage: 'Supported version of 1Integrate found',
    })}
  />
);

export const UnsupportedTestResult: FC = () => (
  <MessageBox
    level="error"
    message={intl.formatMessage({
      id: 'serviceConfiguration.oneIntegrate.serverSettings.unsupportedVersion.message',
      defaultMessage:
        'This version of 1Integrate is unsupported with this version of 1Data Gateway',
    })}
  />
);

export const UnknownTestResult: FC = () => (
  <MessageBox
    level="warning"
    message={intl.formatMessage({
      id: 'serviceConfiguration.oneIntegrate.serverSettings.unknownVersion.message',
      defaultMessage:
        'This version of 1Integrate has not been tested against this version of 1Data Gateway',
    })}
  />
);
