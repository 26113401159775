import { Grid } from '@mui/material';

import { ImageCardSkeleton } from './';

const BrowseImageCardsSkeleton = () => (
  <Grid container alignContent="stretch" justifyContent="flex-start" spacing={3}>
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      <ImageCardSkeleton />
    </Grid>
    <Grid item xs={12} sm={6} lg={4} xl={3}>
      <ImageCardSkeleton />
    </Grid>
  </Grid>
);

export default BrowseImageCardsSkeleton;
