import React, { FC, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Box,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import { ConfirmDialog, MinWidthTableCell } from '../../../components';
import { intl } from '../../../Internationalization';
import {
  DataStoreClassMapping,
  DataStoreClassSchema,
  MappedAttributes,
  SchemaMappingMode,
} from '../../../types';

import { SchemaMappingContext } from './SchemaMappingContext';
import { findClassSchema, generateNaturalMapping } from './schemaMappingUtils';
import { SCHEMA_MAPPING_DIRECTION_METADATA } from './schemaMappingMetadata';

import MappingStatusIcon from './MappingStatusIcon';
import DataStoreClassAttributes from './DataStoreClassAttributes';

interface DataStoreClassProps {
  schemaMappingMode: SchemaMappingMode;
  leftClass: DataStoreClassSchema;
  classMappings: DataStoreClassMapping[];
  sourceClasses: DataStoreClassSchema[];
  targetClasses: DataStoreClassSchema[];

  classAttributesOpen: boolean;
  handleOpenClassAttribute: (className: string) => void;
  onClassChange: () => void;
}

const DataStoreClass: FC<DataStoreClassProps> = ({
  schemaMappingMode,
  leftClass,
  classMappings,
  sourceClasses,
  targetClasses,
  classAttributesOpen,
  handleOpenClassAttribute,
  onClassChange,
}) => {
  const { updateClassMappings, validationResults, selectedDataStoreName, direction } =
    useContext(SchemaMappingContext);

  const [resetDialogOpen, setResetDialogOpen] = useState<boolean>(false);
  const [sourceClassConfirm, setSourceClassConfirm] = useState<string>();

  const {
    extractLeftValidationResults,
    findClassMappingForLeft,
    findClassMappingForRight,
    selectLeftOrSource,
    selectRightOrTarget,
  } = SCHEMA_MAPPING_DIRECTION_METADATA[direction];

  const leftClasses = selectLeftOrSource(sourceClasses, targetClasses);
  const rightClasses = selectRightOrTarget(sourceClasses, targetClasses);
  const leftMappingResults =
    extractLeftValidationResults(validationResults).dataStoreValidationResults[
      selectedDataStoreName
    ].classValidationResults[leftClass.name];

  const classMapping = findClassMappingForLeft(leftClass.name, classMappings);
  const rightClassName = selectRightOrTarget(classMapping?.source, classMapping?.target);
  const rightClass = rightClassName ? findClassSchema(rightClassName, rightClasses) : undefined;

  const handleClassSelected = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newRightClassName = event.target.value;
    const currentRightClassName =
      classMapping && selectRightOrTarget(classMapping.source, classMapping.target);
    if (currentRightClassName !== newRightClassName) {
      if (findClassMappingForRight(newRightClassName, classMappings)) {
        setSourceClassConfirm(newRightClassName);
      } else {
        updateSelectedClass(newRightClassName);
        onClassChange();
      }
    }
  };

  const updateSelectedClass = (newRightClassName: string) => {
    const leftClassSchema = findClassSchema(leftClass.name, leftClasses)!;
    const rightClassSchema = findClassSchema(newRightClassName, rightClasses)!;
    updateClassMapping({
      source: selectLeftOrSource(leftClass.name, newRightClassName),
      target: selectRightOrTarget(leftClass.name, newRightClassName),
      attributes: generateNaturalMapping(leftClassSchema, rightClassSchema),
    });
  };

  const updateClassMapping = (newClassMapping: DataStoreClassMapping) => {
    const updatedClassMappings = classMappings.filter(
      ({ source, target }) => target !== newClassMapping.target && source !== newClassMapping.source
    );
    updatedClassMappings.push(newClassMapping);
    updateClassMappings(updatedClassMappings);
  };

  const handleClassChangeDialogConfirm = (newRightClassName: string) => {
    updateSelectedClass(newRightClassName);
    setSourceClassConfirm(undefined);
    onClassChange();
  };

  const handleResetDialogConfirm = () => {
    updateClassMappings(
      classMappings.filter(
        (mapping) => selectLeftOrSource(mapping.source, mapping.target) !== leftClass.name
      )
    );
    setResetDialogOpen(false);
    onClassChange();
  };

  const handleAttributesUpdated = (attributes: MappedAttributes) => {
    if (classMapping) {
      updateClassMapping({ ...classMapping, attributes });
    }
  };

  const renderRightClassIcon = (className: string) => {
    const mapping = findClassMappingForRight(className, classMappings);

    if (!mapping) {
      return (
        <Tooltip
          describeChild
          title={intl.formatMessage({
            id: 'schemaMapper.dataStoreClasses.missingMapping',
            defaultMessage: 'Missing mapping',
          })}
        >
          <HelpIcon sx={{ mr: 1, color: 'default.main' }} fontSize="small" />
        </Tooltip>
      );
    }

    return (
      <Tooltip
        describeChild
        title={intl.formatMessage({
          id: 'schemaMapper.dataStoreClasses.classMapped',
          defaultMessage: 'Class mapped',
        })}
      >
        <CheckCircleIcon sx={{ mr: 1, color: 'success.main' }} fontSize="small" />
      </Tooltip>
    );
  };

  return (
    <>
      <TableRow className="DataStoreClass-tableRow">
        <MinWidthTableCell>
          <IconButton
            size="small"
            onClick={() => handleOpenClassAttribute(leftClass.name)}
            disabled={!rightClass}
            aria-label={
              classAttributesOpen && rightClass
                ? intl.formatMessage({
                    id: 'schemaMapper.dataStoreClasses.closeClass.ariaLabel',
                    defaultMessage: 'Close class attribute mappings',
                  })
                : intl.formatMessage({
                    id: 'schemaMapper.dataStoreClasses.openClassMappings.ariaLabel',
                    defaultMessage: 'Open class attribute mappings',
                  })
            }
          >
            {classAttributesOpen && rightClass ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
        </MinWidthTableCell>
        <MinWidthTableCell align="center">
          <Box display="flex" alignItems="center" justifyContent="center">
            <MappingStatusIcon status={leftMappingResults.status} iconSize="large" />
          </Box>
        </MinWidthTableCell>
        <TableCell>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {leftClass.name}
            <ArrowRightAltIcon sx={{ mr: 1.25 }} />
          </Typography>
        </TableCell>
        <TableCell>
          {schemaMappingMode === SchemaMappingMode.MANUAL ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <TextField
                select
                fullWidth
                sx={{
                  '& .MuiInput-input svg': {
                    display: 'none',
                  },
                }}
                value={rightClassName || ''}
                onChange={(event) => handleClassSelected(event)}
                variant="standard"
              >
                {rightClasses.length ? (
                  rightClasses.map(({ name }) => (
                    <MenuItem key={name} value={name}>
                      {renderRightClassIcon(name)} {name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value="">
                    <FormattedMessage
                      id="schemaMapper.dataStoreClasses.classSelect.noneFound"
                      defaultMessage="No classes found"
                    />
                  </MenuItem>
                )}
                ;
              </TextField>
              <IconButton
                size="small"
                disabled={!classMapping}
                aria-label={intl.formatMessage({
                  id: 'schemaMapper.dataStoreClasses.deleteMapping.ariaLabel',
                  defaultMessage: 'Delete class mapping',
                })}
                sx={{ ml: 1 }}
                onClick={() => setResetDialogOpen(true)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          ) : (
            <Typography sx={{ alignItems: 'center' }}>{rightClassName}</Typography>
          )}
        </TableCell>
      </TableRow>
      {classMapping && rightClass && (
        <DataStoreClassAttributes
          schemaMappingMode={schemaMappingMode}
          classAttributesOpen={classAttributesOpen}
          sourceClass={selectLeftOrSource(leftClass, rightClass)}
          targetClass={selectRightOrTarget(leftClass, rightClass)}
          mappedAttributes={classMapping.attributes}
          onAttributesUpdated={handleAttributesUpdated}
        />
      )}
      <ConfirmDialog
        id="confirm-remap-class"
        isOpen={!!sourceClassConfirm}
        confirmAction={() =>
          sourceClassConfirm && handleClassChangeDialogConfirm(sourceClassConfirm)
        }
        closeAction={() => setSourceClassConfirm(undefined)}
        text={intl.formatMessage(
          {
            id: 'schemaMapper.dataStoreClasses.confirmRemapClass.text',
            defaultMessage:
              'The selected class {name} is already mapped. Confirming this selection will override its previous mappings.',
          },
          { name: sourceClassConfirm }
        )}
      />
      <ConfirmDialog
        id="confirm-clear-class"
        isOpen={resetDialogOpen}
        confirmAction={handleResetDialogConfirm}
        closeAction={() => setResetDialogOpen(false)}
        text={intl.formatMessage(
          {
            id: 'schemaMapper.dataStoreClasses.confirmClearClass.text',
            defaultMessage:
              'Clear the selected class for {name}? This will remove all associated mappings.',
          },
          { name: leftClass.name }
        )}
      />
    </>
  );
};

export default DataStoreClass;
