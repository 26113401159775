import { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { NavigationPromptContext } from './context';
import RouterNavigationHandler from './RouterNavigationHandler';
import ConfirmNavigationDialog from './ConfirmNavigationDialog';

const NavigationPrompt: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();

  const [blockNavigation, setBlockNavigation] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const onConfirmCallbackRef = useRef<() => void>();

  useEffect(() => {
    clearNavigationBlock();
  }, [pathname]);

  const raiseNavigationBlock = () => {
    setBlockNavigation(true);
  };

  const clearNavigationBlock = () => {
    setBlockNavigation(false);
    setShowPrompt(false);
    onConfirmCallbackRef.current = undefined;
  };

  const handleNavigationPrompt = useCallback(
    (onConfirm: () => void) => {
      if (blockNavigation) {
        setShowPrompt(true);
        onConfirmCallbackRef.current = onConfirm;
      } else {
        onConfirm();
      }
    },
    [blockNavigation]
  );

  const confirmNavigation = () => {
    onConfirmCallbackRef.current && onConfirmCallbackRef.current();
    clearNavigationBlock();
  };

  const cancelNavigation = () => {
    setShowPrompt(false);
  };

  return (
    <NavigationPromptContext.Provider
      value={{
        raiseNavigationBlock,
        clearNavigationBlock,
        handleNavigationPrompt,
      }}
    >
      <ConfirmNavigationDialog
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
        isOpen={showPrompt}
      />
      <RouterNavigationHandler blockNavigation={blockNavigation} />
      {children}
    </NavigationPromptContext.Provider>
  );
};

export default NavigationPrompt;
