import React, { FC } from 'react';
import { MenuItem } from '@mui/material';

import { booleanToString, stringToBoolean } from '../../util';
import { intl } from '../../Internationalization';

import ValidatedTextField, { ValidatedTextFieldProps } from './ValidatedTextField';

export interface ValidatedBooleanSelectProps
  extends Omit<ValidatedTextFieldProps, 'children' | 'value' | 'onChange' | 'select'> {
  name: string;
  value?: boolean;
  onChange: (name: React.ChangeEvent<HTMLInputElement>, value?: boolean) => void;

  includeUnset?: boolean;
  unsetLabel?: string;
  trueLabel?: string;
  falseLabel?: string;
}

const ValidatedBooleanSelect: FC<ValidatedBooleanSelectProps> = ({
  includeUnset = false,
  unsetLabel = '',
  trueLabel = intl.formatMessage({
    id: 'components.fields.validatedBooleanSelect.trueLabel',
    defaultMessage: 'Yes',
  }),
  falseLabel = intl.formatMessage({
    id: 'components.fields.validatedBooleanSelect.falseLabel',
    defaultMessage: 'No',
  }),
  value,
  onChange,
  ...rest
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event, stringToBoolean(event.target.value));
  };

  return (
    <ValidatedTextField select value={booleanToString(value)} onChange={handleChange} {...rest}>
      {includeUnset && <MenuItem value="">{unsetLabel}</MenuItem>}
      <MenuItem value="true">{trueLabel}</MenuItem>
      <MenuItem value="false">{falseLabel}</MenuItem>
    </ValidatedTextField>
  );
};

export default ValidatedBooleanSelect;
