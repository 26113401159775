import { FC } from 'react';
import { Link, Path } from 'react-router-dom';

import { Tab, TabProps } from '@mui/material';

interface RouterTabProps extends Omit<TabProps<typeof Link>, 'to' | 'component'> {
  resolveTabRoute: (value: string) => Path;
  visible?: boolean;
}

const RouterTab: FC<RouterTabProps> = ({ value, resolveTabRoute, visible = true, sx, ...rest }) => (
  <Tab
    value={value}
    to={resolveTabRoute(value)}
    component={Link}
    sx={[!visible && { display: 'none', visibility: 'hidden' }, ...(Array.isArray(sx) ? sx : [sx])]}
    {...rest}
  />
);

export default RouterTab;
