import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Divider, Step, StepLabel, Stepper } from '@mui/material';

import { SavedMappingDetail } from '../../../../../types';

import SelectMapping from './SelectMapping';
import AssignDataStores from './AssignDataStores';
import CopyMapping from './CopyMapping';

interface CopySavedMappingProps {
  onClose: () => void;
  disableNext: boolean;
}

const CopySavedMapping: FC<CopySavedMappingProps> = ({ onClose, disableNext }) => {
  const [sourceSavedMapping, setSourceSavedMapping] = useState<SavedMappingDetail>();
  const [dataStoreMappings, setDataStoreMappings] = useState<Record<string, string>>();

  const activeStep = () => {
    if (!sourceSavedMapping) {
      return 0;
    }
    if (!dataStoreMappings) {
      return 1;
    }
    return 2;
  };

  const renderStep = () => {
    if (!sourceSavedMapping) {
      return (
        <SelectMapping
          disableNext={disableNext}
          onSavedMappingSelected={setSourceSavedMapping}
          onCancel={onClose}
        />
      );
    }
    if (!dataStoreMappings) {
      return (
        <AssignDataStores
          sourceSavedMapping={sourceSavedMapping}
          onSelectDataStoreMappings={setDataStoreMappings}
          onBack={() => setSourceSavedMapping(undefined)}
          onCancel={onClose}
        />
      );
    }
    return (
      <CopyMapping
        sourceSavedMapping={sourceSavedMapping}
        dataStoreMappings={dataStoreMappings}
        onBack={() => setDataStoreMappings(undefined)}
        onCancel={onClose}
      />
    );
  };

  return (
    <>
      <Divider />
      <Stepper sx={{ p: 3 }} activeStep={activeStep()}>
        <Step>
          <StepLabel>
            <FormattedMessage
              id="myAssignment.savedMapping.copy.selectMapping"
              defaultMessage="Select Mapping"
            />
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <FormattedMessage
              id="myAssignment.savedMapping.copy.assignDataSets"
              defaultMessage="Assign Data Sets"
            />
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <FormattedMessage
              id="myAssignment.savedMapping.copy.copyMapping"
              defaultMessage="Copy Mapping"
            />
          </StepLabel>
        </Step>
      </Stepper>
      {renderStep()}
    </>
  );
};

export default CopySavedMapping;
